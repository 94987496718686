/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useMemo, ChangeEvent } from 'react';
import {
  faChevronDown,
  faFileExport,
  // faPlus,
  faTrash,
  faUndo,
  faFilter,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import KategoriLayananTable, {
  kategoriLayananTableColumns
} from 'components/tables/kategoriLayananTable';
import { daftarKategoriLayananBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Dropdown } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import DownloadTableModal from 'components/modals/DownloadTableModal';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
// import DeleteServiceModal from 'components/modals/DeleteServiceModal';
// import TambahKategoriLayananModal from 'components/modals/TambahKategoriLayananModal';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { PositionResponse } from 'api/response/PositionResponse';
import {
  useGetAllPositionsQuery,
  useDeletePositionMutation
} from 'api/positionApi';
import TambahKategoriLayananModal from 'components/modals/TambahKategoriLayananModal';
import {
  useGetAllCategoryServiceQuery,
  useDeleteCategoryServiceMutation
} from 'api/categoryServiceApi';
import { KategoriLayanan } from 'api/response/ServiceResponse';
import { debounce } from 'lodash';
import useRefreshHandler from 'hooks/useRefreshHandler';

const DaftarKategoriLayanan = () => {
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number>(0);
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTambahKategoriLayananModal, setShowTambahKategoriLayananModal] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [previousPage, setPreviousPage] = useState(1);

  // Fetch data menggunakan RTK Query
  const {
    data: categoryData,
    isLoading,
    isFetching,
    refetch
  } = useGetAllCategoryServiceQuery({
    page,
    limit: pageSize,
    orderBy: 'createdDate',
    direction: 'DESC',
    name: searchQuery
  });

  // Update table data
  const table = useAdvanceTable<KategoriLayanan>({
    data: categoryData?.data?.result || [],
    columns: kategoriLayananTableColumns,
    pageSize,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      pagination: {
        pageSize,
        pageIndex: page - 1
      }
    }
  });

  const { isRefreshing, handleRefresh } = useRefreshHandler(refetch);
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    table.setPageIndex(newPage - 1);
  };

  // Tambahkan debounced search
  const debouncedSearch = useMemo(
    () =>
      debounce((searchValue: string) => {
        if (searchValue) {
          setPreviousPage(page);
          setPage(1);
        } else {
          setPage(previousPage);
        }
        setSearchQuery(searchValue);
        refetch();
      }, 800),
    [refetch, page, previousPage]
  );

  // Cleanup debounce pada unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  const handleDownload = (format: string) => {
    const doc = new jsPDF(format === 'portrait-pdf' ? 'p' : 'l', 'mm', 'a4');

    // Ambil data dari tabel
    const tableData = table.getRowModel().rows.map(row => {
      const cells = row.getAllCells();
      return cells.map(cell => cell.getValue());
    });

    // Ambil header kolom
    const headers = table
      .getAllColumns()
      .map(column => column.columnDef.header);

    // Generate PDF menggunakan autoTable
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (doc as any).autoTable({
      head: [headers],
      body: tableData,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255 }
    });

    // Download PDF
    doc.save('daftar-kategoriLayanan.pdf');
    toast.success('Berhasil mengunduh tabel');
  };

  // const { refetch } = useGetAllPositionsQuery({
  //   page,
  //   limit: pageSize,
  //   orderBy: 'id',
  //   direction: 'ASC'
  // });

  useEffect(() => {
    const selectedRowsCount = Object.keys(table.getState().rowSelection).length;
    setSelectedRows(selectedRowsCount);
  }, [table.getState().rowSelection]);

  // First, import the delete mutation
  // const [deleteService, { isLoading: isDeleting }] = useDeleteServiceMutation();

  const [deletePosition, { isLoading: isDeletingPosition }] =
    useDeletePositionMutation();

  const [deleteCategory, { isLoading: isDeleting }] =
    useDeleteCategoryServiceMutation();

  const handleConfirmDelete = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const selectedIds = selectedRows.map(row => row.original.id);

    // Cek apakah ada kategori yang memiliki layanan
    const hasServices = selectedRows.some(
      row => row.original.totalServices > 0
    );

    showCustomAlert({
      icon: 'warning',
      label: 'Kategori akan dihapus!',
      deskripsi: hasServices
        ? 'Terdapat kategori yang sudah memiliki layanan. Apakah Anda yakin untuk menghapus kategori ini?'
        : 'Apakah Anda yakin untuk menghapus kategori ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await deleteCategory({ id: selectedIds }).unwrap();

          // Reset selection
          table.resetRowSelection();
          setSelectedRows(0);
          setShowDeleteModal(false);

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: `${selectedIds.length} kategori layanan berhasil dihapus`,
            buttonType: 'ok'
          });

          // Refresh data
          refetch();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          const errorMessage =
            error.data?.message || 'Gagal menghapus kategori layanan';
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: errorMessage,
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleDeleteClick = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    if (selectedRows.length === 0) {
      showCustomAlert({
        icon: 'warning',
        label: 'Pilih Kategori',
        deskripsi: 'Silakan pilih kategori layanan yang akan dihapus',
        buttonType: 'ok'
      });
      return;
    }
    handleConfirmDelete();
  };

  const handleShowTambahKategoriLayananModal = () => {
    setShowTambahKategoriLayananModal(true);
  };

  const handleCloseTambahKategoriLayananModal = () => {
    setShowTambahKategoriLayananModal(false);
  };
  const [lastToastTime, setLastToastTime] = useState(0);
  useEffect(() => {
    // Check for empty data
    if (categoryData && categoryData.data.total === 0) {
      const currentTime = new Date().getTime();

      // If more than 3 seconds have passed since the last toast
      if (!lastToastTime || currentTime - lastToastTime >= 3000) {
        toast.info('Data Tidak Ditemukan');

        // Update the last toast time
        setLastToastTime(currentTime);
      }
    }
  }, [categoryData, lastToastTime]);

  return (
    <div>
      <PageBreadcrumb items={daftarKategoriLayananBreadcrumbItems} />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="mb-9">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="mb-0">Kategori Layanan</h2>
        </div>

        <div className="d-flex justify-content-between align-items-center gap-3 mb-4">
          <div className="d-flex gap-2 align-items-center">
            <SearchBox
              placeholder="Cari kategori"
              style={{ maxWidth: '300px' }}
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
          <div className="d-flex align-items-center gap-3">
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={() => setShowDownloadModal(true)}
            >
              <FontAwesomeIcon icon={faFileExport} className="me-2" />
              Unduh tabel
            </Button>
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={handleRefresh}
              disabled={isRefreshing || isFetching}
            >
              <FontAwesomeIcon
                icon={faUndo}
                className={`me-2 ${
                  isRefreshing || isFetching ? 'fa-spin' : ''
                }`}
              />
              {isRefreshing || isFetching ? 'Memperbarui...' : 'Refresh'}
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                className="text-secondary p-0 dropdown-caret-none"
              >
                Tindakan lainnya
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item>Export Selection</Dropdown.Item>
                <Dropdown.Item>Bulk Edit</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant="outline-danger"
              onClick={handleDeleteClick}
              disabled={selectedRows === 0 || isDeleting}
              size="sm"
            >
              <FontAwesomeIcon icon={faTrash} className="me-2" />
              {isDeleting ? 'Menghapus...' : `Hapus Kategori (${selectedRows})`}
            </Button>
            {/* <DeleteServiceModal
              show={showDeleteModal}
              onHide={() => setShowDeleteModal(false)}
              selectedServices={table.getSelectedRowModel().rows.map(row => ({
                name: row.original.names?.fullName || 'Nama tidak tersedia',
                id: row.original.id
              }))}
              onDelete={handleConfirmDelete}
              isDeleting={isDeleting}
            /> */}
            <Button
              variant="primary"
              size="sm"
              onClick={handleShowTambahKategoriLayananModal}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Tambah Kategori
            </Button>
          </div>
        </div>

        <AdvanceTableProvider {...table}>
          <div style={{ width: '95%' }} className="mx-auto">
            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
              {isLoading ? (
                <div className="text-center py-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <KategoriLayananTable
                  onPageChange={handlePageChange}
                  currentPage={page}
                  totalPages={categoryData?.data?.totalPages || 1}
                  categoryData={{
                    totalRecords: categoryData?.data?.total || 0
                  }}
                />
              )}
            </div>
          </div>
        </AdvanceTableProvider>
      </div>

      <DownloadTableModal
        show={showDownloadModal}
        onHide={() => setShowDownloadModal(false)}
        onDownload={handleDownload}
        title="Unduh Tabel"
      />

      <TambahKategoriLayananModal
        show={showTambahKategoriLayananModal}
        handleClose={handleCloseTambahKategoriLayananModal}
        onSuccess={() => {
          handleCloseTambahKategoriLayananModal();
          handleRefresh();
        }}
      />
    </div>
  );
};

export default DaftarKategoriLayanan;
