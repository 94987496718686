import React, { useState } from 'react';
import { Card, Col, Row, Table, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import {
  useGetServiceByIdQuery,
  useUpdateClinicalServiceEmployeeMutation,
  UpdateEmployeeRequest
} from 'api/serviceApi';
import PilihPemilikJabatanModal from 'components/modals/PilihPemilikJabatanModal';
import { showCustomAlert } from 'helpers/showCustomAlert';

interface StaffRow {
  id: string;
  name: string;
  position: string;
}

const ServiceEmployeeCard = () => {
  const { id } = useParams();
  const { data: serviceData, refetch } = useGetServiceByIdQuery(id || '');
  const [isEditing, setIsEditing] = useState(false);
  const [showPilihPegawai, setShowPilihPegawai] = useState(false);

  const [staffSettings, setStaffSettings] = useState({
    staffPerBooking: serviceData?.data?.totalEmployee?.toString() || '1'
  });

  const [staffRows, setStaffRows] = useState<StaffRow[]>(
    serviceData?.data?.clinicalServiceEmployee.map(emp => ({
      id: emp.employee.id,
      name: emp.employee.fullName,
      position: emp.employee.jobTitleName || '-'
    })) || []
  );

  const [updateEmployee] = useUpdateClinicalServiceEmployeeMutation();

  const handleEditClick = () => setIsEditing(true);

  const hasChanges = () => {
    if (!serviceData?.data) return false;

    const originalSettings = {
      staffPerBooking: serviceData.data.totalEmployee?.toString() || '1'
    };

    const originalStaff =
      serviceData.data.clinicalServiceEmployee?.map(emp => ({
        id: emp.employee.id,
        name: emp.employee.fullName,
        position: emp.employee.jobTitleName || '-'
      })) || [];

    if (staffSettings.staffPerBooking !== originalSettings.staffPerBooking) {
      return true;
    }

    if (staffRows.length !== originalStaff.length) return true;

    return staffRows.some((staff, index) => {
      const original = originalStaff[index];
      return staff.id !== original.id || staff.position !== original.position;
    });
  };

  const handleCancel = () => {
    if (!hasChanges()) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Perubahan data tidak akan disimpan',
      deskripsi: 'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        setIsEditing(false);
        setStaffSettings({
          staffPerBooking: serviceData?.data?.totalEmployee?.toString() || '1'
        });
        setStaffRows(
          serviceData?.data?.clinicalServiceEmployee.map(emp => ({
            id: emp.employee.id,
            name: emp.employee.fullName,
            position: emp.employee.jobTitleName || '-'
          })) || []
        );
      }
    });
  };

  const handleSave = async () => {
    showCustomAlert({
      icon: 'question',
      label: 'Perubahan data akan disimpan',
      deskripsi:
        'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const requestData: UpdateEmployeeRequest = {
            totalEmployee: parseInt(staffSettings.staffPerBooking),
            additionalCostType: 'Fixed',
            employee: staffRows.map(staff => ({
              employeeId: staff.id,
              additionalCost: '0'
            }))
          };

          const response = await updateEmployee({
            id: id || '',
            data: requestData
          }).unwrap();

          if (response.code === 200) {
            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Data pegawai berhasil diperbarui',
              buttonType: 'ok',
              onConfirm: () => {
                setIsEditing(false);
                refetch();
              }
            });
          }
        } catch (error) {
          console.error('Error updating employee:', error);
          showCustomAlert({
            icon: 'error',
            label: 'Gagal menyimpan data',
            deskripsi: 'Mohon perbaiki masalah yang ditampilkan dan coba lagi.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleRemoveStaff = (id: string) => {
    setStaffRows(prev => prev.filter(row => row.id !== id));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePilihPegawai = (selectedEmployees: any[]) => {
    const updatedStaffRows = selectedEmployees.map(employee => ({
      id: employee.id,
      name: employee.fullName,
      position: employee.jobTitle?.name || '-'
    }));

    setStaffRows(updatedStaffRows);
    setShowPilihPegawai(false);
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="mb-0">
            Pegawai
            {!isEditing && (
              <Button variant="ghost" size="sm" onClick={handleEditClick}>
                <FontAwesomeIcon icon={faPencil} className="ms-2 fs-9" />
              </Button>
            )}
          </h3>
        </div>

        {!isEditing ? (
          <>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column">
                <span className="fw-bold text-muted">Pegawai per Booking</span>
                <span className="fw-normal">
                  {serviceData?.data?.totalEmployee || '-'}
                </span>
              </Col>
            </Row>

            <Table responsive className="fs-9">
              <thead>
                <tr>
                  <th className="ps-0">NAMA PEGAWAI</th>
                  <th>JABATAN</th>
                </tr>
              </thead>
              <tbody>
                {serviceData?.data?.clinicalServiceEmployee.length === 0 ? (
                  <tr>
                    <td colSpan={2} className="text-center">
                      Tidak ada data pegawai
                    </td>
                  </tr>
                ) : (
                  serviceData?.data?.clinicalServiceEmployee.map(employee => (
                    <tr key={employee.id}>
                      <td>{employee.employee.fullName}</td>
                      <td>{employee.employee.jobTitleName || '-'}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <Row className="mb-4">
              <Col md={12}>
                <Form.Group>
                  <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                    Pegawai per Booking
                  </Form.Label>
                  <Form.Select
                    value={staffSettings.staffPerBooking}
                    onChange={e =>
                      setStaffSettings(prev => ({
                        ...prev,
                        staffPerBooking: e.target.value
                      }))
                    }
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Form.Label className="text-dark mb-2 p-0 fs-8 text-capitalize">
              Pegawai
            </Form.Label>

            <div className="table-responsive mb-3">
              <table className="table">
                <thead>
                  <tr className="fs-9">
                    <th className="ps-0 text-muted">NAMA PEGAWAI</th>
                    <th className="text-muted">JABATAN</th>
                    <th style={{ width: '40px' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {staffRows.length === 0 ? (
                    <tr>
                      <td colSpan={3} className="text-center text-muted fs-9">
                        Belum ada pegawai
                      </td>
                    </tr>
                  ) : (
                    staffRows.map(staff => (
                      <tr key={staff.id} className="fs-9 align-middle">
                        <td>{staff.name}</td>
                        <td>{staff.position}</td>
                        <td className="text-end">
                          <Button
                            variant="link"
                            className="text-dark p-0"
                            onClick={() => handleRemoveStaff(staff.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            <Button
              variant="outline-primary"
              className="w-100 rounded-3"
              onClick={() => setShowPilihPegawai(true)}
            >
              + Tambah Pegawai
            </Button>

            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button onClick={handleSave}>Simpan</Button>
            </div>
          </>
        )}
      </Card.Body>

      <PilihPemilikJabatanModal
        show={showPilihPegawai}
        onHide={() => setShowPilihPegawai(false)}
        onSelect={handlePilihPegawai}
        selectedIds={staffRows.map(staff => staff.id)}
        onMount={() => {}}
        style={{ zIndex: 1060 }}
        showPositionAlert={false}
        showConfirmationAlert={false}
      />
    </Card>
  );
};

export default ServiceEmployeeCard;
