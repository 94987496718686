import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Badge, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useUpdatePolicyBasicInfoMutation } from 'api/serviceApi';

interface InformasiDasarKebijakanCardProps {
  data: {
    id: string;
    name: string;
    status: boolean;
    description: string;
    isRefundAllowed: boolean;
    startDate: string;
    endDate: string;
    createdDate: string;
    policyType?: 'Layanan' | 'Subscription';
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdate: (data: any) => void;
}

const InformasiDasarKebijakanCard: React.FC<
  InformasiDasarKebijakanCardProps
> = ({ data, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);

  // Inisialisasi formData dengan nilai default
  const [formData, setFormData] = useState({
    status: false,
    name: '',
    description: '',
    isRefundAllowed: false,
    startDate: '',
    createdDate: '',
    endDate: '',
    policyType: ''
  });

  const [updatePolicyBasicInfo] = useUpdatePolicyBasicInfoMutation();

  // Tambahkan helper function untuk memformat tanggal
  const formatDateForInput = (dateString: string) => {
    if (!dateString) return '';
    try {
      // Parse tanggal dengan mempertimbangkan timezone lokal
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    } catch {
      return '';
    }
  };

  // Update useEffect untuk memformat tanggal dengan benar
  useEffect(() => {
    if (data) {
      setFormData({
        status: data.status,
        name: data.name,
        description: data.description,
        isRefundAllowed: data.isRefundAllowed,
        startDate: formatDateForInput(data.startDate),
        createdDate: formatDateForInput(data.createdDate),
        endDate: formatDateForInput(data.endDate),
        policyType: data.policyType || 'Layanan'
      });
    }
  }, [data]);

  const hasChanges = () => {
    // Format data untuk perbandingan yang konsisten
    const formattedFormData = {
      status: formData.status,
      name: formData.name,
      description: formData.description,
      isRefundAllowed: formData.isRefundAllowed,
      startDate: formatDateForInput(formData.startDate),
      createdDate: formatDateForInput(formData.createdDate),
      endDate: formatDateForInput(formData.endDate),
      policyType: formData.policyType
    };

    const formattedData = {
      status: data.status,
      name: data.name,
      description: data.description,
      isRefundAllowed: data.isRefundAllowed,
      startDate: formatDateForInput(data.startDate),
      createdDate: formatDateForInput(data.createdDate),
      endDate: formatDateForInput(data.endDate),
      policyType: data.policyType || 'Layanan'
    };

    // Bandingkan setiap field
    return (
      formattedFormData.status !== formattedData.status ||
      formattedFormData.name !== formattedData.name ||
      formattedFormData.description !== formattedData.description ||
      formattedFormData.isRefundAllowed !== formattedData.isRefundAllowed ||
      formattedFormData.startDate !== formattedData.startDate ||
      formattedFormData.createdDate !== formattedData.createdDate ||
      formattedFormData.endDate !== formattedData.endDate ||
      formattedFormData.policyType !== formattedData.policyType
    );
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]:
        type === 'checkbox'
          ? (e.target as HTMLInputElement).checked
          : name === 'status'
          ? value === 'true' // Konversi string ke boolean untuk status
          : value
    }));
  };

  const handleCancel = () => {
    if (!hasChanges()) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Perubahan data tidak akan disimpan',
      deskripsi: 'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        setFormData({
          status: data.status,
          name: data.name,
          description: data.description,
          isRefundAllowed: data.isRefundAllowed,
          startDate: formatDateForInput(data.startDate),
          createdDate: formatDateForInput(data.createdDate),
          endDate: formatDateForInput(data.endDate),
          policyType: data.policyType || 'Layanan'
        });
        setIsEditing(false);
      }
    });
  };

  const handleSave = () => {
    showCustomAlert({
      icon: 'question',
      label: 'Perubahan data akan disimpan',
      deskripsi:
        'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const requestData = {
            name: formData.name,
            status: formData.status,
            description: formData.description,
            isRefundAllowed: formData.isRefundAllowed,
            startDate: formData.startDate,
            endDate: formData.endDate || null,
            createdDate: formData.createdDate,
            policyType: formData.policyType
          };

          await updatePolicyBasicInfo({
            id: data.id,
            data: requestData
          }).unwrap();

          if (onUpdate) {
            await onUpdate(formData);
          }

          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Data berhasil diperbarui',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          // Cek apakah error adalah duplikat nama kebijakan
          if (error?.data?.message?.includes('Duplicate entry for name')) {
            showCustomAlert({
              icon: 'error',
              label: 'Terdapat nama kebijakan yang sama',
              deskripsi: 'Mohon ubah nama kebijakan yang akan Anda tambahkan',
              buttonType: 'ok'
            });
          } else {
            showCustomAlert({
              icon: 'error',
              label: 'Error',
              deskripsi: 'Gagal menyimpan informasi dasar kebijakan',
              buttonType: 'ok'
            });
          }
        }
      }
    });
  };

  const getStatusBadge = (status: boolean) => {
    return (
      <Badge bg={status ? 'success' : 'danger'} className="fs-10">
        {status ? 'Aktif' : 'Tidak Aktif'}
      </Badge>
    );
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '-';

    const months = [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember'
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  if (isEditing) {
    return (
      <Card>
        <Card.Body>
          <Row className="mb-4">
            <Col xs={12} className="d-flex align-items-center">
              <h3>Informasi Dasar</h3>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
              Tipe Kebijakan
            </Form.Label>
            <div className="d-flex gap-3">
              <Form.Check
                type="radio"
                id="layanan"
                name="policyType"
                label="Layanan"
                value="Layanan"
                checked={formData.policyType === 'Layanan'}
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                id="subscription"
                name="policyType"
                label="Subscription"
                value="Subscription"
                checked={formData.policyType === 'Subscription'}
                onChange={handleChange}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
              Nama Kebijakan <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Masukkan nama kebijakan"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
              Deskripsi Kebijakan
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Masukan deskripsi kebijakan"
            />
          </Form.Group>

          {/* Tampilkan Kebijakan Pemesanan hanya jika tipe adalah Layanan */}
          {(formData.policyType === 'Layanan' || !formData.policyType) && (
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                Kebijakan Pemesanan
              </Form.Label>
              <div className="d-flex flex-column">
                <Form.Check
                  type="checkbox"
                  id="isRefundAllowed"
                  label="Izinkan pengembalian dana"
                  name="isRefundAllowed"
                  checked={formData.isRefundAllowed}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
              Status <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              name="status"
              value={formData.status.toString()}
              onChange={handleChange}
            >
              <option value="true">Aktif</option>
              <option value="false">Tidak Aktif</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
              Tanggal Dibuat <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="date"
              name="createdDate"
              value={formData.createdDate}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
              Tanggal Mulai Berlaku <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
              Tanggal Berakhir
            </Form.Label>
            <Form.Control
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
            />
          </Form.Group>

          <div className="d-flex justify-content-end gap-2 pt-4 mt-4 border-top">
            <Button
              variant="link"
              className="text-danger"
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Simpan
            </Button>
          </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title as="h3" className="mb-4">
          Informasi Dasar{' '}
          <FontAwesomeIcon
            icon={faPencilAlt}
            className="fs-8 ms-2"
            style={{ cursor: 'pointer' }}
            onClick={() => setIsEditing(true)}
          />
        </Card.Title>

        <Row className="mb-3 d-flex flex-wrap">
          <Col sm={12} xs={12} md={6} className="flex-wrap">
            <span className="text-muted d-block fw-bold">Tipe Kebijakan</span>
            <span>{data.policyType || 'Layanan'}</span>
          </Col>
          <Col sm={12} xs={12} md={6} className="flex-wrap">
            <span className="text-muted d-block fw-bold">Status</span>
            {getStatusBadge(data.status)}
          </Col>
        </Row>

        <div className="mb-3">
          <span className="text-muted d-block fw-bold">Nama Kebijakan</span>
          <span>{data.name}</span>
        </div>

        <div className="mb-3">
          <span className="text-muted d-block fw-bold">
            Deskripsi Kebijakan
          </span>
          <span>{data.description}</span>
        </div>

        {/* Tampilkan Kebijakan Pemesanan hanya jika tipe adalah Layanan */}
        {(data.policyType === 'Layanan' || !data.policyType) && (
          <div className="mb-3">
            <span className="text-muted d-block fw-bold">
              Izinkan Pengembalian Dana
            </span>
            <span>{data.isRefundAllowed ? 'Ya' : 'Tidak'}</span>
          </div>
        )}

        <div className="mb-3">
          <span className="text-muted d-block fw-bold">Dibuat Pada</span>
          <span>{formatDate(data.createdDate)}</span>
        </div>

        <div className="mb-3">
          <span className="text-muted d-block fw-bold">
            Terakhir Diperbarui Pada
          </span>
          <span>{formatDate(data.startDate)}</span>
        </div>

        <div className="mb-3">
          <span className="text-muted d-block fw-bold">Berakhir Pada</span>
          <span>{formatDate(data.endDate)}</span>
        </div>
      </Card.Body>
    </Card>
  );
};

export default InformasiDasarKebijakanCard;
