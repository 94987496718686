/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useMemo, ChangeEvent } from 'react';
import {
  // faChevronDown,
  faFileExport,
  // faPlus,
  faTrash,
  faUndo,
  faFilter,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import LayananTable, {
  layananTableColumns
} from 'components/tables/LayananTable';
import { daftarLayananBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Dropdown } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import DownloadTableModal from 'components/modals/DownloadTableModal';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
// import { showCustomAlert } from 'helpers/showCustomAlert';
// import { ServiceResponse } from 'api/response/ServiceResponse';
// import { useDeleteServiceMutation, useGetServiceQuery } from 'api/serviceApi';
import TambahLayananModal from 'components/modals/TambahLayananModal';
import { showCustomAlert } from 'helpers/showCustomAlert';
import CheckboxDropdownItem from 'components/common/CheckboxDropdownItem';
import { debounce } from 'lodash';
import { useGetAllPositionsQuery } from 'api/positionApi';
import { useGetServiceQuery, useDeleteServiceMutation } from 'api/serviceApi';
import { ClinicalServiceResponse } from 'api/response/ServiceResponse';
import DeleteCustomerModal from 'components/modals/DeleteCustomerModal';
import { useGetAllCategoryServiceQuery } from 'api/categoryServiceApi';
import useRefreshHandler from 'hooks/useRefreshHandler';

// Tambahkan opsi harga
const priceOptions = [
  { value: 'Fixed', label: 'Fixed' },
  { value: 'Rated', label: 'Rated' }
];

const DaftarLayanan = () => {
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const [selectedRows, setSelectedRows] = useState<number>(0);
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTambahLayananModal, setShowTambahLayananModal] = useState(false);
  const [selectedServices, setSelectedServices] = useState<{ name: string }[]>(
    []
  );

  const [activeFilters, setActiveFilters] = useState<{
    location: string[];
    gender: string[];
    isActive: string[];
    category: string[];
  }>({
    location: [],
    gender: [],
    isActive: [],
    category: []
  });

  const [tempFilters, setTempFilters] = useState<{
    location: string[];
    gender: string[];
    isActive: string[];
    category: string[];
    price: string[]; // Tambahkan state untuk harga
  }>({
    location: [],
    gender: [],
    isActive: [],
    category: [],
    price: [] // Inisialisasi harga
  });

  const [dateRange, setDateRange] = useState({
    startAt: '',
    endAt: ''
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [activePeriod, setActivePeriod] = useState<string>('all');
  const [timeFilterMode, setTimeFilterMode] = useState<
    'period' | 'range' | null
  >(null);

  // Tambahkan state untuk menyimpan halaman sebelumnya
  const [previousPage, setPreviousPage] = useState(1);

  // Gunakan query untuk mengambil data layanan dengan filter
  const {
    data: serviceData,
    refetch,
    isFetching
  } = useGetServiceQuery({
    page: Math.max(1, page),
    limit: pageSize,
    orderBy: 'createdDate',
    direction: 'DESC',
    gender: activeFilters.gender,
    location: activeFilters.location,
    isActive: activeFilters.isActive,
    startAt: dateRange.startAt,
    endAt: dateRange.endAt,
    name: searchQuery
  });

  const { isRefreshing, handleRefresh } = useRefreshHandler(refetch);

  // Fetch data jabatan
  const { data: positionData } = useGetAllPositionsQuery({
    page: 1,
    limit: 1000,
    orderBy: 'name',
    direction: 'ASC',
    name: searchQuery || ''
  });

  // Fetch data kategori
  const { data: categoryData } = useGetAllCategoryServiceQuery({
    page: 1,
    limit: 10,
    orderBy: 'createdDate',
    direction: 'DESC'
  });

  const filterOptions = {
    gender: [
      { value: 'M', label: 'Laki-laki' },
      { value: 'F', label: 'Perempuan' }
    ],
    isActive: [
      { value: 'true', label: 'Aktif' },
      { value: 'false', label: 'Tidak Aktif' }
    ],
    jobTitle:
      positionData?.data?.result?.map(position => ({
        value: position.id,
        label: position.name
      })) || [],
    category:
      categoryData?.data?.result?.map(category => ({
        value: category.id,
        label: category.name
      })) || [],
    price: priceOptions // Tambahkan opsi harga
  };

  // Modifikasi debounced search
  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchValue: string) => {
        if (searchValue) {
          setPreviousPage(page);
          await setPage(1); // Tunggu sampai page diupdate
          setSearchQuery(searchValue);
          refetch();
        } else {
          setSearchQuery(searchValue);
          await setPage(previousPage); // Tunggu sampai page diupdate
          refetch();
        }
      }, 800),
    [refetch, page, previousPage]
  );

  // Tambahkan useEffect untuk memantau perubahan page
  useEffect(() => {
    if (page > 0) {
      refetch();
    }
  }, [page, refetch]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  // Fungsi untuk handle filter checkbox
  const handleCheckboxChange = (
    type: 'location' | 'gender' | 'isActive' | 'category' | 'price',
    value: string,
    checked: boolean
  ) => {
    setTempFilters(prev => ({
      ...prev,
      [type]: checked
        ? [...prev[type], value]
        : prev[type].filter(v => v !== value)
    }));
  };

  // Fungsi untuk handle period filter
  const handlePeriodClick = (period: string) => {
    setTimeFilterMode('period');
    setActivePeriod(period);
    const today = new Date();
    const startDate = new Date();

    switch (period) {
      case 'today':
        setDateRange({
          startAt: today.toISOString().split('T')[0],
          endAt: today.toISOString().split('T')[0]
        });
        break;
      case 'week':
        startDate.setDate(today.getDate() - today.getDay());
        setDateRange({
          startAt: startDate.toISOString().split('T')[0],
          endAt: today.toISOString().split('T')[0]
        });
        break;
      case 'month':
        startDate.setDate(1);
        setDateRange({
          startAt: startDate.toISOString().split('T')[0],
          endAt: today.toISOString().split('T')[0]
        });
        break;
      case 'year':
        startDate.setMonth(0, 1);
        setDateRange({
          startAt: startDate.toISOString().split('T')[0],
          endAt: today.toISOString().split('T')[0]
        });
        break;
    }
  };

  // Fungsi untuk handle date range
  const handleDateChange = (type: 'startAt' | 'endAt', value: string) => {
    setTimeFilterMode('range');
    setActivePeriod('');
    setDateRange(prev => ({
      ...prev,
      [type]: value
    }));
  };

  // Fungsi untuk apply filter
  const handleApplyFilter = () => {
    setActiveFilters(tempFilters);
    refetch();
  };

  // Fungsi untuk reset filter
  const handleResetFilter = () => {
    setTempFilters({
      location: [],
      gender: [],
      isActive: [],
      category: [],
      price: []
    });
    setActiveFilters({
      location: [],
      gender: [],
      isActive: [],
      category: []
    });
    setTimeFilterMode(null);
    setActivePeriod('all');
    setDateRange({
      startAt: '',
      endAt: ''
    });
    setSearchQuery('');
    refetch();
  };

  // Fungsi untuk cek apakah ada filter aktif
  const isFiltering = () => {
    return (
      tempFilters.location.length > 0 ||
      tempFilters.gender.length > 0 ||
      tempFilters.isActive.length > 0 ||
      tempFilters.category.length > 0 ||
      tempFilters.price.length > 0 ||
      dateRange.startAt !== '' ||
      dateRange.endAt !== '' ||
      searchQuery !== ''
    );
  };

  // console.log('Service Data:', serviceData);
  // console.log('Is Loading:', isLoading);
  // console.log('Is Error:', isError);
  // console.log('Error:', error);

  const table = useAdvanceTable<ClinicalServiceResponse>({
    data: serviceData?.data.result || [],
    columns: layananTableColumns,
    pageSize,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      pagination: {
        pageSize,
        pageIndex: page - 1
      }
    }
  });

  const handlePageChange = (newPage: number) => {
    const validPage = Math.max(1, newPage);
    setPage(validPage);
    table.setPageIndex(validPage - 1);
  };

  const handleShowTambahLayananModal = () => {
    setShowTambahLayananModal(true);
  };

  const handleCloseTambahLayananModal = () => {
    setShowTambahLayananModal(false);
    setPage(1);
    setTimeout(() => {
      refetch();
    }, 100);
  };

  const handleDownload = (format: string) => {
    const doc = new jsPDF(format === 'portrait-pdf' ? 'p' : 'l', 'mm', 'a4');

    // Ambil data dari tabel
    const tableData = table.getRowModel().rows.map(row => {
      const cells = row.getAllCells();
      return cells.map(cell => cell.getValue());
    });

    // Ambil header kolom
    const headers = table
      .getAllColumns()
      .map(column => column.columnDef.header);

    // Generate PDF menggunakan autoTable
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (doc as any).autoTable({
      head: [headers],
      body: tableData,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255 }
    });

    // Download PDF
    doc.save('daftar-layanan.pdf');
    toast.success('Berhasil mengunduh tabel');
  };

  useEffect(() => {
    const selectedRowsCount = Object.keys(table.getState().rowSelection).length;
    setSelectedRows(selectedRowsCount);
  }, [table.getState().rowSelection]);

  // Aktifkan mutation yang sudah ada
  const [deleteService, { isLoading: isDeleting }] = useDeleteServiceMutation();

  // Update handleConfirmDelete
  const handleConfirmDelete = async (reason: string, notes: string) => {
    const selectedRows = table.getSelectedRowModel().rows;
    const selectedIds = selectedRows.map(row => String(row.original.id));
    const deletedCount = selectedRows.length;

    showCustomAlert({
      icon: 'warning',
      label: 'Layanan akan dihapus!',
      deskripsi: 'Apakah Anda yakin untuk menghapus layanan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await deleteService({
            notes: notes,
            deletionReason: reason,
            data: selectedIds
          }).unwrap();

          // Reset selection
          table.resetRowSelection();
          setSelectedRows(0);
          setShowDeleteModal(false);
          await refetch();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: `${deletedCount} layanan berhasil dihapus`,
            buttonType: 'ok'
          });
        } catch (error) {
          toast.error('Gagal menghapus layanan');
          console.error('Delete error:', error);
        }
      }
    });
  };

  // Update handleDeleteClick
  const handleDeleteClick = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    if (selectedRows.length === 0) {
      toast.warning('Silakan pilih layanan yang akan dihapus');
      return;
    }

    // Set selected services data for modal
    const servicesData = selectedRows.map(row => ({
      name: row.original.name
    }));
    setSelectedServices(servicesData);
    setShowDeleteModal(true);
  };

  // Tambahkan handler untuk reset page ke 1 saat ada perubahan filter
  useEffect(() => {
    setPage(1);
  }, [searchQuery, activeFilters, dateRange.startAt, dateRange.endAt]);

  const [lastToastTime, setLastToastTime] = useState(0);
  useEffect(() => {
    // Check for empty data
    if (serviceData && serviceData.data.total === 0) {
      const currentTime = new Date().getTime();

      // If more than 3 seconds have passed since the last toast
      if (!lastToastTime || currentTime - lastToastTime >= 3000) {
        toast.info('Data Tidak Ditemukan');

        // Update the last toast time
        setLastToastTime(currentTime);
      }
    }
  }, [serviceData, lastToastTime]);

  const periodOptions = [
    { value: 'all', label: 'Semua' },
    { value: 'today', label: 'Hari ini' },
    { value: 'week', label: 'Minggu ini' },
    { value: 'month', label: 'Bulan ini' },
    { value: 'year', label: 'Tahun ini' },
    { value: 'custom', label: 'Kustom' }
  ];

  return (
    <div>
      <PageBreadcrumb items={daftarLayananBreadcrumbItems} />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="mb-9">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="mb-0">Daftar Layanan</h2>
        </div>

        <div className="d-flex justify-content-between align-items-center gap-3 mb-4">
          <div className="d-flex gap-2 align-items-center">
            <SearchBox
              placeholder="Cari layanan"
              onChange={handleSearchInputChange}
              style={{ maxWidth: '300px' }}
            />
            <Button
              variant={showFilterPanel ? 'primary' : 'light'}
              size="sm"
              onClick={() => setShowFilterPanel(!showFilterPanel)}
              className="d-flex align-items-center gap-2"
            >
              <FontAwesomeIcon icon={faFilter} />
              Filter
            </Button>
          </div>
          <div className="d-flex align-items-center gap-3">
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={() => setShowDownloadModal(true)}
            >
              <FontAwesomeIcon icon={faFileExport} className="me-2" />
              Unduh tabel
            </Button>
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={handleRefresh}
              disabled={isRefreshing || isFetching}
            >
              <FontAwesomeIcon
                icon={faUndo}
                className={`me-2 ${
                  isRefreshing || isFetching ? 'fa-spin' : ''
                }`}
              />
              {isRefreshing || isFetching ? 'Memperbarui...' : 'Refresh'}
            </Button>
            {/* kesini */}
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                className="text-secondary p-0 dropdown-caret-none"
              >
                Tindakan lainnya
                {/* <FontAwesomeIcon icon={faChevronDown} className="ms-2" /> */}
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item>Export Selection</Dropdown.Item>
                <Dropdown.Item>Bulk Edit</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant="outline-danger"
              onClick={handleDeleteClick}
              disabled={selectedRows === 0 || isDeleting}
              size="sm"
            >
              <FontAwesomeIcon icon={faTrash} className="me-2" />
              Hapus Layanan ({selectedRows})
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={handleShowTambahLayananModal}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Tambah Layanan
            </Button>
          </div>
        </div>

        {showFilterPanel && (
          <div
            className="p-3 rounded mb-4 border border-1"
            style={{ backgroundColor: '#EFF2F6' }}
          >
            <div className="row g-0 align-items-center">
              {/* Filter Dropdown Lokasi */}
              <div
                className="col-auto"
                style={{
                  minWidth: '100px',
                  width: activePeriod === 'custom' ? '115px' : '200px'
                }}
              >
                <Dropdown
                  className="w-100"
                  style={{ backgroundColor: '#F5F7FA' }}
                >
                  <Dropdown.Toggle
                    variant="white"
                    className="border rounded-end-0 w-100 d-flex justify-content-between align-items-center"
                  >
                    <div className="text-truncate px-1">
                      {tempFilters.location.length > 0 ? (
                        <div className="d-flex gap-1 flex-wrap">
                          {tempFilters.location.map((loc, index) => (
                            <span
                              key={index}
                              className="badge bg-light text-dark"
                            >
                              {loc}
                            </span>
                          ))}
                        </div>
                      ) : (
                        'Lokasi'
                      )}
                    </div>
                    {/* <FontAwesomeIcon icon={faChevronDown} className="ms-2" /> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item disabled>
                      Tidak ada data lokasi
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Filter Dropdown Status */}
              <div
                className="col-auto"
                style={{
                  minWidth: '100px',
                  width: activePeriod === 'custom' ? '115px' : '200px'
                }}
              >
                <Dropdown
                  className="w-100"
                  style={{ backgroundColor: '#F5F7FA' }}
                >
                  <Dropdown.Toggle
                    variant="white"
                    className="border rounded-0 w-100 d-flex justify-content-between align-items-center"
                  >
                    <div className="text-truncate px-1">
                      {tempFilters.isActive.length > 0 ? (
                        <div className="d-flex gap-1 flex-wrap">
                          {tempFilters.isActive.map((status, index) => (
                            <span
                              key={index}
                              className="badge bg-light text-dark"
                            >
                              {
                                filterOptions.isActive.find(
                                  s => s.value === status
                                )?.label
                              }
                            </span>
                          ))}
                        </div>
                      ) : (
                        'Status'
                      )}
                    </div>
                    {/* <FontAwesomeIcon icon={faChevronDown} className="ms-2" /> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {filterOptions.isActive.map(status => (
                      <CheckboxDropdownItem
                        key={status.value}
                        label={status.label}
                        value={status.value}
                        checked={tempFilters.isActive.includes(status.value)}
                        onChange={(value, checked) =>
                          handleCheckboxChange('isActive', value, checked)
                        }
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Filter Dropdown Kategori */}
              <div
                className="col-auto"
                style={{
                  minWidth: '100px',
                  width: activePeriod === 'custom' ? '115px' : '200px'
                }}
              >
                <Dropdown
                  className="w-100"
                  style={{ backgroundColor: '#F5F7FA' }}
                >
                  <Dropdown.Toggle
                    variant="white"
                    className="border w-100 rounded-0 d-flex justify-content-between align-items-center"
                  >
                    <div className="text-truncate px-1">
                      {tempFilters.category.length > 0 ? (
                        <div className="d-flex gap-1 flex-wrap">
                          {tempFilters.category.map((cat, index) => (
                            <span
                              key={index}
                              className="badge bg-light text-dark"
                            >
                              {
                                filterOptions.category.find(
                                  c => c.value === cat
                                )?.label
                              }
                            </span>
                          ))}
                        </div>
                      ) : (
                        'Kategori'
                      )}
                    </div>
                    {/* <FontAwesomeIcon icon={faChevronDown} className="ms-2" /> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {filterOptions.category.map(category => (
                      <CheckboxDropdownItem
                        key={category.value}
                        label={category.label}
                        value={category.value}
                        checked={tempFilters.category.includes(category.value)}
                        onChange={(value, checked) =>
                          handleCheckboxChange('category', value, checked)
                        }
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Filter Dropdown Harga */}
              <div
                className="col-auto"
                style={{
                  minWidth: '100px',
                  width: activePeriod === 'custom' ? '115px' : '200px'
                }}
              >
                <Dropdown
                  className="w-100"
                  style={{ backgroundColor: '#F5F7FA' }}
                >
                  <Dropdown.Toggle
                    variant="white"
                    className="border rounded-start-0 w-100 d-flex justify-content-between align-items-center"
                  >
                    <div className="text-truncate px-1">
                      {tempFilters.price.length > 0 ? (
                        <div className="d-flex gap-1 flex-wrap">
                          {tempFilters.price.map((price, index) => (
                            <span
                              key={index}
                              className="badge bg-light text-dark"
                            >
                              {
                                filterOptions.price.find(p => p.value === price)
                                  ?.label
                              }
                            </span>
                          ))}
                        </div>
                      ) : (
                        'Harga'
                      )}
                    </div>
                    {/* <FontAwesomeIcon icon={faChevronDown} className="ms-2" /> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {filterOptions.price.map(price => (
                      <CheckboxDropdownItem
                        key={price.value}
                        label={price.label}
                        value={price.value}
                        checked={tempFilters.price.includes(price.value)}
                        onChange={(value, checked) =>
                          handleCheckboxChange('price', value, checked)
                        }
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Period Buttons dan Date Range */}
              <div className="col">
                <div className="d-flex justify-content-end">
                  {periodOptions.map(period => (
                    <Button
                      key={period.value}
                      variant={
                        activePeriod === period.value ? 'secondary' : 'light'
                      }
                      size="sm"
                      onClick={() => handlePeriodClick(period.value)}
                      className={`
                        border py-2
                        ${period.value === 'all' ? 'rounded-end-0' : ''}
                        ${period.value === 'today' ? 'rounded-0' : ''}
                        ${period.value === 'week' ? 'rounded-0' : ''}
                        ${period.value === 'month' ? 'rounded-0' : ''}
                        ${period.value === 'year' ? 'rounded-0' : ''}
                        ${
                          period.value === 'custom'
                            ? 'rounded-start-0 me-2'
                            : ''
                        }
                        h-100
                        
                      `}
                    >
                      {period.label}
                    </Button>
                  ))}

                  {/* Show date range inputs only when Kustom is selected */}
                  {activePeriod === 'custom' && (
                    <div className="d-flex gap-2 align-items-center">
                      <input
                        type="date"
                        className="form-control form-control-sm"
                        style={{ width: '140px' }}
                        value={dateRange.startAt}
                        onChange={e =>
                          handleDateChange('startAt', e.target.value)
                        }
                      />
                      <span>sampai</span>
                      <input
                        type="date"
                        className="form-control form-control-sm"
                        style={{ width: '140px' }}
                        value={dateRange.endAt}
                        onChange={e =>
                          handleDateChange('endAt', e.target.value)
                        }
                      />
                    </div>
                  )}
                  <div className="d-flex gap-2 align-items-center ms-2">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={handleResetFilter}
                      disabled={!isFiltering()}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={handleApplyFilter}
                    >
                      <FontAwesomeIcon icon={faFilter} /> Terapkan
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <AdvanceTableProvider {...table}>
          <div style={{ width: '95%' }} className="mx-auto">
            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
              <LayananTable
                onPageChange={handlePageChange}
                currentPage={page}
                totalPages={serviceData?.data?.totalPages || 1}
                serviceData={{
                  totalRecords: serviceData?.data?.total || 0
                }}
              />
            </div>
          </div>
        </AdvanceTableProvider>
      </div>

      <DownloadTableModal
        show={showDownloadModal}
        onHide={() => setShowDownloadModal(false)}
        onDownload={handleDownload}
        title="Unduh Tabel"
      />

      <TambahLayananModal
        show={showTambahLayananModal}
        handleClose={handleCloseTambahLayananModal}
        onSuccess={() => {
          handleCloseTambahLayananModal();
          handleRefresh();
        }}
      />

      <DeleteCustomerModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        selectedCustomers={selectedServices}
        onDelete={handleConfirmDelete}
        title="Hapus Layanan"
      />
    </div>
  );
};

export default DaftarLayanan;
