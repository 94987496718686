import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { CustomerResponse } from 'api/response/CustomerResponse';
import { Link } from 'react-router-dom';

export const customersTablecolumns: ColumnDef<CustomerResponse>[] = [
  {
    accessorKey: 'firstName',
    header: 'Nama Pelanggan',
    cell: ({ row: { original } }) => {
      return (
        <Link
          to={`/apps/e-commerce/admin/customer-details/${original.id}`}
          className="d-flex align-items-center gap-3 text-body-emphasis"
        >
          <p className="mb-0 fw-bold">
            {`${original.firstName || ''} ${original.lastName || ''}`}
          </p>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'emails',
    header: 'Email',
    cell: ({ row: { original } }) => {
      const emails = original.addressAndContact?.emails || [];
      const email = emails[0]?.email || 'Tidak Ada';
      return (
        <Link
          to={`mailto:${email !== 'Tidak Ada' ? email : ''}`}
          className={`fw-semibold text-decoration-none ${
            email === 'Tidak Ada' ? 'text-body cursor-default' : ''
          }`}
        >
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  },
  {
    accessorKey: 'phoneNumbers',
    header: 'No. Telepon',
    cell: ({ row: { original } }) => {
      const contacts = original.addressAndContact?.contacts || [];
      const phoneNumber = contacts[0]?.number || 'Tidak Ada';
      return <span className="fw-semibold">{phoneNumber}</span>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  },
  {
    accessorKey: 'addresses',
    header: 'Kecamatan',
    cell: ({ row: { original } }) => {
      const addresses = original.addressAndContact?.addresses || [];
      const district = addresses[0]?.district?.name || 'Tidak Ada';
      return <span>{district}</span>;
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'joinDate',
    header: 'Tanggal Bergabung',
    cell: ({ row: { original } }) => {
      return original.joinDate
        ? new Date(original.joinDate).toLocaleDateString('id-ID', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })
        : 'Tidak Ada';
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  }
];

interface CustomersTableProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
  customerData?: {
    totalRecords?: number;
  };
}

const CustomersTable = ({
  onPageChange,
  currentPage,
  totalPages,
  customerData
}: CustomersTableProps) => {
  return (
    <div>
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
        }}
      />
      <AdvanceTableFooter
        pagination
        className="mt-3"
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalRecords={customerData?.totalRecords || 0}
      />
    </div>
  );
};

export default CustomersTable;
