import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { detailKategoriLayananBreadcrumbItems } from 'data/commonData';
import { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import KategoriLayananInformasiDasarCard from 'components/cards/KategoriLayananInformasiDasarCard';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useGetCategoryServiceByIdQuery,
  useDeleteCategoryServiceMutation,
  useUpdateCategoryServiceMutation
} from 'api/categoryServiceApi';
import { showCustomAlert } from 'helpers/showCustomAlert';
import PilihLayananModal from 'components/modals/PilihLayananModal';
import { ClinicalServiceResponse } from 'api/response/ServiceResponse';

const KategoriLayananDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [deleteCategory, { isLoading: isDeleting }] =
    useDeleteCategoryServiceMutation();
  const [showPilihLayananModal, setShowPilihLayananModal] = useState(false);
  const [updateCategoryService] = useUpdateCategoryServiceMutation();

  const {
    data: categoryData,
    isLoading,
    error
  } = useGetCategoryServiceByIdQuery(id || '');

  if (isLoading) {
    return (
      <div className="text-center py-4">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error loading category details</div>;
  }

  const handleDeleteService = (serviceId: string) => {
    showCustomAlert({
      icon: 'warning',
      label: 'Layanan akan dihapus dari kategori',
      deskripsi: 'Apakah Anda yakin untuk menghapus layanan ini dari kategori?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await updateCategoryService({
            id: id || '',
            data: {
              name: categoryData?.name || '',
              description: categoryData?.description || '',
              color: categoryData?.color || '',
              clinicalServices: categoryData?.clinicalServices
                ?.filter(s => s.id !== serviceId)
                .map(s => s.id)
            }
          }).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Layanan berhasil dihapus dari kategori',
            buttonType: 'ok'
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: 'Gagal menghapus layanan dari kategori',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleDeleteCategory = () => {
    if (!id || !categoryData) return;

    const hasServices = categoryData.totalServices > 0;

    showCustomAlert({
      icon: 'warning',
      label: 'Kategori akan dihapus!',
      deskripsi: hasServices
        ? 'Terdapat kategori yang sudah memiliki layanan. Apakah Anda yakin untuk menghapus kategori ini?'
        : 'Apakah Anda yakin untuk menghapus kategori ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await deleteCategory({ id: [id] }).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Kategori layanan berhasil dihapus',
            buttonType: 'ok',
            onConfirm: () => {
              navigate('/layanan/kategori-layanan');
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          const errorMessage =
            error.data?.message || 'Gagal menghapus kategori layanan';
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: errorMessage,
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handlePilihLayanan = async (
    selectedServices: ClinicalServiceResponse[]
  ) => {
    try {
      await updateCategoryService({
        id: id || '',
        data: {
          name: categoryData?.name || '',
          description: categoryData?.description || '',
          color: categoryData?.color || '',
          clinicalServices: selectedServices.map(s => s.id)
        }
      }).unwrap();
      setShowPilihLayananModal(false);
    } catch (error) {
      showCustomAlert({
        icon: 'error',
        label: 'Gagal',
        deskripsi: 'Gagal mengupdate layanan kategori',
        buttonType: 'ok'
      });
    }
  };

  return (
    <>
      <div>
        <PageBreadcrumb items={detailKategoriLayananBreadcrumbItems} />
        <Row className="d-flex align-items-center justify-content-between g-3 mb-4">
          <Col xs="auto">
            <h2 className="mb-0">Detail Kategori</h2>
          </Col>
          <Col xs="auto" className="d-flex gap-3 flex-wrap p-0">
            <Button
              variant="outline-danger"
              size="sm"
              onClick={handleDeleteCategory}
              disabled={isDeleting}
            >
              <FontAwesomeIcon icon={faTrash} className="me-2" />
              {isDeleting ? 'Menghapus...' : 'Hapus Kategori'}
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={() => setShowPilihLayananModal(true)}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Tambah Layanan
            </Button>
          </Col>
        </Row>

        <Row className="g-5">
          <Col xs={12} sm={12} md={12} lg={12} xl={6}>
            <KategoriLayananInformasiDasarCard
              id={categoryData?.id || ''}
              name={categoryData?.name || '-'}
              color={categoryData?.color || '#E3E6ED'}
              description={categoryData?.description || '-'}
              clinicalServices={categoryData?.clinicalServices?.map(s => s.id)}
              onUpdate={() => {}}
            />
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={6}>
            <Card className="bg-transparent border-0">
              <Card.Body>
                <Card.Title as="h3" className="mb-4">
                  Daftar Layanan
                </Card.Title>
                <div className="table-responsive mb-3">
                  <table className="table">
                    <thead>
                      <tr className="fs-9">
                        <th className="ps-0">NAMA LAYANAN</th>
                        <th style={{ width: '40px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr className="fs-9">
                          <td colSpan={2} className="text-center">
                            Loading...
                          </td>
                        </tr>
                      ) : categoryData?.clinicalServices?.length === 0 ? (
                        <tr className="fs-9">
                          <td colSpan={2} className="text-center text-muted">
                            Belum ada layanan yang ditambahkan
                          </td>
                        </tr>
                      ) : (
                        categoryData?.clinicalServices?.map(service => (
                          <tr key={service.id} className="fs-9">
                            <td>{service.name}</td>
                            <td className="text-end">
                              <Button
                                variant="link"
                                className="text-muted p-0"
                                onClick={() => handleDeleteService(service.id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <Button
                  className="w-100 fs-9"
                  variant="outline-primary"
                  size="lg"
                  onClick={() => setShowPilihLayananModal(true)}
                >
                  + Tambah Layanan
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <PilihLayananModal
        show={showPilihLayananModal}
        onHide={() => setShowPilihLayananModal(false)}
        onSelect={handlePilihLayanan}
        selectedIds={categoryData?.clinicalServices?.map(s => s.id) || []}
        onMount={() => {}}
        currentCategoryId={id}
      />
    </>
  );
};

export default KategoriLayananDetails;
