import {
  faEdit,
  faRightFromBracket,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import Badge from 'components/base/Badge';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { detailAkunBreadcrumbItems } from 'data/commonData';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { Card, Col, Row } from 'react-bootstrap';

import HistoriLoginTable from 'components/tables/HistoriLoginTable';
import UbahInformasiAkunModal from 'components/modals/UbahInformasiAkunModal';
import KonfirmasiPasswordModal from 'components/modals/KonfirmasiPasswordModal';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  useGetAccountByIdQuery,
  useDeleteAccountMutation
} from 'api/accountApi';
import DeleteCustomerModal from 'components/modals/DeleteCustomerModal';
import { showCustomAlert } from 'helpers/showCustomAlert';
import LogoutAllDevicesModal from 'components/modals/LogoutAllDevicesModal';
import ForbiddenWrapper from 'components/modules/auth/ForbiddenWrapper';

const DetailAkun = () => {
  const { id } = useParams<{ id: string }>();
  const [showKonfirmasiModal, setShowKonfirmasiModal] = useState(false);
  const [showUbahModal, setShowUbahModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [deleteAccount] = useDeleteAccountMutation();
  const navigate = useNavigate();

  // Fetch account details
  const {
    data: accountData,
    isLoading,
    error
  } = useGetAccountByIdQuery(id || '');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUbahAkun = (data: any) => {
    console.log('Data akun yang diubah:', data);
    toast.success('Akun berhasil diubah');
    setShowUbahModal(false);
  };

  const handleKonfirmasiSuccess = () => {
    setShowKonfirmasiModal(false);
    setShowUbahModal(true);
  };

  const handleDelete = async (reason: string, notes: string) => {
    try {
      const deleteRequest = {
        notes: notes,
        deletionReason: reason,
        data: [id as string] // karena ini single delete, kita masukkan id akun yang sedang dilihat
      };

      await deleteAccount(deleteRequest).unwrap();
      showCustomAlert({
        icon: 'success',
        label: 'Berhasil',
        deskripsi: 'Akun berhasil dihapus',
        buttonType: 'ok',
        onConfirm: () => {
          navigate('/manajemen-akun/kelola-akun');
        }
      });
    } catch (error) {
      showCustomAlert({
        icon: 'error',
        label: 'Gagal',
        deskripsi: 'Gagal menghapus akun',
        buttonType: 'ok',
        onConfirm: () => {
          console.error('Delete error:', error);
        }
      });
    } finally {
      setShowDeleteModal(false);
    }
  };

  const getSelectedAccounts = () => {
    return [
      {
        name: account?.email || 'Unknown'
      }
    ];
  };

  const handleLogoutAllDevices = () => {
    showCustomAlert({
      icon: 'warning',
      label: 'Akun Anda akan logout dari seluruh perangkat.',
      deskripsi: 'Apakah Anda yakin untuk melanjutkan proses ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        // Jika pengguna mengonfirmasi, lakukan logout
        showCustomAlert({
          icon: 'success',
          label: 'Sukses!',
          deskripsi:
            'Akun telah berhasil logout pada seluruh perangkat terdaftar.',
          buttonType: 'ok',
          onConfirm: () => {
            setShowLogoutModal(false);
          }
        });
      },
      onCancel: () => {
        // Jika pengguna membatalkan, tutup modal
        setShowLogoutModal(false);
      }
    });
  };

  const isLightColor = (color: string): boolean => {
    if (!color) return false;
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128;
  };

  // Handle loading and error states
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading account details</div>;

  // Safely access account data
  const account = accountData?.data;
  console.log('Account Data:', accountData);
  console.log('Account:', account);
  return (
    <div>
      <PageBreadcrumb items={detailAkunBreadcrumbItems} />
      <Row className="d-flex align-items-center justify-content-between g-3 mb-4">
        <Col xs="auto">
          <h2 className="mb-0">Detail Akun</h2>
        </Col>
        <Col xs="auto" className="d-flex gap-3 flex-wrap p-0">
          <ForbiddenWrapper privilege="USER_MANAGEMENT_FULL" isBlank={true}>
            <Button
              variant="outline-danger"
              onClick={() => setShowDeleteModal(true)}
              disabled={account?.role?.code === 'Master'}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
              Hapus Akun
            </Button>
            <Button variant="danger" onClick={() => setShowLogoutModal(true)}>
              <FontAwesomeIcon icon={faRightFromBracket} className="me-2" />
              Logout dari Semua Perangkat
            </Button>
          </ForbiddenWrapper>
          <ForbiddenWrapper privilege="USER_MANAGEMENT_WRITE" isBlank={true}>
            <Button
              variant="primary"
              onClick={() => setShowKonfirmasiModal(true)}
            >
              <FontAwesomeIcon icon={faEdit} className="me-2" />
              Ganti Informasi Akun
            </Button>
          </ForbiddenWrapper>
        </Col>
      </Row>

      <Row className="g-5">
        <Col xs={12} sm={12} md={12} lg={12} xl={5}>
          <Row className="g-3">
            <Card>
              <Card.Body>
                <Card.Title as="h3" className="mb-4">
                  Informasi Dasar
                </Card.Title>
                <div className="d-flex flex-column gap-4">
                  <div>
                    <h5 className="text-body">Alamat Email</h5>
                    <p className="mb-0">{account?.email || 'Tidak Ada'}</p>
                  </div>

                  <div>
                    <h5 className="text-body">Nama Pegawai Terkait</h5>
                    {accountData?.data?.employee ? (
                      <Link
                        to={`/pegawai/detail-pegawai/${accountData.data.employee.id}`}
                        className="text-primary text-decoration-none"
                      >
                        {accountData.data.employee.fullName}
                      </Link>
                    ) : (
                      <span
                        style={{ color: '#6c757d' }}
                        onClick={() => toast.warn('Tidak ada pegawai terkait')}
                      >
                        Tidak Ada
                      </span>
                    )}
                  </div>
                  <div>
                    <h5 className="text-body">Peran Keamanan</h5>
                    {account?.role && (
                      <Badge
                        style={{
                          borderRadius: '15px',
                          backgroundColor: account.role.color || '#6c757d',
                          color: isLightColor(account.role.color || '')
                            ? '#000'
                            : '#fff'
                        }}
                        variant="phoenix"
                        className="fs-10 py-1 px-2"
                      >
                        {account.role.code}
                      </Badge>
                    )}
                  </div>
                  <div>
                    <h5 className="text-body">Status</h5>
                    <Badge
                      bg={account?.status ? 'success' : 'danger'}
                      className="fs-10 py-1 px-2"
                    >
                      {account?.status ? 'AKTIF' : 'TIDAK AKTIF'}
                    </Badge>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Row>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={7}>
          <Row className="d-flex flex-column g-3">
            <HistoriLoginTable />
          </Row>
        </Col>
      </Row>

      <KonfirmasiPasswordModal
        show={showKonfirmasiModal}
        onHide={() => setShowKonfirmasiModal(false)}
        onConfirm={handleKonfirmasiSuccess}
        userEmail={account?.email}
      />

      <UbahInformasiAkunModal
        show={showUbahModal}
        onHide={() => setShowUbahModal(false)}
        accountId={id || ''}
        onSubmit={handleUbahAkun}
      />

      <DeleteCustomerModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        selectedCustomers={getSelectedAccounts()}
        onDelete={handleDelete}
        title="Hapus Akun"
      />

      <LogoutAllDevicesModal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        onLogout={handleLogoutAllDevices}
      />
    </div>
  );
};

export default DetailAkun;
