import { Modal, Form, Row, Col } from 'react-bootstrap';
import Button from 'components/base/Button';
import { useState, useEffect } from 'react';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {
  useGetAccountByIdQuery,
  useUpdateAccountMutation,
  useGetAccountsQuery
} from 'api/accountApi';
import { useGetEmployeeQuery } from 'api/employeeApi';
import { useGetAllRolesQuery } from 'api/roleApi';
import { toast } from 'react-toastify';
import { RoleResponse } from 'api/response/RoleResponse';

interface UbahInformasiAkunModalProps {
  show: boolean;
  onHide: () => void;
  accountId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: any) => void;
}

const UbahInformasiAkunModal = ({
  show,
  onHide,
  accountId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSubmit
}: UbahInformasiAkunModalProps) => {
  const {
    data: accountData,
    isLoading: isAccountLoading,
    refetch
  } = useGetAccountByIdQuery(accountId);
  const { data: employeeData, isLoading: isEmployeeLoading } =
    useGetEmployeeQuery({
      page: 1,
      limit: 100,
      orderBy: 'firstName',
      direction: 'ASC'
    });
  const { data: rolesData, isLoading: isRolesLoading } = useGetAllRolesQuery({
    page: 1,
    limit: 100
  });
  const { data: allAccounts } = useGetAccountsQuery({
    page: 1,
    limit: 100,
    orderBy: 'createdDate',
    direction: 'DESC'
  });

  const [formData, setFormData] = useState({
    email: '',
    employeeId: '',
    roleId: '',
    status: true,
    passwordBaru: '',
    konfirmasiPasswordBaru: ''
  });

  useEffect(() => {
    if (accountData?.data) {
      setFormData({
        email: accountData.data.email || '',
        employeeId: accountData.data.employee?.id || '',
        roleId: accountData.data.role?.id || '',
        status: accountData.data.status,
        passwordBaru: '',
        konfirmasiPasswordBaru: ''
      });
    }
  }, [accountData]);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showKonfirmasiPassword, setShowKonfirmasiPassword] = useState(false);

  const [updateAccount, { isLoading: isUpdating }] = useUpdateAccountMutation();

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    // Validasi email
    if (!formData.email.trim()) {
      newErrors.email = 'Email harus diisi';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Format email tidak valid';
    }

    // Validasi nama pegawai (UUID validation)
    if (!formData.employeeId) {
      newErrors.employeeId = 'Nama pegawai harus dipilih';
    } else if (
      !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
        formData.employeeId
      )
    ) {
      newErrors.employeeId = 'Format ID pegawai tidak valid';
    }

    // Validasi peran keamanan
    if (!formData.roleId) {
      newErrors.roleId = 'Peran keamanan harus dipilih';
    }

    // Validasi password jika field password ditampilkan
    if (showPasswordFields) {
      if (!formData.passwordBaru) {
        newErrors.passwordBaru = 'Password baru harus diisi';
      } else if (formData.passwordBaru.length < 8) {
        newErrors.passwordBaru = 'Password minimal 8 karakter';
      } else if (!/^[A-Z]/.test(formData.passwordBaru)) {
        newErrors.passwordBaru = 'Password harus diawali huruf kapital';
      } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.passwordBaru)) {
        newErrors.passwordBaru =
          'Password harus mengandung minimal 1 karakter spesial';
      } else if (!/\d/.test(formData.passwordBaru)) {
        newErrors.passwordBaru = 'Password harus mengandung minimal 1 angka';
      }

      if (!formData.konfirmasiPasswordBaru) {
        newErrors.konfirmasiPasswordBaru =
          'Konfirmasi password baru harus diisi';
      } else if (formData.passwordBaru !== formData.konfirmasiPasswordBaru) {
        newErrors.konfirmasiPasswordBaru = 'Password tidak cocok';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      showCustomAlert({
        icon: 'question',
        label: 'Perubahan data akan disimpan',
        deskripsi:
          'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
        buttonType: 'yes-no',
        onConfirm: async () => {
          try {
            const passwordData = showPasswordFields
              ? {
                  isChangePassword: true,
                  password: formData.passwordBaru,
                  confirmPassword: formData.konfirmasiPasswordBaru
                }
              : {
                  isChangePassword: false
                };

            const updateData = {
              email: formData.email,
              employeeId: formData.employeeId,
              name: accountData?.data?.employee?.names?.fullName || '',
              status: formData.status,
              roleId: formData.roleId,
              placeholder: 'Updated via admin panel',
              ...passwordData
            };

            const response = await updateAccount({
              id: accountId,
              data: updateData
            }).unwrap();

            if (response.code === 200) {
              showCustomAlert({
                icon: 'success',
                label: 'Sukses',
                deskripsi: 'Data berhasil diperbarui.',
                buttonType: 'ok',
                onConfirm: () => {
                  refetch();
                  onHide();
                }
              });
            } else {
              toast.error(response.message || 'Gagal memperbarui akun');
            }
          } catch (error) {
            console.error('Error updating account:', error);
            if (error && typeof error === 'object' && 'data' in error) {
              const errorData = error as { data?: { message?: string } };

              // Cek pesan error "Email Already Exists"
              if (errorData.data?.message === 'Email Already Exists') {
                showCustomAlert({
                  icon: 'error',
                  label: 'Terdapat alamat email yang sama',
                  deskripsi:
                    'Mohon ubah alamat email yang akan Anda tambahkan.',
                  buttonType: 'ok'
                });
              } else {
                toast.error(
                  errorData.data?.message || 'Gagal memperbarui akun'
                );
              }
            } else {
              toast.error('Gagal memperbarui akun');
            }
          }
        }
      });
    }
  };

  const handleClose = () => {
    const initialFormData = {
      email: accountData?.data?.email || '',
      employeeId: accountData?.data?.employee?.id || '',
      roleId: accountData?.data?.role?.id || '',
      status: accountData?.data?.status || false,
      passwordBaru: '',
      konfirmasiPasswordBaru: ''
    };

    // Cek apakah ada perubahan data
    const isFormChanged =
      JSON.stringify(formData) !== JSON.stringify(initialFormData);

    if (isFormChanged || showPasswordFields) {
      showCustomAlert({
        icon: 'warning',
        label: 'Perubahan data tidak akan disimpan',
        deskripsi: 'Apakah Anda yakin untuk membatalkan proses ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          setFormData(initialFormData);
          setShowPasswordFields(false);
          setShowPassword(false);
          setShowKonfirmasiPassword(false);
          setErrors({});
          onHide();
        }
      });
    } else {
      onHide();
    }
  };

  const handleEmployeeChange = (employeeId: string) => {
    // Skip pengecekan jika memilih opsi kosong
    if (!employeeId) {
      setFormData(prev => ({ ...prev, employeeId }));
      return;
    }

    // Cek apakah employee sudah digunakan oleh akun lain
    const existingAccount = allAccounts?.data?.result?.find(
      account => account.employee?.id === employeeId && account.id !== accountId // Exclude akun yang sedang diedit
    );

    if (existingAccount) {
      showCustomAlert({
        icon: 'warning',
        label: 'Pegawai sudah terkait dengan akun lain',
        deskripsi: `Pegawai ini sudah terkait dengan akun ${existingAccount.email}. Apakah Anda yakin ingin mengubah kepemilikan akun pada pegawai ini?`,
        buttonType: 'yes-no',
        onConfirm: () => {
          setFormData(prev => ({ ...prev, employeeId }));
        }
      });
    } else {
      // Jika employee belum digunakan atau dipilih opsi kosong, langsung update
      setFormData(prev => ({ ...prev, employeeId }));
    }
  };

  if (isAccountLoading || isEmployeeLoading || isRolesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header className="px-5 py-4 bg-modal-header">
        <Modal.Title className="fw-bolder">Ubah Informasi Akun</Modal.Title>
        <div className="ms-auto">
          <Button
            variant="link"
            className="text-danger me-2"
            onClick={handleClose}
          >
            Batalkan
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="ubahAkunForm"
            disabled={isUpdating}
          >
            {isUpdating ? 'Menyimpan...' : 'Ubah'}
          </Button>
        </div>
      </Modal.Header>
      <Form id="ubahAkunForm" onSubmit={handleSubmit}>
        <Modal.Body className="px-5 py-4 bg-light">
          <h4 className="mb-3">Informasi Akun</h4>
          <Form.Group className="mb-3">
            <Form.Label className="p-0 fs-8 mb-2 text-capitalize">
              Nama Akun <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              value={formData.email}
              onChange={e =>
                setFormData({ ...formData, email: e.target.value })
              }
              isInvalid={!!errors.email}
              disabled={accountData?.data?.role?.code === 'Master'}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="p-0 fs-8 mb-2 text-capitalize">
              Nama Pegawai Terkait <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              value={formData.employeeId}
              onChange={e => handleEmployeeChange(e.target.value)}
              isInvalid={!!errors.employeeId}
            >
              <option value="">Pilih nama pegawai terkait</option>
              {employeeData?.data
                ?.filter(employee => employee)
                ?.slice()
                .sort((a, b) =>
                  (a.fullName || '').localeCompare(b.fullName || '')
                )
                .map(employee => (
                  <option key={employee.id} value={employee.id}>
                    {employee.fullName || '(Nama tidak tersedia)'}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.employeeId}
            </Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="p-0 fs-8 mb-2 text-capitalize">
                  Peran Keamanan <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  value={formData.roleId}
                  onChange={e =>
                    setFormData({ ...formData, roleId: e.target.value })
                  }
                  isInvalid={!!errors.roleId}
                  disabled={accountData?.data?.role?.code === 'Master'}
                >
                  <option value="">Pilih peran keamanan</option>
                  {rolesData?.data
                    ?.slice()
                    .sort((a: RoleResponse, b: RoleResponse) =>
                      a.code.localeCompare(b.code)
                    )
                    .map((role: RoleResponse) => (
                      <option key={role.id} value={role.id}>
                        {role.code}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.roleId}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="p-0 fs-8 mb-2 text-capitalize">
                  Status <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  value={formData.status ? 'true' : 'false'}
                  onChange={e =>
                    setFormData({
                      ...formData,
                      status: e.target.value === 'true'
                    })
                  }
                  isInvalid={!!errors.status}
                  disabled={accountData?.data?.role?.code === 'Master'}
                >
                  <option value="true">Aktif</option>
                  <option value="false">Tidak Aktif</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.status}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <div className="mt-4">
            <h4 className="mb-3">Ubah Password</h4>
            {!showPasswordFields ? (
              <Button
                variant="primary"
                onClick={() => setShowPasswordFields(true)}
              >
                Ubah Password
              </Button>
            ) : (
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="p-0 fs-8 mb-2 text-capitalize">
                      Password Baru <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Masukkan password baru"
                        value={formData.passwordBaru}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            passwordBaru: e.target.value
                          })
                        }
                        isInvalid={!!errors.passwordBaru}
                        style={{
                          paddingRight: '40px',
                          backgroundImage: 'none'
                        }}
                      />
                      <Button
                        variant="link"
                        className="position-absolute top-50 end-0 translate-middle-y text-body pe-3"
                        onClick={() => setShowPassword(!showPassword)}
                        type="button"
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          className="fs-9"
                        />
                      </Button>
                    </div>
                    {errors.passwordBaru && (
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: 'block' }}
                      >
                        {errors.passwordBaru}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="p-0 fs-8 mb-2 text-capitalize">
                      Konfirmasi Password Baru{' '}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={showKonfirmasiPassword ? 'text' : 'password'}
                        placeholder="Ketik ulang password baru"
                        value={formData.konfirmasiPasswordBaru}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            konfirmasiPasswordBaru: e.target.value
                          })
                        }
                        isInvalid={!!errors.konfirmasiPasswordBaru}
                        style={{
                          paddingRight: '40px',
                          backgroundImage: 'none'
                        }}
                      />
                      <Button
                        variant="link"
                        className="position-absolute top-50 end-0 translate-middle-y text-body pe-3"
                        onClick={() =>
                          setShowKonfirmasiPassword(!showKonfirmasiPassword)
                        }
                        type="button"
                      >
                        <FontAwesomeIcon
                          icon={showKonfirmasiPassword ? faEye : faEyeSlash}
                          className="fs-9"
                        />
                      </Button>
                    </div>
                    {errors.konfirmasiPasswordBaru && (
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: 'block' }}
                      >
                        {errors.konfirmasiPasswordBaru}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default UbahInformasiAkunModal;
