import { Modal, Form } from 'react-bootstrap';
import Button from 'components/base/Button';
import { useState, useEffect } from 'react';
import SearchBox from 'components/common/SearchBox';
import { debounce } from 'lodash';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useGetServiceQuery } from 'api/serviceApi';
import { ClinicalServiceResponse } from 'api/response/ServiceResponse';

interface PilihLayananModalProps {
  show: boolean;
  onHide: () => void;
  onSelect: (selectedServices: ClinicalServiceResponse[]) => void;
  selectedIds: string[];
  onMount: (services: ClinicalServiceResponse[]) => void;
  style?: React.CSSProperties;
  currentCategoryId?: string;
}

// Tambahkan style untuk overlay
const overlayStyle = {
  position: 'fixed' as const,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 1050
};

const PilihLayananModal = ({
  show,
  onHide,
  onSelect,
  selectedIds,
  onMount,
  style,
  currentCategoryId
}: PilihLayananModalProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedServices, setSelectedServices] =
    useState<string[]>(selectedIds);

  const {
    data: serviceData,
    isLoading,
    refetch
  } = useGetServiceQuery({
    page: currentPage,
    limit: 100,
    orderBy: 'createdDate',
    direction: 'DESC',
    name: searchTerm || undefined
  });

  const handleSearchChange = debounce((value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  }, 300);

  const handleSelect = (serviceId: string) => {
    setSelectedServices(prev =>
      prev.includes(serviceId)
        ? prev.filter(id => id !== serviceId)
        : [...prev, serviceId]
    );
  };

  const handleSubmit = () => {
    const selectedServicesData = serviceData?.data?.result.filter(service =>
      selectedServices.includes(service.id)
    );

    // Pisahkan layanan berdasarkan status kategorinya
    const servicesWithCategory = selectedServicesData?.filter(
      service =>
        service.categoryName && service.categoryName !== currentCategoryId
    );

    // Cek apakah ada layanan baru yang sudah memiliki kategori lain
    const hasNewServicesWithOtherCategory = servicesWithCategory?.some(
      service => !selectedIds.includes(service.id)
    );

    if (hasNewServicesWithOtherCategory) {
      // Kasus 3: Ada layanan baru yang dipilih dan sudah memiliki kategori
      showCustomAlert({
        icon: 'warning',
        label: 'Layanan yang dipilih sudah memiliki kategori',
        deskripsi:
          'Apakah Anda yakin akan mengubah kategori pada layanan tersebut?',
        buttonType: 'yes-no',
        onConfirm: () => {
          onSelect(selectedServicesData || []);
          onHide();
          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Data berhasil diperbarui',
            buttonType: 'ok'
          });
        }
      });
    } else {
      // Kasus 1 & 2: Layanan dengan kategori kosong atau kombinasi dengan yang sudah terassign
      showCustomAlert({
        icon: 'question',
        label: 'Perubahan data akan disimpan',
        deskripsi:
          'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
        buttonType: 'yes-no',
        onConfirm: () => {
          onSelect(selectedServicesData || []);
          onHide();
          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Data berhasil diperbarui',
            buttonType: 'ok'
          });
        }
      });
    }
  };

  const handleCancel = () => {
    // Cek apakah ada perubahan dengan membandingkan selectedServices dengan selectedIds
    const hasChanges =
      JSON.stringify(selectedServices.sort()) !==
      JSON.stringify(selectedIds.sort());

    if (hasChanges) {
      showCustomAlert({
        icon: 'warning',
        label: 'Batalkan pemilihan layanan',
        deskripsi: 'Apakah Anda yakin untuk membatalkan proses ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          setSelectedServices(selectedIds);
          onHide();
        }
      });
    } else {
      onHide();
    }
  };

  useEffect(() => {
    setSelectedServices(selectedIds);
  }, [selectedIds]);

  useEffect(() => {
    if (serviceData?.data?.result) {
      onMount(serviceData.data.result);
    }
  }, [serviceData, onMount]);

  useEffect(() => {
    if (show) {
      refetch();
    }
  }, [show, refetch]);

  useEffect(() => {
    if (!show) {
      setSelectedServices(selectedIds);
      setCurrentPage(1);
      setSearchTerm('');
    }
  }, [show, selectedIds]);

  return (
    <>
      {show && <div style={overlayStyle} />}
      <Modal
        show={show}
        onHide={handleCancel}
        size="lg"
        keyboard={false}
        centered
        contentClassName="shadow"
        style={{
          boxShadow: '0 0 20px rgba(0, 0, 0, 1)',
          border: 'none',
          borderRadius: '8px',
          ...style,
          zIndex: 1060
        }}
      >
        <Modal.Header className="px-5 py-4 bg-modal-header">
          <Modal.Title className="fw-bolder">Pilih Layanan</Modal.Title>
          <div className="ms-auto">
            <Button
              variant="link"
              className="text-danger me-2"
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Pilih Layanan
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="px-5 py-4 bg-light">
          <h4 className="mb-4">Daftar Layanan</h4>

          <div className="d-flex gap-3 align-items-center mb-4">
            <SearchBox
              placeholder="Cari layanan"
              onChange={e => handleSearchChange(e.target.value)}
              style={{ maxWidth: '300px' }}
            />
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: '40px' }}></th>
                  <th>NAMA LAYANAN</th>
                  <th>KATEGORI</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={3}>Loading...</td>
                  </tr>
                ) : (
                  serviceData?.data?.result.map(service => (
                    <tr key={service.id}>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={selectedServices.includes(service.id)}
                          onChange={() => handleSelect(service.id)}
                        />
                      </td>
                      <td>{service.name}</td>
                      <td>{service.categoryName || '-'}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PilihLayananModal;
