/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Nav,
  Tab,
  InputGroup,
  Table
} from 'react-bootstrap';
import Avatar from 'components/base/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapLocation,
  faContactBook,
  faBriefcase,
  faFileLines,
  faClipboard,
  faCircleMinus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import {
  useGetDistrictsQuery,
  useGetProvincesQuery,
  useGetRegenciesQuery,
  useGetVillagesQuery
} from 'api/locationApi';
import type { FormData } from 'api/request/EmployeeCreateReq';
import { showCustomAlert } from 'helpers/showCustomAlert';
import {
  EmployeeTemplateFieldDetail,
  EmployeeTemplateResponseData
} from 'api/response/EmployeeTemplateResponse';
import { useAddEmployeeMutation } from 'api/employeeApi';
import { useGetAllPositionsQuery } from 'api/positionApi'; // Tambahkan import untuk jabatan
import {
  useGetEmployeeTemplateQuery,
  useGetEmployeeTemplateFieldQuery
} from 'api/employeeTemplateApi';
import {
  EmployeeTemplateFieldResponse,
  EmployeeTemplateFieldValue
} from 'api/response/EmployeeTemplateResponse';
// Import Select dari react-select
import Select from 'react-select';

interface TambahPegawaiModalProps {
  show: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
}

const useLocationQueries = (
  provinceId: string,
  regencyId: string,
  districtId: string
) => {
  const { data: regenciesResponse, isLoading: isLoadingRegencies } =
    useGetRegenciesQuery(provinceId || '', { skip: !provinceId });

  const { data: districtsResponse, isLoading: isLoadingDistricts } =
    useGetDistrictsQuery(regencyId || '', { skip: !regencyId });

  const { data: villagesResponse, isLoading: isLoadingVillages } =
    useGetVillagesQuery(districtId || '', { skip: !districtId });

  return {
    regencies: regenciesResponse?.data || [],
    districts: districtsResponse?.data || [],
    villages: villagesResponse?.data || [],
    isLoadingRegencies,
    isLoadingDistricts,
    isLoadingVillages
  };
};

const TambahPegawaiModal = ({
  show,
  handleClose,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSuccess
}: TambahPegawaiModalProps) => {
  const [activeTab, setActiveTab] = useState('pekerjaan');
  const [validated, setValidated] = useState(false);
  const [addEmployee] = useAddEmployeeMutation();

  // Definisikan initial state dengan nilai default untuk warna
  const initialFormState: FormData = {
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    religion: '',
    maritalStatus: '',
    idType: '',
    idNumber: '',
    occupation: '',
    clientGroup: '',
    branch: '',
    joinDate: '',
    endDate: '',
    workPeriod: {
      startDate: '',
      endDate: '',
      isIndefinite: false
    },
    securityRole: '',
    employeeStatus: true,
    registrationNumber: '',
    annualReview: '',
    monthlyReview: '',
    location: '',
    paymentPeriod: '',
    paymentAmount: '',
    annualLeaveAllowance: '',
    sickLeaveAllowance: '',
    bank: '',
    accountNumber: '',
    color: '#E3E6ED', // Tambahkan nilai default untuk warna
    accountingId: 'B-123123', // Tambahkan ini
    overtimeAllowance: '', // Tambahkan ini
    otherAllowance: '', // Tambahkan ini
    accountName: '', // Tambahkan ini
    email: [{ emailAddress: '', usageType: '' }],
    phoneNumber: [{ phoneNumber: '', usageType: '', isWhatsapp: false }],
    emergencyContact: [
      {
        contactName: '',
        phoneNumber: '',
        relationshipStatus: '',
        isWhatsapp: false,
        address: ''
      }
    ],
    address: [
      {
        streetAddress: '',
        state: 'NY',
        province: 0,
        city: 0,
        district: 0,
        village: 0,
        country: '',
        postalCode: '',
        addressType: ''
      }
    ],
    employeeTemplateResponse: [],
    employeeNote: [{ noteType: 'private', content: 'Test' }],
    createdBy: 'SYSTEM',
    modifiedBy: 'SYSTEM',
    clinicId: 0,
    employeeType: 'Individual',
    employmentType: '', // Tambahkan ini
    jobTitle: '', // Ubah dari object menjadi string
    employmentRelationship: '', // Tambahkan ini
    employeeNotes: [{ noteType: 'private', content: 'Test' }], // Set default
    employeeInfo: {
      // Tambahkan ini
      id: '',
      startDate: '',
      endDate: null,
      isCurrent: true,
      role: '',
      employeeStatus: true,
      employmentRelationship: '',
      registrationNumber: '',
      jobTitle: { id: '', name: '', description: '', color: '' },
      annualReview: '',
      monthlyReview: '',
      location: ''
    },
    employeeRemuneration: '',
    isActive: true
  };

  // Gunakan initialFormState sebagai nilai awal useState
  const [formData, setFormData] = useState<FormData>(initialFormState);

  const location0 = useLocationQueries(
    formData.address[0]?.province?.toString() || '',
    formData.address[0]?.city?.toString() || '',
    formData.address[0]?.district?.toString() || ''
  );
  const location1 = useLocationQueries(
    formData.address[1]?.province?.toString() || '',
    formData.address[1]?.city?.toString() || '',
    formData.address[1]?.district?.toString() || ''
  );
  const location2 = useLocationQueries(
    formData.address[2]?.province?.toString() || '',
    formData.address[2]?.city?.toString() || '',
    formData.address[2]?.district?.toString() || ''
  );
  const location3 = useLocationQueries(
    formData.address[3]?.province?.toString() || '',
    formData.address[3]?.city?.toString() || '',
    formData.address[3]?.district?.toString() || ''
  );
  const location4 = useLocationQueries(
    formData.address[4]?.province?.toString() || '',
    formData.address[4]?.city?.toString() || '',
    formData.address[4]?.district?.toString() || ''
  );
  const location5 = useLocationQueries(
    formData.address[5]?.province?.toString() || '',
    formData.address[5]?.city?.toString() || '',
    formData.address[5]?.district?.toString() || ''
  );
  // ... lanjutkan hingga location9

  const locationQueries = useMemo(() => {
    return [
      location0,
      location1,
      location2,
      location3,
      location4,
      location5
    ].slice(0, formData.address.length);
  }, [
    location0,
    location1,
    location2,
    location3,
    location4,
    location5,

    formData.address.length
  ]);
  // Tambahkan state untuk tracking ID
  const [selectedProvinceIds, setSelectedProvinceIds] = useState<string[]>([
    ''
  ]);
  const [selectedRegencyIds, setSelectedRegencyIds] = useState<string[]>(['']);
  const [selectedDistrictIds, setSelectedDistrictIds] = useState<string[]>([
    ''
  ]);

  // Fetch data lokasi
  const { data: provincesResponse } = useGetProvincesQuery();

  // Tambahkan state untuk tracking error
  const [sectionErrors, setSectionErrors] = useState<{
    template: string[];
    personalInfo: string[];
    address: string[];
    contact: string[];
  }>({
    personalInfo: [],
    address: [],
    contact: [],
    template: []
  });

  // Tambahkan state untuk tracking detail visibility
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showErrorDetails, setShowErrorDetails] = useState<{
    personalInfo: boolean;
    address: boolean;
    contact: boolean;
  }>({
    personalInfo: false,
    address: false,
    contact: false
  });

  // Tambahkan state untuk loading
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true);

  // Tambahkan state untuk tracking reset form
  const [shouldResetForm, setShouldResetForm] = useState(false);

  // Tambahkan refetch pada query template dan fields
  const { data: templates, refetch: refetchTemplates } =
    useGetEmployeeTemplateQuery({
      limit: 1000,
      orderBy: 'createdDate',
      direction: 'ASC',
      status: 'true'
    });

  const { data: allFields, refetch: refetchFields } =
    useGetEmployeeTemplateFieldQuery(0);

  // Update useEffect untuk modal show
  useEffect(() => {
    if (show) {
      // Refetch data template dan fields
      refetchTemplates();
      refetchFields();

      // Loading effect
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [show, refetchTemplates, refetchFields]);

  const days: string[] = [
    'Senin',
    'Selasa',
    'Rabu',
    'Kamis',
    'Jumaat',
    'Sabtu',
    'Minggu'
  ];

  // Definisi tipe untuk state
  interface TimeValue {
    start: string;
    end: string;
  }

  const [fullDay, setFullDay] = useState<boolean[]>(Array(7).fill(false));
  const [timeValues, setTimeValues] = useState<TimeValue[]>(
    Array(7).fill({ start: '', end: '' })
  );

  const handleFullDayChange = (index: number) => {
    const updatedFullDay = [...fullDay];
    updatedFullDay[index] = !updatedFullDay[index];

    const updatedTimes = [...timeValues];
    updatedTimes[index] = updatedFullDay[index]
      ? { start: '00:00', end: '23:59' } // Jika dicentang, set ke 24 jam
      : { start: '', end: '' }; // Jika tidak dicentang, kosongkan kembali

    setFullDay(updatedFullDay);
    setTimeValues(updatedTimes);
  };

  const handleTimeChange = (
    index: number,
    type: 'start' | 'end',
    value: string
  ) => {
    const updatedTimes = [...timeValues];
    updatedTimes[index] = { ...updatedTimes[index], [type]: value };
    setTimeValues(updatedTimes);
  };
  // Tambahkan fungsi untuk mengecek validasi per section
  const validateSections = () => {
    const errors = {
      personalInfo: [] as string[],
      address: [] as string[],
      contact: [] as string[],
      template: [] as string[]
    };

    // Validasi Alamat (urutan sesuai layout visual)
    formData.address.forEach((addr, index) => {
      const addressValidation = [
        {
          field: 'streetAddress',
          message: 'Alamat jalan harus diisi',
          id: `address[${index}].streetAddress`
        },
        {
          field: 'province',
          message: 'Provinsi harus dipilih',
          id: `address[${index}].province`
        },
        {
          field: 'city',
          message: 'Kota harus dipilih',
          id: `address[${index}].city`
        },
        {
          field: 'district',
          message: 'Kecamatan harus dipilih',
          id: `address[${index}].district`
        },
        {
          field: 'village',
          message: 'Desa/Kelurahan harus dipilih',
          id: `address[${index}].village`
        },
        {
          field: 'country',
          message: 'Negara harus dipilih',
          id: `address[${index}].country`
        },
        {
          field: 'addressType',
          message: 'Jenis alamat harus dipilih',
          id: `address[${index}].addressType`
        }
      ];

      addressValidation.forEach(({ field, message }) => {
        if (!addr[field as keyof typeof addr]) {
          errors.address.push(message);
        }
      });
    });
    // Validasi Kontak
    if (!formData.phoneNumber[0]?.phoneNumber)
      errors.contact.push('Nomor telepon harus diisi');
    if (!formData.phoneNumber[0]?.usageType)
      errors.contact.push('Jenis penggunaan telepon harus dipilih');

    // Tambahkan validasi untuk template fields
    if (templates?.data.result) {
      templates.data.result
        .filter(template => template.status)
        .forEach(template => {
          const templateFields = allFields?.data.filter(field =>
            template.employeeTemplateFields.some(tf => tf.id === field.id)
          );

          templateFields?.forEach(field => {
            if (field.isRequired) {
              const response = formData.employeeTemplateResponse.find(
                resp => resp.fieldId === field.id
              );

              let isEmpty = false;
              switch (field.fieldType.code) {
                case 'RADIO_BUTTONS':
                case 'DROPDOWN_LIST':
                  isEmpty = !response?.optionResponse?.some(
                    opt => opt.selectedChoice
                  );
                  break;
                case 'CHECKBOXES':
                  isEmpty = !response?.optionResponse?.some(
                    opt => opt.selectedChoice
                  );
                  break;
                case 'MULTI_SELECT_LIST':
                  isEmpty =
                    !response?.responseValue || response.responseValue === '';
                  break;
                case 'DATE':
                  isEmpty =
                    !response?.responseValue ||
                    response.responseValue.trim() === '';
                  break;
                default:
                  isEmpty =
                    !response?.responseValue ||
                    response.responseValue.trim() === '';
              }

              if (isEmpty) {
                errors.template.push(
                  `${field.label} pada ${template.templateName} harus diisi`
                );
              }
            }
          });
        });
    }

    setSectionErrors(errors);
    setValidated(true);
    return Object.values(errors).every(section => section.length === 0);
  };

  // Tambahkan fungsi untuk scroll ke elemen dan berikan animasi
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const scrollToElement = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.classList.add('highlight-animation');
      setTimeout(() => {
        element.classList.remove('highlight-animation');
      }, 2000);
    }
  };

  // Modifikasi fungsi handleErrorClick
  const handleErrorClick = (
    section: 'personalInfo' | 'address' | 'contact'
  ) => {
    if (section === 'contact') {
      // Urutan field kontak yang akan dicek
      const contactFieldOrder = [
        'phoneNumber[0].phoneNumber',
        'phoneNumber[0].usageType'
      ];

      // Cari field kontak pertama yang kosong berdasarkan urutan
      const firstEmptyField = contactFieldOrder.find(fieldId => {
        const element = document.getElementById(fieldId);
        if (!element) return false;

        if (
          element instanceof HTMLInputElement ||
          element instanceof HTMLSelectElement
        ) {
          return !element.value;
        }
        return false;
      });

      if (firstEmptyField) {
        // Pastikan tab kontak aktif sebelum scroll
        setActiveTab('kontak');

        // Beri delay untuk memastikan tab sudah aktif
        setTimeout(() => {
          scrollToAndHighlightElement(firstEmptyField);
        }, 100);
      }
    } else if (section === 'address') {
      // Urutan field alamat yang akan dicek
      const addressFieldOrder = [
        'address[0].streetAddress',
        'address[0].province',
        'address[0].city',
        'address[0].district',
        'address[0].village',
        'address[0].country',
        'address[0].addressType'
      ];

      const firstEmptyField = addressFieldOrder.find(fieldId => {
        const element = document.getElementById(fieldId);
        if (!element) return false;

        if (
          element instanceof HTMLInputElement ||
          element instanceof HTMLSelectElement
        ) {
          return !element.value;
        }
        return false;
      });

      if (firstEmptyField) {
        // Pastikan tab alamat aktif sebelum scroll
        setActiveTab('alamat');

        // Beri delay untuk memastikan tab sudah aktif
        setTimeout(() => {
          scrollToAndHighlightElement(firstEmptyField);
        }, 100);
      }
    } else if (section === 'personalInfo') {
      // Existing personal info validation logic
      const fieldOrder = [
        'firstName',
        // 'lastName',
        'employeeType',
        'gender',
        'religion',
        'maritalStatus',
        'idType',
        'idNumber',
        'dateOfBirth',
        'branch',
        'joinDate'
      ];

      const firstEmptyField = fieldOrder.find(fieldId => {
        const element = document.getElementById(fieldId);
        if (!element) return false;

        if (
          element instanceof HTMLInputElement ||
          element instanceof HTMLSelectElement
        ) {
          return !element.value;
        }
        return false;
      });

      if (firstEmptyField) {
        scrollToAndHighlightElement(firstEmptyField);
      }
    }
  };

  // Fungsi untuk scroll dan highlight yang lebih robust
  const scrollToAndHighlightElement = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      // Tunggu sebentar untuk memastikan tab transition selesai
      setTimeout(() => {
        // Scroll ke element dengan offset
        const headerOffset = 100;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });

        // Tambahkan class untuk animasi highlight
        element.classList.add('highlight-animation');

        // Focus ke element
        setTimeout(() => {
          element.focus();
        }, 500); // Delay focus sedikit untuk memastikan scroll selesai

        // Hapus class setelah animasi selesai
        setTimeout(() => {
          element.classList.remove('highlight-animation');
        }, 2000);
      }, 150); // Tunggu tab transition
    }
  };
  // Handle New Adress
  const addNewAddress = () => {
    setFormData(prevData => ({
      ...prevData,
      address: [
        ...prevData.address,
        {
          streetAddress: '',
          state: 'NY',
          province: 0,
          city: 0,
          district: 0,
          village: 0,
          country: '',
          postalCode: '',
          addressType: ''
        }
      ]
    }));

    // Tambahkan state baru untuk lokasi
    setSelectedProvinceIds(prev => [...prev, '']);
    setSelectedRegencyIds(prev => [...prev, '']);
    setSelectedDistrictIds(prev => [...prev, '']);
  };

  const removeAddress = (indexToRemove: number) => {
    if (formData.address.length > 1) {
      setFormData(prevData => ({
        ...prevData,
        address: prevData.address.filter((_, index) => index !== indexToRemove)
      }));

      // Hapus state lokasi untuk alamat yang dihapus
      setSelectedProvinceIds(prev =>
        prev.filter((_, i) => i !== indexToRemove)
      );
      setSelectedRegencyIds(prev => prev.filter((_, i) => i !== indexToRemove));
      setSelectedDistrictIds(prev =>
        prev.filter((_, i) => i !== indexToRemove)
      );
    }
  };

  const addNewPhoneNumber = () => {
    setFormData(prevData => ({
      ...prevData,
      phoneNumber: [
        ...prevData.phoneNumber,
        { phoneNumber: '', usageType: '', isWhatsapp: false }
      ]
    }));
  };

  const removePhoneNumber = (indexToRemove: number) => {
    if (formData.phoneNumber.length > 1) {
      setFormData(prevData => ({
        ...prevData,
        phoneNumber: prevData.phoneNumber.filter(
          (_, index) => index !== indexToRemove
        )
      }));
    }
  };

  const addNewEmergencyContact = () => {
    setFormData(prevData => ({
      ...prevData,
      emergencyContact: [
        ...prevData.emergencyContact,
        {
          contactName: '',
          phoneNumber: '',
          relationshipStatus: '',
          isWhatsapp: false,
          address: ''
        }
      ]
    }));
  };

  const removeEmergencyContact = (indexToRemove: number) => {
    if (formData.emergencyContact.length > 1) {
      setFormData(prevData => ({
        ...prevData,
        emergencyContact: prevData.emergencyContact.filter(
          (_, index) => index !== indexToRemove
        )
      }));
    }
  };

  const addNewEmail = () => {
    setFormData(prevData => ({
      ...prevData,
      email: [...prevData.email, { emailAddress: '', usageType: '' }]
    }));
  };

  const removeEmail = (indexToRemove: number) => {
    if (formData.email.length > 1) {
      setFormData(prevData => ({
        ...prevData,
        email: prevData.email.filter((_, index) => index !== indexToRemove)
      }));
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;

    // Ubah penanganan khusus untuk radio button
    if (type === 'radio') {
      setFormData(prevData => ({
        ...prevData,
        [name]: value === 'true' // Konversi string ke boolean
      }));
      return;
    }

    // Existing form data update logic
    setFormData(prevData => {
      if (name.includes('[')) {
        const [arrayField, indexAndField] = name.split('[');
        const index = parseInt(indexAndField.split(']')[0]);
        const fieldName = indexAndField.split('.')[1];

        return {
          ...prevData,
          [arrayField]: Array.isArray(prevData[arrayField as keyof FormData])
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (prevData[arrayField as keyof FormData] as any[]).map(
                (item, idx) =>
                  idx === index ? { ...item, [fieldName]: value } : item
              )
            : prevData[arrayField as keyof FormData]
        };
      }

      return {
        ...prevData,
        [name]: value
      };
    });
  };

  // Modifikasi fungsi handleTemplateFieldChange untuk menangani date dengan benar
  const handleTemplateFieldChange = (
    field: EmployeeTemplateFieldDetail,
    value: any
  ) => {
    setFormData(prev => {
      const existingResponseIndex = prev.employeeTemplateResponse?.findIndex(
        resp => resp.fieldId === String(field.id) // Pastikan konsisten menggunakan String
      );

      const newResponse: EmployeeTemplateFieldValue = {
        fieldId: String(field.id),
        responseValue: '',
        optionResponse: []
      };

      switch (field.fieldType.code) {
        case 'DATE': {
          const dateValue = value ? new Date(value).toISOString() : '';
          newResponse.responseValue = dateValue;
          break;
        }
        case 'CHECKBOXES': {
          const optionId = value.id; // Tidak perlu Number()
          const isChecked = value.checked;

          const fieldResponse = prev.employeeTemplateResponse?.find(
            resp => resp.fieldId === String(field.id)
          );

          const currentOptionResponses =
            fieldResponse?.optionResponse ||
            field.employeeTemplateFieldOption.map(opt => ({
              optionId: opt.id, // Tidak perlu Number()
              selectedChoice: false
            }));

          const updatedOptionResponses = field.employeeTemplateFieldOption.map(
            opt => ({
              optionId: opt.id, // Tidak perlu Number()
              selectedChoice:
                opt.id === optionId
                  ? isChecked
                  : currentOptionResponses.find(
                      curr => curr.optionId === opt.id // Tidak perlu Number()
                    )?.selectedChoice || false
            })
          );

          newResponse.optionResponse = updatedOptionResponses;
          break;
        }
        case 'RADIO_BUTTONS':
        case 'DROPDOWN_LIST': {
          newResponse.responseValue = value;
          newResponse.optionResponse = field.employeeTemplateFieldOption.map(
            opt => ({
              optionId: opt.id, // Tidak perlu Number()
              selectedChoice: opt.optionValue === value
            })
          );
          break;
        }
        case 'SINGLE_LINE_TEXT':
        case 'MULTIPLE_LINE_TEXT': {
          newResponse.responseValue = value;
          break;
        }
        case 'MULTI_SELECT_LIST': {
          const selectedValues = value as string[];
          newResponse.responseValue = selectedValues.join(',');
          newResponse.optionResponse = field.employeeTemplateFieldOption.map(
            opt => ({
              optionId: opt.id,
              selectedChoice: selectedValues.includes(opt.optionValue)
            })
          );
          break;
        }
      }

      const newEmployeeTemplateResponse = [
        ...(prev.employeeTemplateResponse || [])
      ];
      if (existingResponseIndex >= 0) {
        newEmployeeTemplateResponse[existingResponseIndex] = newResponse;
      } else {
        newEmployeeTemplateResponse.push(newResponse);
      }

      return {
        ...prev,
        employeeTemplateResponse: newEmployeeTemplateResponse
      };
    });
  };

  // Tambahkan useEffect untuk menginisialisasi nilai default
  useEffect(() => {
    if (allFields?.data) {
      const defaultResponses: EmployeeTemplateFieldValue[] = [];

      allFields.data.forEach(field => {
        if (field.employeeTemplateFieldOption?.length) {
          switch (field.fieldType.code) {
            case 'MULTI_SELECT_LIST': {
              const selectedOptions = field.employeeTemplateFieldOption
                .filter(opt => opt.selectedChoice)
                .map(opt => opt.optionValue);

              if (selectedOptions.length > 0) {
                defaultResponses.push({
                  fieldId: String(field.id),
                  responseValue: selectedOptions.join(','),
                  optionResponse: field.employeeTemplateFieldOption.map(
                    opt => ({
                      optionId: opt.id,
                      selectedChoice: opt.selectedChoice
                    })
                  )
                });
              }
              break;
            }
            case 'CHECKBOXES': {
              const optionResponses = field.employeeTemplateFieldOption.map(
                opt => ({
                  optionId: opt.id, // Hapus Number()
                  selectedChoice: opt.selectedChoice
                })
              );

              defaultResponses.push({
                fieldId: String(field.id),
                responseValue: '',
                optionResponse: optionResponses
              });
              break;
            }
            case 'RADIO_BUTTONS':
            case 'DROPDOWN_LIST': {
              const selectedOption = field.employeeTemplateFieldOption.find(
                opt => opt.selectedChoice
              );
              if (selectedOption) {
                defaultResponses.push({
                  fieldId: String(field.id),
                  responseValue: selectedOption.optionValue,
                  optionResponse: field.employeeTemplateFieldOption.map(
                    opt => ({
                      optionId: opt.id, // Hapus Number()
                      selectedChoice: opt.id === selectedOption.id
                    })
                  )
                });
              }
              break;
            }
          }
        }
      });

      if (defaultResponses.length > 0) {
        setFormData(prev => ({
          ...prev,
          employeeTemplateResponse: defaultResponses
        }));
      }
    }
  }, [allFields?.data]);

  // Modifikasi fungsi isFieldInvalid
  const isFieldInvalid = (field: EmployeeTemplateFieldDetail) => {
    if (!field.isRequired) return false;

    const response = formData.employeeTemplateResponse?.find(
      resp => resp.fieldId === String(field.id)
    );

    switch (field.fieldType.code) {
      case 'CHECKBOXES':
        return !response?.optionResponse?.some(opt => opt.selectedChoice);
      case 'MULTI_SELECT_LIST':
        return !response?.responseValue || response.responseValue === '';
      default:
        return !response?.responseValue || response.responseValue.trim() === '';
    }
  };

  // Modifikasi renderDynamicField untuk menampilkan tanggal dengan benar
  const renderDynamicField = (field: EmployeeTemplateFieldDetail) => {
    const fieldResponse = formData.employeeTemplateResponse.find(
      resp => resp.fieldId === String(field.id)
    );

    const fieldValue = fieldResponse?.responseValue || '';

    switch (field.fieldType.code) {
      case 'SINGLE_LINE_TEXT':
        return (
          <Form.Group className="mb-3">
            <Form.Label className="p-0 fs-9 text-capitalize">
              {field.label}{' '}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Control
              type="text"
              value={fieldValue}
              onChange={e => handleTemplateFieldChange(field, e.target.value)}
              isInvalid={validated && isFieldInvalid(field)}
            />
            {validated && isFieldInvalid(field) && (
              <div className="invalid-feedback d-block">
                {field.label} harus diisi
              </div>
            )}
          </Form.Group>
        );

      case 'MULTIPLE_LINE_TEXT':
        return (
          <Form.Group className="mb-3">
            <Form.Label className="p-0 fs-9 text-capitalize">
              {field.label}{' '}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={fieldValue}
              onChange={e => handleTemplateFieldChange(field, e.target.value)}
              isInvalid={validated && isFieldInvalid(field)}
            />
            {validated && isFieldInvalid(field) && (
              <div className="invalid-feedback d-block">
                {field.label} harus diisi
              </div>
            )}
          </Form.Group>
        );

      case 'RADIO_BUTTONS':
        return (
          <Form.Group className="mb-3">
            <Form.Label className="p-0 fs-9 text-capitalize">
              {field.label}{' '}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <div className="d-flex flex-column">
              {field.employeeTemplateFieldOption.map(option => (
                <Form.Check
                  key={option.id}
                  type="radio"
                  label={option.optionValue}
                  checked={fieldValue === option.optionValue}
                  onChange={() =>
                    handleTemplateFieldChange(field, option.optionValue)
                  }
                />
              ))}
            </div>
          </Form.Group>
        );

      case 'CHECKBOXES':
        return (
          <Form.Group className="mb-3">
            <Form.Label className="p-0 fs-9 text-capitalize">
              {field.label}{' '}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <div className="d-flex flex-column">
              {field.employeeTemplateFieldOption.map(option => (
                <Form.Check
                  key={option.id}
                  type="checkbox"
                  label={option.optionValue}
                  checked={
                    fieldResponse?.optionResponse?.find(
                      resp => resp.optionId === option.id // Tidak perlu Number()
                    )?.selectedChoice ?? option.selectedChoice
                  }
                  onChange={e => {
                    handleTemplateFieldChange(field, {
                      id: option.id,
                      checked: e.target.checked
                    });
                  }}
                  isInvalid={validated && isFieldInvalid(field)}
                />
              ))}
            </div>
            {validated && isFieldInvalid(field) && (
              <div className="invalid-feedback d-block">
                Pilih minimal satu {field.label}
              </div>
            )}
          </Form.Group>
        );

      case 'DROPDOWN_LIST':
        return (
          <Form.Group className="mb-3">
            <Form.Label className="p-0 fs-9 text-capitalize">
              {field.label}{' '}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Select
              value={fieldValue}
              onChange={e => handleTemplateFieldChange(field, e.target.value)}
              isInvalid={validated && isFieldInvalid(field)}
            >
              <option value="">Pilih {field.label}</option>
              {field.employeeTemplateFieldOption.map(option => (
                <option key={option.id} value={option.optionValue}>
                  {option.optionValue}
                </option>
              ))}
            </Form.Select>
            {validated && isFieldInvalid(field) && (
              <div className="invalid-feedback d-block">
                {field.label} harus diisi
              </div>
            )}
          </Form.Group>
        );

      case 'DATE':
        return (
          <Form.Group className="mb-3">
            <Form.Label className="p-0 fs-9 text-capitalize">
              {field.label}{' '}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Control
              type="date"
              value={fieldValue ? fieldValue.split('T')[0] : ''}
              onChange={e => handleTemplateFieldChange(field, e.target.value)}
              isInvalid={validated && isFieldInvalid(field)}
            />
            {validated && isFieldInvalid(field) && (
              <div className="invalid-feedback d-block">
                {field.label} harus diisi
              </div>
            )}
          </Form.Group>
        );

      case 'MULTI_SELECT_LIST':
        return (
          <Form.Group controlId={field.fieldName} className="mb-3">
            <Form.Label className="p-0 fs-9 text-capitalize">
              {field.label}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Select
              isMulti
              name={field.fieldName}
              className="custom-select"
              classNamePrefix="select"
              styles={{
                option: base => ({
                  ...base,
                  cursor: 'pointer'
                }),
                multiValue: base => ({
                  ...base,
                  cursor: 'pointer'
                }),
                clearIndicator: base => ({
                  ...base,
                  cursor: 'pointer'
                }),
                dropdownIndicator: base => ({
                  ...base,
                  cursor: 'pointer'
                })
              }}
              value={fieldValue
                .split(',')
                .filter(Boolean)
                .map(val => ({
                  value: val,
                  label: val
                }))}
              onChange={selectedOptions => {
                const selectedValues = (selectedOptions || []).map(
                  option => option.value
                );
                handleTemplateFieldChange(field, selectedValues);
              }}
              options={field.employeeTemplateFieldOption.map(option => ({
                value: option.optionValue,
                label: option.optionValue
              }))}
              placeholder={`Pilih ${field.label}`}
            />
            {validated && isFieldInvalid(field) && (
              <div className="invalid-feedback d-block">
                {field.label} harus diisi
              </div>
            )}
          </Form.Group>
        );

      default:
        return null;
    }
  };

  // Modifikasi handleSubmit untuk menyimpan data template fields
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setValidated(true);

    if (!validateSections()) {
      showCustomAlert({
        icon: 'error',
        label: 'Validasi Gagal',
        deskripsi:
          'Silakan periksa kembali form anda dan isi semua field yang wajib diisi.',
        buttonType: 'ok'
      });
      return;
    }

    try {
      const transformedData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        nickName: formData.firstName,
        color: formData.color || '#E3E6EF',
        hireDate: new Date(formData.joinDate).toISOString().split('T')[0],
        isActive: formData.isActive,
        joinDate: new Date(formData.joinDate).toISOString().split('T')[0],
        employmentType: formData.employmentType,
        gender: formData.gender === 'male' ? 'M' : 'F',
        religion: formData.religion,
        idType: formData.idType,
        idNumber: formData.idNumber,
        maritalStatus: formData.maritalStatus,
        jobTitle: formData.jobTitle,
        branch: formData.branch,
        dateOfBirth: new Date(formData.dateOfBirth).toISOString().split('T')[0],

        phoneNumbers: formData.phoneNumber.map(phone => ({
          usageType: phone.usageType,
          phoneNumber: phone.phoneNumber,
          isWhatsapp: phone.isWhatsapp
        })),

        emergencyContacts: formData.emergencyContact.map(contact => ({
          contactName: contact.contactName,
          relationshipStatus: contact.relationshipStatus,
          phoneNumber: contact.phoneNumber,
          isWhatsapp: contact.isWhatsapp,
          address: contact.address
        })),

        emails: formData.email.map(email => ({
          usageType: email.usageType,
          emailAddress: email.emailAddress
        })),

        addresses: formData.address.map(addr => ({
          streetAddress: addr.streetAddress,
          state: addr.state,
          province: Number(addr.province),
          city: Number(addr.city),
          district: Number(addr.district),
          village: Number(addr.village),
          country: addr.country,
          postalCode: addr.postalCode,
          addressType: addr.addressType
        })),

        employeeNotes: [
          {
            noteType: 'private',
            content: 'Initial note'
          }
        ],

        employeeInfo: {
          employeeStatus: formData.employeeStatus,
          employmentRelationship: formData.employmentRelationship || 'tetap',
          registrationNumber: formData.registrationNumber || 'EMP123456',
          annualReview:
            formData.annualReview || new Date().toISOString().split('T')[0]
        },

        employeeRemuneration: {
          paymentPeriod: formData.paymentPeriod,
          paymentAmount: Number(formData.paymentAmount),
          annualLeaveAllowance: Number(formData.annualLeaveAllowance),
          sickLeaveAllowance: Number(formData.sickLeaveAllowance),
          bankName: formData.bank,
          accountNumber: formData.accountNumber
        },

        // Template responses - hanya ambil response untuk template yang aktif
        employeeTemplateResponse: formData.employeeTemplateResponse
          .filter(resp => {
            // Cari template yang sesuai dengan field
            const field = allFields?.data.find(f => f.id === resp.fieldId);
            const template = templates?.data.result.find(t =>
              t.employeeTemplateFields.some(tf => tf.id === field?.id)
            );
            // Hanya ambil jika template aktif dan memiliki fields
            return (
              template?.status && template?.employeeTemplateFields.length > 0
            );
          })
          .map(resp => ({
            ...resp,
            responseValue: resp.responseValue || '-' // Berikan nilai default jika kosong
          }))
      };

      // Tampilkan konfirmasi dengan loading
      const confirmAlert = showCustomAlert({
        icon: 'question',
        label: 'Konfirmasi Submit',
        deskripsi: 'Apakah anda yakin ingin menyimpan data pegawai ini?',
        buttonType: 'yes-no',
        onConfirm: async () => {
          try {
            const response = await addEmployee(
              transformedData as unknown as FormData
            ).unwrap();

            showCustomAlert({
              icon: 'success',
              label: 'Berhasil',
              deskripsi: 'Data pegawai berhasil ditambahkan',
              buttonType: 'ok',
              onConfirm: () => {
                if (shouldResetForm) {
                  // Reset form tapi tidak tutup modal
                  setFormData(initialFormState);
                  setValidated(false);
                  setShouldResetForm(false);
                } else {
                  // Tutup modal dan reset form
                  handleClose();
                  setFormData(initialFormState);
                  setValidated(false);
                }
                onSuccess?.();
              }
            });
          } catch (error: any) {
            showCustomAlert({
              icon: 'error',
              label: 'Gagal',
              deskripsi: error.data?.message
                ? formatErrorMessage(error.data.message)
                : 'Gagal menambahkan data pegawai',
              buttonType: 'ok'
            });
          }
        },
        onCancel: () => {
          // Jangan lakukan apapun, biarkan modal tetap terbuka
        }
      });
    } catch (error) {
      showCustomAlert({
        icon: 'error',
        label: 'Gagal',
        deskripsi: 'Gagal menambahkan data pegawai',
        buttonType: 'ok'
      });
    }
  };

  // Tambahkan handler untuk button "Simpan & Buat Baru"
  const handleSaveAndNew = () => {
    setShouldResetForm(true);
    const form = document.getElementById('addEmployeeForm') as HTMLFormElement;
    if (form) {
      form.requestSubmit();
    }
  };

  const handleSaveClick = () => {
    const form = document.getElementById('addClientForm') as HTMLFormElement;
    if (form) {
      form.requestSubmit();
    }
  };

  const handleSelect = (key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  };

  // Modifikasi handleCancelClick
  const handleCancelClick = () => {
    showCustomAlert({
      icon: 'warning',
      label: 'Konfirmasi Batal',
      deskripsi:
        'Apakah anda yakin ingin membatalkan? Semua perubahan akan hilang',
      buttonType: 'yes-no',
      onConfirm: () => {
        setFormData(initialFormState); // Reset form ke nilai awal
        setValidated(false);
        setSelectedProvinceIds([]);
        setSelectedRegencyIds([]);
        setSelectedDistrictIds([]);
        setSectionErrors({
          personalInfo: [],
          address: [],
          contact: [],
          template: []
        });
        setShowErrorDetails({
          personalInfo: false,
          address: false,
          contact: false
        });
        setActiveTab('alamat');
        handleClose();
      }
    });
  };

  // Di dalam komponen TambahPegawaiModal, ambil data jabatan
  const { data: positionData } = useGetAllPositionsQuery({
    page: 1,
    limit: 1000,
    orderBy: 'name',
    direction: 'ASC',
    name: ''
  });

  // Update useEffect untuk reset form saat modal dibuka
  useEffect(() => {
    if (show) {
      setFormData(initialFormState);
      setValidated(false);
      setShouldResetForm(false);
    }
  }, [show]);

  // Tambahkan helper function untuk memformat pesan error
  const formatErrorMessage = (message: string) => {
    const errorMap: { [key: string]: string } = {
      'employmentType should not be empty': 'Tipe Pekerjaan harus diisi',
      'jobTitle should not be empty': 'Jabatan harus diisi',
      'jobTitle must be a UUID': 'Format Jabatan tidak valid',
      'paymentPeriod should not be empty': 'Periode Pembayaran harus diisi',
      'bankName should not be empty': 'Nama Bank harus diisi',
      'accountNumber must match /^\\d{10,12}$/ regular expression':
        'Nomor Rekening harus berupa 10-12 digit angka',
      'accountNumber should not be empty': 'Nomor Rekening harus diisi'
    };

    return message
      .split(', ')
      .map(err => errorMap[err.trim()] || err)
      .join('\n');
  };

  return (
    <>
      <style>
        {`
          .vertical-divider {
            position: absolute;
            left: 50%;
            top: 0;
            bottom: 0;
            width: 1px;
            background-color: #c5c5c5;
            transform: translateX(-50%);
            z-index: 1;
            opacity: 1;
          }
          
        `}
      </style>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header className="px-5 py-4">
          <Modal.Title className="me-auto fw-bolder">
            Tambah pegawai
          </Modal.Title>
          <div className="d-flex gap-2">
            <Button
              variant="link"
              className="text-danger"
              onClick={handleCancelClick}
            >
              Batalkan
            </Button>
            <Button variant="outline-primary" onClick={handleSaveAndNew}>
              Simpan & Buat Baru
            </Button>
            <Button variant="primary" type="submit" form="addEmployeeForm">
              Simpan
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body className="bg-light px-5 py-4">
          <Form
            id="addEmployeeForm"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <div className="vertical-divider" />
            <Row>
              <Col md={6} className="d-flex flex-column gap-3">
                <div>
                  <h4 className="mt-2">Informasi Pribadi</h4>
                  <Row>
                    <Col
                      md={4}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Avatar size="4xl" />
                    </Col>
                    <Col md={8}>
                      <Form.Group controlId="firstName">
                        <Form.Label className="p-0 fs-9 text-capitalize">
                          Nama Depan <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          required
                          placeholder="Masukkan nama depan"
                          isInvalid={validated && !formData.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama depan harus diisi
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="lastName" className="mt-2">
                        <Form.Label className="p-0 fs-9 text-capitalize">
                          Nama Belakang
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          placeholder="Masukkan nama belakang"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group className="mb-3" controlId="color">
                        <Form.Label className="text-capitalize fs-9 p-0">
                          Warna <span className="text-danger">*</span>
                        </Form.Label>
                        <div className="d-flex gap-2 align-items-center">
                          <div
                            className="position-relative"
                            style={{ width: '100%' }}
                          >
                            <Form.Control
                              type="color"
                              value={formData.color}
                              onChange={e =>
                                setFormData({
                                  ...formData,
                                  color: e.target.value
                                })
                              }
                              required
                              title="Pilih warna"
                              style={{
                                width: '100%',
                                height: '38px',
                                padding: '2px',
                                cursor: 'pointer',
                                opacity: formData.color ? '1' : '0.5'
                              }}
                            />
                            {!formData.color && (
                              <div
                                className="position-absolute top-50 start-50 translate-middle"
                                style={{
                                  pointerEvents: 'none',
                                  color: '#FFFFFF'
                                }}
                              >
                                Pilih warna
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              padding: '8px 12px',
                              borderRadius: '4px',
                              border: '1px solid #dee2e6',
                              fontSize: '14px',
                              backgroundColor: '#f8f9fa'
                            }}
                          >
                            {formData.color.toUpperCase()}
                          </div>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          Warna harus dipilih
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Form.Group controlId="idType">
                        <Form.Label className="p-0 fs-9 text-capitalize">
                          Tipe Identifikasi{' '}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name="idType"
                          value={formData.idType}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Pilih tipe identifikasi</option>
                          <option value="KTP">KTP</option>
                          <option value="SIM">SIM</option>
                          <option value="Passport">Passport</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Tipe identifikasi harus dipilih
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="idNumber">
                        <Form.Label className="p-0 fs-9 text-capitalize">
                          Nomor Identifikasi{' '}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="idNumber"
                          value={formData.idNumber}
                          onChange={handleChange}
                          required
                          placeholder="Masukkan nomor identifikasi"
                          isInvalid={validated && !formData.idNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          Nomor identifikasi harus diisi
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={12}>
                      <Form.Group controlId="gender">
                        <Form.Label className="p-0 fs-9 text-capitalize">
                          Jenis Kelamin <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name="gender"
                          value={formData.gender}
                          onChange={handleChange}
                          required
                          isInvalid={validated && !formData.gender}
                        >
                          <option value="">Pilih jenis kelamin</option>
                          <option value="male">Laki-laki</option>
                          <option value="female">Perempuan</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Jenis kelamin harus dipilih
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Form.Group controlId="religion">
                        <Form.Label className="p-0 fs-9 text-capitalize">
                          Agama <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name="religion"
                          value={formData.religion}
                          onChange={handleChange}
                          required
                          isInvalid={validated && !formData.religion}
                        >
                          <option value="">Pilih agama</option>
                          <option value="Islam">Islam</option>
                          <option value="Kristen">Kristen</option>
                          <option value="Katolik">Katolik</option>
                          <option value="Hindu">Hindu</option>
                          <option value="Buddha">Buddha</option>
                          <option value="Konghucu">Konghucu</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Agama harus dipilih
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="maritalStatus">
                        <Form.Label className="p-0 fs-9 text-capitalize">
                          Status Perkawinan{' '}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name="maritalStatus"
                          value={formData.maritalStatus}
                          onChange={handleChange}
                          required
                          isInvalid={validated && !formData.maritalStatus}
                        >
                          <option value="">Pilih status perkawinan</option>
                          <option value="single">Belum Menikah</option>
                          <option value="married">Menikah</option>
                          <option value="divorced">Cerai</option>
                          <option value="widowed">Duda</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Status perkawinan harus dipilih
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12}>
                      <Form.Group controlId="dateOfBirth">
                        <Form.Label className="p-0 fs-9 text-capitalize">
                          Tanggal Lahir <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="dateOfBirth"
                          value={formData.dateOfBirth}
                          onChange={handleChange}
                          required
                          isInvalid={validated && !formData.dateOfBirth}
                        />
                        <Form.Control.Feedback type="invalid">
                          Tanggal lahir harus diisi
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Form.Group controlId="joinDate">
                        <Form.Label className="p-0 fs-9 text-capitalize">
                          Tanggal Bergabung{' '}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="joinDate"
                          value={formData.joinDate}
                          onChange={handleChange}
                          required
                          isInvalid={validated && !formData.joinDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          Tanggal bergabung harus diisi
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="joinDate">
                        <Form.Label className="p-0 fs-9 text-capitalize">
                          Tanggal Berakhir
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          value={formData.endDate}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={12}>
                      <Form.Group controlId="location">
                        <Form.Label className="p-0 fs-9 text-capitalize">
                          Lokasi Kerja <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name="branch"
                          value={formData.branch}
                          onChange={handleChange}
                          required
                          isInvalid={validated && !formData.branch}
                        >
                          <option value="">Pilih lokasi</option>
                          <option value="jakarta">Jakarta</option>
                          <option value="bandung">Bandung</option>
                          <option value="surabaya">Surabaya</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Lokasi kerja harus dipilih
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12}></Col>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <Tab.Container
                  activeKey={activeTab}
                  onSelect={k => k && setActiveTab(k)}
                >
                  <Nav
                    variant="tabs"
                    className="mb-3 d-flex justify-content-between"
                  >
                    <Nav.Item>
                      <Button
                        size="sm"
                        className="rounded-pill fs-10"
                        variant={
                          activeTab === 'pekerjaan'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('pekerjaan')}
                      >
                        <FontAwesomeIcon icon={faBriefcase} className="me-2" />
                        Pekerjaan
                      </Button>
                    </Nav.Item>
                    <Nav.Item>
                      <Button
                        size="sm"
                        className="rounded-pill fs-10"
                        variant={
                          activeTab === 'alamat' ? 'primary' : 'outline-primary'
                        }
                        onClick={() => setActiveTab('alamat')}
                      >
                        <FontAwesomeIcon
                          icon={faMapLocation}
                          className="me-2"
                        />
                        Alamat
                      </Button>
                    </Nav.Item>
                    <Nav.Item>
                      <Button
                        size="sm"
                        className="rounded-pill fs-10"
                        variant={
                          activeTab === 'kontak' ? 'primary' : 'outline-primary'
                        }
                        onClick={() => setActiveTab('kontak')}
                      >
                        <FontAwesomeIcon
                          icon={faContactBook}
                          className="me-2"
                        />
                        Kontak
                      </Button>
                    </Nav.Item>
                    <Nav.Item>
                      <Button
                        size="sm"
                        className="rounded-pill fs-10"
                        variant={
                          activeTab === 'layanan'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('layanan')}
                      >
                        <FontAwesomeIcon icon={faClipboard} className="me-2" />
                        Layanan
                      </Button>
                    </Nav.Item>
                    <Nav.Item>
                      <Button
                        size="sm"
                        className="rounded-pill fs-10"
                        variant={
                          activeTab === 'formTemplat'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('formTemplat')}
                      >
                        <FontAwesomeIcon icon={faFileLines} className="me-2" />
                        Form Templat
                      </Button>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="pekerjaan">
                      <div className="d-flex flex-column gap-3">
                        <h4>Informasi Pekerjaan</h4>

                        <Form.Group controlId="employeeStatus">
                          <Form.Label className="p-0 fs-9 text-capitalize">
                            Status Kepegawaian
                          </Form.Label>
                          <div className="d-flex align-items-center gap-3">
                            <Form.Check
                              className="m-0"
                              inline
                              type="radio"
                              label="Aktif"
                              name="employeeStatus"
                              value="true"
                              checked={formData.employeeStatus === true}
                              onChange={handleChange}
                              required
                            />
                            <Form.Check
                              className="m-0"
                              inline
                              type="radio"
                              label="Tidak Aktif"
                              name="employeeStatus"
                              value="false"
                              checked={formData.employeeStatus === false}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </Form.Group>
                        <Row>
                          <Col md={12}>
                            <Form.Group controlId="jobTitle">
                              <Form.Label className="p-0 fs-9 text-capitalize">
                                Jabatan <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                name="jobTitle"
                                value={formData.jobTitle} // Sekarang value-nya langsung UUID
                                onChange={handleChange}
                                required
                              >
                                <option value="">Pilih jabatan</option>
                                {positionData?.data?.result?.map(position => (
                                  <option key={position.id} value={position.id}>
                                    {position.name}
                                  </option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Jabatan harus dipilih
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Form.Group controlId="employmentType">
                          <Form.Label className="p-0 fs-9 text-capitalize">
                            Tipe Pekerjaan
                          </Form.Label>
                          <Form.Select
                            name="employmentType"
                            value={formData.employmentType}
                            onChange={handleChange}
                          >
                            <option value="">Pilih tipe pekerjaan</option>
                            <option value="Full-Time">Tetao</option>
                            <option value="Part-Time">Paruh Waktu</option>
                            <option value="Contract">Kontrak</option>
                            <option value="Intern">Magang</option>
                          </Form.Select>
                        </Form.Group>
                        <Row>
                          <Col md={6}>
                            <Form.Group controlId="employmentRelationship">
                              <Form.Label className="p-0 fs-9 text-capitalize">
                                Jenis Hubungan Kerja
                              </Form.Label>
                              <Form.Select
                                name="employmentRelationship"
                                value={formData.employmentRelationship}
                                onChange={handleChange}
                              >
                                <option value="">
                                  Pilih jenis hubungan kerja
                                </option>
                                <option value="Tetap">Tetap</option>
                                <option value="Kontrak">Kontrak</option>
                                <option value="Magang">Magang</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group controlId="registrationNumber">
                              <Form.Label className="p-0 fs-9 text-capitalize">
                                Nomor Registrasi
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="registrationNumber"
                                value={formData.registrationNumber}
                                onChange={handleChange}
                                placeholder="Masukkan nomor registrasi"
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <Form.Group controlId="annualReview">
                              <Form.Label className="p-0 fs-9 text-capitalize">
                                Bulan Tinjauan Tahunan
                              </Form.Label>
                              <Form.Select
                                name="annualReview"
                                value={formData.annualReview}
                                onChange={handleChange}
                              >
                                <option value="">
                                  Pilih bulan tinjauan tahunan
                                </option>
                                <option value="Januari">Januari</option>
                                <option value="Februari">Februari</option>
                                <option value="Maret">Maret</option>
                                <option value="April">April</option>
                                <option value="Mei">Mei</option>
                                <option value="Juni">Juni</option>
                                <option value="Juli">Juli</option>
                                <option value="Agustus">Agustus</option>
                                <option value="September">September</option>
                                <option value="Oktober">Oktober</option>
                                <option value="November">November</option>
                                <option value="Desember">Desember</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* <Form.Group controlId="monthlyReview">
                          <Form.Label className="p-0 fs-9 text-capitalize">
                            Tinjauan Bulanan
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="monthlyReview"
                            value={formData.monthlyReview}
                            onChange={handleChange}
                            placeholder="Masukkan tinjauan bulanan"
                          />
                        </Form.Group> */}
                        <h4 className="mt-2">Gaji dan Kompensasi</h4>

                        <Row>
                          <Col md={6}>
                            <Form.Group controlId="paymentPeriod">
                              <Form.Label className="p-0 fs-9 text-capitalize">
                                Periode Pembayaran Gaji
                              </Form.Label>
                              <Form.Select
                                name="paymentPeriod"
                                value={formData.paymentPeriod}
                                onChange={handleChange}
                              >
                                <option value="">
                                  Pilih periode pembayaran gaji
                                </option>
                                <option value="hour">Perjam</option>
                                <option value="month">Perbulan</option>
                                <option value="year">Pertahun</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group controlId="paymentAmount">
                              <Form.Label className="p-0 fs-9 text-capitalize">
                                Jumlah Pembayaran
                              </Form.Label>
                              <Form.Control
                                name="paymentAmount"
                                value={formData.paymentAmount}
                                onChange={handleChange}
                                placeholder="Masukan jumlah pembayaran"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Form.Group controlId="annualLeaveAllowance">
                          <Form.Label className="p-0 fs-9 text-capitalize">
                            Tunjangan Liburan Tahunan (Hari)
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="annualLeaveAllowance"
                            value={formData.annualLeaveAllowance}
                            onChange={handleChange}
                            placeholder="Masukkan tunjangan (hari)"
                          />
                        </Form.Group>

                        <Form.Group controlId="sickLeaveAllowance">
                          <Form.Label className="p-0 fs-9 text-capitalize">
                            Tunjangan Sakit Tahunan (Hari)
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="sickLeaveAllowance"
                            value={formData.sickLeaveAllowance}
                            onChange={handleChange}
                            placeholder="Masukkan tunjangan (hari)"
                          />
                        </Form.Group>

                        <Row>
                          <Col md={6}>
                            <Form.Group controlId="bank">
                              <Form.Label className="p-0 fs-9 text-capitalize">
                                Bank
                              </Form.Label>
                              <Form.Select
                                name="bank"
                                value={formData.bank}
                                onChange={handleChange}
                              >
                                <option value="">Pilih bank</option>
                                <option value="bca">BCA</option>
                                <option value="mandiri">Mandiri</option>
                                <option value="bni">BNI</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group controlId="accountNumber">
                              <Form.Label className="p-0 fs-9 text-capitalize">
                                Nomor Rekening
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="accountNumber"
                                value={formData.accountNumber}
                                onChange={handleChange}
                                placeholder="Masukkan nomor rekening"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="alamat">
                      {formData.address.map((address, index) => {
                        const queries = locationQueries[index] || {
                          regencies: [],
                          districts: [],
                          villages: [],
                          isLoadingRegencies: false,
                          isLoadingDistricts: false,
                          isLoadingVillages: false
                        };

                        return (
                          <div
                            key={index}
                            className="d-flex flex-column gap-2 mb-2 pb-3 border-bottom"
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <h4 className="mt-1">
                                Alamat {index + 1}{' '}
                                <span className="text-danger">*</span>
                              </h4>
                              {formData.address.length > 1 && (
                                <Button
                                  variant="link"
                                  className="text-primary pe-0"
                                  size="sm"
                                  onClick={() => removeAddress(index)}
                                >
                                  <FontAwesomeIcon icon={faCircleMinus} /> Hapus
                                  alamat
                                </Button>
                              )}
                            </div>
                            {/* Form fields untuk alamat, hapus simbol required dari masing-masing field */}
                            <Form.Group
                              controlId={`address[${index}].streetAddress`}
                            >
                              <Form.Label className="fs-9 p-0 text-capitalize">
                                Alamat
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`address[${index}].streetAddress`}
                                value={formData.address[index]?.streetAddress}
                                onChange={handleChange}
                                placeholder="Masukkan alamat jalan"
                                required
                                isInvalid={
                                  validated &&
                                  !formData.address[index]?.streetAddress
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Alamat harus diisi
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="country">
                              <Form.Label className="fs-9 p-0 text-capitalize">
                                Negara
                              </Form.Label>
                              <Form.Select
                                name={`address[${index}].country`}
                                value={formData.address[index]?.country || ''}
                                onChange={handleChange}
                                required
                                isInvalid={
                                  validated && !formData.address[index]?.country
                                }
                              >
                                <option value="">Pilih negara</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Singapore">Singapura</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Thailand">Thailand</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Negara harus dipilih
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Row>
                              <Col md={6}>
                                <Form.Group controlId="province">
                                  <Form.Label className="fs-9 p-0 text-capitalize">
                                    Provinsi
                                  </Form.Label>
                                  <Form.Select
                                    name={`address[${index}].province`}
                                    value={address.province || ''}
                                    onChange={e => {
                                      const newAddress = [...formData.address];
                                      newAddress[index] = {
                                        ...newAddress[index],
                                        province: Number(e.target.value),
                                        city: 0,
                                        district: 0,
                                        village: 0
                                      };
                                      setFormData(prev => ({
                                        ...prev,
                                        address: newAddress
                                      }));
                                    }}
                                    required
                                    isInvalid={validated && !address.province}
                                  >
                                    <option value="">Pilih Provinsi</option>
                                    {provincesResponse?.data?.map(province => (
                                      <option
                                        key={province.id}
                                        value={province.id}
                                      >
                                        {province.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    Provinsi harus dipilih
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label className="fs-9 p-0 text-capitalize">
                                    Kota <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Select
                                    name={`address[${index}].city`}
                                    value={formData.address[index]?.city || ''}
                                    onChange={e => {
                                      const newAddress = [...formData.address];
                                      newAddress[index] = {
                                        ...newAddress[index],
                                        city: Number(e.target.value),
                                        district: 0,
                                        village: 0
                                      };
                                      setFormData(prev => ({
                                        ...prev,
                                        address: newAddress
                                      }));
                                    }}
                                    required
                                    disabled={
                                      !formData.address[index]?.province
                                    }
                                    isInvalid={
                                      validated &&
                                      !formData.address[index]?.city
                                    }
                                  >
                                    <option value="">Pilih Kota</option>
                                    {queries.regencies.map(city => (
                                      <option key={city.id} value={city.id}>
                                        {city.name}
                                      </option>
                                    ))}
                                    <Form.Control.Feedback type="invalid">
                                      Kota harus dipilih
                                    </Form.Control.Feedback>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label className="fs-9 p-0 text-capitalize">
                                    Kecamatan{' '}
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Select
                                    name={`address[${index}].district`}
                                    value={
                                      formData.address[index]?.district || ''
                                    }
                                    onChange={e => {
                                      const newAddress = [...formData.address];
                                      newAddress[index] = {
                                        ...newAddress[index],
                                        district: Number(e.target.value),
                                        village: 0
                                      };
                                      setFormData(prev => ({
                                        ...prev,
                                        address: newAddress
                                      }));
                                    }}
                                    required
                                    disabled={!formData.address[index]?.city}
                                    isInvalid={
                                      validated &&
                                      !formData.address[index]?.district
                                    }
                                  >
                                    <option value="">Pilih Kecamatan</option>
                                    {queries.districts.map(district => (
                                      <option
                                        key={district.id}
                                        value={district.id}
                                      >
                                        {district.name}
                                      </option>
                                    ))}
                                    <Form.Control.Feedback type="invalid">
                                      Kecamatan harus dipilih
                                    </Form.Control.Feedback>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label className="fs-9 p-0 text-capitalize">
                                    Desa/Kelurahan{' '}
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Select
                                    name={`address[${index}].village`}
                                    value={
                                      formData.address[index]?.village || ''
                                    }
                                    onChange={e => {
                                      const newAddress = [...formData.address];
                                      newAddress[index] = {
                                        ...newAddress[index],
                                        village: Number(e.target.value)
                                      };
                                      setFormData(prev => ({
                                        ...prev,
                                        address: newAddress
                                      }));
                                    }}
                                    required
                                    disabled={
                                      !formData.address[index]?.district
                                    }
                                    isInvalid={
                                      validated &&
                                      !formData.address[index]?.village
                                    }
                                  >
                                    <option value="">
                                      Pilih Desa/Kelurahan
                                    </option>
                                    {queries.villages.map(village => (
                                      <option
                                        key={village.id}
                                        value={village.id}
                                      >
                                        {village.name}
                                      </option>
                                    ))}
                                    <Form.Control.Feedback type="invalid">
                                      Desa/Kelurahan harus dipilih
                                    </Form.Control.Feedback>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>

                            <Form.Group controlId="postalCode">
                              <Form.Label className="fs-9 p-0 text-capitalize">
                                Kode Pos
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`address[${index}].postalCode`}
                                value={
                                  formData.address[index]?.postalCode || ''
                                }
                                onChange={handleChange}
                                placeholder="Masukkan Kode Pos"
                              />
                              <Form.Control.Feedback type="invalid">
                                Kode Pos harus diisi
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="addressType">
                              <Form.Label className="fs-9 p-0 text-capitalize">
                                Jenis Alamat{' '}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                name={`address[${index}].addressType`}
                                value={
                                  formData.address[index]?.addressType || ''
                                }
                                onChange={handleChange}
                                required
                              >
                                <option value="">Pilih jenis alamat</option>
                                <option value="Rumah">Rumah</option>
                                <option value="Kos">Kos</option>
                                <option value="Apartemen">Apartemen</option>
                                <option value="Lainnya">Lainnya</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Jenis Alamat harus dipilih
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        );
                      })}

                      <Button
                        variant="outline-primary"
                        className="mt-2 mb-2 w-100"
                        onClick={addNewAddress}
                      >
                        + Tambah Alamat
                      </Button>
                    </Tab.Pane>

                    <Tab.Pane eventKey="kontak">
                      {/* Tambahkan simbol required di sebelah judul */}
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="mt-2">
                          Telepon Pribadi <span className="text-danger">*</span>
                        </h4>
                      </div>

                      {formData.phoneNumber.map((phone, index) => (
                        <div
                          key={index}
                          className="d-flex flex-column gap-2 mb-2 pb-3 border-bottom"
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mt-2">Telepon {index + 1}</h5>
                            {formData.phoneNumber.length > 1 && (
                              <Button
                                variant="link"
                                className="text-primary pe-0"
                                size="sm"
                                onClick={() => removePhoneNumber(index)}
                              >
                                <FontAwesomeIcon icon={faCircleMinus} /> Hapus
                                telepon
                              </Button>
                            )}
                          </div>
                          <Form.Group
                            controlId={`phoneNumber[${index}].phoneNumber`}
                          >
                            <Form.Label className="fs-9 p-0 text-capitalize">
                              Nomor Telepon
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name={`phoneNumber[${index}].phoneNumber`}
                              value={phone.phoneNumber}
                              onChange={handleChange}
                              placeholder="Masukkan Nomor Telepon"
                              required
                              isInvalid={validated && !phone.phoneNumber}
                            />
                            <Form.Control.Feedback type="invalid">
                              Nomor telepon harus diisi
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Row>
                            <Col md={6}>
                              <Form.Group
                                controlId={`phoneNumber[${index}].usageType`}
                              >
                                <Form.Label className="fs-9 p-0 text-capitalize">
                                  Jenis Penggunaan
                                </Form.Label>
                                <Form.Select
                                  name={`phoneNumber[${index}].usageType`}
                                  value={phone.usageType}
                                  onChange={handleChange}
                                  required
                                  isInvalid={validated && !phone.usageType}
                                >
                                  <option value="">
                                    Pilih jenis penggunaan
                                  </option>
                                  <option value="Individual">Pribadi</option>
                                  <option value="Corporate/Business">
                                    Bisnis
                                  </option>
                                  <option value="Group">Grup</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  Jenis penggunaan telepon harus dipilih
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                controlId={`phoneNumber[${index}].isWhatsapp`}
                              >
                                <Form.Label className="fs-9 p-0 text-capitalize">
                                  Terhubung WhatsApp
                                </Form.Label>
                                <div className="d-flex align-items-center gap-3 mt-1">
                                  <Form.Check
                                    inline
                                    label="Ya"
                                    type="radio"
                                    name={`phoneNumber[${index}].isWhatsapp`}
                                    checked={phone.isWhatsapp === true}
                                    onChange={() => {
                                      handleChange({
                                        target: {
                                          name: `phoneNumber[${index}].isWhatsapp`,
                                          value: true
                                        }
                                      } as unknown as ChangeEvent<HTMLInputElement>);
                                    }}
                                    className="d-flex align-items-center gap-2"
                                  />
                                  <Form.Check
                                    inline
                                    label="Tidak"
                                    type="radio"
                                    name={`phoneNumber[${index}].isWhatsapp`}
                                    checked={phone.isWhatsapp === false}
                                    onChange={() => {
                                      handleChange({
                                        target: {
                                          name: `phoneNumber[${index}].isWhatsapp`,
                                          value: false
                                        }
                                      } as unknown as ChangeEvent<HTMLInputElement>);
                                    }}
                                    className="d-flex align-items-center gap-2"
                                  />
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      ))}

                      <Button
                        variant="outline-primary"
                        className="mb-2 w-100"
                        onClick={addNewPhoneNumber}
                      >
                        + Tambah Telepon
                      </Button>

                      {formData.emergencyContact.map((contact, index) => (
                        <div
                          key={index}
                          className="d-flex flex-column gap-2 mb-2 pb-3 border-bottom"
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mt-2">Kontak Darurat {index + 1}</h5>
                            {formData.emergencyContact.length > 1 && (
                              <Button
                                variant="link"
                                className="text-primary pe-0"
                                size="sm"
                                onClick={() => removeEmergencyContact(index)}
                              >
                                <FontAwesomeIcon icon={faCircleMinus} /> Hapus
                                kontak darurat
                              </Button>
                            )}
                          </div>
                          <Form.Group
                            controlId={`emergencyContact[${index}].contactName`}
                          >
                            <Form.Label className="fs-9 p-0 text-capitalize">
                              Nama Kontak Darurat
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name={`emergencyContact[${index}].contactName`}
                              value={contact.contactName}
                              onChange={handleChange}
                              placeholder="Masukkan nama kontak darurat"
                            />
                          </Form.Group>

                          <Form.Group
                            controlId={`emergencyContact[${index}].phoneNumber`}
                          >
                            <Form.Label className="fs-9 p-0 text-capitalize">
                              Nomor Telepon
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name={`emergencyContact[${index}].phoneNumber`}
                              value={contact.phoneNumber}
                              onChange={handleChange}
                              placeholder="Masukkan nomor telepon"
                            />
                          </Form.Group>

                          <Row>
                            <Col md={6}>
                              <Form.Group
                                controlId={`emergencyContact[${index}].relationshipStatus`}
                              >
                                <Form.Label className="fs-9 p-0 text-capitalize">
                                  Status Hubungan
                                </Form.Label>
                                <Form.Select
                                  name={`emergencyContact[${index}].relationshipStatus`}
                                  value={contact.relationshipStatus}
                                  onChange={handleChange}
                                >
                                  <option value="">
                                    Pilih status hubungan
                                  </option>
                                  <option value="family">Keluarga</option>
                                  <option value="friend">Teman</option>
                                  <option value="colleague">Rekan Kerja</option>
                                  <option value="other">Lainnya</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                controlId={`emergencyContact[${index}].isWhatsapp`}
                              >
                                <Form.Label className="fs-9 p-0 text-capitalize">
                                  Terhubung WhatsApp
                                </Form.Label>
                                <div className="d-flex align-items-center gap-3 mt-1">
                                  <Form.Check
                                    inline
                                    label="Ya"
                                    type="radio"
                                    name={`emergencyContact[${index}].isWhatsapp`}
                                    checked={contact.isWhatsapp === true}
                                    onChange={() => {
                                      handleChange({
                                        target: {
                                          name: `emergencyContact[${index}].isWhatsapp`,
                                          value: true
                                        }
                                      } as unknown as ChangeEvent<HTMLInputElement>);
                                    }}
                                    className="d-flex align-items-center gap-2"
                                  />
                                  <Form.Check
                                    inline
                                    label="Tidak"
                                    type="radio"
                                    name={`emergencyContact[${index}].isWhatsapp`}
                                    checked={contact.isWhatsapp === false}
                                    onChange={() => {
                                      handleChange({
                                        target: {
                                          name: `emergencyContact[${index}].isWhatsapp`,
                                          value: false
                                        }
                                      } as unknown as ChangeEvent<HTMLInputElement>);
                                    }}
                                    className="d-flex align-items-center gap-2"
                                  />
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>

                          <Form.Group
                            controlId={`emergencyContact[${index}].address`}
                          >
                            <Form.Label className="fs-9 p-0 text-capitalize">
                              Alamat
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name={`emergencyContact[${index}].address`}
                              value={contact.address}
                              onChange={handleChange}
                              placeholder="Masukkan alamat kontak darurat"
                            />
                          </Form.Group>
                        </div>
                      ))}

                      <Button
                        variant="outline-primary"
                        className="mb-2 w-100"
                        onClick={addNewEmergencyContact}
                      >
                        + Tambah Kontak Darurat
                      </Button>

                      {formData.email.map((emailData, index) => (
                        <div
                          key={index}
                          className="d-flex flex-column gap-2 mb-2 pb-3 border-bottom"
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mt-2">Email {index + 1}</h5>
                            {formData.email.length > 1 && (
                              <Button
                                variant="link"
                                className="text-primary pe-0"
                                size="sm"
                                onClick={() => removeEmail(index)}
                              >
                                <FontAwesomeIcon icon={faCircleMinus} /> Hapus
                                email
                              </Button>
                            )}
                          </div>
                          <Form.Group
                            controlId={`email[${index}].emailAddress`}
                          >
                            <Form.Label className="fs-9 p-0 text-capitalize">
                              Alamat Email
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name={`email[${index}].emailAddress`}
                              value={emailData.emailAddress}
                              onChange={handleChange}
                              placeholder="Masukan alamat email"
                            />
                          </Form.Group>

                          <Form.Group controlId={`email[${index}].usageType`}>
                            <Form.Label className="fs-9 p-0 text-capitalize">
                              Jenis Penggunaan
                            </Form.Label>
                            <Form.Select
                              name={`email[${index}].usageType`}
                              value={emailData.usageType}
                              onChange={handleChange}
                            >
                              <option value="">Pilih jenis penggunaan</option>
                              <option value="personal">Pribadi</option>
                              <option value="work">Pekerjaan</option>
                              <option value="other">Lainnya</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      ))}

                      <Button
                        variant="outline-primary"
                        className="mb-2 w-100"
                        onClick={addNewEmail}
                      >
                        + Tambah Email
                      </Button>
                    </Tab.Pane>

                    <Tab.Pane eventKey="layanan">
                      <div className="d-flex flex-column gap-2">
                        <h4>Waktu Kerja</h4>
                        <Row>
                          <Col md={12}>
                            <Form.Group controlId="jadwalKerja">
                              <Form.Label className="p-0 fs-9 text-capitalize">
                                Jadwal Kerja
                              </Form.Label>
                              <Form.Select
                                name="jadwalKerja"
                                // value={formData.jobTitle} //nanti ubah
                                onChange={handleChange}
                                required
                              >
                                {/* kesini */}
                                <option value="">Pilih jadwal kerja</option>
                                <option value="cabang">
                                  Berdasarkan jam kerja pada cabang tempat
                                  pegawai bekerja
                                </option>
                                <option value="hari">
                                  Berdasarkan hari dalam seminggu
                                </option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Jadwal kerja harus dipilih
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Form.Group controlId="maxBookingHarian">
                              <Form.Label className="p-0 fs-9 text-capitalize">
                                Maksimal Booking Harian
                              </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="maxBookingHarian"
                                  // value={formData.jobTitle} // nanti diubah
                                  onChange={handleChange}
                                  placeholder="Masukkan maksimal booking harian"
                                />
                                <InputGroup.Text>Jam</InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* Jam Operasional */}
                        <Row>
                          <Col md={12}>
                            <Form.Label className="p-0 fs-8 text-capitalize mb-2 mt-3">
                              Jam Operasional
                            </Form.Label>
                            <Table>
                              <thead>
                                <tr>
                                  <th className="fs-9">HARI</th>
                                  <th className="fs-9">MULAI</th>
                                  <th className="fs-9">AKHIR</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {days.map((day, index) => (
                                  <tr key={index}>
                                    <td className="p-1">
                                      <Form.Check type="checkbox" label={day} />
                                    </td>
                                    <td className="p-1">
                                      <InputGroup>
                                        <Form.Control
                                          type="time"
                                          value={timeValues[index].start}
                                          onChange={e =>
                                            handleTimeChange(
                                              index,
                                              'start',
                                              e.target.value
                                            )
                                          }
                                          disabled={fullDay[index]}
                                        />
                                      </InputGroup>
                                    </td>
                                    <td className="p-1">
                                      <InputGroup>
                                        <Form.Control
                                          type="time"
                                          value={timeValues[index].end}
                                          onChange={e =>
                                            handleTimeChange(
                                              index,
                                              'end',
                                              e.target.value
                                            )
                                          }
                                          disabled={fullDay[index]}
                                        />
                                      </InputGroup>
                                    </td>
                                    <td className="p-1">
                                      <Form.Check
                                        type="checkbox"
                                        label="Sehari Penuh"
                                        checked={fullDay[index]}
                                        onChange={() =>
                                          handleFullDayChange(index)
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <h4 className="mb-2">Layanan</h4>
                            <Form.Group controlId="biayaTambahan">
                              <Form.Label className="fs-9 p-0 text-capitalize">
                                Biaya Tambahan
                              </Form.Label>
                              <Form.Select
                                name={`address.biayaTambahan`}
                                // value={formData.address?.biayaTambahan || ""}
                                onChange={handleChange}
                                required
                                // isInvalid={validated && !formData.address?.biayaTambahan}
                              >
                                <option value="">
                                  Pilih jenis biaya tambahan
                                </option>
                                <option value="Tetap">Tetap</option>
                                <option value="Persentase">Persentase</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Jenis biaya tambahan harus dipilih
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="table-responsive mb-3">
                              <table className="table table-striped">
                                <thead>
                                  <tr className="fs-9">
                                    <th>NAMA LAYANAN</th>
                                    <th>BIAYA TAMBAHAN (RP)</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Dummy</td>
                                    <td>Dummy</td>
                                    <td className="text-end">
                                      <Button
                                        variant="link"
                                        className="text-danger p-0"
                                        // onClick={() => handleDeleteUser(user.id)}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <Button
                                className="w-100"
                                variant="outline-primary"
                                size="lg"
                                // onClick={() => setShowPilihPemilikModal(true)}
                              >
                                + Tambah Layanan
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="formTemplat">
                      <div className="d-flex flex-column gap-2">
                        <h4>Informasi Tambahan</h4>

                        {/* Render template sections */}
                        {templates?.data.result
                          .filter(
                            template =>
                              template.status && // Template harus aktif
                              template.employeeTemplateFields.length > 0 // Template harus memiliki fields
                          )
                          .map(template => (
                            <div key={template.id}>
                              <h5 className="mb-1">{template.templateName}</h5>
                              {allFields?.data
                                .filter(field =>
                                  template.employeeTemplateFields.some(
                                    tf => tf.id === field.id
                                  )
                                )
                                .sort((a, b) => a.position - b.position)
                                .map((field, idx) => (
                                  <div key={`${template.id}-${idx}`}>
                                    {renderDynamicField(field)}
                                  </div>
                                ))}
                            </div>
                          ))}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TambahPegawaiModal;
