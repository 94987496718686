import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { ServiceTemplate } from 'api/response/ServiceTemplateResponse';

export const servicesTemplateTablecolumns: ColumnDef<ServiceTemplate>[] = [
  {
    accessorKey: 'templateName',
    header: 'Nama Form Template',
    cell: ({ row: { original } }) => {
      const { templateName, id } = original;
      return (
        <Link
          to={`/layanan/templat-details/${id}`}
          className="d-flex align-items-center text-body-emphasis"
        >
          <p className="mb-0 fw-bold">{templateName}</p>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '30%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'py-0 pe-3 ps-0 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'createdBy',
    header: 'Dibuat oleh',
    cell: ({ row: { original } }) => {
      return <span className="fw-semibold">{original.createdBy}</span>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-1' },
      cellProps: { className: 'pe-3 ps-1' }
    }
  },
  {
    accessorKey: 'totalClinicalService',
    header: 'Bagian Form Templat',
    cell: ({ row: { original } }) => original.totalClinicalService,
    meta: {
      headerProps: { style: { width: '12%' }, className: 'ps-3' },
      cellProps: {
        className: 'ps-3 text-body-highlight white-space-nowrap text-end'
      }
    }
  },
  {
    accessorKey: 'modifiedDate',
    header: 'Terakhir Diperbarui',
    cell: ({ row: { original } }) => {
      return new Date(original.modifiedDate).toLocaleString('id-ID', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    meta: {
      headerProps: { style: { width: '17%' }, className: 'text-end px-2' },
      cellProps: { className: 'text-body-tertiary text-end px-2' }
    }
  },
  {
    accessorKey: 'createdDate',
    header: 'Dibuat Tanggal',
    cell: ({ row: { original } }) => {
      return new Date(original.createdDate).toLocaleString('id-ID', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    meta: {
      headerProps: {
        style: { width: '17%' },
        className: 'text-end px-2'
      },
      cellProps: { className: 'text-body-tertiary text-end px-2' }
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row: { original } }) => {
      return (
        <span
          className={`badge ${original.status ? 'bg-success' : 'bg-danger'}`}
        >
          {original.status ? 'AKTIF' : 'TIDAK AKTIF'}
        </span>
      );
    },
    meta: {
      headerProps: {
        style: { width: '7%' },
        className: 'px-2 text-end'
      },
      cellProps: {
        className: 'fw-semibold text-body-highlight px-2 text-end'
      }
    }
  }
];

interface ServicesTemplateTableProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
  templateData?: {
    totalRecords?: number;
  };
}

const ServicesTemplateTable = ({
  onPageChange,
  currentPage,
  totalPages,
  templateData
}: ServicesTemplateTableProps) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter
        pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalRecords={templateData?.totalRecords || 0}
      />
    </div>
  );
};

export default ServicesTemplateTable;
