/* eslint-disable @typescript-eslint/no-unused-vars */
// Customers.tsx
import {
  faChevronDown,
  faFileExport,
  faPlus,
  faTrash,
  faUndo,
  faFilter
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu,
  FilterItem
} from 'components/common/FilterButtonGroup';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import AddClientModal from 'components/modals/AddCustomerModal';
import CustomersTable, {
  customersTablecolumns
} from 'components/tables/CustomersTable';
import { daftarPelangganBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import {
  useGetCustomerQuery,
  useDeleteCustomerMutation,
  useGetUniqueBranchesQuery,
  useGetCustomerByIdQuery
} from 'api/customerApi';

import { toast, ToastContainer } from 'react-toastify';
import DownloadTableModal from 'components/modals/DownloadTableModal';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import DeleteCustomerModal from 'components/modals/DeleteCustomerModal';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { CustomerResponse } from 'api/response/CustomerResponse';
import CheckboxDropdownItem from 'components/common/CheckboxDropdownItem';
import { debounce } from 'lodash';
import { hasPrivilege } from 'slices/privilegeSlice';
import useRefreshHandler from 'hooks/useRefreshHandler';

// Definisikan tipe untuk filter
type FilterType = 'location' | 'gender' | 'customerType';

// Tambahkan enum untuk mode filter waktu
type TimeFilterMode = 'period' | 'range' | null;

// Tambahkan fungsi untuk mengkonversi nilai filter
const convertFilterValue = (type: string, value: string) => {
  const mappings: { [key: string]: { [key: string]: string } } = {
    gender: {
      'Laki-laki': 'M',
      Perempuan: 'F'
    },
    customerType: {
      New: 'new',
      Existing: 'existing',
      Prospect: 'prospect',
      'Belum Teridentifikasi': 'unidentified'
    }
  };

  return mappings[type]?.[value] || value;
};

const Customers = () => {
  const [errorShown, setErrorShown] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 10;

  const [activeFilters, setActiveFilters] = useState<{
    location: string[];
    gender: string[];
    customerType: string[];
  }>({
    location: [],
    gender: [],
    customerType: []
  });

  const [dateRange, setDateRange] = useState({
    startAt: '',
    endAt: ''
  });
  const [tempDateRange, setTempDateRange] = useState({
    startAt: '',
    endAt: ''
  });

  const [searchQuery, setSearchQuery] = useState('');

  const {
    data: customerData,
    error,
    isLoading,
    isFetching,
    refetch
  } = useGetCustomerQuery({
    page,
    limit: pageSize,
    orderBy: 'createdDate',
    direction: 'DESC',
    gender: activeFilters.gender,
    location: activeFilters.location,
    customerType: activeFilters.customerType,
    startAt: dateRange.startAt,
    endAt: dateRange.endAt,
    name: searchQuery
  });
  const [deleteCustomer] = useDeleteCustomerMutation();

  // State untuk menyimpan data detail customer
  const [customerDetails, setCustomerDetails] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }>({});
  // Fetch detail untuk setiap customer
  useEffect(() => {
    const fetchCustomerDetails = async () => {
      if (customerData?.data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const details: { [key: string]: any } = {};

        // Fetch detail untuk setiap customer secara parallel
        const detailPromises = customerData.data.map(customer =>
          fetch(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/customer/detail/${customer.id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
              }
            }
          ).then(res => res.json())
        );

        try {
          const detailResults = await Promise.all(detailPromises);

          // Simpan hasil ke dalam object
          customerData.data.forEach((customer, index) => {
            details[customer.id] = detailResults[index].data;
          });

          setCustomerDetails(details);
        } catch (error) {
          console.error('Error fetching customer details:', error);
          toast.error('Gagal memuat detail pelanggan');
        }
      }
    };

    fetchCustomerDetails();
  }, [customerData?.data]);

  // Gabungkan data customer dengan detailnya
  const enrichedCustomerData = useMemo(() => {
    if (!customerData?.data || Object.keys(customerDetails).length === 0) {
      return [];
    }

    return customerData.data.map(customer => ({
      ...customer,
      addressAndContact: customerDetails[customer.id]?.addressAndContact || {}
    }));
  }, [customerData?.data, customerDetails]);

  const table = useAdvanceTable<CustomerResponse>({
    // Gunakan enrichedCustomerData sebagai gantinya
    data: enrichedCustomerData,
    columns: customersTablecolumns,
    pageSize,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      pagination: {
        pageSize,
        pageIndex: page - 1
      }
    }
  });

  const [showModal, setShowModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number>(0);
  const [activeFilterTags, setActiveFilterTags] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [activePeriod, setActivePeriod] = useState<string>('all');

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    refetch();
  };

  // Tambahkan state untuk menyimpan halaman sebelumnya
  const [previousPage, setPreviousPage] = useState(1);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    debouncedSearch(searchValue);
  };
  // Modifikasi debounced search untuk menangani perubahan halaman
  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        setSearchQuery(query);
        if (query) {
          setPreviousPage(page);
          setPage(1);
        } else {
          setPage(previousPage);
        }
        refetch();
      }, 800),
    [refetch, page, previousPage]
  );
  // Cleanup debounce pada unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleConfirmDelete = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    if (selectedRows.length === 0) {
      toast.warning('Silakan pilih pelanggan yang akan dihapus');
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Data pelanggan akan dihapus',
      deskripsi: 'Apakah Anda yakin untuk menghapus data pelanggan?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const selectedIds = selectedRows.map(row => Number(row.original.id));
          const deletedCount = selectedRows.length;

          await deleteCustomer({
            notes: '', // Isi dengan notes dari modal
            deletionReason: 'Customer Request', // Isi dengan reason dari modal
            data: selectedIds
          }).unwrap();

          // Reset selection
          table.resetRowSelection();
          setSelectedRows(0);

          // Refresh data
          await refetch();

          setShowDeleteModal(false);
          toast.success(`${deletedCount} pelanggan berhasil dihapus`);
        } catch (error) {
          toast.error('Gagal menghapus pelanggan');
          console.error('Delete error:', error);
        }
      }
    });
  };

  const handleDownload = (format: string) => {
    const doc = new jsPDF(format === 'portrait-pdf' ? 'p' : 'l', 'mm', 'a4');

    // Ambil data dari tabel
    const tableData = table.getRowModel().rows.map(row => {
      const cells = row.getAllCells();
      return cells.map(cell => cell.getValue());
    });

    // Ambil header kolom
    const headers = table
      .getAllColumns()
      .map(column => column.columnDef.header);
    // Generate PDF menggunakan autoTable
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (doc as any).autoTable({
      head: [headers],
      body: tableData,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255 }
    });

    // Download PDF
    doc.save('daftar-pelanggan.pdf');
    toast.success('Berhasil mengunduh tabel');
  };
  const { isRefreshing, handleRefresh } = useRefreshHandler(refetch);
  const periodOptions = [
    { value: 'all', label: 'Semua' },
    { value: 'today', label: 'Hari ini' },
    { value: 'week', label: 'Minggu ini' },
    { value: 'month', label: 'Bulan ini' },
    { value: 'year', label: 'Tahun ini' },
    { value: 'custom', label: 'Kustom' }
  ];

  const handleDeleteClick = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    if (selectedRows.length === 0) {
      toast.warning('Silakan pilih pelanggan yang akan dihapus');
      return;
    }
    setShowDeleteModal(true);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    table.setPageIndex(newPage - 1);
  };

  const handleFilterChange = (filterType: FilterType, value: string) => {
    const convertedValue = convertFilterValue(filterType, value);
    setActiveFilters(prev => ({
      ...prev,
      [filterType]: [...prev[filterType], convertedValue]
    }));
  };

  const { data: branchesData, isLoading: branchesLoading } =
    useGetUniqueBranchesQuery();

  const [filterMenus, setFilterMenus] = useState<FilterMenu[]>([
    {
      label: 'Lokasi',
      items: [],
      isMultiple: true
    },
    {
      label: 'Status',
      items: [
        { label: 'Aktif', value: 'active' },
        { label: 'Tidak Aktif', value: 'inactive' }
      ],
      isMultiple: true
    },
    {
      label: 'Jenis Kelamin',
      items: [
        { label: 'Laki-Laki', value: 'M' },
        { label: 'Perempuan', value: 'F' }
      ],
      isMultiple: true
    },
    {
      label: 'Jenis Pelanggan',
      items: [
        { label: 'New', value: 'new' },
        { label: 'Existing', value: 'existing' },
        { label: 'Prospect', value: 'prospect' },
        { label: 'Belum Teridentifikasi', value: 'unidentified' }
      ],
      isMultiple: true
    }
  ]);

  useEffect(() => {
    console.log('Branches Data:', branchesData);
    if (branchesData) {
      setFilterMenus(prevMenus => {
        const updatedMenus = [...prevMenus];
        const locationMenu = updatedMenus.find(menu => menu.label === 'Lokasi');
        if (locationMenu) {
          locationMenu.items = branchesData.map(branch => ({
            label: branch,
            value: branch
          }));
        }
        return updatedMenus;
      });
    }
  }, [branchesData]);

  useEffect(() => {
    if (error && !errorShown) {
      console.log('Error detail:', error);
      toast.error('Error loading customer data');
      setErrorShown(true);
    } else if (!error && errorShown) {
      setErrorShown(false);
    }
  }, [error, errorShown]);

  const [lastToastTime, setLastToastTime] = useState(0);

  useEffect(() => {
    // Check for empty data
    if (customerData && customerData.data.length === 0) {
      const currentTime = new Date().getTime();

      // If more than 3 seconds have passed since the last toast
      if (!lastToastTime || currentTime - lastToastTime >= 3000) {
        toast.info('Data Tidak Ditemukan');

        // Update the last toast time
        setLastToastTime(currentTime);
      }
    }
  }, [customerData, lastToastTime]);

  useEffect(() => {
    const selectedRowsCount = Object.keys(table.getState().rowSelection).length;
    setSelectedRows(selectedRowsCount);
  }, [table.getState().rowSelection]);

  const filterOptions = {
    gender: [
      { value: 'M', label: 'Laki-laki' },
      { value: 'F', label: 'Perempuan' }
    ],
    customerType: [
      { value: 'new', label: 'New' },
      { value: 'existing', label: 'Existing' },
      { value: 'prospect', label: 'Prospect' },
      { value: 'unidentified', label: 'Belum Teridentifikasi' }
    ]
  };

  const [timeFilterMode, setTimeFilterMode] = useState<TimeFilterMode>(null);

  // Tambahkan fungsi untuk mendapatkan rentang tanggal berdasarkan period
  const getDateRangeFromPeriod = (period: string) => {
    const today = new Date();
    const startDate = new Date();

    switch (period) {
      case 'today':
        return {
          startAt: today.toISOString().split('T')[0],
          endAt: today.toISOString().split('T')[0]
        };
      case 'week':
        startDate.setDate(today.getDate() - today.getDay());
        return {
          startAt: startDate.toISOString().split('T')[0],
          endAt: today.toISOString().split('T')[0]
        };
      case 'month':
        startDate.setDate(1);
        return {
          startAt: startDate.toISOString().split('T')[0],
          endAt: today.toISOString().split('T')[0]
        };
      case 'year':
        startDate.setMonth(0, 1);
        return {
          startAt: startDate.toISOString().split('T')[0],
          endAt: today.toISOString().split('T')[0]
        };
      default:
        return { startAt: '', endAt: '' };
    }
  };

  // Tambahkan state untuk mengecek apakah ada filter aktif
  const isFiltering = () => {
    return (
      tempFilters.location.length > 0 ||
      tempFilters.gender.length > 0 ||
      tempFilters.customerType.length > 0 ||
      dateRange.startAt !== '' ||
      dateRange.endAt !== ''
    );
  };

  // Modifikasi handleCheckboxChange untuk hanya mengupdate tempFilters
  const handleCheckboxChange = (
    type: FilterType,
    value: string,
    checked: boolean
  ) => {
    setTempFilters(prev => ({
      ...prev,
      [type]: checked
        ? [...prev[type], value]
        : prev[type].filter(v => v !== value)
    }));
  };

  // Modifikasi handlePeriodClick untuk hanya mengupdate tempDate
  const handlePeriodClick = (period: string) => {
    setTimeFilterMode(period === 'custom' ? 'range' : 'period');
    setActivePeriod(period);
    if (period !== 'custom') {
      const newDateRange = getDateRangeFromPeriod(period);
      setTempDateRange(newDateRange);
    }
  };
  const handleDateChange = (type: 'startAt' | 'endAt', value: string) => {
    // Keep the timeFilterMode as 'range' and activePeriod as 'custom'
    setTimeFilterMode('range');
    setActivePeriod('custom');

    // Update the tempDateRange without closing the custom view
    setTempDateRange(prev => ({
      ...prev,
      [type]: value
    }));
  };

  // Update handleApplyFilter untuk menerapkan semua filter sekaligus
  const handleApplyFilter = () => {
    setActiveFilters(tempFilters);
    setDateRange(tempDateRange);
    refetch();
  };
  // Update handleResetFilter
  const handleResetFilter = () => {
    setTempFilters({
      location: [],
      gender: [],
      customerType: []
    });
    setActiveFilters({
      location: [],
      gender: [],
      customerType: []
    });
    setTimeFilterMode(null);
    setActivePeriod('all');
    setTempDateRange({
      startAt: '',
      endAt: ''
    });
    setDateRange({
      startAt: '',
      endAt: ''
    });
    refetch();
  };

  const [tempFilters, setTempFilters] = useState<{
    location: string[];
    gender: string[];
    customerType: string[];
  }>({
    location: [],
    gender: [],
    customerType: []
  });

  return (
    <div>
      <PageBreadcrumb items={daftarPelangganBreadcrumbItems} />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="mb-9">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="mb-0">Daftar Pelanggan</h2>
        </div>

        <div className="d-flex justify-content-between align-items-center gap-3 mb-4">
          <div className="d-flex gap-2 align-items-center">
            <SearchBox
              placeholder="Cari"
              onChange={handleSearchInputChange}
              style={{ maxWidth: '300px' }}
            />
            <Button
              variant={showFilterPanel ? 'primary' : 'light'}
              size="sm"
              onClick={() => setShowFilterPanel(!showFilterPanel)}
              className="d-flex align-items-center gap-2"
            >
              <FontAwesomeIcon icon={faFilter} />
              Filter
            </Button>
          </div>
          <div className="d-flex align-items-center gap-3">
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={() => setShowDownloadModal(true)}
            >
              <FontAwesomeIcon icon={faFileExport} className="me-2" />
              Unduh tabel
            </Button>
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={handleRefresh}
              disabled={isRefreshing || isFetching}
            >
              <FontAwesomeIcon
                icon={faUndo}
                className={`me-2 ${
                  isRefreshing || isFetching ? 'fa-spin' : ''
                }`}
              />
              {isRefreshing || isFetching ? 'Memperbarui...' : 'Refresh'}
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                className="text-secondary p-0 dropdown-caret-none"
              >
                Tindakan lainnya
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item>Bulk Update</Dropdown.Item>
                <Dropdown.Item>Konfigurasi Tabel</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {hasPrivilege('CUSTOMER_DATA_FULL') && (
              <Button
                variant="outline-danger"
                onClick={handleDeleteClick}
                disabled={selectedRows === 0}
                size="sm"
              >
                <FontAwesomeIcon icon={faTrash} className="me-2" />
                Hapus Pelanggan ({selectedRows})
              </Button>
            )}
            {hasPrivilege('CUSTOMER_DATA_WRITE') && (
              <Button variant="primary" onClick={handleShowModal} size="sm">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Tambah Pelanggan
              </Button>
            )}
            <AddClientModal
              show={showModal}
              handleClose={handleCloseModal}
              onSuccess={() => refetch()}
            />
          </div>
        </div>

        {showFilterPanel && (
          <div
            className="p-3 rounded mb-4 border border-1"
            style={{ backgroundColor: '#EFF2F6' }}
          >
            <div className="row g-0 align-items-center">
              <div
                className="col-auto"
                style={{
                  minWidth: '100px',
                  width: activePeriod === 'custom' ? '115px' : '200px'
                }}
              >
                <Dropdown
                  className="w-100"
                  style={{ backgroundColor: '#F5F7FA' }}
                >
                  <Dropdown.Toggle
                    variant="white"
                    className="border rounded-end-0 w-100 d-flex justify-content-between align-items-center"
                  >
                    <div className="text-truncate">
                      {tempFilters.location.length > 0 ? (
                        <div className="d-flex gap-1 flex-wrap">
                          {tempFilters.location.map((loc, index) => (
                            <span
                              key={index}
                              className="badge bg-light text-dark d-flex align-items-center gap-2 me-1"
                            >
                              {loc}
                              <button
                                className="btn-close btn-close-sm"
                                onClick={e => {
                                  e.stopPropagation();
                                  handleCheckboxChange('location', loc, false);
                                }}
                              />
                            </span>
                          ))}
                        </div>
                      ) : (
                        'Lokasi'
                      )}
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {branchesLoading ? (
                      <Dropdown.Item disabled>Loading...</Dropdown.Item>
                    ) : branchesData && branchesData.length > 0 ? (
                      branchesData.map(branch => (
                        <CheckboxDropdownItem
                          key={branch}
                          label={branch}
                          value={branch}
                          checked={tempFilters.location.some(
                            loc => loc.toLowerCase() === branch.toLowerCase()
                          )}
                          onChange={(value, checked) =>
                            handleCheckboxChange('location', value, checked)
                          }
                        />
                      ))
                    ) : (
                      <Dropdown.Item disabled>
                        Tidak ada lokasi tersedia
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div
                className="col-auto"
                style={{
                  minWidth: '100px',
                  width: activePeriod === 'custom' ? '115px' : '200px'
                }}
              >
                <Dropdown
                  className="w-100"
                  style={{ backgroundColor: '#F5F7FA' }}
                >
                  <Dropdown.Toggle
                    variant="white"
                    className="border rounded-0 w-100 d-flex justify-content-between align-items-center"
                  >
                    <div className="text-truncate">
                      {tempFilters.gender.length > 0 ? (
                        <div className="d-flex gap-1 flex-wrap">
                          {tempFilters.gender.map((gen, index) => (
                            <span
                              key={index}
                              className="badge bg-light text-dark d-flex align-items-center gap-2 me-1"
                            >
                              {filterOptions.gender.find(g => g.value === gen)
                                ?.label || gen}
                              <button
                                className="btn-close btn-close-sm"
                                onClick={e => {
                                  e.stopPropagation();
                                  handleCheckboxChange('gender', gen, false);
                                }}
                              />
                            </span>
                          ))}
                        </div>
                      ) : (
                        'Jenis Kelamin'
                      )}
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {filterOptions.gender.map(gender => (
                      <CheckboxDropdownItem
                        key={gender.value}
                        label={gender.label}
                        value={gender.value}
                        checked={tempFilters.gender.includes(gender.value)}
                        onChange={(value, checked) =>
                          handleCheckboxChange('gender', value, checked)
                        }
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div
                className="col-auto"
                style={{
                  minWidth: '100px',
                  width: activePeriod === 'custom' ? '115px' : '200px'
                }}
              >
                <Dropdown
                  className="w-100"
                  style={{ backgroundColor: '#F5F7FA' }}
                >
                  <Dropdown.Toggle
                    variant="white"
                    className="border rounded-start-0 w-100 d-flex justify-content-between align-items-center"
                  >
                    <div className="text-truncate">
                      {tempFilters.customerType.length > 0 ? (
                        <div className="d-flex gap-1 flex-wrap">
                          {tempFilters.customerType.map((type, index) => (
                            <span
                              key={index}
                              className="badge bg-light text-dark d-flex align-items-center gap-2 me-1"
                            >
                              {filterOptions.customerType.find(
                                t => t.value === type
                              )?.label || type}
                              <button
                                className="btn-close btn-close-sm"
                                onClick={e => {
                                  e.stopPropagation();
                                  handleCheckboxChange(
                                    'customerType',
                                    type,
                                    false
                                  );
                                }}
                              />
                            </span>
                          ))}
                        </div>
                      ) : (
                        'Jenis Pelanggan'
                      )}
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {filterOptions.customerType.map(type => (
                      <CheckboxDropdownItem
                        key={type.value}
                        label={type.label}
                        value={type.value}
                        checked={tempFilters.customerType.includes(type.value)}
                        onChange={(value, checked) =>
                          handleCheckboxChange('customerType', value, checked)
                        }
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Period Buttons dan Date Range */}
              <div className="col">
                <div className="d-flex justify-content-end">
                  {periodOptions.map(period => (
                    <Button
                      key={period.value}
                      variant={
                        activePeriod === period.value ? 'secondary' : 'light'
                      }
                      size="sm"
                      onClick={() => handlePeriodClick(period.value)}
                      className={`
                        border py-2
                        ${period.value === 'all' ? 'rounded-end-0' : ''}
                        ${period.value === 'today' ? 'rounded-0' : ''}
                        ${period.value === 'week' ? 'rounded-0' : ''}
                        ${period.value === 'month' ? 'rounded-0' : ''}
                        ${period.value === 'year' ? 'rounded-0' : ''}
                        ${
                          period.value === 'custom'
                            ? 'rounded-start-0 me-2'
                            : ''
                        }
                        h-100
                        
                      `}
                    >
                      {period.label}
                    </Button>
                  ))}

                  {/* Show date range inputs only when Kustom is selected */}
                  {activePeriod === 'custom' && (
                    <div className="d-flex gap-2 align-items-center">
                      <input
                        type="date"
                        className="form-control form-control-sm"
                        style={{ width: '140px' }}
                        value={tempDateRange.startAt}
                        onChange={e =>
                          handleDateChange('startAt', e.target.value)
                        }
                      />
                      <span>sampai</span>
                      <input
                        type="date"
                        className="form-control form-control-sm"
                        style={{ width: '140px' }}
                        value={tempDateRange.endAt}
                        onChange={e =>
                          handleDateChange('endAt', e.target.value)
                        }
                      />
                    </div>
                  )}
                  <div className="d-flex gap-2 align-items-center ms-2">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={handleResetFilter}
                      disabled={!isFiltering()}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={handleApplyFilter}
                    >
                      <FontAwesomeIcon icon={faFilter} /> Terapkan
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <AdvanceTableProvider {...table}>
          <div style={{ width: '95%' }} className="mx-auto">
            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
              {isLoading ? (
                <div className="text-center py-5">Loading...</div>
              ) : (
                <CustomersTable
                  onPageChange={handlePageChange}
                  currentPage={page}
                  totalPages={customerData?.totalPages || 1}
                  customerData={{
                    totalRecords: customerData?.totalItems || 0
                  }}
                />
              )}
            </div>
          </div>
        </AdvanceTableProvider>
      </div>

      <DownloadTableModal
        show={showDownloadModal}
        onHide={() => setShowDownloadModal(false)}
        onDownload={handleDownload}
        title="Unduh Tabel"
      />

      <DeleteCustomerModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        selectedCustomers={table.getSelectedRowModel().rows.map(row => ({
          name: `${row.original.firstName || ''} ${row.original.lastName || ''}`
        }))}
        onDelete={handleConfirmDelete}
      />
    </div>
  );
};

export default Customers;
