import React, { useState, useEffect, useMemo, ChangeEvent } from 'react';
import {
  faChevronDown,
  faFileExport,
  // faPlus,
  faTrash,
  faUndo,
  faFilter,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import PegawaiTable, {
  pegawaiTableColumns
} from 'components/tables/PegawaiTable';
import { daftarPegawaiBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Dropdown } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import DownloadTableModal from 'components/modals/DownloadTableModal';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
// import { showCustomAlert } from 'helpers/showCustomAlert';
import { EmployeeResponse } from 'api/response/EmployeeResponse';
import {
  useDeleteEmployeeMutation,
  useGetEmployeeQuery
} from 'api/employeeApi';
import DeleteCustomerModal from 'components/modals/DeleteCustomerModal';
import TambahPegawaiModal from 'components/modals/TambahPegawaiModal';
import { showCustomAlert } from 'helpers/showCustomAlert';
import CheckboxDropdownItem from 'components/common/CheckboxDropdownItem';
import { debounce } from 'lodash';
import { useGetAllPositionsQuery } from 'api/positionApi';
import useRefreshHandler from 'hooks/useRefreshHandler';

const DaftarPegawai = () => {
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const [selectedRows, setSelectedRows] = useState<number>(0);
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTambahPegawaiModal, setShowTambahPegawaiModal] = useState(false);

  const [activeFilters, setActiveFilters] = useState<{
    location: string[];
    gender: string[];
    jobTitle: string[];
    isActive: string[];
  }>({
    location: [],
    gender: [],
    jobTitle: [],
    isActive: []
  });

  const [tempFilters, setTempFilters] = useState<{
    location: string[];
    gender: string[];
    jobTitle: string[];
    isActive: string[];
  }>({
    location: [],
    gender: [],
    jobTitle: [],
    isActive: []
  });

  const [dateRange, setDateRange] = useState({
    startAt: '',
    endAt: ''
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [activePeriod, setActivePeriod] = useState<string>('');
  const [timeFilterMode, setTimeFilterMode] = useState<
    'period' | 'range' | null
  >(null);

  // Gunakan query untuk mengambil data pegawai dengan filter
  const {
    data: employeeData,
    refetch,
    isFetching
  } = useGetEmployeeQuery({
    page,
    limit: pageSize,
    orderBy: 'createdDate',
    direction: 'DESC',
    gender: activeFilters.gender,
    location: activeFilters.location,
    jobTitle: activeFilters.jobTitle,
    isActive: activeFilters.isActive,
    startAt: dateRange.startAt,
    endAt: dateRange.endAt,
    name: searchQuery
  });

  // Fetch data jabatan
  const { data: positionData } = useGetAllPositionsQuery({
    page: 1,
    limit: 1000,
    orderBy: 'name',
    direction: 'ASC',
    name: searchQuery || ''
  });

  const { isRefreshing, handleRefresh } = useRefreshHandler(refetch);

  const filterOptions = {
    gender: [
      { value: 'M', label: 'Laki-laki' },
      { value: 'F', label: 'Perempuan' }
    ],
    isActive: [
      { value: 'true', label: 'Aktif' },
      { value: 'false', label: 'Tidak Aktif' }
    ],
    jobTitle:
      positionData?.data?.result?.map(position => ({
        value: position.id,
        label: position.name
      })) || []
  };

  // Fungsi untuk handle search dengan debounce
  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        setSearchQuery(query);
        refetch();
      }, 300),
    [refetch]
  );

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  // Fungsi untuk handle filter checkbox
  const handleCheckboxChange = (
    type: 'location' | 'gender' | 'jobTitle' | 'isActive',
    value: string,
    checked: boolean
  ) => {
    setTempFilters(prev => ({
      ...prev,
      [type]: checked
        ? [...prev[type], value]
        : prev[type].filter(v => v !== value)
    }));
  };

  // Fungsi untuk handle period filter
  const handlePeriodClick = (period: string) => {
    setTimeFilterMode('period');
    setActivePeriod(period);
    const today = new Date();
    const startDate = new Date();

    switch (period) {
      case 'today':
        setDateRange({
          startAt: today.toISOString().split('T')[0],
          endAt: today.toISOString().split('T')[0]
        });
        break;
      case 'week':
        startDate.setDate(today.getDate() - today.getDay());
        setDateRange({
          startAt: startDate.toISOString().split('T')[0],
          endAt: today.toISOString().split('T')[0]
        });
        break;
      case 'month':
        startDate.setDate(1);
        setDateRange({
          startAt: startDate.toISOString().split('T')[0],
          endAt: today.toISOString().split('T')[0]
        });
        break;
      case 'year':
        startDate.setMonth(0, 1);
        setDateRange({
          startAt: startDate.toISOString().split('T')[0],
          endAt: today.toISOString().split('T')[0]
        });
        break;
    }
  };

  // Fungsi untuk handle date range
  const handleDateChange = (type: 'startAt' | 'endAt', value: string) => {
    setTimeFilterMode('range');
    setActivePeriod('');
    setDateRange(prev => ({
      ...prev,
      [type]: value
    }));
  };

  // Fungsi untuk apply filter
  const handleApplyFilter = () => {
    setActiveFilters(tempFilters);
    refetch();
  };

  // Fungsi untuk reset filter
  const handleResetFilter = () => {
    setTempFilters({
      location: [],
      gender: [],
      jobTitle: [],
      isActive: []
    });
    setActiveFilters({
      location: [],
      gender: [],
      jobTitle: [],
      isActive: []
    });
    setTimeFilterMode(null);
    setActivePeriod('');
    setDateRange({
      startAt: '',
      endAt: ''
    });
    setSearchQuery('');
    refetch();
  };

  // Fungsi untuk cek apakah ada filter aktif
  const isFiltering = () => {
    return (
      tempFilters.location.length > 0 ||
      tempFilters.gender.length > 0 ||
      tempFilters.jobTitle.length > 0 ||
      tempFilters.isActive.length > 0 ||
      dateRange.startAt !== '' ||
      dateRange.endAt !== '' ||
      searchQuery !== ''
    );
  };

  // console.log('Employee Data:', employeeData);
  // console.log('Is Loading:', isLoading);
  // console.log('Is Error:', isError);
  // console.log('Error:', error);

  const table = useAdvanceTable<EmployeeResponse>({
    data: employeeData?.data || [],
    columns: pegawaiTableColumns,
    pageSize,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      pagination: {
        pageSize,
        pageIndex: page - 1
      }
    }
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    table.setPageIndex(newPage - 1);
  };

  // const handleShowModal = () => {
  //   // Implementasi untuk menampilkan modal tambah pegawai
  // };

  const handleDownload = (format: string) => {
    const doc = new jsPDF(format === 'portrait-pdf' ? 'p' : 'l', 'mm', 'a4');

    // Ambil data dari tabel
    const tableData = table.getRowModel().rows.map(row => {
      const cells = row.getAllCells();
      return cells.map(cell => cell.getValue());
    });

    // Ambil header kolom
    const headers = table
      .getAllColumns()
      .map(column => column.columnDef.header);

    // Generate PDF menggunakan autoTable
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (doc as any).autoTable({
      head: [headers],
      body: tableData,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255 }
    });

    // Download PDF
    doc.save('daftar-pegawai.pdf');
    toast.success('Berhasil mengunduh tabel');
  };

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    const selectedRowsCount = Object.keys(table.getState().rowSelection).length;
    setSelectedRows(selectedRowsCount);
  }, [table.getState().rowSelection]);

  // Inside the Customers component, modify the useEffect that handles the query result
  const [lastToastTime, setLastToastTime] = useState(0);

  useEffect(() => {
    // Check for empty data
    if (employeeData && employeeData.data.length === 0) {
      const currentTime = new Date().getTime();

      // If more than 3 seconds have passed since the last toast
      if (!lastToastTime || currentTime - lastToastTime >= 3000) {
        toast.info('Data Tidak Ditemukan');

        // Update the last toast time
        setLastToastTime(currentTime);
      }
    }
  }, [employeeData, lastToastTime]);

  // First, import the delete mutation
  const [deleteEmployee, { isLoading: isDeleting }] =
    useDeleteEmployeeMutation();

  const handleConfirmDelete = async (reason: string, notes: string) => {
    showCustomAlert({
      icon: 'warning',
      label: 'Pegawai akan dihapus',
      deskripsi: 'Apakah Anda yakin untuk menghapus pegawai ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const selectedRows = table.getSelectedRowModel().rows;
          const selectedIds = selectedRows.map(row => String(row.original.id));
          const deletedCount = selectedRows.length;

          await deleteEmployee({
            notes: notes,
            deletionReason: reason,
            data: selectedIds
          }).unwrap();

          // Reset selection
          table.resetRowSelection();
          setSelectedRows(0);
          setShowDeleteModal(false);
          await refetch();

          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: `${deletedCount} pegawai berhasil dihapus`,
            buttonType: 'ok'
          });
        } catch (error) {
          toast.error('Gagal menghapus pegawai');
          console.error('Delete error:', error);
        }
      }
    });
  };

  const handleDeleteClick = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    if (selectedRows.length === 0) {
      toast.warning('Silakan pilih pegawai yang akan dihapus');
      return;
    }
    setShowDeleteModal(true);
  };

  const handleShowTambahPegawaiModal = () => {
    setShowTambahPegawaiModal(true);
  };

  const handleCloseTambahPegawaiModal = () => {
    setShowTambahPegawaiModal(false);
  };

  return (
    <div>
      <PageBreadcrumb items={daftarPegawaiBreadcrumbItems} />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="mb-9">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="mb-0">Daftar Pegawai</h2>
        </div>

        <div className="d-flex justify-content-between align-items-center gap-3 mb-4">
          <div className="d-flex gap-2 align-items-center">
            <SearchBox
              placeholder="Cari pegawai"
              onChange={handleSearchInputChange}
              style={{ maxWidth: '300px' }}
            />
            <Button
              variant={showFilterPanel ? 'primary' : 'light'}
              size="sm"
              onClick={() => setShowFilterPanel(!showFilterPanel)}
              className="d-flex align-items-center gap-2"
            >
              <FontAwesomeIcon icon={faFilter} />
              Filter
            </Button>
          </div>
          <div className="d-flex align-items-center gap-3">
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={() => setShowDownloadModal(true)}
            >
              <FontAwesomeIcon icon={faFileExport} className="me-2" />
              Unduh tabel
            </Button>
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={handleRefresh}
              disabled={isRefreshing || isFetching}
            >
              <FontAwesomeIcon
                icon={faUndo}
                className={`me-2 ${
                  isRefreshing || isFetching ? 'fa-spin' : ''
                }`}
              />
              {isRefreshing || isFetching ? 'Memperbarui...' : 'Refresh'}
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                className="text-secondary p-0 dropdown-caret-none"
              >
                Tindakan lainnya
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item>Export Selection</Dropdown.Item>
                <Dropdown.Item>Bulk Edit</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant="outline-danger"
              onClick={handleDeleteClick}
              disabled={selectedRows === 0 || isDeleting}
              size="sm"
            >
              <FontAwesomeIcon icon={faTrash} className="me-2" />
              Hapus Pegawai ({selectedRows})
            </Button>
            <DeleteCustomerModal
              show={showDeleteModal}
              onHide={() => setShowDeleteModal(false)}
              selectedCustomers={table.getSelectedRowModel().rows.map(row => ({
                name:
                  row.original.names?.fullName ||
                  row.original.fullName ||
                  'Nama tidak tersedia'
              }))}
              onDelete={handleConfirmDelete}
              title="Hapus Pegawai"
            />
            <Button
              variant="primary"
              size="sm"
              onClick={handleShowTambahPegawaiModal}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Tambah Pegawai
            </Button>
          </div>
        </div>

        {showFilterPanel && (
          <div className="bg-light p-3 rounded mb-4">
            <div className="row g-3 align-items-center">
              {/* Filter Dropdown Lokasi */}
              <div className="col-auto" style={{ minWidth: '150px' }}>
                <Dropdown className="w-100">
                  <Dropdown.Toggle
                    variant="white"
                    className="border w-100 d-flex justify-content-between align-items-center"
                  >
                    <div className="text-truncate">
                      {tempFilters.location.length > 0 ? (
                        <div className="d-flex gap-1 flex-wrap">
                          {tempFilters.location.map((loc, index) => (
                            <span
                              key={index}
                              className="badge bg-light text-dark"
                            >
                              {loc}
                            </span>
                          ))}
                        </div>
                      ) : (
                        'Lokasi'
                      )}
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item disabled>
                      Tidak ada data lokasi
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Filter Dropdown Status */}
              <div className="col-auto" style={{ minWidth: '150px' }}>
                <Dropdown className="w-100">
                  <Dropdown.Toggle
                    variant="white"
                    className="border w-100 d-flex justify-content-between align-items-center"
                  >
                    <div className="text-truncate">
                      {tempFilters.isActive.length > 0 ? (
                        <div className="d-flex gap-1 flex-wrap">
                          {tempFilters.isActive.map((status, index) => (
                            <span
                              key={index}
                              className="badge bg-light text-dark"
                            >
                              {
                                filterOptions.isActive.find(
                                  s => s.value === status
                                )?.label
                              }
                            </span>
                          ))}
                        </div>
                      ) : (
                        'Status'
                      )}
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {filterOptions.isActive.map(status => (
                      <CheckboxDropdownItem
                        key={status.value}
                        label={status.label}
                        value={status.value}
                        checked={tempFilters.isActive.includes(status.value)}
                        onChange={(value, checked) =>
                          handleCheckboxChange('isActive', value, checked)
                        }
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Filter Dropdown Gender */}
              <div className="col-auto" style={{ minWidth: '150px' }}>
                <Dropdown className="w-100">
                  <Dropdown.Toggle
                    variant="white"
                    className="border w-100 d-flex justify-content-between align-items-center"
                  >
                    <div className="text-truncate">
                      {tempFilters.gender.length > 0 ? (
                        <div className="d-flex gap-1 flex-wrap">
                          {tempFilters.gender.map((gen, index) => (
                            <span
                              key={index}
                              className="badge bg-light text-dark"
                            >
                              {
                                filterOptions.gender.find(g => g.value === gen)
                                  ?.label
                              }
                            </span>
                          ))}
                        </div>
                      ) : (
                        'Jenis Kelamin'
                      )}
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {filterOptions.gender.map(gender => (
                      <CheckboxDropdownItem
                        key={gender.value}
                        label={gender.label}
                        value={gender.value}
                        checked={tempFilters.gender.includes(gender.value)}
                        onChange={(value, checked) =>
                          handleCheckboxChange('gender', value, checked)
                        }
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Filter Dropdown Jabatan */}
              <div className="col-auto" style={{ minWidth: '150px' }}>
                <Dropdown className="w-100">
                  <Dropdown.Toggle
                    variant="white"
                    className="border w-100 d-flex justify-content-between align-items-center"
                  >
                    <div className="text-truncate">
                      {tempFilters.jobTitle.length > 0 ? (
                        <div className="d-flex gap-1 flex-wrap">
                          {tempFilters.jobTitle.map((job, index) => (
                            <span
                              key={index}
                              className="badge bg-light text-dark"
                            >
                              {
                                filterOptions.jobTitle.find(
                                  j => j.value === job
                                )?.label
                              }
                            </span>
                          ))}
                        </div>
                      ) : (
                        'Jabatan'
                      )}
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {filterOptions.jobTitle.map(job => (
                      <CheckboxDropdownItem
                        key={job.value}
                        label={job.label}
                        value={job.value}
                        checked={tempFilters.jobTitle.includes(job.value)}
                        onChange={(value, checked) =>
                          handleCheckboxChange('jobTitle', value, checked)
                        }
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Period Buttons dan Date Range */}
              <div className="col">
                <div className="d-flex gap-2 justify-content-end">
                  <Button
                    variant={activePeriod === 'today' ? 'primary' : 'light'}
                    size="sm"
                    onClick={() => handlePeriodClick('today')}
                    disabled={timeFilterMode === 'range'}
                  >
                    Hari ini
                  </Button>
                  <Button
                    variant={activePeriod === 'week' ? 'primary' : 'light'}
                    size="sm"
                    onClick={() => handlePeriodClick('week')}
                    disabled={timeFilterMode === 'range'}
                  >
                    Minggu ini
                  </Button>
                  <Button
                    variant={activePeriod === 'month' ? 'primary' : 'light'}
                    size="sm"
                    onClick={() => handlePeriodClick('month')}
                    disabled={timeFilterMode === 'range'}
                  >
                    Bulan ini
                  </Button>
                  <Button
                    variant={activePeriod === 'year' ? 'primary' : 'light'}
                    size="sm"
                    onClick={() => handlePeriodClick('year')}
                    disabled={timeFilterMode === 'range'}
                  >
                    Tahun ini
                  </Button>

                  <div className="d-flex gap-2 align-items-center">
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      style={{ width: '140px' }}
                      value={dateRange.startAt}
                      onChange={e =>
                        handleDateChange('startAt', e.target.value)
                      }
                      disabled={timeFilterMode === 'period'}
                    />
                    <span>sampai</span>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      style={{ width: '140px' }}
                      value={dateRange.endAt}
                      onChange={e => handleDateChange('endAt', e.target.value)}
                      disabled={timeFilterMode === 'period'}
                    />
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={handleApplyFilter}
                    >
                      Terapkan
                    </Button>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={handleResetFilter}
                      disabled={!isFiltering()}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <AdvanceTableProvider {...table}>
          <div style={{ width: '95%' }} className="mx-auto">
            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
              <PegawaiTable
                onPageChange={handlePageChange}
                currentPage={page}
                totalPages={employeeData?.totalPages || 1}
                employeeData={employeeData}
              />
            </div>
          </div>
        </AdvanceTableProvider>
      </div>

      <DownloadTableModal
        show={showDownloadModal}
        onHide={() => setShowDownloadModal(false)}
        onDownload={handleDownload}
        title="Unduh Tabel"
      />

      <TambahPegawaiModal
        show={showTambahPegawaiModal}
        handleClose={handleCloseTambahPegawaiModal}
        onSuccess={() => {
          handleCloseTambahPegawaiModal();
          handleRefresh();
        }}
      />
    </div>
  );
};

export default DaftarPegawai;
