import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useUpdatePolicyRefundMutation } from 'api/serviceApi';

interface PengembalianDanaCardProps {
  data: {
    refundPaymentMethod: string[];
    refundDaysBeforeBooking: number;
  };
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdate?: (updatedData: any) => void;
  refetch?: () => void;
}

const refundMethodLabels: { [key: string]: string } = {
  'Hanya Transfer': 'HANYA_TRANSFER',
  'Hanya Cash': 'HANYA_CASH',
  'Hanya Credit/Voucher': 'HANYA_CREDIT_VOUCHER',
  'Semua Metode': 'ALL'
};

// Tambahkan reverse mapping untuk display
const refundMethodDisplay: { [key: string]: string } = {
  HANYA_TRANSFER: 'Hanya Transfer',
  HANYA_CASH: 'Hanya Cash',
  HANYA_CREDIT_VOUCHER: 'Hanya Credit/Voucher',
  ALL: 'Semua Metode'
};

const refundMethodOptions = [
  { value: 'Hanya Transfer', label: 'Hanya Transfer' },
  { value: 'Hanya Cash', label: 'Hanya Cash' },
  { value: 'Hanya Credit/Voucher', label: 'Hanya Credit/Voucher' },
  { value: 'Semua Metode', label: 'Semua Metode' }
];

const PengembalianDanaCard: React.FC<PengembalianDanaCardProps> = ({
  data,
  id,
  onUpdate
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    refundMethods: data.refundPaymentMethod.map(method => ({
      value: method,
      label: method
    })),
    refundDeadline: data.refundDaysBeforeBooking.toString(),
    refundDeadlineUnit: 'MINUTES_BEFORE_BOOKING' as
      | 'MINUTES_BEFORE_BOOKING'
      | 'HOURS_BEFORE_BOOKING'
      | 'DAYS_BEFORE_BOOKING'
  });

  const [updatePolicyRefund] = useUpdatePolicyRefundMutation();

  // Modifikasi cara render options
  const getAvailableOptions = () => {
    const selectedValues = formData.refundMethods.map(method => method.value);
    // Hanya tampilkan opsi yang belum dipilih
    const availableOptions = refundMethodOptions.filter(
      option => !selectedValues.includes(option.value)
    );
    return availableOptions;
  };

  // Modifikasi handleRefundMethodChange
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRefundMethodChange = (selectedOptions: any) => {
    setFormData(prev => ({
      ...prev,
      refundMethods: selectedOptions || []
    }));
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const hasChanges = () => {
    const originalData = {
      refundMethods: data.refundPaymentMethod.map(method => ({
        value: method,
        label: method
      })),
      refundDeadline: data.refundDaysBeforeBooking.toString(),
      refundDeadlineUnit: 'MINUTES_BEFORE_BOOKING'
    };

    // Bandingkan nilai yang sebenarnya
    return (
      JSON.stringify(formData.refundMethods) !==
        JSON.stringify(originalData.refundMethods) ||
      formData.refundDeadline !== originalData.refundDeadline ||
      formData.refundDeadlineUnit !== originalData.refundDeadlineUnit
    );
  };

  const handleCancel = () => {
    if (!hasChanges()) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Perubahan data tidak akan disimpan',
      deskripsi: 'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        setFormData({
          refundMethods: data.refundPaymentMethod.map(method => ({
            value: method,
            label: method
          })),
          refundDeadline: data.refundDaysBeforeBooking.toString(),
          refundDeadlineUnit: 'MINUTES_BEFORE_BOOKING'
        });
        setIsEditing(false);
      }
    });
  };

  const handleSave = () => {
    showCustomAlert({
      icon: 'question',
      label: 'Perubahan data akan disimpan',
      deskripsi:
        'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await updatePolicyRefund({
            id,
            data: {
              refundDaysBeforeBooking: Number(formData.refundDeadline),
              refundPaymentMethod: formData.refundMethods.map(
                method => refundMethodLabels[method.value] || method.value
              ),
              refundDaysBeforeBookingType: formData.refundDeadlineUnit
            }
          }).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Pengaturan pengembalian dana berhasil disimpan',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
              if (onUpdate) {
                onUpdate({
                  refundPaymentMethod: formData.refundMethods.map(
                    method => refundMethodLabels[method.value] || method.value
                  ),
                  refundDaysBeforeBooking: Number(formData.refundDeadline)
                });
              }
            }
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Error',
            deskripsi: 'Gagal menyimpan pengaturan pengembalian dana',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  if (isEditing) {
    return (
      <Card className="shadow-sm">
        <Card.Body>
          <Row className="mb-4">
            <Col xs={12} className="d-flex align-items-center">
              <h3>Kebijakan Pengembalian Dana</h3>
            </Col>
          </Row>

          <div className="d-flex flex-column">
            {/* Metode Pembayaran */}
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                Metode Pembayaran Pengembalian Dana
              </Form.Label>
              <Select
                isMulti
                options={getAvailableOptions()}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={{
                  placeholder: provided => ({
                    ...provided,
                    fontSize: '12px'
                  }),
                  option: provided => ({
                    ...provided,
                    fontSize: '12px'
                  }),
                  control: provided => ({
                    ...provided,
                    fontSize: '12px'
                  }),
                  multiValue: provided => ({
                    ...provided,
                    fontSize: '12px'
                  }),
                  multiValueLabel: provided => ({
                    ...provided,
                    fontSize: '12px'
                  })
                }}
                onChange={handleRefundMethodChange}
                value={formData.refundMethods}
                placeholder="Pilih metode pembayaran"
                hideSelectedOptions={true}
              />
            </Form.Group>

            {/* Batas Waktu Pengajuan */}
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                Pelanggan Harus Mengisi Data Ajuan Pengembalian Dana Sebelum
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="refundDeadline"
                  value={formData.refundDeadline}
                  onChange={handleChange}
                  placeholder="0"
                  min="0"
                  style={{ width: '70%' }}
                />
                <Form.Select
                  name="refundDeadlineUnit"
                  value={formData.refundDeadlineUnit}
                  onChange={handleChange}
                  style={{ width: '30%' }}
                >
                  <option value="MINUTES_BEFORE_BOOKING">
                    Menit Sebelum Waktu Booking
                  </option>
                  <option value="HOURS_BEFORE_BOOKING">
                    Jam Sebelum Waktu Booking
                  </option>
                  <option value="DAYS_BEFORE_BOOKING">
                    Hari Sebelum Waktu Booking
                  </option>
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </div>

          {/* Tombol Batalkan dan Simpan */}
          <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
            <Button
              variant="link"
              style={{ color: 'red' }}
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button onClick={handleSave}>Simpan</Button>
          </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title as="h3" className="mb-4">
          Kebijakan Pengembalian Dana{' '}
          <FontAwesomeIcon
            icon={faPencilAlt}
            className="fs-8 ms-2"
            style={{ cursor: 'pointer' }}
            onClick={() => setIsEditing(true)}
          />
        </Card.Title>
        <div className="mb-3">
          <span className="text-muted d-block fw-bold">
            Metode Pembayaran Pengembalian Dana
          </span>
          <span>
            {data.refundPaymentMethod
              .map(method => refundMethodDisplay[method] || method)
              .join(', ') || '-'}
          </span>
        </div>
        <div className="mb-3">
          <span className="text-muted d-block fw-bold">
            Pelanggan Harus Mengisi Data Ajuan Pengembalian Dana Sebelum
          </span>
          <span>{data.refundDaysBeforeBooking}</span>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PengembalianDanaCard;
