/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Nav,
  Tab,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBill,
  faUsers,
  faCalendarCheck,
  faBuilding,
  faFileLines,
  faMinus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import PilihPemilikJabatanModal from './PilihPemilikJabatanModal';
import { useCreateClinicalServiceMutation } from 'api/serviceApi';
import { ClinicalServiceCreateRequest } from 'api/request/ClinicalServiceCreateReq';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useGetAllCategoryServiceQuery } from 'api/categoryServiceApi';
import { useGetCustomerQuery } from 'api/customerApi';
import { useGetActiveTemplatesQuery } from 'api/serviceTemplateApi';
import Select from 'react-select';

interface TambahLayananModalProps {
  show: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
}
interface PriceRow {
  id: string;
  title: string;
  duration: string;
  durationUnit: string;
  customerType: string;
  location: string;
  price: string;
}

interface RatedPriceRow {
  id: string;
  title: string;
  rate: string;
  minDuration: string;
  maxDuration: string;
  customerType: string;
  location: string;
  price: string;
}

interface StaffRow {
  id: string;
  name: string;
  position: string;
}

interface StaffSettings {
  staffPerBooking: string;
}

const TambahLayananModal = ({
  show,
  handleClose,
  onSuccess
}: TambahLayananModalProps) => {
  const [activeTab, setActiveTab] = useState('harga');
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    serviceName: '',
    serviceId: '',
    color: '#E3E6EF',
    description: '',
    category: '',
    status: '',
    createdDate: '',
    bufferTime: 'default',
    bufferTimeBeforeValue: '0',
    bufferTimeBeforeUnit: 'Menit',
    bufferTimeAfterValue: '0',
    bufferTimeAfterUnit: 'Menit',
    priceType: 'fixed',
    isDefaultForTimeGap: ''
  });

  const [priceRows, setPriceRows] = useState<PriceRow[]>([
    {
      id: '1',
      title: '',
      duration: '0',
      durationUnit: 'Menit',
      customerType: '',
      location: '',
      price: '0'
    }
  ]);

  const [ratedPriceRows, setRatedPriceRows] = useState<RatedPriceRow[]>([
    {
      id: '1',
      title: '',
      rate: 'per Menit',
      minDuration: '0',
      maxDuration: '0',
      customerType: '',
      location: '',
      price: '0'
    }
  ]);

  const [staffSettings, setStaffSettings] = useState({
    staffPerBooking: '1'
  });

  const [staffRows, setStaffRows] = useState<StaffRow[]>([]);

  const [showPilihPegawai, setShowPilihPegawai] = useState(false);

  const [createClinicalService] = useCreateClinicalServiceMutation();

  // Tambahkan state untuk menyimpan data kategori
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [categories, setCategories] = useState<any[]>([]);

  // Tambahkan state untuk tipe kebijakan
  const [bookingSettings, setBookingSettings] = useState({
    policyType: 'default',
    policyId: '',
    isNotedForReasonVisit: false,
    isDiagnosisRecord: false
  });

  // Tambahkan state untuk tracking perubahan form
  const [isFormDirty, setIsFormDirty] = useState(false);

  // Tambahkan state untuk menyimpan data customer
  const [customers, setCustomers] = useState<
    Array<{ id: string; name: string }>
  >([]);

  // Tambahkan state untuk template responses
  const [templateResponses, setTemplateResponses] = useState<
    Array<{
      fieldId: string;
      responseValue?: string;
      optionResponse?: Array<{
        optionId: string;
        selectedChoice: boolean;
      }>;
    }>
  >([]);

  // Tambahkan state untuk error messages
  const [templateFieldErrors, setTemplateFieldErrors] = useState<{
    [key: string]: string;
  }>({});

  // Ganti query template dengan skip option
  const { data: templateData, refetch: refetchTemplate } =
    useGetActiveTemplatesQuery(undefined, {
      // Skip query ketika modal tertutup
      skip: !show
    });

  // Fetch data kategori
  const { data: categoryData } = useGetAllCategoryServiceQuery({
    page: 1,
    limit: 100,
    orderBy: 'name',
    direction: 'ASC'
  });

  // Fetch data customer
  const { data: customerData } = useGetCustomerQuery({
    page: 1,
    limit: 100,
    orderBy: 'fullName',
    direction: 'ASC'
  });

  // Tambahkan useEffect untuk update categories saat categoryData berubah
  useEffect(() => {
    if (categoryData?.data?.result) {
      setCategories(categoryData.data.result);
    }
  }, [categoryData]); // Hanya re-run saat categoryData berubah

  // Update customers saat customerData berubah
  useEffect(() => {
    if (customerData?.data) {
      const formattedCustomers = customerData.data.map(customer => ({
        id: customer.id,
        name: customer.fullName || '-'
      }));
      setCustomers(formattedCustomers);
    }
  }, [customerData]);

  // Tambahkan useEffect untuk set nilai default dan refetch saat modal dibuka
  useEffect(() => {
    if (show) {
      // Refetch template data
      refetchTemplate();

      // Set nilai default dari selectedChoice
      if (templateData?.data) {
        const defaultResponses: Array<{
          fieldId: string;
          responseValue?: string;
          optionResponse?: Array<{
            optionId: string;
            selectedChoice: boolean;
          }>;
        }> = [];

        templateData.data.forEach(template => {
          template.clinicalserviceTemplateFields.forEach(field => {
            if (field.clinicalserviceTemplateFieldOption.length > 0) {
              // Buat salinan baru dari array option
              const options = [...field.clinicalserviceTemplateFieldOption];
              const selectedOptions = options
                .map(option => ({
                  optionId: option.id,
                  selectedChoice: option.selectedChoice
                }))
                .filter(opt => opt.selectedChoice);

              // Jika ada opsi yang selected, tambahkan ke responses
              if (selectedOptions.length > 0) {
                defaultResponses.push({
                  fieldId: field.id,
                  optionResponse: options.map(option => ({
                    optionId: option.id,
                    selectedChoice: option.selectedChoice
                  }))
                });
              }
            }
          });
        });

        // Set template responses dengan nilai default
        setTemplateResponses(defaultResponses);
      }
    } else {
      // Reset template responses saat modal ditutup
      setTemplateResponses([]);
    }
  }, [show, templateData?.data, refetchTemplate]);

  // Fungsi untuk mengecek apakah form sudah diubah
  const checkFormDirty = () => {
    const isDirty =
      formData.serviceName !== '' ||
      formData.description !== '' ||
      formData.category !== '' ||
      formData.createdDate !== '' ||
      priceRows.some(row => row.title !== '' || row.price !== '') ||
      ratedPriceRows.some(row => row.title !== '' || row.price !== '') ||
      staffRows.length > 0;

    setIsFormDirty(isDirty);
  };

  // Fungsi untuk reset form ke nilai default
  const resetForm = () => {
    setFormData({
      serviceName: '',
      serviceId: '',
      color: '#E3E6EF',
      description: '',
      category: '',
      status: '',
      createdDate: '',
      bufferTime: 'default',
      bufferTimeBeforeValue: '0',
      bufferTimeBeforeUnit: 'Menit',
      bufferTimeAfterValue: '0',
      bufferTimeAfterUnit: 'Menit',
      priceType: 'fixed',
      isDefaultForTimeGap: ''
    });

    setPriceRows([
      {
        id: '1',
        title: '',
        duration: '0',
        durationUnit: 'Menit',
        customerType: '',
        location: '',
        price: '0'
      }
    ]);

    setRatedPriceRows([
      {
        id: '1',
        title: '',
        rate: 'per Menit',
        minDuration: '0',
        maxDuration: '0',
        customerType: '',
        location: '',
        price: '0'
      }
    ]);

    setStaffSettings({
      staffPerBooking: '1'
    });

    setStaffRows([]);

    setBookingSettings({
      policyType: 'default',
      policyId: '',
      isNotedForReasonVisit: false,
      isDiagnosisRecord: false
    });

    setValidated(false);
    setIsFormDirty(false);
    setActiveTab('harga');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget;
    const errors: { [key: string]: string } = {};

    // Validasi template fields
    templateData?.data?.forEach(template => {
      template.clinicalserviceTemplateFields.forEach(field => {
        if (field.isRequired) {
          const response = templateResponses.find(
            resp => resp.fieldId === field.id
          );
          const hasOptions =
            field.clinicalserviceTemplateFieldOption.length > 0;

          if (hasOptions) {
            // Validasi untuk field dengan options
            const hasSelected = response?.optionResponse?.some(
              opt => opt.selectedChoice
            );
            if (!hasSelected) {
              errors[field.id] = 'Pilih minimal satu opsi';
            }
          } else {
            // Validasi untuk field tanpa options
            if (
              !response?.responseValue ||
              response.responseValue.trim() === ''
            ) {
              errors[field.id] = `${field.label} tidak boleh kosong`;
            }
          }
        }
      });
    });

    setTemplateFieldErrors(errors);

    if (Object.keys(errors).length > 0 || form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // Tambahkan konfirmasi sebelum menyimpan
    showCustomAlert({
      icon: 'warning',
      label: 'Konfirmasi Simpan',
      deskripsi: 'Apakah Anda yakin ingin menyimpan layanan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const trimmedServiceName = formData.serviceName
            .replace(/\s\s+/g, ' ')
            .trim();
          // Persiapkan data pricing
          const servicePricing =
            formData.priceType === 'fixed'
              ? priceRows.map(row => ({
                  title: row.title,
                  duration: parseInt(row.duration),
                  durationType: row.durationUnit.toLowerCase(),
                  customerId: row.customerType.split(',').map(id => id.trim()),
                  location: row.location.split(',').map(loc => loc.trim()),
                  price: parseInt(row.price)
                }))
              : ratedPriceRows.map(row => ({
                  title: row.title,
                  min: parseInt(row.minDuration),
                  max: parseInt(row.maxDuration),
                  customerId: row.customerType.split(',').map(id => id.trim()),
                  location: row.location.split(',').map(loc => loc.trim()),
                  price: parseInt(row.price)
                }));

          // Persiapkan data pegawai
          const serviceEmployees = staffRows.map(staff => ({
            employeeId: staff.id,
            additionalCost: '0' // Set default ke '0' karena additionalFee sudah dihapus
          }));

          const requestData: ClinicalServiceCreateRequest = {
            serviceId: formData.serviceId,
            isDefaultForTimeGap: formData.isDefaultForTimeGap === 'true',
            name: trimmedServiceName,
            description: formData.description,
            categoryId: formData.category,
            color: formData.color,
            beforeTreatmentInMinutes: parseInt(formData.bufferTimeBeforeValue),
            afterTreatmentInMinutes: parseInt(formData.bufferTimeAfterValue),
            pricingType: formData.priceType === 'fixed' ? 'Fixed' : 'Rated',
            status: true,
            taxPolicy: 'VAT',
            totalEmployee: staffRows.length,
            bookingSettings: {
              policyName:
                bookingSettings.policyType === 'default'
                  ? 'Default Policy'
                  : bookingSettings.policyId,
              isNotedForReasonVisit: bookingSettings.isNotedForReasonVisit,
              isDiagnosisRecord: bookingSettings.isDiagnosisRecord
            },
            clinicalServiceNotes: [
              {
                noteType: 'private',
                content: 'Catatan default'
              }
            ],
            clinicalServiceEmployees: serviceEmployees,
            clinicalServicePricing: servicePricing,
            clinicalServiceTemplateResponses: templateResponses.map(
              response => ({
                fieldId: response.fieldId,
                responseValue: response.responseValue || '',
                optionResponse: response.optionResponse
              })
            ),
            additionalCostType: 'Fixed',
            additionalCost: 'Jumlah Tetap'
          };

          await createClinicalService(requestData).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Layanan berhasil ditambahkan',
            buttonType: 'ok',
            onConfirm: () => {
              handleClose();
              if (onSuccess) {
                onSuccess();
              }
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          if (error?.data?.message?.includes('Duplicate entry for name')) {
            showCustomAlert({
              icon: 'error',
              label: 'Terdapat layanan dengan nama yang sama.',
              deskripsi: 'Mohon ubah nama layanan yang akan Anda tambahkan.',
              buttonType: 'ok'
            });
          }
        }
      }
    });
  };

  const handleCancelClick = () => {
    if (isFormDirty) {
      showCustomAlert({
        icon: 'warning',
        label: 'Layanan tidak akan disimpan',
        deskripsi: 'Apakah Anda yakin untuk membatalkan proses ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          resetForm(); // Reset form setelah konfirmasi batal
          handleClose();
        }
      });
    } else {
      handleClose();
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Set form dirty saat ada perubahan
    setIsFormDirty(true);
  };

  const handleAddPriceRow = () => {
    setPriceRows(prev => [
      ...prev,
      {
        id: Date.now().toString(), // unique ID
        title: '',
        duration: '0',
        durationUnit: 'Menit',
        customerType: '',
        location: '',
        price: '0'
      }
    ]);
  };

  const handleRemovePriceRow = (id: string) => {
    if (priceRows.length > 1) {
      setPriceRows(prev => prev.filter(row => row.id !== id));
    }
  };

  const handlePriceRowChange = (
    id: string,
    field: keyof PriceRow,
    value: string
  ) => {
    setPriceRows(prev =>
      prev.map(row =>
        row.id === id
          ? {
              ...row,
              [field]: value
            }
          : row
      )
    );
  };

  const handleAddRatedPriceRow = () => {
    setRatedPriceRows(prev => [
      ...prev,
      {
        id: Date.now().toString(),
        title: '',
        rate: 'per Menit',
        minDuration: '0',
        maxDuration: '0',
        customerType: '',
        location: '',
        price: '0'
      }
    ]);
  };

  const handleRemoveRatedPriceRow = (id: string) => {
    if (ratedPriceRows.length > 1) {
      setRatedPriceRows(prev => prev.filter(row => row.id !== id));
    }
  };

  const handleRatedPriceRowChange = (
    id: string,
    field: keyof RatedPriceRow,
    value: string
  ) => {
    setRatedPriceRows(prev =>
      prev.map(row =>
        row.id === id
          ? {
              ...row,
              [field]: value
            }
          : row
      )
    );
  };

  const handleRemoveStaff = (id: string) => {
    setStaffRows(prev => prev.filter(row => row.id !== id));
  };

  // Ubah handlePilihPegawai untuk langsung mengganti staffRows dengan data baru
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePilihPegawai = (selectedEmployees: any[]) => {
    // Langsung set staffRows dengan data yang dipilih dari modal
    const updatedStaffRows = selectedEmployees.map(employee => ({
      id: employee.id,
      name: employee.fullName,
      position: employee.jobTitle?.name || '-'
    }));

    setStaffRows(updatedStaffRows);
    setShowPilihPegawai(false);
  };

  // Handler untuk perubahan booking settings
  const handleBookingSettingsChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const isInput = e.target instanceof HTMLInputElement;

    if (name === 'policyType') {
      setBookingSettings(prev => ({
        ...prev,
        policyType: value,
        // Reset policyId jika beralih ke default
        policyId: value === 'default' ? '' : prev.policyId
      }));
    } else {
      setBookingSettings(prev => ({
        ...prev,
        [name]:
          isInput && e.target.type === 'checkbox'
            ? (e.target as HTMLInputElement).checked
            : value
      }));
    }
  };

  // Tambahkan handler untuk update template responses
  const handleTemplateChange = (
    templateId: string,
    fieldId: string,
    value: string | { optionId: string; selectedChoice: boolean }[]
  ) => {
    setTemplateResponses(prev => {
      const existingIndex = prev.findIndex(item => item.fieldId === fieldId);
      const newResponse =
        typeof value === 'string'
          ? { fieldId, responseValue: value }
          : { fieldId, optionResponse: value };

      if (existingIndex >= 0) {
        const newResponses = [...prev];
        newResponses[existingIndex] = newResponse;
        return newResponses;
      }

      return [...prev, newResponse];
    });
  };

  // Fungsi untuk render field berdasarkan tipe
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderField = (field: any) => {
    const fieldType = field.fieldType.code;
    const fieldId = field.id;

    return (
      <Form.Group key={fieldId} className="mb-3">
        <Form.Label className="mb-2 fs-8 p-0 text-capitalize">
          {field.label}
          {field.isRequired && <span className="text-danger">*</span>}
        </Form.Label>

        {renderFieldInput(field)}

        {templateFieldErrors[fieldId] && (
          <Form.Text className="text-danger">
            {templateFieldErrors[fieldId]}
          </Form.Text>
        )}
      </Form.Group>
    );
  };

  // Pindahkan logic render input ke fungsi terpisah
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderFieldInput = (field: any) => {
    const fieldType = field.fieldType.code;
    const fieldId = field.id;

    switch (fieldType) {
      case 'SINGLE_LINE_TEXT':
        return (
          <Form.Control
            type="text"
            value={
              templateResponses.find(resp => resp.fieldId === fieldId)
                ?.responseValue ?? ''
            }
            onChange={e =>
              handleTemplateChange(field.id, fieldId, e.target.value)
            }
            required={field.isRequired}
            maxLength={field.fieldTextLength}
            placeholder={`Masukkan ${field.label.toLowerCase()}`}
            isInvalid={!!templateFieldErrors[fieldId]}
          />
        );

      case 'MULTIPLE_LINE_TEXT':
        return (
          <Form.Control
            as="textarea"
            rows={3}
            value={
              templateResponses.find(resp => resp.fieldId === fieldId)
                ?.responseValue ?? ''
            }
            onChange={e =>
              handleTemplateChange(field.id, fieldId, e.target.value)
            }
            required={field.isRequired}
            maxLength={field.fieldTextLength}
            placeholder={`Masukkan ${field.label.toLowerCase()}`}
            isInvalid={!!templateFieldErrors[fieldId]}
          />
        );

      case 'RADIO_BUTTONS':
        return (
          <Form.Group>
            {field.clinicalserviceTemplateFieldOption.map(
              (option: {
                id: string;
                optionValue: string;
                selectedChoice: boolean;
              }) => (
                <Form.Check
                  key={option.id}
                  type="radio"
                  id={`${fieldId}-${option.id}`}
                  label={option.optionValue}
                  name={fieldId}
                  checked={
                    templateResponses
                      .find(resp => resp.fieldId === fieldId)
                      ?.optionResponse?.find(opt => opt.optionId === option.id)
                      ?.selectedChoice ?? option.selectedChoice
                  }
                  onChange={e => {
                    if (e.target.checked) {
                      handleTemplateChange(
                        field.id,
                        fieldId,
                        field.clinicalserviceTemplateFieldOption.map(
                          (opt: {
                            id: string;
                            optionValue: string;
                            selectedChoice: boolean;
                          }) => ({
                            optionId: opt.id,
                            selectedChoice: opt.id === option.id
                          })
                        )
                      );
                    }
                  }}
                  isInvalid={!!templateFieldErrors[fieldId]}
                />
              )
            )}
          </Form.Group>
        );

      case 'CHECKBOXES':
        return (
          <Form.Group>
            {field.clinicalserviceTemplateFieldOption.map(
              (option: {
                id: string;
                optionValue: string;
                selectedChoice: boolean;
              }) => (
                <Form.Check
                  key={option.id}
                  type="checkbox"
                  id={`${fieldId}-${option.id}`}
                  label={option.optionValue}
                  checked={
                    templateResponses
                      .find(resp => resp.fieldId === fieldId)
                      ?.optionResponse?.find(opt => opt.optionId === option.id)
                      ?.selectedChoice ?? option.selectedChoice
                  }
                  onChange={e => {
                    const currentResponses =
                      templateResponses.find(resp => resp.fieldId === fieldId)
                        ?.optionResponse || [];

                    const updatedResponses = currentResponses.map(resp =>
                      resp.optionId === option.id
                        ? { ...resp, selectedChoice: e.target.checked }
                        : resp
                    );

                    if (
                      !currentResponses.find(
                        resp => resp.optionId === option.id
                      )
                    ) {
                      updatedResponses.push({
                        optionId: option.id,
                        selectedChoice: e.target.checked
                      });
                    }

                    handleTemplateChange(field.id, fieldId, updatedResponses);
                  }}
                  isInvalid={!!templateFieldErrors[fieldId]}
                />
              )
            )}
          </Form.Group>
        );

      case 'DROPDOWN_LIST':
        return (
          <Form.Group>
            <Form.Select
              value={
                templateResponses
                  .find(resp => resp.fieldId === fieldId)
                  ?.optionResponse?.find(opt => opt.selectedChoice)?.optionId ??
                ''
              }
              onChange={e => {
                const selectedValue = e.target.value;
                // Buat array optionResponse baru
                const optionResponse =
                  field.clinicalserviceTemplateFieldOption.map(
                    (option: { id: string }) => ({
                      id: option.id, // Tambahkan id
                      optionId: option.id,
                      selectedChoice: option.id === selectedValue
                    })
                  );

                // Update template responses dengan format yang benar
                setTemplateResponses(prev => {
                  const existingIndex = prev.findIndex(
                    item => item.fieldId === fieldId
                  );
                  const newResponse = {
                    fieldId,
                    optionResponse
                  };

                  if (existingIndex >= 0) {
                    const newResponses = [...prev];
                    newResponses[existingIndex] = newResponse;
                    return newResponses;
                  }

                  return [...prev, newResponse];
                });
              }}
              required={field.isRequired}
              isInvalid={!!templateFieldErrors[fieldId]}
            >
              <option value="">Pilih {field.label.toLowerCase()}</option>
              {field.clinicalserviceTemplateFieldOption.map(
                (option: { id: string; optionValue: string }) => (
                  <option key={option.id} value={option.id}>
                    {option.optionValue}
                  </option>
                )
              )}
            </Form.Select>
          </Form.Group>
        );

      case 'MULTI_SELECT_LIST':
        return (
          <Form.Group>
            <Select
              isMulti
              className={`basic-multi-select ${
                templateFieldErrors[fieldId] ? 'is-invalid' : ''
              }`}
              classNamePrefix="select"
              value={field.clinicalserviceTemplateFieldOption
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .filter((opt: any) => {
                  const response = templateResponses.find(
                    resp => resp.fieldId === fieldId
                  );
                  if (response?.optionResponse) {
                    return response.optionResponse.find(
                      respOpt =>
                        respOpt.optionId === opt.id && respOpt.selectedChoice
                    );
                  }
                  return opt.selectedChoice;
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .map((opt: any) => ({
                  value: opt.id,
                  label: opt.optionValue
                }))}
              options={field.clinicalserviceTemplateFieldOption.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (option: any) => ({
                  value: option.id,
                  label: option.optionValue
                })
              )}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(selectedOptions: any) => {
                const updatedResponses =
                  field.clinicalserviceTemplateFieldOption.map(
                    (option: { id: string }) => ({
                      optionId: option.id,
                      selectedChoice: selectedOptions.some(
                        (selected: { value: string }) =>
                          selected.value === option.id
                      )
                    })
                  );
                handleTemplateChange(field.id, fieldId, updatedResponses);
              }}
              placeholder={`Pilih ${field.label.toLowerCase()}`}
            />
          </Form.Group>
        );

      case 'DATE':
        return (
          <Form.Group>
            <Form.Control
              type="date"
              value={
                templateResponses.find(resp => resp.fieldId === fieldId)
                  ?.responseValue ?? ''
              }
              onChange={e =>
                handleTemplateChange(field.id, fieldId, e.target.value)
              }
              required={field.isRequired}
              isInvalid={!!templateFieldErrors[fieldId]}
            />
          </Form.Group>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <style>
        {`
          /* Style untuk modal utama TambahLayanan */
          .modal-dialog-centered.tambah-layanan {
            max-width: 1800px !important;
            width: 95% !important;
          }

          /* Style untuk overlay modal Pilih Pegawai */
          .modal-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 1050;
          }

          /* Style lainnya tetap sama */
          .vertical-divider {
            position: absolute;
            left: 41.666667%;
            top: 0;
            bottom: 0;
            width: 1px;
            background-color: #c5c5c5;
            transform: translateX(-50%);
            z-index: 1;
            opacity: 1;
          }
        `}
      </style>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
        dialogClassName="tambah-layanan"
      >
        {showPilihPegawai && <div className="modal-overlay" />}
        <Modal.Header className="px-5 py-4">
          <Modal.Title className="me-auto fw-bolder fs-6">
            Tambah Layanan
          </Modal.Title>
          <div className="d-flex gap-2">
            <Button
              variant="link"
              className="text-danger"
              onClick={handleCancelClick}
            >
              Batalkan
            </Button>
            <Button variant="outline-primary">Simpan & Buat Baru</Button>
            <Button variant="primary" type="submit" form="addServiceForm">
              Simpan
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body className="bg-light px-5 py-4 position-relative">
          <Form
            id="addServiceForm"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <div className="vertical-divider" />
            <Row>
              <Col md={5} className="d-flex flex-column gap-3">
                <div>
                  <h4 className="mb-3">Informasi Layanan</h4>

                  {/* Nama Layanan */}
                  <Form.Group controlId="serviceName" className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Nama Layanan <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="serviceName"
                      value={formData.serviceName}
                      onChange={handleChange}
                      required
                      placeholder="Masukkan nama layanan"
                    />
                    <Form.Control.Feedback type="invalid">
                      Nama layanan harus diisi
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="serviceId">
                        <Form.Label className="mb-2 text-capitalize fs-8 p-0">
                          ID Service
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="serviceId"
                          value={formData.serviceId}
                          onChange={handleChange}
                          placeholder="Masukkan ID Service"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          ID Service harus diisi
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="mb-2 text-capitalize fs-8 p-0">
                          Warna
                        </Form.Label>
                        <div className="d-flex gap-2 align-items-center">
                          <div
                            className="position-relative"
                            style={{ width: '100%' }}
                          >
                            <Form.Control
                              type="color"
                              name="color"
                              value={formData.color}
                              onChange={handleChange}
                              title="Pilih warna"
                              style={{
                                width: '100%',
                                height: '38px',
                                padding: '2px',
                                cursor: 'pointer'
                              }}
                            />
                          </div>
                          <div
                            style={{
                              padding: '8px 12px',
                              borderRadius: '4px',
                              border: '1px solid #dee2e6',
                              fontSize: '14px',
                              backgroundColor: '#f8f9fa'
                            }}
                          >
                            {formData.color.toUpperCase()}
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Deskripsi Layanan */}
                  <Form.Group controlId="description" className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Deskripsi Layanan
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder="Masukkan deskripsi layanan"
                    />
                  </Form.Group>

                  {/* Kategori dan Status */}
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="category">
                        <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                          Kategori <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name="category"
                          value={formData.category}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Pilih Kategori</option>
                          {categories.map(category => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Kategori harus dipilih
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="status">
                        <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                          Status <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name="status"
                          value={formData.status}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Pilih Status</option>
                          <option value="aktif">Aktif</option>
                          <option value="tidak aktif">Tidak Aktif</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Status harus dipilih
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Tanggal Dibuat */}
                  <Form.Group controlId="createdDate" className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Tanggal Dibuat <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="createdDate"
                      value={formData.createdDate}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Tanggal dibuat harus diisi
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Waktu Jeda */}
                  <Form.Group controlId="bufferTime" className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Waktu Jeda
                    </Form.Label>
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex gap-3">
                        <Form.Check
                          type="radio"
                          id="buffer-default"
                          name="bufferTime"
                          label="Default"
                          value="default"
                          checked={formData.bufferTime === 'default'}
                          onChange={handleChange}
                        />
                        <Form.Check
                          type="radio"
                          id="buffer-custom"
                          name="bufferTime"
                          label="Custom"
                          value="custom"
                          checked={formData.bufferTime === 'custom'}
                          onChange={handleChange}
                        />
                      </div>

                      {formData.bufferTime === 'custom' && (
                        <div className="d-flex flex-column gap-2">
                          <div className="d-flex align-items-center gap-2">
                            <InputGroup style={{ width: '220px' }}>
                              <Form.Control
                                type="number"
                                min="0"
                                name="bufferTimeBeforeValue"
                                placeholder="0"
                                onChange={handleChange}
                                disabled={formData.bufferTime !== 'custom'}
                                style={{ maxWidth: '100px', borderRight: 0 }}
                              />
                              <Form.Select
                                name="bufferTimeBeforeUnit"
                                value={formData.bufferTimeBeforeUnit}
                                onChange={handleChange}
                                disabled={formData.bufferTime !== 'custom'}
                                style={{
                                  width: '120px',
                                  borderLeft: '0.5px solid #CBD0DD'
                                }}
                              >
                                <option value="Menit">Menit</option>
                                <option value="Jam">Jam</option>
                              </Form.Select>
                            </InputGroup>
                            <span className="text-secondary fs-9">
                              Sebelum booking dimulai
                            </span>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <InputGroup style={{ width: '220px' }}>
                              <Form.Control
                                type="number"
                                min="0"
                                name="bufferTimeAfterValue"
                                placeholder="0"
                                onChange={handleChange}
                                disabled={formData.bufferTime !== 'custom'}
                                style={{ maxWidth: '100px', borderRight: 0 }}
                              />
                              <Form.Select
                                name="bufferTimeAfterUnit"
                                value={formData.bufferTimeAfterUnit}
                                onChange={handleChange}
                                disabled={formData.bufferTime !== 'custom'}
                                style={{
                                  width: '120px',
                                  borderLeft: '0.5px solid #CBD0DD'
                                }}
                              >
                                <option value="Menit">Menit</option>
                                <option value="Jam">Jam</option>
                              </Form.Select>
                            </InputGroup>
                            <span className="text-secondary fs-9">
                              Setelah booking selesai
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </div>
              </Col>

              <Col md={7}>
                <Tab.Container
                  activeKey={activeTab}
                  onSelect={k => k && setActiveTab(k)}
                >
                  <Nav
                    variant="tabs"
                    className="mb-3 d-flex justify-content-between flex-wrap gap-3"
                  >
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'harga' ? 'primary' : 'outline-primary'
                        }
                        onClick={() => setActiveTab('harga')}
                      >
                        <FontAwesomeIcon icon={faMoneyBill} className="me-2" />
                        Harga Layanan
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'pegawai'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('pegawai')}
                      >
                        <FontAwesomeIcon icon={faUsers} className="me-2" />
                        Pegawai
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'booking'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('booking')}
                      >
                        <FontAwesomeIcon
                          icon={faCalendarCheck}
                          className="me-2"
                        />
                        Booking
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'fasilitas'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('fasilitas')}
                      >
                        <FontAwesomeIcon icon={faBuilding} className="me-2" />
                        Fasilitas
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'formTemplat'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('formTemplat')}
                      >
                        <FontAwesomeIcon icon={faFileLines} className="me-2" />
                        Form Templat
                      </Button>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="harga">
                      <div className="d-flex flex-column">
                        <h4 className="my-3">Harga Layanan</h4>

                        {/* Radio buttons untuk jenis harga */}
                        <div className="mb-2">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Harga
                          </Form.Label>
                          <div className="d-flex gap-3">
                            <Form.Check
                              type="radio"
                              id="fixed-price"
                              name="priceType"
                              label="Fixed Prices"
                              value="fixed"
                              checked={formData.priceType === 'fixed'}
                              onChange={handleChange}
                            />
                            <Form.Check
                              type="radio"
                              id="rated-price"
                              name="priceType"
                              label="Rated Prices"
                              value="rated"
                              checked={formData.priceType === 'rated'}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        {/* Price Input Rows */}
                        {formData.priceType === 'fixed'
                          ? priceRows.map((row, index) => (
                              <div
                                key={row.id}
                                className="d-flex gap-2 mb-2 justify-content-between"
                              >
                                {/* Judul */}
                                <div style={{ width: '180px' }}>
                                  {index === 0 && (
                                    <span className="d-block fs-9 text-secondary mb-2">
                                      Judul
                                    </span>
                                  )}
                                  <Form.Control
                                    type="text"
                                    placeholder="judul"
                                    value={row.title}
                                    onChange={e =>
                                      handlePriceRowChange(
                                        row.id,
                                        'title',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>

                                {/* Durasi */}
                                <div style={{ width: '200px' }}>
                                  {index === 0 && (
                                    <span className="d-block fs-9 text-secondary mb-2">
                                      Durasi
                                    </span>
                                  )}
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      min="0"
                                      placeholder="0"
                                      onChange={e =>
                                        handlePriceRowChange(
                                          row.id,
                                          'duration',
                                          e.target.value
                                        )
                                      }
                                      style={{ width: '50px', borderRight: 0 }}
                                    />
                                    <Form.Select
                                      value={row.durationUnit}
                                      onChange={e =>
                                        handlePriceRowChange(
                                          row.id,
                                          'durationUnit',
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        width: '70px',
                                        borderLeft: '0.5px solid #CBD0DD'
                                      }}
                                    >
                                      <option value="Menit">Menit</option>
                                      <option value="Jam">Jam</option>
                                    </Form.Select>
                                  </InputGroup>
                                </div>

                                {/* Pelanggan dan Lokasi */}
                                <div style={{ width: '380px' }}>
                                  {index === 0 && (
                                    <div className="d-flex gap-2">
                                      <div style={{ width: '50%' }}>
                                        <span className="d-block fs-9 text-secondary mb-2">
                                          Pelanggan
                                        </span>
                                      </div>
                                      <div style={{ width: '50%' }}>
                                        <span className="d-block fs-9 text-secondary mb-2">
                                          Lokasi
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  <InputGroup>
                                    <Form.Select
                                      value={row.customerType}
                                      onChange={e =>
                                        handlePriceRowChange(
                                          row.id,
                                          'customerType',
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        borderRight: '0.5px solid #CBD0DD'
                                      }}
                                    >
                                      <option value="all">
                                        Semua Pelanggan
                                      </option>
                                      {customers.map(customer => (
                                        <option
                                          key={customer.id}
                                          value={customer.id}
                                        >
                                          {customer.name}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    <Form.Select
                                      value={row.location}
                                      onChange={e =>
                                        handlePriceRowChange(
                                          row.id,
                                          'location',
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="all">Semua Lokasi</option>
                                      <option value="klinik">Klinik</option>
                                      <option value="rumah">Rumah</option>
                                    </Form.Select>
                                  </InputGroup>
                                </div>

                                {/* Harga */}
                                <div style={{ width: '140px' }}>
                                  {index === 0 && (
                                    <span className="d-block fs-9 text-secondary mb-2">
                                      Harga (Rp)
                                    </span>
                                  )}
                                  <Form.Control
                                    type="number"
                                    min="0"
                                    placeholder="Harga"
                                    value={row.price}
                                    onChange={e =>
                                      handlePriceRowChange(
                                        row.id,
                                        'price',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>

                                {/* Button Hapus */}
                                <div
                                  style={{
                                    width: '50px',
                                    marginTop: index === 0 ? '28px' : '0'
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    className="d-flex align-items-center justify-content-center w-50"
                                    onClick={() => handleRemovePriceRow(row.id)}
                                    disabled={priceRows.length === 1}
                                    style={{
                                      border: '1px solid #dee2e6'
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </Button>
                                </div>
                              </div>
                            ))
                          : ratedPriceRows.map((row, index) => (
                              <div
                                key={row.id}
                                className="d-flex gap-2 mb-2 justify-content-between"
                              >
                                {/* Judul */}
                                <div style={{ width: '130px' }}>
                                  {index === 0 && (
                                    <span className="d-block fs-9 text-secondary mb-2">
                                      Judul
                                    </span>
                                  )}
                                  <Form.Control
                                    type="text"
                                    placeholder="Judul"
                                    value={row.title}
                                    onChange={e =>
                                      handleRatedPriceRowChange(
                                        row.id,
                                        'title',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>

                                {/* Tarif */}
                                <div style={{ width: '140px' }}>
                                  {index === 0 && (
                                    <span className="d-block fs-9 text-secondary mb-2">
                                      Tarif
                                    </span>
                                  )}
                                  <Form.Select
                                    value={row.rate}
                                    onChange={e =>
                                      handleRatedPriceRowChange(
                                        row.id,
                                        'rate',
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="per Menit">per Menit</option>
                                    <option value="per Jam">per Jam</option>
                                    <option value="per Hari">per Hari</option>
                                  </Form.Select>
                                </div>

                                {/* Min Max Duration */}
                                <div style={{ width: '190px' }}>
                                  {index === 0 && (
                                    <div className="d-flex gap-2">
                                      <div style={{ width: '50%' }}>
                                        <span className="d-block fs-9 text-secondary mb-2">
                                          Min
                                        </span>
                                      </div>
                                      <div style={{ width: '50%' }}>
                                        <span className="d-block fs-9 text-secondary mb-2">
                                          Max
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      min="0"
                                      placeholder="0"
                                      onChange={e =>
                                        handleRatedPriceRowChange(
                                          row.id,
                                          'minDuration',
                                          e.target.value
                                        )
                                      }
                                      style={{ borderRight: 0 }}
                                    />
                                    <Form.Control
                                      type="number"
                                      min="0"
                                      placeholder="0"
                                      onChange={e =>
                                        handleRatedPriceRowChange(
                                          row.id,
                                          'maxDuration',
                                          e.target.value
                                        )
                                      }
                                    />
                                  </InputGroup>
                                </div>

                                {/* Pelanggan dan Lokasi */}
                                <div style={{ width: '300px' }}>
                                  {index === 0 && (
                                    <div className="d-flex gap-2">
                                      <div style={{ width: '50%' }}>
                                        <span className="d-block fs-9 text-secondary mb-2">
                                          Pelanggan
                                        </span>
                                      </div>
                                      <div style={{ width: '50%' }}>
                                        <span className="d-block fs-9 text-secondary mb-2">
                                          Lokasi
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  <InputGroup>
                                    <Form.Select
                                      value={row.customerType}
                                      onChange={e =>
                                        handleRatedPriceRowChange(
                                          row.id,
                                          'customerType',
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        borderRight: '0.5px solid #CBD0DD'
                                      }}
                                    >
                                      <option value="all">
                                        Semua Pelanggan
                                      </option>
                                      {customers.map(customer => (
                                        <option
                                          key={customer.id}
                                          value={customer.id}
                                        >
                                          {customer.name}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    <Form.Select
                                      value={row.location}
                                      onChange={e =>
                                        handleRatedPriceRowChange(
                                          row.id,
                                          'location',
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Semua Lokasi</option>
                                      <option value="klinik">Klinik</option>
                                      <option value="rumah">Rumah</option>
                                    </Form.Select>
                                  </InputGroup>
                                </div>

                                {/* Harga */}
                                <div style={{ width: '140px' }}>
                                  {index === 0 && (
                                    <span className="d-block fs-9 text-secondary mb-2">
                                      Harga (Rp)
                                    </span>
                                  )}
                                  <Form.Control
                                    type="number"
                                    min="0"
                                    placeholder="Harga"
                                    value={row.price}
                                    onChange={e =>
                                      handleRatedPriceRowChange(
                                        row.id,
                                        'price',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>

                                {/* Button Hapus */}
                                <div
                                  style={{
                                    width: '50px',
                                    marginTop: index === 0 ? '28px' : '0'
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    className="d-flex align-items-center justify-content-center w-50"
                                    onClick={() =>
                                      handleRemoveRatedPriceRow(row.id)
                                    }
                                    disabled={ratedPriceRows.length === 1}
                                    style={{
                                      border: '1px solid #dee2e6'
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </Button>
                                </div>
                              </div>
                            ))}

                        {/* Button Tambah */}
                        <Button
                          variant="outline-primary"
                          className="w-100 rounded-3 mb-3"
                          onClick={
                            formData.priceType === 'fixed'
                              ? handleAddPriceRow
                              : handleAddRatedPriceRow
                          }
                        >
                          + Tambah
                        </Button>
                        <Form.Group controlId="tax" className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Pajak
                          </Form.Label>
                          <Form.Select name="tax">
                            <option value="">Pilih Pajak</option>
                            <option value="5%">5%</option>
                            <option value="10%">10%</option>
                            <option value="15%">15%</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="pegawai">
                      <div className="d-flex flex-column">
                        <h4 className="my-3">Pegawai</h4>

                        {/* Settings Row */}
                        <Row className="mb-4">
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                                Pegawai per Booking
                              </Form.Label>
                              <Form.Select
                                value={staffSettings.staffPerBooking}
                                onChange={e =>
                                  setStaffSettings(prev => ({
                                    ...prev,
                                    staffPerBooking: e.target.value
                                  }))
                                }
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Form.Label className="text-dark mb-2 p-0 fs-8 text-capitalize">
                          Pegawai
                        </Form.Label>

                        {/* Staff Table */}
                        <div className="table-responsive mb-3">
                          <table className="table">
                            <thead>
                              <tr className="fs-9">
                                <th className="ps-0 text-muted">
                                  NAMA PEGAWAI
                                </th>
                                <th className="text-muted">JABATAN</th>
                                <th style={{ width: '40px' }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {staffRows.length === 0 ? (
                                <tr>
                                  <td
                                    colSpan={3}
                                    className="text-center text-muted fs-9"
                                  >
                                    Belum ada pegawai
                                  </td>
                                </tr>
                              ) : (
                                staffRows.map(staff => (
                                  <tr
                                    key={staff.id}
                                    className="fs-9 align-middle"
                                  >
                                    <td>{staff.name}</td>
                                    <td>{staff.position}</td>
                                    <td className="text-end">
                                      <Button
                                        variant="link"
                                        className="text-dark p-0"
                                        onClick={() =>
                                          handleRemoveStaff(staff.id)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>

                        {/* Button Tambah Pegawai */}
                        <Button
                          variant="outline-primary"
                          className="w-100 rounded-3"
                          onClick={() => setShowPilihPegawai(true)}
                        >
                          + Tambah Pegawai
                        </Button>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="booking">
                      <div className="d-flex flex-column">
                        <h4 className="my-3">Pengaturan Booking</h4>

                        {/* Kebijakan */}
                        <div className="mb-2">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Kebijakan
                          </Form.Label>
                          <div className="d-flex gap-3">
                            <Form.Check
                              type="radio"
                              id="default-policy"
                              name="policyType"
                              label="Kebijakan Default"
                              value="default"
                              checked={bookingSettings.policyType === 'default'}
                              onChange={handleBookingSettingsChange}
                            />
                            <Form.Check
                              type="radio"
                              id="custom-policy"
                              name="policyType"
                              label="Kebijakan Kustom"
                              value="custom"
                              checked={bookingSettings.policyType === 'custom'}
                              onChange={handleBookingSettingsChange}
                            />
                          </div>
                        </div>

                        {/* Dropdown Pilih Kebijakan */}
                        <Form.Group className="mb-2">
                          <Form.Select
                            name="policyId"
                            value={bookingSettings.policyId}
                            onChange={handleBookingSettingsChange}
                            disabled={bookingSettings.policyType === 'default'}
                          >
                            <option value="">Pilih Kebijakan</option>
                            <option value="1">Kebijakan 1</option>
                            <option value="2">Kebijakan 2</option>
                          </Form.Select>
                        </Form.Group>

                        {/* Checkbox Options */}
                        <div>
                          <Form.Check
                            type="checkbox"
                            id="recordVisitReason"
                            name="isNotedForReasonVisit"
                            label="Catat alasan kunjungan"
                            checked={bookingSettings.isNotedForReasonVisit}
                            onChange={handleBookingSettingsChange}
                          />
                        </div>

                        <div className="mb-1">
                          <Form.Check
                            type="checkbox"
                            id="recordDiagnosis"
                            name="isDiagnosisRecord"
                            label="Rekam Diagnosa"
                            checked={bookingSettings.isDiagnosisRecord}
                            onChange={handleBookingSettingsChange}
                          />
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="fasilitas">
                      <div className="d-flex flex-column gap-3">
                        <h4>Informasi Fasilitas</h4>
                        {/* Form fields untuk fasilitas */}
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="formTemplat">
                      <div className="d-flex flex-column">
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        {templateData?.data
                          ?.filter(
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (template: any) =>
                              template.clinicalserviceTemplateFields.length > 0
                          )
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          .map((template: any) => (
                            <div key={template.id} className="mb-2">
                              <h4 className="mb-3">{template.templateName}</h4>
                              {/* Buat salinan array sebelum sorting */}
                              {[...template.clinicalserviceTemplateFields]
                                // Sort berdasarkan label (A-Z)
                                .sort((a, b) => a.label.localeCompare(b.label))
                                .map(
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  (field: any) => renderField(field)
                                )}
                            </div>
                          ))}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal Pilih Pegawai */}
      <PilihPemilikJabatanModal
        show={showPilihPegawai}
        onHide={() => setShowPilihPegawai(false)}
        onSelect={handlePilihPegawai}
        selectedIds={staffRows.map(staff => staff.id)}
        onMount={() => {}}
        style={{ zIndex: 1060 }}
        showPositionAlert={false}
        showConfirmationAlert={false}
      />
    </>
  );
};

export default TambahLayananModal;
