/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useEffect, useState, useMemo } from 'react';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import {
  useGetEmployeeByIdQuery,
  useUpdateAddressContactMutation
} from 'api/employeeApi';
import {
  useGetProvincesQuery,
  useGetRegenciesQuery,
  useGetDistrictsQuery,
  useGetVillagesQuery
} from 'api/locationApi';
import { showCustomAlert } from 'helpers/showCustomAlert';

interface Address {
  id: string;
  streetAddress: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  province: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  city: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  district: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  village: any;
  country: string;
  postalCode: string;
  addressType: string;
}

// Custom hook untuk mengelola queries lokasi
const useLocationQueries = (
  provinceId: string,
  regencyId: string,
  districtId: string
) => {
  const { data: regenciesResponse, isLoading: isLoadingRegencies } =
    useGetRegenciesQuery(provinceId || '', { skip: !provinceId });

  const { data: districtsResponse, isLoading: isLoadingDistricts } =
    useGetDistrictsQuery(regencyId || '', { skip: !regencyId });

  const { data: villagesResponse, isLoading: isLoadingVillages } =
    useGetVillagesQuery(districtId || '', { skip: !districtId });

  return {
    regencies: regenciesResponse?.data || [],
    districts: districtsResponse?.data || [],
    villages: villagesResponse?.data || [],
    isLoadingRegencies,
    isLoadingDistricts,
    isLoadingVillages
  };
};

interface PhoneContact {
  id: string;
  number: string;
  usageType: string;
  isWhatsapp: boolean;
}

interface EmailContact {
  id: string;
  email: string;
  usageType: string;
}

const AlamatKontakPegawaiCard = () => {
  const { id } = useParams<{ id: string }>();
  const { data: response, isLoading, refetch } = useGetEmployeeByIdQuery(id!);
  const [updateAddressContact] = useUpdateAddressContactMutation();
  const { data: employeeData } = useGetEmployeeByIdQuery(id || '');
  if (!employeeData?.data) {
    return null;
  }

  // State untuk form data
  const [formDataList, setFormDataList] = useState<
    Array<{
      id: string;
      alamat: string;
      kota: string;
      kotaName: string;
      provinsi: string;
      provinsiName: string;
      negara: string;
      kodePos: string;
      jenisAlamat: string;
      district: string;
      districtName: string;
      village: string;
      villageName: string;
      phones: Array<{
        id: string;
        number: string;
        usageType: string;
        isWhatsapp: boolean;
      }>;
      emails: Array<{
        id: string;
        email: string;
        usageType: string;
      }>;
    }>
  >([]);

  const [isEditing, setIsEditing] = useState(false);
  const [originalFormData, setOriginalFormData] = useState<typeof formDataList>(
    []
  );

  // State untuk error
  const [errors, setErrors] = useState({
    alamat: '',
    kota: '',
    provinsi: '',
    negara: '',
    jenisAlamat: '',
    district: '',
    village: '',
    phone: '',
    jenisPhone: '',
    email: ''
  });

  // Ambil data provinsi
  const { data: provincesResponse, isLoading: isLoadingProvinces } =
    useGetProvincesQuery();
  const provinces = provincesResponse?.data || [];

  // Ubah setup location queries
  const location0 = useLocationQueries(
    formDataList[0]?.provinsi || '',
    formDataList[0]?.kota || '',
    formDataList[0]?.district || ''
  );
  const location1 = useLocationQueries(
    formDataList[1]?.provinsi || '',
    formDataList[1]?.kota || '',
    formDataList[1]?.district || ''
  );
  const location2 = useLocationQueries(
    formDataList[2]?.provinsi || '',
    formDataList[2]?.kota || '',
    formDataList[2]?.district || ''
  );
  const location3 = useLocationQueries(
    formDataList[3]?.provinsi || '',
    formDataList[3]?.kota || '',
    formDataList[3]?.district || ''
  );
  const location4 = useLocationQueries(
    formDataList[4]?.provinsi || '',
    formDataList[4]?.kota || '',
    formDataList[4]?.district || ''
  );

  // Gabungkan hasil queries ke dalam array
  const locationQueries = useMemo(() => {
    return [location0, location1, location2, location3, location4].slice(
      0,
      formDataList.length
    );
  }, [
    location0,
    location1,
    location2,
    location3,
    location4,
    formDataList.length
  ]);

  // Modifikasi useEffect untuk load data
  useEffect(() => {
    if (response?.data) {
      const { addresses, contacts, emails } = response.data;

      const mappedAddresses = addresses.map(address => ({
        id: address.id,
        alamat: address.streetAddress || '',
        provinsi: address.province?.id || '',
        provinsiName: address.province?.name || '',
        kota: address.city?.id || '',
        kotaName: address.city?.name || '',
        district: address.district?.id || '',
        districtName: address.district?.name || '',
        village: address.village?.id || '',
        villageName: address.village?.name || '',
        negara: address.country || '',
        kodePos: address.postalCode || '',
        jenisAlamat: address.addressType || '',
        phones: contacts.map(contact => ({
          id: contact.id,
          number: contact.phoneNumber || '',
          usageType: contact.usageType || '',
          isWhatsapp: contact.isWhatsapp || false
        })),
        emails: emails.map(email => ({
          id: email.id,
          email: email.emailAddress || '',
          usageType: email.usageType || ''
        }))
      }));

      if (mappedAddresses.length === 0) {
        mappedAddresses.push({
          id: 'new-0',
          alamat: '',
          provinsi: '',
          provinsiName: '',
          kota: '',
          kotaName: '',
          district: '',
          districtName: '',
          village: '',
          villageName: '',
          negara: '',
          kodePos: '',
          jenisAlamat: '',
          phones: [],
          emails: []
        });
      }

      setFormDataList(mappedAddresses);
    }
  }, [response?.data]);

  // Handle edit click
  const handleEditClick = () => {
    setOriginalFormData(JSON.parse(JSON.stringify(formDataList)));
    setIsEditing(true);
  };

  // Handle cancel
  const handleCancel = () => {
    const currentData = JSON.stringify(formDataList);
    const originalData = JSON.stringify(originalFormData);

    const hasChanges = currentData !== originalData;

    if (!hasChanges) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Batalkan Perubahan',
      deskripsi:
        'Anda yakin ingin membatalkan perubahan? Semua perubahan yang belum disimpan akan hilang.',
      buttonType: 'yes-no',
      onConfirm: () => {
        setFormDataList(JSON.parse(JSON.stringify(originalFormData)));
        setIsEditing(false);
      }
    });
  };

  // Handle save
  const handleSave = async () => {
    // Reset errors
    const newErrors = {
      alamat: '',
      kota: '',
      provinsi: '',
      negara: '',
      jenisAlamat: '',
      district: '',
      village: '',
      phone: '',
      jenisPhone: '',
      email: ''
    };

    let hasError = false;

    // Validasi untuk setiap alamat
    formDataList.forEach((formData, index) => {
      if (!formData.alamat) {
        newErrors.alamat = 'Alamat harus diisi';
        hasError = true;
      }
      if (!formData.provinsi) {
        newErrors.provinsi = 'Provinsi harus diisi';
        hasError = true;
      }
      if (!formData.kota) {
        newErrors.kota = 'Kota harus diisi';
        hasError = true;
      }
      if (!formData.district || formData.district === '') {
        newErrors.district = 'Kecamatan harus diisi';
        hasError = true;
      }
      if (!formData.village || formData.village === '') {
        newErrors.village = 'Desa/Kelurahan harus diisi';
        hasError = true;
      }
      if (!formData.negara) {
        newErrors.negara = 'Negara harus diisi';
        hasError = true;
      }
      if (!formData.jenisAlamat) {
        newErrors.jenisAlamat = 'Jenis alamat harus diisi';
        hasError = true;
      }
    });

    // Validasi untuk nomor telepon
    formDataList[0]?.phones.forEach(phone => {
      if (!phone.number) {
        newErrors.phone = 'Nomor telepon harus diisi';
        hasError = true;
      }
      if (!phone.usageType) {
        newErrors.jenisPhone = 'Jenis penggunaan telepon harus diisi';
        hasError = true;
      }
    });

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Simpan Perubahan',
      deskripsi:
        'Anda yakin ingin menyimpan perubahan data alamat dan kontak ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const payload = {
            emails: formDataList[0].emails.map(email => ({
              ...(email.id && { id: email.id }),
              emailAddress: email.email,
              usageType: email.usageType
            })),
            contacts: formDataList[0].phones.map(phone => ({
              ...(phone.id && { id: phone.id }),
              phoneNumber: phone.number,
              usageType: phone.usageType,
              isWhatsapp: phone.isWhatsapp
            })),
            locations: formDataList.map(address => ({
              ...(address.id && { id: address.id }),
              streetAddress: address.alamat,
              state: 'Default',
              country: address.negara,
              postalCode: address.kodePos,
              addressType: address.jenisAlamat,
              province: parseInt(address.provinsi),
              city: parseInt(address.kota),
              district: parseInt(address.district),
              village: parseInt(address.village)
            }))
          };

          await updateAddressContact({
            id: id!,
            data: payload
          }).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data berhasil disimpan',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
              refetch(); // Refetch data setelah berhasil update
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: error?.data?.message || 'Terjadi kesalahan pada server.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  // Handle input changes
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === 'provinsi') {
      setFormDataList(prevData =>
        prevData.map(formData => ({
          ...formData,
          [name]: value,
          provinsiName: provinces?.find(p => p.id === value)?.name || '',
          kota: '',
          kotaName: ''
        }))
      );
    } else {
      setFormDataList(prevData =>
        prevData.map(formData => ({
          ...formData,
          [name]: value
        }))
      );
    }
  };

  const handleDeleteAddress = (index: number) => {
    const newFormDataList = formDataList.filter((_, i) => i !== index);
    setFormDataList(newFormDataList);
  };

  // Tambahkan handler untuk phone dan email
  const handleAddPhone = () => {
    const newFormDataList = [...formDataList];
    newFormDataList[0].phones.push({
      id: '',
      number: '',
      usageType: '',
      isWhatsapp: false
    });
    setFormDataList(newFormDataList);
  };

  const handleAddEmail = () => {
    const newFormDataList = [...formDataList];
    newFormDataList[0].emails.push({
      id: '',
      email: '',
      usageType: ''
    });
    setFormDataList(newFormDataList);
  };

  const handleDeletePhone = (phoneIndex: number) => {
    const newFormDataList = [...formDataList];
    newFormDataList[0].phones = newFormDataList[0].phones.filter(
      (_, index) => index !== phoneIndex
    );
    setFormDataList(newFormDataList);
  };

  const handleDeleteEmail = (emailIndex: number) => {
    const newFormDataList = [...formDataList];
    newFormDataList[0].emails = newFormDataList[0].emails.filter(
      (_, index) => index !== emailIndex
    );
    setFormDataList(newFormDataList);
  };

  if (isLoading) return <div>Loading...</div>;
  if (!response?.data?.addresses) return <div>No employee data found</div>;

  return (
    <Card>
      <Card.Body>
        {/* Judul dan Tombol Edit */}
        <Row className="mb-4">
          <Col xs={12} className="d-flex align-items-center">
            <h3>Alamat & Kontak</h3>
            {!isEditing && (
              <Button variant="ghost" size="sm" onClick={handleEditClick}>
                <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
              </Button>
            )}
          </Col>
        </Row>

        {/* Mode tampilan */}
        {!isEditing && (
          <>
            {/* Addresses */}
            <div className="mb-2">
              {employeeData.data.addresses.map((employee: Address, index) => (
                <div key={employee.id} className="mb-4">
                  <Row className="mb-2">
                    <Col xs={12} md={12} className="d-flex flex-column pe-3">
                      <span className="fw-bold text-muted">
                        Alamat {index + 1}
                      </span>
                      <span className="fw-normal">
                        {employee?.streetAddress || '-'}
                        <br />
                        Kecamatan{' '}
                        {typeof employee.district === 'object'
                          ? employee.district.name
                          : employee.district || '-'}
                        <br />
                        {typeof employee.city === 'object'
                          ? employee.city.name
                          : employee.city || '-'}
                        <br />
                        {typeof employee.province === 'object'
                          ? employee.province.name
                          : employee.province || '-'}
                        <br />
                        {employee?.country || '-'}
                        <br />
                        {employee?.postalCode || '-'}
                      </span>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>

            <hr className="my-3 border border-dashed" />

            {/* Emails */}
            <Row className="mb-2">
              <Col xs={12} md={12}>
                {employeeData.data.emails.map((email, index) => (
                  <div key={email.id} className="mb-2">
                    <span className="fw-bold text-muted">
                      Email {index + 1}
                    </span>
                    <span className="fw-normal d-block">
                      {email.emailAddress ? (
                        <a href={`mailto:${email.emailAddress}`}>
                          {email.emailAddress}
                        </a>
                      ) : (
                        '-'
                      )}
                    </span>
                  </div>
                ))}
              </Col>
            </Row>

            <hr className="my-3 border border-dashed" />

            {/* Phones */}
            <Row className="mb-2">
              <Col xs={12} md={12}>
                {employeeData.data.contacts.map((contact, index) => (
                  <div key={contact.id} className="mb-2">
                    <span className="fw-bold text-muted">
                      Nomor Telepon {index + 1}
                    </span>
                    <span className="fw-normal d-block">
                      {contact.phoneNumber ? (
                        <a
                          href={`https://wa.me/${contact.phoneNumber}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {contact.phoneNumber}
                        </a>
                      ) : (
                        '-'
                      )}
                    </span>
                  </div>
                ))}
              </Col>
            </Row>
          </>
        )}

        {/* Mode edit */}
        {isEditing && (
          <>
            {formDataList.map((formData, index) => {
              const queries = locationQueries[index] || {
                regencies: [],
                districts: [],
                villages: [],
                isLoadingRegencies: false,
                isLoadingDistricts: false,
                isLoadingVillages: false
              };

              const regencies = queries.regencies || [];
              const districts = queries.districts || [];
              const villages = queries.villages || [];

              return (
                <div key={formData.id}>
                  {index > 0 && <hr className="my-4 border-dashed" />}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h2 className="mb-0 fs-7 text-capitalize">
                      Alamat {index + 1}
                    </h2>
                    {index > 0 && (
                      <Button
                        variant="link"
                        className="text-danger p-0"
                        onClick={() => handleDeleteAddress(index)}
                      >
                        Hapus Alamat
                      </Button>
                    )}
                  </div>

                  <Row className="mb-3">
                    <Col xs={12} md={12}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Alamat <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          name={`alamat-${index}`}
                          type="text"
                          placeholder="Alamat"
                          value={formData.alamat}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              alamat: e.target.value
                            };
                            setFormDataList(newFormDataList);
                          }}
                          isInvalid={!!errors.alamat}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.alamat}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Provinsi <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name={`provinsi-${index}`}
                          value={formData.provinsi}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              provinsi: e.target.value,
                              provinsiName:
                                provinces?.find(p => p.id === e.target.value)
                                  ?.name || '',
                              kota: '',
                              kotaName: '',
                              district: '',
                              districtName: '',
                              village: '',
                              villageName: ''
                            };
                            setFormDataList(newFormDataList);
                          }}
                          isInvalid={!!errors.provinsi}
                          disabled={isLoadingProvinces}
                        >
                          <option value="">Pilih Provinsi</option>
                          {provinces.map(province => (
                            <option key={province.id} value={province.id}>
                              {province.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.provinsi}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Kota <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name={`kota-${index}`}
                          value={formData.kota}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              kota: e.target.value,
                              kotaName:
                                regencies?.find(r => r.id === e.target.value)
                                  ?.name || '',
                              district: '',
                              districtName: '',
                              village: '',
                              villageName: ''
                            };
                            setFormDataList(newFormDataList);
                          }}
                          isInvalid={!!errors.kota}
                          disabled={
                            !formData.provinsi || queries.isLoadingRegencies
                          }
                        >
                          <option value="">Pilih Kota</option>
                          {regencies.map(regency => (
                            <option key={regency.id} value={regency.id}>
                              {regency.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.kota}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Tambahkan field Kecamatan dan Desa/Kelurahan */}
                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Kecamatan <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name={`district-${index}`}
                          value={formData.district}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              district: e.target.value,
                              districtName:
                                districts?.find(d => d.id === e.target.value)
                                  ?.name || '',
                              village: '',
                              villageName: ''
                            };
                            setFormDataList(newFormDataList);
                          }}
                          disabled={
                            !formData.kota || queries.isLoadingDistricts
                          }
                          isInvalid={!!errors.district}
                        >
                          <option value="">Pilih Kecamatan</option>
                          {districts.map(district => (
                            <option key={district.id} value={district.id}>
                              {district.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.district}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Desa/Kelurahan <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name={`village-${index}`}
                          value={formData.village}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              village: e.target.value,
                              villageName:
                                villages?.find(v => v.id === e.target.value)
                                  ?.name || ''
                            };
                            setFormDataList(newFormDataList);
                          }}
                          disabled={
                            !formData.district || queries.isLoadingVillages
                          }
                          isInvalid={!!errors.village}
                        >
                          <option value="">Pilih Desa/Kelurahan</option>
                          {villages.map(village => (
                            <option key={village.id} value={village.id}>
                              {village.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.village}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Negara <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name={`negara-${index}`}
                          value={formData.negara}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              negara: e.target.value
                            };
                            setFormDataList(newFormDataList);
                          }}
                          isInvalid={!!errors.negara}
                        >
                          <option value="">Pilih negara</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Singapore">Singapura</option>
                          <option value="Malaysia">Malaysia</option>
                          <option value="Thailand">Thailand</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.negara}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Kode Pos
                        </Form.Label>
                        <Form.Control
                          name={`kodePos-${index}`}
                          type="text"
                          placeholder="Kode Pos"
                          value={formData.kodePos}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              kodePos: e.target.value
                            };
                            setFormDataList(newFormDataList);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={12}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Jenis Alamat <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name={`jenisAlamat-${index}`}
                          value={formData.jenisAlamat}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              jenisAlamat: e.target.value
                            };
                            setFormDataList(newFormDataList);
                          }}
                          isInvalid={!!errors.jenisAlamat}
                        >
                          <option value="">Pilih Jenis Alamat</option>
                          <option value="Rumah">Rumah</option>
                          <option value="Kos">Kos</option>
                          <option value="Apartemen">Apartemen</option>
                          <option value="Lainnya">Lainnya</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.jenisAlamat}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              );
            })}

            {/* Tombol Tambah Alamat */}
            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Button
                  variant="outline-primary"
                  className="mt-2 w-100"
                  onClick={() => {
                    setFormDataList([
                      ...formDataList,
                      {
                        id: '',
                        alamat: '',
                        kota: '',
                        kotaName: '',
                        provinsi: '',
                        provinsiName: '',
                        negara: '',
                        kodePos: '',
                        jenisAlamat: '',
                        district: '',
                        districtName: '',
                        village: '',
                        villageName: '',
                        phones: [],
                        emails: []
                      }
                    ]);
                  }}
                >
                  + Tambah Alamat
                </Button>
              </Col>
            </Row>

            {/* Bagian Telepon */}
            <h2 className="mb-2 mt-5 fs-7 text-capitalize">Telepon Pribadi</h2>
            {formDataList[0]?.phones.map((phoneData, phoneIndex) => (
              <div key={phoneData.id}>
                {phoneIndex > 0 && <hr className="my-4" />}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="mb-0 fs-8 text-capitalize">
                    Telepon {phoneIndex + 1}
                  </h2>
                  {phoneIndex > 0 && (
                    <Button
                      variant="link"
                      className="text-danger p-0"
                      onClick={() => handleDeletePhone(phoneIndex)}
                    >
                      Hapus Telepon
                    </Button>
                  )}
                </div>
                <Row className="mb-3">
                  <Col xs={12} md={12}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Nomor Telepon <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        name={`phone-${phoneIndex}`}
                        type="text"
                        placeholder="Nomor telepon"
                        value={phoneData.number}
                        onChange={e => {
                          const newFormDataList = [...formDataList];
                          newFormDataList[0].phones[phoneIndex].number =
                            e.target.value;
                          setFormDataList(newFormDataList);
                        }}
                        isInvalid={!!errors.phone}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={12} md={8}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Jenis Penggunaan <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name={`jenisPhone-${phoneIndex}`}
                        value={phoneData.usageType}
                        onChange={e => {
                          const newFormDataList = [...formDataList];
                          newFormDataList[0].phones[phoneIndex].usageType =
                            e.target.value;
                          setFormDataList(newFormDataList);
                        }}
                        isInvalid={!!errors.jenisPhone}
                      >
                        <option value="">Pilih jenis penggunaan</option>
                        <option value="Individual">Pribadi</option>
                        <option value="Corporate/Business">Bisnis</option>
                        <option value="Group">Grup</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.jenisPhone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Terhubung WhatsApp
                      </Form.Label>
                      <div className="d-flex gap-2 pt-1">
                        <Form.Check
                          type="radio"
                          name={`terhubungWA-${phoneIndex}`}
                          label="Ya"
                          checked={phoneData.isWhatsapp === true}
                          onChange={() => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[0].phones[phoneIndex].isWhatsapp =
                              true;
                            setFormDataList(newFormDataList);
                          }}
                        />
                        <Form.Check
                          type="radio"
                          name={`terhubungWA-${phoneIndex}`}
                          label="Tidak"
                          checked={phoneData.isWhatsapp === false}
                          onChange={() => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[0].phones[phoneIndex].isWhatsapp =
                              false;
                            setFormDataList(newFormDataList);
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            ))}
            <Button
              variant="outline-primary"
              className="mt-2 w-100"
              onClick={handleAddPhone}
            >
              + Tambah Telepon
            </Button>

            {/* Bagian Email */}
            <h2 className="mb-2 mt-5 fs-7 text-capitalize">Email</h2>
            {formDataList[0]?.emails.map((emailData, emailIndex) => (
              <div key={emailData.id}>
                {emailIndex > 0 && <hr className="my-4" />}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="mb-0 fs-8 text-capitalize">
                    Email {emailIndex + 1}
                  </h2>
                  {emailIndex > 0 && (
                    <Button
                      variant="link"
                      className="text-danger p-0"
                      onClick={() => handleDeleteEmail(emailIndex)}
                    >
                      Hapus Email
                    </Button>
                  )}
                </div>
                <Row className="mb-3">
                  <Col xs={12} md={12}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Alamat Email
                      </Form.Label>
                      <Form.Control
                        name={`email-${emailIndex}`}
                        type="email"
                        placeholder="Email"
                        value={emailData.email}
                        onChange={e => {
                          const newFormDataList = [...formDataList];
                          newFormDataList[0].emails[emailIndex].email =
                            e.target.value;
                          setFormDataList(newFormDataList);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={12} md={12}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Jenis Penggunaan
                      </Form.Label>
                      <Form.Select
                        name={`jenisEmail-${emailIndex}`}
                        value={emailData.usageType}
                        onChange={e => {
                          const newFormDataList = [...formDataList];
                          newFormDataList[0].emails[emailIndex].usageType =
                            e.target.value;
                          setFormDataList(newFormDataList);
                        }}
                      >
                        <option value="">Pilih Jenis Penggunaan</option>
                        <option value="personal">Pribadi</option>
                        <option value="work">Pekerjaan</option>
                        <option value="other">Lainnya</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            ))}
            <Button
              variant="outline-primary"
              className="mt-2 w-100"
              onClick={handleAddEmail}
            >
              + Tambah Email
            </Button>

            {/* Tombol aksi */}
            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button onClick={handleSave}>Simpan</Button>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default AlamatKontakPegawaiCard;
