import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const defaultBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Page 1',
    url: '#!'
  },
  {
    label: 'Page 2',
    url: '#!'
  },
  {
    label: 'Default',
    active: true
  }
];

export const ecomBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Fashion',
    url: '#!'
  },
  {
    label: 'Womens Fashion',
    url: '#!'
  },
  {
    label: 'Footwear',
    url: '#!'
  },
  {
    label: 'Hills',
    active: true
  }
];

export const daftarPelangganBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pelanggan',
    url: '#!'
  },
  {
    label: 'Daftar Pelanggan',
    active: true
  }
];

export const detailPelangganBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pelanggan',
    url: '#!'
  },
  {
    label: 'Daftar Pelanggan',
    url: '/apps/e-commerce/admin/customers'
  },
  {
    label: 'Detail Pelanggan',
    active: true
  }
];

export const formTemplatBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pelanggan',
    url: '#!'
  },
  {
    label: 'Form Templat',
    active: true
  }
];

export const detailFormTemplatBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pelanggan',
    url: '#!'
  },
  {
    label: 'Form Templat',
    url: '/apps/e-commerce/admin/customer/templat'
  },
  {
    label: 'Detail Form Templat',
    active: true
  }
];

export const daftarPegawaiBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pegawai',
    url: '#!'
  },
  {
    label: 'Daftar Pegawai',
    active: true
  }
];

export const daftarPegawaiDetailBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pegawai',
    url: '#!'
  },
  {
    label: 'Daftar Pegawai',
    url: '/pegawai/daftar-pegawai'
  },
  {
    label: 'Detail Daftar Pegawai',
    active: true
  }
];

export const daftarJabatanBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pegawai',
    url: '#!'
  },
  {
    label: 'Daftar Jabatan',
    active: true
  }
];

export const daftarJabatanDetailBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pegawai',
    url: '#!'
  },
  {
    label: 'Daftar Jabatan',
    url: '/pegawai/daftar-jabatan'
  },
  {
    label: 'Detail Daftar Jabatan',
    active: true
  }
];

export const pegawaiFormTemplatBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pegawai',
    url: '#!'
  },
  {
    label: 'Form Templat',
    active: true
  }
];

export const detailPegawaiFormTemplatBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pegawai',
    url: '#!'
  },
  {
    label: 'Form Templat',
    url: '/pegawai/templat'
  },
  {
    label: 'Detail Form Templat',
    active: true
  }
];

export const kelolaAkunBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Manajemen Akun',
    url: '#!'
  },
  {
    label: 'Kelola Akun',
    active: true
  }
];

export const detailAkunBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Manajemen Akun',
    url: '#!'
  },
  {
    label: 'Kelola Akun',
    url: '/manajemen-akun/kelola-akun/'
  },
  {
    label: 'Detail Akun',
    active: true
  }
];

export const peranKeamananBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Manajemen Akun',
    url: '#!'
  },
  {
    label: 'Peran Keamanan',
    active: true
  }
];

export const detailPeranKeamananBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Manajemen Akun',
    url: '#!'
  },
  {
    label: 'Peran Keamanan',
    url: '#!'
  },
  {
    label: 'Detail Peran Keamanan',
    active: true
  }
];

export const kontrolAksesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Manajemen Akun',
    url: '#!'
  },
  {
    label: 'Kontrol Akses',
    active: true
  }
];

export const daftarLayananBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Layanan',
    url: '#!'
  },
  {
    label: 'Daftar Layanan',
    active: true
  }
];

export const detailLayananBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Layanan',
    url: '#!'
  },
  {
    label: 'Kategori Layanan',
    url: '/layanan/kategori-layanan'
  },
  {
    label: 'Detail Kategori',
    active: true
  }
];

export const daftarKategoriLayananBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Layanan',
    url: '#!'
  },
  {
    label: 'Kategori Layanan',
    active: true
  }
];

export const detailKategoriLayananBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Layanan',
    url: '#!'
  },
  {
    label: 'Kategori Layanan',
    url: '/layanan/kategori-layanan'
  },
  {
    label: 'Detail Kategori',
    active: true
  }
];

export const daftarKebijakanBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Kebijakan',
    url: '#!'
  },
  {
    label: 'Daftar Kebijakan',
    active: true
  }
];

export const detailKebijakanBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Layanan',
    url: '#!'
  },
  {
    label: 'Daftar Kebijakan',
    url: '/layanan/kebijakan'
  },
  {
    label: 'Detail Kebijakan',
    active: true
  }
];

export const layananFormTemplatBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Layanan',
    url: '#!'
  },
  {
    label: 'Form Templat',
    active: true
  }
];

export const detailLayananFormTemplatBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Layanan',
    url: '#!'
  },
  {
    label: 'Form Templat',
    url: '/layanan/templat'
  },
  {
    label: 'Detail Form Templat',
    active: true
  }
];
