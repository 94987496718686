/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faFileExport,
  faEnvelope,
  faTrashAlt,
  faFileInvoiceDollar,
  faCalendarPlus,
  faGear,
  faClipboard,
  faSquarePlus,
  faBriefcase,
  faFileMedical,
  faFaceSmile,
  faClock,
  faTable,
  faStar
} from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import {
  useGetEmployeeByIdQuery,
  useDeleteEmployeeMutation
} from 'api/employeeApi';
import { useGetEmployeeTemplateQuery } from 'api/employeeTemplateApi';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { daftarPegawaiDetailBreadcrumbItems } from 'data/commonData';
import { showCustomAlert } from 'helpers/showCustomAlert';
import DownloadTableModal from 'components/modals/DownloadTableModal';
import EmployeeProfileCard from 'components/cards/EmployeeProfileCard';
import InformasiPribadiPegawaiCard from 'components/cards/InformasiPribadiPegawaiCard';
import AlamatKontakPegawaiCard from 'components/cards/AlamatKontakPegawaiCard';
import KontakDaruratPegawaiCard from 'components/cards/KontakDaruratPegawaiCard';
import EmployeeNotesCard from 'components/cards/EmployeeNotesCard';
import InformasiPekerjaanCard from 'components/cards/InformasiPekerjaanCard';
import InformasiRemunerasiCard from 'components/cards/InformasiRemunerasiCard';
import GajiKompensasiCard from 'components/cards/GajiKompensasiCard';
import LayananCard from 'components/cards/LayananPegawaiCard';
import LayananPegawaiCard from 'components/cards/LayananPegawaiCard';
import CheckboxDetailPegawaiModal from 'components/modals/CheckboxDetailPegawaiModal';
import noCheckedBox from 'assets/img/spot-illustrations/404-illustration.png';
import LayananPegawaiTableCard from 'components/cards/LayananPegawaiTableCard';
import JamKerjaPegawaiCard from 'components/cards/JamKerjaPegawaiCard';
import UlasanPegawaiCard from 'components/cards/UlasanPegawaiCard';
import DeleteCustomerModal from 'components/modals/DeleteCustomerModal';
import EmployeeTemplateCard from 'components/cards/EmployeeTemplateCard';

const EmployeeDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const { data: employeeData, refetch } = useGetEmployeeByIdQuery(id || '');

  const [checkboxVisibility, setCheckboxVisibility] = useState({
    showFormTemplat: true,
    showInformasiPekerjaan: true,
    showLayananJamKerja: true,
    showPelayanan: true,
    showPenilaianUlasan: true,
    showGajiKompensasi: false,
    showLayanan: false,
    showJamKerja: false,
    showLayananTable: false,
    showUlasan: false
  });

  const [checkboxChecked, setCheckboxChecked] = useState({
    showFormTemplat: true,
    showInformasiPekerjaan: true,
    showLayananJamKerja: true,
    showPelayanan: true,
    showPenilaianUlasan: true,
    showGajiKompensasi: false,
    showLayanan: false,
    showJamKerja: false,
    showLayananTable: false,
    showUlasan: false
  });

  const [showConfigModal, setShowConfigModal] = useState(false);

  const [deleteEmployee] = useDeleteEmployeeMutation();

  const { data: templateData } = useGetEmployeeTemplateQuery({
    page: 1,
    limit: 10,
    orderBy: 'createdDate',
    direction: 'DESC'
  });

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setCheckboxChecked(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const allUnchecked = Object.values(checkboxChecked).every(value => !value);

  const handleConfirmDelete = async (reason: string, notes: string) => {
    try {
      const selectedIds = [id || '']; // Karena ini di halaman detail, hanya 1 pegawai

      await deleteEmployee({
        notes: notes,
        deletionReason: reason,
        data: selectedIds
      }).unwrap();

      // Tutup modal
      setShowDeleteModal(false);

      showCustomAlert({
        icon: 'success',
        label: 'Berhasil',
        deskripsi: 'Pegawai berhasil dihapus',
        buttonType: 'ok',
        onConfirm: () => {
          // Redirect ke halaman daftar pegawai
          navigate('/pegawai/daftar-pegawai');
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showCustomAlert({
        icon: 'error',
        label: 'Gagal',
        deskripsi: error?.data?.message || 'Gagal menghapus pegawai',
        buttonType: 'ok'
      });
    }
  };

  if (!employeeData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <PageBreadcrumb items={daftarPegawaiDetailBreadcrumbItems} />
      <ToastContainer position="bottom-right" />

      <Row className="d-flex align-items-center justify-content-between g-3 mb-4">
        <Col xs="auto">
          <h2 className="mb-0">Detail Pegawai</h2>
        </Col>
        <Col xs="auto" className="d-flex gap-3 flex-wrap p-0">
          <Button
            variant="link"
            className="text-body px-0"
            onClick={() => setShowDownloadModal(true)}
          >
            <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
            Unduh Data
          </Button>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="dropdown-caret-none text-decoration-none px-1"
            >
              Tindakan Lainnya
              <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item>Atur Jadwal</Dropdown.Item>
              <Dropdown.Item>Atur Cuti</Dropdown.Item>
              <Dropdown.Item>Nonaktifkan</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            variant="outline-danger"
            onClick={() => setShowDeleteModal(true)}
          >
            <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
            Hapus Pegawai
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => setShowEmailModal(true)}
          >
            <FontAwesomeIcon icon={faEnvelope} className="me-2" />
            Kirim Email
          </Button>
          <Button variant="primary">
            <FontAwesomeIcon icon={faClipboard} className="me-2" />
            Buat Slip Gaji
          </Button>
        </Col>
      </Row>

      <Row className="g-5">
        <Col xs={12} sm={12} md={12} lg={12} xl={5}>
          <Row className="g-3 mb-3">
            <EmployeeProfileCard />
            <InformasiPribadiPegawaiCard />
            <AlamatKontakPegawaiCard />
            <KontakDaruratPegawaiCard />
            <EmployeeNotesCard />
          </Row>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={7}>
          <Row className="d-flex flex-column g-3">
            <div className="p-0 d-flex align-items-center justify-content-between">
              <div className="d-flex justify-content-between gap-3">
                {Object.entries(checkboxVisibility).map(([key, isVisible]) => {
                  if (!isVisible) return null; // Skip jika tidak visible

                  // Definisikan label dan ikon untuk setiap key
                  const menuLabels = {
                    showFormTemplat: {
                      label: 'Form Templat',
                      icon: faSquarePlus
                    },
                    showInformasiPekerjaan: {
                      label: 'Informasi Pekerjaan',
                      icon: faBriefcase
                    },
                    showLayananJamKerja: {
                      label: 'Layanan & Jam Kerja',
                      icon: faClipboard
                    },
                    showPelayanan: { label: 'Pelayanan', icon: faFileMedical },
                    showPenilaianUlasan: {
                      label: 'Penilaian & Ulasan',
                      icon: faFaceSmile
                    },
                    showGajiKompensasi: {
                      label: 'Gaji & Kompensasi',
                      icon: faFileInvoiceDollar
                    },
                    showLayanan: { label: 'Layanan', icon: faBriefcase },
                    showJamKerja: { label: 'Jam Kerja', icon: faClock },
                    showLayananTable: { label: 'Tabel Layanan', icon: faTable },
                    showUlasan: { label: 'Ulasan', icon: faStar }
                  };

                  const menu = menuLabels[key as keyof typeof menuLabels];
                  if (!menu) return null; // Skip jika key tidak valid

                  return (
                    <div
                      key={key}
                      className={`form-check border rounded-pill px-3 py-2 mt-1 ${
                        checkboxChecked[key as keyof typeof checkboxChecked]
                          ? 'border-primary bg-primary'
                          : 'border-secondary'
                      }`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setCheckboxChecked(prev => ({
                          ...prev,
                          [key]: !prev[key as keyof typeof checkboxChecked]
                        }));
                      }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input d-none"
                        id={key}
                        name={key}
                        checked={
                          checkboxChecked[key as keyof typeof checkboxChecked]
                        }
                        onChange={() => {}}
                      />
                      <label
                        className={`form-check-label mb-0 ${
                          checkboxChecked[key as keyof typeof checkboxChecked]
                            ? 'text-white'
                            : 'text-secondary'
                        }`}
                        style={{ cursor: 'pointer', userSelect: 'none' }}
                      >
                        <FontAwesomeIcon icon={menu.icon} className="me-2" />
                        {menu.label}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div>
                <Button
                  variant="primary"
                  onClick={() => setShowConfigModal(true)}
                  className="px-2 py-2"
                >
                  <FontAwesomeIcon icon={faGear} className="fs-8" />
                </Button>
              </div>
            </div>

            {allUnchecked ? (
              <div className="d-flex flex-column align-items-center">
                <img
                  src={noCheckedBox}
                  alt="No selections"
                  className="img-fluid w-50"
                />
                <h5 className="text-body mt-3 text-center">
                  Anda dapat menampilkan menu tambahan <br />
                  dengan memilih opsi menu diatas.
                </h5>
              </div>
            ) : (
              <>
                {checkboxVisibility.showFormTemplat &&
                  checkboxChecked.showFormTemplat &&
                  templateData?.data?.result
                    ?.filter(template => template.status)
                    .map(template => (
                      <EmployeeTemplateCard
                        key={template.id}
                        template={template}
                        employeeId={id || ''}
                      />
                    ))}
                {checkboxVisibility.showInformasiPekerjaan &&
                  checkboxChecked.showInformasiPekerjaan && (
                    <InformasiPekerjaanCard />
                  )}
                {checkboxVisibility.showLayananJamKerja &&
                  checkboxChecked.showLayananJamKerja && (
                    <>
                      <LayananPegawaiCard />
                      <JamKerjaPegawaiCard />
                    </>
                  )}
                {checkboxVisibility.showPelayanan &&
                  checkboxChecked.showPelayanan && <LayananPegawaiTableCard />}
                {checkboxVisibility.showPenilaianUlasan &&
                  checkboxChecked.showPenilaianUlasan && <UlasanPegawaiCard />}
              </>
            )}
          </Row>
        </Col>
      </Row>

      <CheckboxDetailPegawaiModal
        show={showConfigModal}
        onHide={() => setShowConfigModal(false)}
        checkboxes={checkboxVisibility}
        onCheckboxChange={setCheckboxVisibility}
      />

      <DeleteCustomerModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        selectedCustomers={[
          {
            name: employeeData?.data?.names?.fullName || 'Nama tidak tersedia'
          }
        ]}
        onDelete={handleConfirmDelete}
        title="Hapus Pegawai"
      />

      <DownloadTableModal
        show={showDownloadModal}
        onHide={() => setShowDownloadModal(false)}
        onDownload={() => {
          // Implementasi download
        }}
        title="Unduh Data"
      />
    </div>
  );
};

export default EmployeeDetails;
