import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useUpdatePolicyDepositMutation } from 'api/serviceApi';

interface DepositCardProps {
  data: {
    id: string;
    offlineDepositAmount: string;
    onlineDepositAmount: string;
    securityDepositAmount: string;
    cancellationBefore: number;
    onTimeCancellationPercentage: string;
    lateCancellationPercentage: string;
    offlineDepositAmountType: 'PERCENTAGE' | 'RUPIAH';
    onlineDepositAmountType: 'PERCENTAGE' | 'RUPIAH';
    securityDepositAmountType: 'PERCENTAGE' | 'RUPIAH';
    daysCancellation:
      | 'MINUTES_BEFORE_BOOKING'
      | 'HOURS_BEFORE_BOOKING'
      | 'DAYS_BEFORE_BOOKING';
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdate?: (updatedData: any) => void;
}

const DepositCard: React.FC<DepositCardProps> = ({ data, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    offlineDepositAmount: data.offlineDepositAmount,
    offlineDepositAmountType: data.offlineDepositAmountType,
    onlineDepositAmount: data.onlineDepositAmount,
    onlineDepositAmountType: data.onlineDepositAmountType,
    securityDepositAmount: data.securityDepositAmount,
    securityDepositAmountType: data.securityDepositAmountType,
    cancellationBefore: data.cancellationBefore,
    onTimeCancellationPercentage: data.onTimeCancellationPercentage,
    lateCancellationPercentage: data.lateCancellationPercentage,
    daysCancellation: data.daysCancellation as
      | 'MINUTES_BEFORE_BOOKING'
      | 'HOURS_BEFORE_BOOKING'
      | 'DAYS_BEFORE_BOOKING'
  });

  const [updateDeposit] = useUpdatePolicyDepositMutation();

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const hasChanges = () => {
    const originalData = {
      offlineDepositAmount: data.offlineDepositAmount,
      offlineDepositAmountType: data.offlineDepositAmountType,
      onlineDepositAmount: data.onlineDepositAmount,
      onlineDepositAmountType: data.onlineDepositAmountType,
      securityDepositAmount: data.securityDepositAmount,
      securityDepositAmountType: data.securityDepositAmountType,
      cancellationBefore: data.cancellationBefore,
      onTimeCancellationPercentage: data.onTimeCancellationPercentage,
      lateCancellationPercentage: data.lateCancellationPercentage,
      daysCancellation: data.daysCancellation as
        | 'MINUTES_BEFORE_BOOKING'
        | 'HOURS_BEFORE_BOOKING'
        | 'DAYS_BEFORE_BOOKING'
    };

    return JSON.stringify(formData) !== JSON.stringify(originalData);
  };

  const handleCancel = () => {
    if (!hasChanges()) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Perubahan data tidak akan disimpan',
      deskripsi: 'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        setFormData({
          offlineDepositAmount: data.offlineDepositAmount,
          offlineDepositAmountType: data.offlineDepositAmountType,
          onlineDepositAmount: data.onlineDepositAmount,
          onlineDepositAmountType: data.onlineDepositAmountType,
          securityDepositAmount: data.securityDepositAmount,
          securityDepositAmountType: data.securityDepositAmountType,
          cancellationBefore: data.cancellationBefore,
          onTimeCancellationPercentage: data.onTimeCancellationPercentage,
          lateCancellationPercentage: data.lateCancellationPercentage,
          daysCancellation: data.daysCancellation as
            | 'MINUTES_BEFORE_BOOKING'
            | 'HOURS_BEFORE_BOOKING'
            | 'DAYS_BEFORE_BOOKING'
        });
        setIsEditing(false);
      }
    });
  };

  const handleSave = () => {
    showCustomAlert({
      icon: 'question',
      label: 'Perubahan data akan disimpan',
      deskripsi:
        'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const requestData = {
            offlineDepositAmount: Number(formData.offlineDepositAmount),
            onlineDepositAmount: Number(formData.onlineDepositAmount),
            securityDepositAmount: Number(formData.securityDepositAmount),
            cancellationBefore: Number(formData.cancellationBefore),
            onTimeCancellationPercentage: Number(
              formData.onTimeCancellationPercentage
            ),
            lateCancellationPercentage: Number(
              formData.lateCancellationPercentage
            ),
            offlineDepositAmountType: formData.offlineDepositAmountType,
            onlineDepositAmountType: formData.onlineDepositAmountType,
            securityDepositAmountType: formData.securityDepositAmountType,
            daysCancellation: formData.daysCancellation
          } as const;

          await updateDeposit({ id: data.id, data: requestData }).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Data berhasil diperbarui',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
              if (onUpdate) onUpdate(requestData);
            }
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Error',
            deskripsi: 'Gagal menyimpan pengaturan deposit',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  if (isEditing) {
    return (
      <Card>
        <Card.Body>
          <Row className="mb-4">
            <Col xs={12} className="d-flex align-items-center">
              <h3>Kebijakan Deposit</h3>
            </Col>
          </Row>

          <div className="d-flex flex-column">
            <h4 className="mb-3">Kebijakan Deposit</h4>

            {/* Jumlah Deposit Offline */}
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                Jumlah Deposit pada Pelanggan Offline saat Melakukan Booking
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="offlineDepositAmount"
                  value={formData.offlineDepositAmount}
                  onChange={handleChange}
                  placeholder="0.00"
                  min="0"
                  step="0.01"
                  style={{ width: '70%' }}
                />
                <Form.Select
                  name="offlineDepositAmountType"
                  value={formData.offlineDepositAmountType}
                  onChange={handleChange}
                  style={{ width: '30%' }}
                >
                  <option value="PERCENTAGE">Persen Harga Jual (%)</option>
                  <option value="RUPIAH">Rupiah (Rp)</option>
                </Form.Select>
              </InputGroup>
            </Form.Group>

            {/* Jumlah Deposit Online */}
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                Jumlah Deposit pada Pelanggan Online saat Melakukan Booking
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="onlineDepositAmount"
                  value={formData.onlineDepositAmount}
                  onChange={handleChange}
                  placeholder="0.00"
                  min="0"
                  step="0.01"
                  style={{ width: '70%' }}
                />
                <Form.Select
                  name="onlineDepositAmountType"
                  value={formData.onlineDepositAmountType}
                  onChange={handleChange}
                  style={{ width: '30%' }}
                >
                  <option value="PERCENTAGE">Persen Harga Jual (%)</option>
                  <option value="RUPIAH">Rupiah (Rp)</option>
                </Form.Select>
              </InputGroup>
            </Form.Group>

            {/* Jumlah Deposit Keamanan */}
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                Jumlah Deposit Keamanan yang Harus Dibayar saat Melakukan
                Booking
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="securityDepositAmount"
                  value={formData.securityDepositAmount}
                  onChange={handleChange}
                  placeholder="0.00"
                  min="0"
                  step="0.01"
                  style={{ width: '70%' }}
                />
                <Form.Select
                  name="securityDepositAmountType"
                  value={formData.securityDepositAmountType}
                  onChange={handleChange}
                  style={{ width: '30%' }}
                >
                  <option value="PERCENTAGE">Persen Harga Jual (%)</option>
                  <option value="RUPIAH">Rupiah (Rp)</option>
                </Form.Select>
              </InputGroup>
            </Form.Group>

            <h4 className="mb-3 mt-4">Kebijakan Pengembalian Deposit</h4>

            {/* Batas Waktu Pengajuan */}
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                Pelanggan Harus Mengisi Data Ajuan Pembatalan Jadwal Sebelum
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="cancellationBefore"
                  value={formData.cancellationBefore}
                  onChange={handleChange}
                  placeholder="0"
                  min="0"
                  style={{ width: '70%' }}
                />
                <Form.Select
                  name="daysCancellation"
                  value={formData.daysCancellation}
                  onChange={handleChange}
                  style={{ width: '30%' }}
                >
                  <option value="MINUTES_BEFORE_BOOKING">
                    Menit Sebelum Waktu Booking
                  </option>
                  <option value="HOURS_BEFORE_BOOKING">
                    Jam Sebelum Waktu Booking
                  </option>
                  <option value="DAYS_BEFORE_BOOKING">
                    Hari Sebelum Waktu Booking
                  </option>
                </Form.Select>
              </InputGroup>
            </Form.Group>

            {/* Persentase Deposit yang Ditahan bila Dibatalkan Tepat Waktu */}
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                Persentase Deposit yang Ditahan bila Dibatalkan Tepat Waktu
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="onTimeCancellationPercentage"
                  value={formData.onTimeCancellationPercentage}
                  onChange={handleChange}
                  placeholder="0.00"
                  min="0"
                  step="0.01"
                  style={{ width: '70%' }}
                />
                <Form.Select style={{ width: '30%' }} disabled>
                  <option>Persen Jumlah Deposit (%)</option>
                </Form.Select>
              </InputGroup>
            </Form.Group>

            {/* Persentase Deposit yang Ditahan bila Dibatalkan Terlambat */}
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                Persentase Deposit yang Ditahan bila Dibatalkan Terlambat
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="lateCancellationPercentage"
                  value={formData.lateCancellationPercentage}
                  onChange={handleChange}
                  placeholder="100"
                  min="0"
                  step="0.01"
                  style={{ width: '70%' }}
                />
                <Form.Select style={{ width: '30%' }} disabled>
                  <option>Persen Jumlah Deposit (%)</option>
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </div>

          {/* Tombol Batalkan dan Simpan */}
          <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
            <Button
              variant="link"
              style={{ color: 'red' }}
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button onClick={handleSave}>Simpan</Button>
          </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title as="h3" className="mb-4">
          Kebijakan Deposit{' '}
          <FontAwesomeIcon
            icon={faPencilAlt}
            className="fs-8 ms-2"
            style={{ cursor: 'pointer' }}
            onClick={() => setIsEditing(true)}
          />
        </Card.Title>
        <Row>
          <Col xs={12} md={12} sm={12} xl={6}>
            <Card.Title as="h4" className="mb-3">
              Kebijakan Deposit
            </Card.Title>
            <div className="mb-3">
              <span className="text-muted d-block fw-bold">
                Jumlah Deposit pada Pelanggan Offline saat Melakukan Booking
              </span>
              <span>
                {data.offlineDepositAmountType === 'PERCENTAGE'
                  ? `${data.offlineDepositAmount}% dari harga jual`
                  : `Rp ${data.offlineDepositAmount},-`}
              </span>
            </div>

            <div className="mb-3">
              <span className="text-muted d-block fw-bold">
                Jumlah Deposit pada Pelanggan Online saat Melakukan Booking
              </span>
              <span>
                {data.onlineDepositAmountType === 'PERCENTAGE'
                  ? `${data.onlineDepositAmount}% dari harga jual`
                  : `Rp ${data.onlineDepositAmount},-`}
              </span>
            </div>
            <div className="mb-3">
              <span className="text-muted d-block fw-bold">
                Jumlah Deposit Keamanan yang Harus Dibayar saat Melakukan
                Booking
              </span>
              <span>
                {data.securityDepositAmountType === 'PERCENTAGE'
                  ? `${data.securityDepositAmount}% dari harga jual`
                  : `Rp ${data.securityDepositAmount},-`}
              </span>
            </div>
          </Col>
          <Col xs={12} md={12} sm={12} xl={6}>
            <Card.Title as="h4" className="mb-3">
              Kebijakan Pengembalian Deposit
            </Card.Title>
            <div className="mb-3">
              <span className="text-muted d-block fw-bold">
                Pelanggan Harus Mengisi Data Ajuan Pembatalan Jadwal Sebelum
              </span>
              <span>
                {data.daysCancellation.startsWith('Menit')
                  ? `${data.cancellationBefore} menit sebelum waktu booking`
                  : data.daysCancellation.startsWith('Jam')
                  ? `${data.cancellationBefore} jam sebelum waktu booking`
                  : `${data.cancellationBefore} hari sebelum waktu booking`}
              </span>
            </div>
            <div className="mb-3">
              <span className="text-muted d-block fw-bold">
                Persentase Deposit yang Ditahan bila Dibatalkan Tepat Waktu
              </span>
              <span>
                {data.onTimeCancellationPercentage}% dari jumlah deposit
              </span>
            </div>
            <div className="mb-3">
              <span className="text-muted d-block fw-bold">
                Persentase Deposit yang Ditahan bila Dibatalkan Terlambat
              </span>
              <span>
                {data.lateCancellationPercentage}% dari jumlah deposit
              </span>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DepositCard;
