import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Badge from 'components/base/Badge';
import { Link } from 'react-router-dom';

import { EmployeeData, EmployeeResponse } from 'api/response/EmployeeResponse';

// Definisikan tipe data Pegawai

// Data dummy pegawai
// export const dummyPegawaiData: PegawaiResponse[] = [
//   {
//     id: 1,
//     nip: '001',
//     nama: 'Ilham Dumydumydummy',
//     email: 'Ilhamabaroblox@gmail.com',
//     telepon: '081234567890',
//     jenisKelamin: 'L',
//     jabatan: 'Dokter Gigi',
//     lokasiPenempatan: 'Klinik Utama',
//     tanggalBergabung: '2022-01-15',
//     status: 'aktif',
//     warna: '#FF6B6B' // Warna merah muda
//   },
//   {
//     id: 2,
//     nip: '002',
//     nama: 'Rayhan Dummydummy',
//     email: 'rayhanpengendapatmakanan@gmail.com',
//     telepon: '087654321098',
//     jenisKelamin: 'P',
//     jabatan: 'Perawat',
//     lokasiPenempatan: 'Klinik Cabang',
//     tanggalBergabung: '2022-03-20',
//     status: 'aktif',
//     warna: '#4ECDC4' // Warna tosca
//   }
// ];

// Kolom tabel pegawai
export const pegawaiTableColumns: ColumnDef<EmployeeResponse>[] = [
  {
    accessorKey: 'color',
    header: 'Warna',
    cell: ({ row: { original } }) => {
      const colorValue = original.color;
      return (
        <div
          style={{
            width: '80px',
            height: '20px',
            backgroundColor: colorValue || '#CCCCCC',
            borderRadius: '4px'
          }}
        />
      );
    },
    meta: {
      headerProps: { style: { width: '5%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 align-middle' }
    }
  },
  {
    accessorKey: 'names.fullName',
    header: 'Nama Pegawai',
    cell: ({ row: { original } }) => {
      const fullName =
        original.names?.fullName || original.fullName || 'Nama tidak tersedia';
      return (
        <Link
          to={`/pegawai/detail-pegawai/${original.id}`}
          className="d-flex align-items-center gap-3 text-body-emphasis"
        >
          <p className="mb-0 fw-bold">{fullName}</p>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'emails',
    header: 'Email',
    cell: ({ row: { original } }) => {
      const email = original.emails?.[0]?.emailAddress || 'Tidak Ada';
      return (
        <Link
          to={`mailto:${email}`}
          className="fw-semibold text-decoration-none text-end"
        >
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '20%' },
        className: 'px-3 text-end'
      },
      cellProps: { className: 'px-3 py-2 text-end' }
    }
  },
  {
    accessorKey: 'phoneNumbers',
    header: 'No. Telepon',
    cell: ({ row: { original } }) => {
      const phoneNumber =
        original.phoneNumbers?.[0]?.phoneNumber || 'Tidak Ada';
      return <span className="fw-semibold">{phoneNumber}</span>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  },
  {
    accessorKey: 'jobTitle',
    header: 'Jabatan',
    cell: ({ row: { original } }) => {
      const jobTitle = original.jobTitle;
      if (!jobTitle) return <span>Tidak Ada</span>;

      return (
        <Badge
          style={{
            borderRadius: '20px',
            backgroundColor: jobTitle.color || '#CCCCCC',
            color: isColorLight(jobTitle.color) ? '#000' : '#fff',
            padding: '6px 14px'
          }}
          variant="phoenix"
          className="fs-10 text-center fw-bold"
        >
          {jobTitle.name}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  },
  {
    accessorKey: 'branch',
    header: 'Lokasi Penempatan',
    cell: ({ row: { original } }) => {
      const branch = original.branch || 'Tidak Ada';
      return <span>{branch}</span>;
    },
    meta: {
      headerProps: {
        style: { width: '20%' },
        className: 'px-3 text-end'
      },
      cellProps: { className: 'px-3 py-2 white-space-nowrap text-end' }
    }
  },
  {
    accessorKey: 'joinDate',
    header: 'Tanggal Bergabung',
    cell: ({ row: { original } }) => {
      return new Date(original.joinDate).toLocaleDateString('id-ID', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
    },
    meta: {
      headerProps: {
        style: { width: '20%' },
        className: 'px-3 text-end'
      },
      cellProps: { className: 'px-3 py-2 text-end' }
    }
  },
  {
    accessorKey: 'isActive',
    header: 'Status',
    cell: ({ row: { original } }) => {
      return (
        <span
          className={`badge ${original.isActive ? 'bg-success' : 'bg-danger'}`}
        >
          {original.isActive ? 'Aktif' : 'Non-Aktif'}
        </span>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 text-end' }
    }
  }
];

// Fungsi helper untuk menentukan apakah warna termasuk terang atau gelap
function isColorLight(color: string): boolean {
  // Jika tidak ada warna, anggap gelap
  if (!color) return false;

  // Konversi hex ke RGB
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  // Hitung kecerahan menggunakan formula YIQ
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Nilai YIQ > 128 dianggap warna terang
  return yiq > 128;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface PegawaiTableProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
  employeeData?: EmployeeData;
}

const PegawaiTable = ({
  onPageChange,
  currentPage,
  totalPages,
  employeeData
}: PegawaiTableProps) => {
  // console.log('Transformed Data:', employeeData);
  // console.log('Columns:', pegawaiTableColumns);
  return (
    <div>
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
        }}
      />
      <AdvanceTableFooter
        pagination
        className="mt-3"
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalRecords={employeeData?.totalRecords || 0}
      />
    </div>
  );
};

export default PegawaiTable;
