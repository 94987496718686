import { ChangeEvent, useEffect, useState, useMemo } from 'react';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import {
  useGetCustomerByIdQuery,
  useUpdateEmailMutation,
  useUpdateLocationMutation,
  useCreateLocationMutation
} from 'api/customerApi';
import { useParams } from 'react-router-dom';
import {
  useGetProvincesQuery,
  useGetRegenciesQuery,
  useGetDistrictsQuery,
  useGetVillagesQuery
} from 'api/locationApi';
import { showCustomAlert } from 'helpers/showCustomAlert';
import ForbiddenWrapper from 'components/modules/auth/ForbiddenWrapper';

// Buat custom hook untuk mengelola queries lokasi
const useLocationQueries = (
  provinceId: string,
  regencyId: string,
  districtId: string
) => {
  const { data: regenciesResponse, isLoading: isLoadingRegencies } =
    useGetRegenciesQuery(provinceId || '', { skip: !provinceId });

  const { data: districtsResponse, isLoading: isLoadingDistricts } =
    useGetDistrictsQuery(regencyId || '', { skip: !regencyId });

  const { data: villagesResponse, isLoading: isLoadingVillages } =
    useGetVillagesQuery(districtId || '', { skip: !districtId });

  return {
    regencies: regenciesResponse?.data || [],
    districts: districtsResponse?.data || [],
    villages: villagesResponse?.data || [],
    isLoadingRegencies,
    isLoadingDistricts,
    isLoadingVillages
  };
};

// Modifikasi interface untuk phone dan email
interface PhoneContact {
  id: string;
  number: string;
  usageType: string;
  isWhatsapp: boolean;
}

interface EmailContact {
  id: string;
  email: string;
  usageType: string;
}

const AlamatKontakCard = () => {
  const { id } = useParams<{ id: string }>();
  const { data: response, isLoading, error } = useGetCustomerByIdQuery(id!);
  const [updateEmail, { isLoading: isSavingEmail }] = useUpdateEmailMutation();
  const [updateLocation, { isLoading: isSavingLocation }] =
    useUpdateLocationMutation(); // Mutation untuk update lokasi
  const [createLocation] = useCreateLocationMutation();
  const { refetch } = useGetCustomerByIdQuery(id!);

  // Modifikasi state untuk menampung multiple alamat
  const [formDataList, setFormDataList] = useState<
    Array<{
      id: string;
      alamat: string;
      kota: string;
      kotaName: string;
      provinsi: string;
      provinsiName: string;
      negara: string;
      kodePos: string;
      jenisAlamat: string;
      phone: string;
      jenisPhone: string;
      terhubungWA: boolean;
      email: string;
      jenisEmail: string;
      district: string;
      districtName: string;
      village: string;
      villageName: string;
      phones: PhoneContact[];
      emails: EmailContact[];
    }>
  >([]);

  // State untuk mode editing dan penyimpanan ID
  const [isEditing, setIsEditing] = useState(false);
  const [emailId, setEmailId] = useState<string | null>(null); // Menyimpan id email
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addressId, setAddressId] = useState<string | null>(null); // Menyimpan id alamat

  // Tambahkan state untuk error
  const [errors, setErrors] = useState({
    alamat: '',
    kota: '',
    provinsi: '',
    negara: '',
    jenisAlamat: '',
    phone: '',
    jenisPhone: ''
  });

  // Ambil data provinsi
  const { data: provincesResponse, isLoading: isLoadingProvinces } =
    useGetProvincesQuery();
  const provinces = provincesResponse?.data || [];

  // Buat hooks untuk maksimal 10 alamat
  const location0 = useLocationQueries(
    formDataList[0]?.provinsi || '',
    formDataList[0]?.kota || '',
    formDataList[0]?.district || ''
  );
  const location1 = useLocationQueries(
    formDataList[1]?.provinsi || '',
    formDataList[1]?.kota || '',
    formDataList[1]?.district || ''
  );
  const location2 = useLocationQueries(
    formDataList[2]?.provinsi || '',
    formDataList[2]?.kota || '',
    formDataList[2]?.district || ''
  );
  const location3 = useLocationQueries(
    formDataList[3]?.provinsi || '',
    formDataList[3]?.kota || '',
    formDataList[3]?.district || ''
  );
  const location4 = useLocationQueries(
    formDataList[4]?.provinsi || '',
    formDataList[4]?.kota || '',
    formDataList[4]?.district || ''
  );
  const location5 = useLocationQueries(
    formDataList[5]?.provinsi || '',
    formDataList[5]?.kota || '',
    formDataList[5]?.district || ''
  );
  const location6 = useLocationQueries(
    formDataList[6]?.provinsi || '',
    formDataList[6]?.kota || '',
    formDataList[6]?.district || ''
  );
  const location7 = useLocationQueries(
    formDataList[7]?.provinsi || '',
    formDataList[7]?.kota || '',
    formDataList[7]?.district || ''
  );
  const location8 = useLocationQueries(
    formDataList[8]?.provinsi || '',
    formDataList[8]?.kota || '',
    formDataList[8]?.district || ''
  );
  const location9 = useLocationQueries(
    formDataList[9]?.provinsi || '',
    formDataList[9]?.kota || '',
    formDataList[9]?.district || ''
  );

  // Gabungkan hasil queries ke dalam array
  const locationQueries = useMemo(() => {
    return [
      location0,
      location1,
      location2,
      location3,
      location4,
      location5,
      location6,
      location7,
      location8,
      location9
    ].slice(0, formDataList.length);
  }, [
    location0,
    location1,
    location2,
    location3,
    location4,
    location5,
    location6,
    location7,
    location8,
    location9,
    formDataList.length
  ]);

  // Modifikasi useEffect untuk handle multiple alamat
  useEffect(() => {
    if (response?.data?.addressAndContact) {
      const { addresses, emails, contacts } = response.data.addressAndContact;

      const mappedAddresses = addresses.map(address => ({
        id: address.id,
        alamat: address.street || '-',
        provinsi: address.province?.id || '',
        provinsiName: address.province?.name || '-',
        kota: address.regency?.id || '',
        kotaName: address.regency?.name || '-',
        negara: address.country || '-',
        kodePos: address.postalCode || '-',
        jenisAlamat: address.addressType || '-',
        phone: contacts[0]?.number || '-',
        jenisPhone: contacts[0]?.usageType || '-',
        terhubungWA: contacts[0]?.isWhatsapp || false,
        email: emails[0]?.email || '-',
        jenisEmail: emails[0]?.usageType || '-',
        district: address.district?.id || '',
        districtName: address.district?.name || '-',
        village: address.village?.id || '',
        villageName: address.village?.value || '-',
        phones: contacts.map(contact => ({
          id: contact.id,
          number: contact.number || '-',
          usageType: contact.usageType || '-',
          isWhatsapp: contact.isWhatsapp || false
        })),
        emails: emails.map(email => ({
          id: email.id,
          email: email.email || '-',
          usageType: email.usageType || '-'
        }))
      }));

      setFormDataList(mappedAddresses);
      setEmailId(emails[0]?.id || null);
    }
  }, [response]);

  // Tambahkan state untuk menyimpan data asli
  const [originalFormData, setOriginalFormData] = useState<typeof formDataList>(
    []
  );

  // Update handleEditClick untuk menyimpan deep copy dari data asli
  const handleEditClick = () => {
    // Gunakan JSON parse/stringify untuk deep copy
    setOriginalFormData(JSON.parse(JSON.stringify(formDataList)));
    setIsEditing(true);
  };

  // Update handleCancel untuk perbandingan yang lebih akurat
  const handleCancel = () => {
    // Bandingkan data saat ini dengan data asli
    const currentData = JSON.stringify(formDataList);
    const originalData = JSON.stringify(originalFormData);

    const hasChanges = currentData !== originalData;

    if (!hasChanges) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Batalkan Perubahan',
      deskripsi:
        'Anda yakin ingin membatalkan perubahan? Semua perubahan yang belum disimpan akan hilang.',
      buttonType: 'yes-no',
      onConfirm: () => {
        // Gunakan JSON parse/stringify untuk deep copy saat mengembalikan data
        setFormDataList(JSON.parse(JSON.stringify(originalFormData)));
        setIsEditing(false);
      }
    });
  };

  // Cek apakah data sudah ada, jika tidak tampilkan pesan error
  if (isLoading) return <div>Loading...</div>;
  if (error || !response?.data?.addressAndContact)
    return <div>No customer data found</div>;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === 'provinsi') {
      setFormDataList(prevData =>
        prevData.map(formData => ({
          ...formData,
          [name]: value,
          provinsiName: provinces?.find(p => p.id === value)?.name || '',
          kota: '',
          kotaName: ''
        }))
      );
    } else {
      setFormDataList(prevData =>
        prevData.map(formData => ({
          ...formData,
          [name]: value
        }))
      );
    }
  };

  const handleSave = async () => {
    // Validasi seperti sebelumnya
    const newErrors = {
      alamat: '',
      kota: '',
      provinsi: '',
      negara: '',
      jenisAlamat: '',
      phone: '',
      jenisPhone: ''
    };

    // Validasi field required untuk semua alamat
    const hasErrors = formDataList.some((formData, index) => {
      if (!formData.alamat.trim())
        newErrors.alamat = `Alamat ${index + 1} harus diisi`;
      if (!formData.kota)
        newErrors.kota = `Kota pada alamat ${index + 1} harus dipilih`;
      if (!formData.provinsi)
        newErrors.provinsi = `Provinsi pada alamat ${index + 1} harus dipilih`;
      if (!formData.negara)
        newErrors.negara = `Negara pada alamat ${index + 1} harus dipilih`;
      if (!formData.jenisAlamat)
        newErrors.jenisAlamat = `Jenis alamat ${index + 1} harus dipilih`;
      return Object.values(newErrors).some(error => error !== '');
    });

    // Validasi untuk phone dan email (tetap menggunakan data pertama)
    if (!formDataList[0]?.phone.trim())
      newErrors.phone = 'Nomor telepon harus diisi';
    if (!formDataList[0]?.jenisPhone)
      newErrors.jenisPhone = 'Jenis penggunaan harus dipilih';

    setErrors(newErrors);

    if (hasErrors || Object.values(newErrors).some(error => error !== '')) {
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Simpan Perubahan',
      deskripsi:
        'Anda yakin ingin menyimpan perubahan data alamat dan kontak ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          // Update email
          if (!emailId) {
            showCustomAlert({
              icon: 'error',
              label: 'Gagal',
              deskripsi: 'Email ID tidak ditemukan',
              buttonType: 'ok'
            });
            return;
          }

          const emailData = {
            id: emailId,
            email: formDataList[0].email,
            usageType: formDataList[0].jenisEmail
          };

          await updateEmail({ id: emailId, data: emailData }).unwrap();

          // Update alamat dengan format yang benar
          const locationData = {
            street: formDataList[0].alamat,
            province: parseInt(formDataList[0].provinsi),
            regency: parseInt(formDataList[0].kota),
            district: parseInt(formDataList[0].district),
            village: parseInt(formDataList[0].village),
            country: formDataList[0].negara,
            postalCode: formDataList[0].kodePos,
            addressType: formDataList[0].jenisAlamat
          };

          // Jika ini alamat baru
          if (formDataList[0].id.startsWith('new-')) {
            await createLocation({
              customerId: id!,
              data: locationData
            }).unwrap();

            // Refresh data
            await refetch();
          } else {
            await updateLocation({
              id: formDataList[0].id,
              data: locationData
            }).unwrap();
          }

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data alamat dan kontak berhasil diperbarui',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: error?.data?.message || 'Terjadi kesalahan pada server.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  // Update fungsi handleDeleteAddress
  const handleDeleteAddress = (index: number) => {
    const newFormDataList = formDataList.filter((_, i) => i !== index);
    setFormDataList(newFormDataList);
  };

  // Update fungsi handleDeletePhone
  const handleDeletePhone = (phoneIndex: number) => {
    const newFormDataList = [...formDataList];
    newFormDataList[0].phones = newFormDataList[0].phones.filter(
      (_, index) => index !== phoneIndex
    );
    setFormDataList(newFormDataList);
  };

  // Update fungsi handleDeleteEmail
  const handleDeleteEmail = (emailIndex: number) => {
    const newFormDataList = [...formDataList];
    newFormDataList[0].emails = newFormDataList[0].emails.filter(
      (_, index) => index !== emailIndex
    );
    setFormDataList(newFormDataList);
  };

  // Tambah fungsi untuk handle phone dan email
  const handleAddPhone = () => {
    const newFormDataList = [...formDataList];
    newFormDataList[0].phones.push({
      id: `new-phone-${Date.now()}`,
      number: '',
      usageType: '',
      isWhatsapp: false
    });
    setFormDataList(newFormDataList);
  };

  const handleAddEmail = () => {
    const newFormDataList = [...formDataList];
    newFormDataList[0].emails.push({
      id: `new-email-${Date.now()}`,
      email: '',
      usageType: ''
    });
    setFormDataList(newFormDataList);
  };

  return (
    <Card className="w-100">
      <Card.Body>
        {/* Judul dan Tombol Edit */}
        <Row className="mb-4">
          <Col xs={12} className="d-flex align-items-center">
            <h3>Alamat & Kontak</h3>
            <ForbiddenWrapper privilege="CUSTOMER_DATA_WRITE" isBlank={true}>
              {!isEditing && (
                <Button variant="ghost" size="sm" onClick={handleEditClick}>
                  <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
                </Button>
              )}
            </ForbiddenWrapper>
          </Col>
        </Row>

        {/* Mode tampilan (display) */}
        {!isEditing && (
          <>
            <div className="mb-2">
              {formDataList.map((formData, index) => (
                <div key={formData.id} className="mb-4">
                  <Row className="mb-2">
                    <Col xs={12} md={12} className="d-flex flex-column pe-3">
                      <span className="fw-bold text-muted">
                        Alamat {index + 1}
                      </span>
                      <span className="fw-normal">
                        {formData.alamat || '-'}
                        <br />
                        Kecamatan {formData.districtName || '-'}
                        <br />
                        {formData.kotaName || '-'}
                        <br />
                        {formData.provinsiName || '-'}
                        <br />
                        {formData.negara || '-'}
                        <br />
                        {formData.kodePos || '-'}
                      </span>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>

            <hr className="my-3 border border-dashed" />

            <Row className="mb-2">
              <Col xs={12} md={12}>
                {formDataList[0]?.emails.map((emailData, index) => (
                  <div key={emailData.id} className="mb-2">
                    <span className="fw-bold text-muted">
                      Email {index + 1}
                    </span>
                    <span className="fw-normal d-block">
                      {emailData.email ? (
                        <a href={`mailto:${emailData.email}`}>
                          {emailData.email}
                        </a>
                      ) : (
                        '-'
                      )}
                    </span>
                  </div>
                ))}
              </Col>
            </Row>

            <hr className="my-3 border border-dashed" />

            <Row className="mb-2">
              <Col xs={12} md={12}>
                {formDataList[0]?.phones.map((phoneData, phoneIndex) => (
                  <div key={phoneData.id} className="mb-2">
                    <span className="fw-bold text-muted">
                      Nomor Telepon {phoneIndex + 1}
                    </span>
                    <span className="fw-normal d-block">
                      {phoneData.number ? (
                        <a
                          href={`https://wa.me/${phoneData.number}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {phoneData.number}
                        </a>
                      ) : (
                        '-'
                      )}
                    </span>
                  </div>
                ))}
              </Col>
            </Row>
          </>
        )}

        {/* Mode edit */}

        {isEditing && (
          <>
            {formDataList.map((formData, index) => {
              const queries = locationQueries[index] || {
                regencies: { data: [] },
                districts: { data: [] },
                villages: { data: [] },
                isLoadingRegencies: false,
                isLoadingDistricts: false,
                isLoadingVillages: false
              };

              const regencies = queries.regencies || [];
              const districts = queries.districts || [];
              const villages = queries.villages || [];

              return (
                <div key={formData.id}>
                  {index > 0 && <hr className="my-4 border-dashed" />}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h2 className="mb-0 fs-7 text-capitalize">
                      Alamat {index + 1}
                    </h2>
                    <ForbiddenWrapper
                      privilege="CUSTOMER_DATA_FULL"
                      isBlank={true}
                    >
                      {index > 0 && (
                        <Button
                          variant="link"
                          className="text-danger p-0"
                          onClick={() => handleDeleteAddress(index)}
                        >
                          Hapus Alamat
                        </Button>
                      )}
                    </ForbiddenWrapper>
                  </div>

                  <Row className="mb-3">
                    <Col xs={12} md={12}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Alamat <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          name={`alamat-${index}`}
                          type="text"
                          placeholder="Alamat"
                          value={formData.alamat}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              alamat: e.target.value
                            };
                            setFormDataList(newFormDataList);
                          }}
                          isInvalid={!!errors.alamat}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.alamat}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Provinsi <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name={`provinsi-${index}`}
                          value={formData.provinsi}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              provinsi: e.target.value,
                              provinsiName:
                                provinces?.find(p => p.id === e.target.value)
                                  ?.name || '',
                              kota: '',
                              kotaName: '',
                              district: '',
                              districtName: '',
                              village: '',
                              villageName: ''
                            };
                            setFormDataList(newFormDataList);
                          }}
                          isInvalid={!!errors.provinsi}
                          disabled={isLoadingProvinces}
                        >
                          <option value="">Pilih Provinsi</option>
                          {Array.isArray(provinces) &&
                            provinces.map(province => (
                              <option key={province.id} value={province.id}>
                                {province.name}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.provinsi}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Kota <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name={`kota-${index}`}
                          value={formData.kota}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              kota: e.target.value,
                              kotaName:
                                regencies?.find(
                                  (r: { id: string; name: string }) =>
                                    r.id === e.target.value
                                )?.name || '',
                              district: '',
                              districtName: '',
                              village: '',
                              villageName: ''
                            };
                            setFormDataList(newFormDataList);
                          }}
                          isInvalid={!!errors.kota}
                          disabled={
                            !formData.provinsi || queries.isLoadingRegencies
                          }
                        >
                          <option value="">Pilih Kota</option>
                          {Array.isArray(regencies) &&
                            regencies.map(regency => (
                              <option key={regency.id} value={regency.id}>
                                {regency.name}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.kota}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Kecamatan <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name={`district-${index}`}
                          value={formData.district}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              district: e.target.value,
                              districtName:
                                districts?.find(
                                  (d: { id: string; name: string }) =>
                                    d.id === e.target.value
                                )?.name || '',
                              village: '',
                              villageName: ''
                            };
                            setFormDataList(newFormDataList);
                          }}
                          disabled={
                            !formData.kota || queries.isLoadingDistricts
                          }
                        >
                          <option value="">Pilih Kecamatan</option>
                          {Array.isArray(districts) &&
                            districts.map(district => (
                              <option key={district.id} value={district.id}>
                                {district.name}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Desa/Kelurahan <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name={`village-${index}`}
                          value={formData.village}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              village: e.target.value,
                              villageName:
                                villages?.find(
                                  (v: { id: string; name: string }) =>
                                    v.id === e.target.value
                                )?.name || ''
                            };
                            setFormDataList(newFormDataList);
                          }}
                          disabled={
                            !formData.district || queries.isLoadingVillages
                          }
                        >
                          <option value="">Pilih Desa/Kelurahan</option>
                          {Array.isArray(villages) &&
                            villages.map(village => (
                              <option key={village.id} value={village.id}>
                                {village.name}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Negara <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name={`negara-${index}`}
                          value={formData.negara}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              negara: e.target.value
                            };
                            setFormDataList(newFormDataList);
                          }}
                          isInvalid={!!errors.negara}
                        >
                          <option value="">Pilih Negara</option>
                          <option value="indonesia">Indonesia</option>
                          <option>Malaysia</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.negara}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Kode Pos
                        </Form.Label>
                        <Form.Control
                          name={`kodePos-${index}`}
                          type="text"
                          placeholder="Kode Pos"
                          value={formData.kodePos}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              kodePos: e.target.value
                            };
                            setFormDataList(newFormDataList);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={12}>
                      <Form.Group>
                        <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                          Jenis Alamat <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name={`jenisAlamat-${index}`}
                          value={formData.jenisAlamat}
                          onChange={e => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index] = {
                              ...newFormDataList[index],
                              jenisAlamat: e.target.value
                            };
                            setFormDataList(newFormDataList);
                          }}
                          isInvalid={!!errors.jenisAlamat}
                        >
                          <option value="">Pilih Jenis Alamat</option>
                          <option>Rumah</option>
                          <option>Kos</option>
                          <option>Apartemen</option>
                          <option>Lainnya</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.jenisAlamat}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              );
            })}

            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Button
                  variant="outline-primary"
                  className="mt-2 w-100"
                  onClick={() => {
                    setFormDataList([
                      ...formDataList,
                      {
                        id: `new-${formDataList.length}`,
                        alamat: '',
                        kota: '',
                        kotaName: '',
                        provinsi: '',
                        provinsiName: '',
                        negara: '',
                        kodePos: '',
                        jenisAlamat: '',
                        phone: formDataList[0].phone,
                        jenisPhone: formDataList[0].jenisPhone,
                        terhubungWA: formDataList[0].terhubungWA,
                        email: formDataList[0].email,
                        jenisEmail: formDataList[0].jenisEmail,
                        district: '',
                        districtName: '',
                        village: '',
                        villageName: '',
                        phones: [],
                        emails: []
                      }
                    ]);
                  }}
                >
                  + Tambah Alamat
                </Button>
              </Col>
            </Row>

            <h2 className="mb-2 mt-5 fs-7 text-capitalize">Telepon Pribadi</h2>
            {formDataList[0]?.phones.map((phoneData, phoneIndex) => (
              <div key={phoneData.id}>
                {phoneIndex > 0 && <hr className="my-4 border-dashed" />}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="mb-0 fs-8 text-capitalize">
                    Telepon {phoneIndex + 1}
                  </h2>
                  <ForbiddenWrapper
                    privilege="CUSTOMER_DATA_FULL"
                    isBlank={true}
                  >
                    {phoneIndex > 0 && (
                      <Button
                        variant="link"
                        className="text-danger p-0"
                        onClick={() => handleDeletePhone(phoneIndex)}
                      >
                        Hapus Telepon
                      </Button>
                    )}
                  </ForbiddenWrapper>
                </div>
                <Row className="mb-3">
                  <Col xs={12} md={12}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Nomor Telepon <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        name={`phone-${phoneIndex}`}
                        type="text"
                        placeholder="Nomor telepon"
                        value={phoneData.number}
                        onChange={e => {
                          const newFormDataList = [...formDataList];
                          newFormDataList[0].phones[phoneIndex].number =
                            e.target.value;
                          setFormDataList(newFormDataList);
                        }}
                        isInvalid={!!errors.phone}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={12} md={8}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Jenis Penggunaan <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name={`jenisPhone-${phoneIndex}`}
                        value={phoneData.usageType}
                        onChange={e => {
                          const newFormDataList = [...formDataList];
                          newFormDataList[0].phones[phoneIndex].usageType =
                            e.target.value;
                          setFormDataList(newFormDataList);
                        }}
                        isInvalid={!!errors.jenisPhone}
                      >
                        <option value="">Pilih jenis penggunaan</option>
                        <option value="Individual">Pribadi</option>
                        <option value="Corporate/Business">Bisnis</option>
                        <option value="Group">Grup</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.jenisPhone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Terhubung WhatsApp
                      </Form.Label>
                      <div className="d-flex gap-2 pt-1">
                        <Form.Check
                          type="radio"
                          name={`terhubungWA-${phoneIndex}`}
                          label="Ya"
                          checked={phoneData.isWhatsapp === true}
                          onChange={() => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[0].phones[phoneIndex].isWhatsapp =
                              true;
                            setFormDataList(newFormDataList);
                          }}
                        />
                        <Form.Check
                          type="radio"
                          name={`terhubungWA-${phoneIndex}`}
                          label="Tidak"
                          checked={phoneData.isWhatsapp === false}
                          onChange={() => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[0].phones[phoneIndex].isWhatsapp =
                              false;
                            setFormDataList(newFormDataList);
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            ))}
            <Button
              variant="outline-primary"
              className="mt-2 w-100"
              onClick={handleAddPhone}
            >
              + Tambah Telepon
            </Button>

            <h2 className="mb-2 mt-5 fs-7 text-capitalize">Email</h2>
            {formDataList[0]?.emails.map((emailData, emailIndex) => (
              <div key={emailData.id}>
                {emailIndex > 0 && <hr className="my-4 border-dashed" />}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="mb-0 fs-8 text-capitalize">
                    Email {emailIndex + 1}
                  </h2>
                  <ForbiddenWrapper
                    privilege="CUSTOMER_DATA_FULL"
                    isBlank={true}
                  >
                    {emailIndex > 0 && (
                      <Button
                        variant="link"
                        className="text-danger p-0"
                        onClick={() => handleDeleteEmail(emailIndex)}
                      >
                        Hapus Email
                      </Button>
                    )}
                  </ForbiddenWrapper>
                </div>
                <Row className="mb-3">
                  <Col xs={12} md={12}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Alamat Email
                      </Form.Label>
                      <Form.Control
                        name={`email-${emailIndex}`}
                        type="email"
                        placeholder="Email"
                        value={emailData.email}
                        onChange={e => {
                          const newFormDataList = [...formDataList];
                          newFormDataList[0].emails[emailIndex].email =
                            e.target.value;
                          setFormDataList(newFormDataList);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={12} md={12}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Jenis Penggunaan
                      </Form.Label>
                      <Form.Select
                        name={`jenisEmail-${emailIndex}`}
                        value={emailData.usageType}
                        onChange={e => {
                          const newFormDataList = [...formDataList];
                          newFormDataList[0].emails[emailIndex].usageType =
                            e.target.value;
                          setFormDataList(newFormDataList);
                        }}
                      >
                        <option value="">Pilih jenis penggunaan</option>
                        <option value="Individual">Pribadi</option>
                        <option value="Corporate/Business">Bisnis</option>
                        <option value="Group">Grup</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            ))}
            <Button
              variant="outline-primary"
              className="mt-2 w-100"
              onClick={handleAddEmail}
            >
              + Tambah Email
            </Button>

            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button
                onClick={handleSave}
                disabled={isSavingEmail || isSavingLocation}
              >
                {isSavingEmail || isSavingLocation ? 'Menyimpan...' : 'Simpan'}
              </Button>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default AlamatKontakCard;
