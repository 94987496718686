/* eslint-disable @typescript-eslint/no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseResponse } from './response/BaseResponse';
import {
  ClinicalServiceData,
  ClinicalServiceResponse,
  ClinicalServiceDetailResponse,
  PolicyData,
  PolicyResponse,
  PolicyResult,
  PolicyDetailResponse
} from './response/ServiceResponse';
import { ClinicalServiceCreateRequest } from './request/ClinicalServiceCreateReq';
import { PolicyCreateRequest } from './request/PolicyCreateRequest';
// import { FormData } from './request/ServiceCreateReq';

// Tambahkan interface untuk parameter query
interface ServiceQueryParams {
  page: number;
  limit: number;
  orderBy: string;
  direction: 'ASC' | 'DESC';
  gender?: string[];
  location?: string[];
  jobTitle?: string[];
  isActive?: string[];
  startAt?: string;
  endAt?: string;
  name?: string;
}

// Tambahkan interface untuk request delete service
interface DeleteServiceRequest {
  notes: string;
  deletionReason: string;
  data: string[];
}

interface ServiceCreateResponse extends BaseResponse {
  data: {
    id: number;
  };
}

// Di serviceApi.ts, tambahkan interface untuk request location
interface LocationRequest {
  street: string;
  province: number; // Pastikan tipe number
  regency: number; // Pastikan tipe number
  district: number; // Pastikan tipe number
  village: number; // Pastikan tipe number
  country: string;
  postalCode: string;
  addressType: string;
}

// Tambahkan interface untuk request update names
interface UpdateServiceNamesRequest {
  name: string;
  // lastName: string;
  // joinedDate: string;
}

// Tambahkan interface untuk request update personal information
interface UpdatePersonalInformationRequest {
  employmentType: string;
  gender: string;
  religion: string;
  login: string;
  nickname: string;
  idType: string;
  idNumber: string;
  dateOfBirth: string;
  maritalStatus: string;
  color: string;
}

// Tambahkan interface untuk request
interface AddressContactRequest {
  emails: Array<{
    id?: string;
    emailAddress: string;
    usageType: string;
  }>;
  contacts: Array<{
    id?: string;
    phoneNumber: string;
    usageType: string;
    isWhatsapp: boolean;
  }>;
  locations: Array<{
    id?: string;
    streetAddress: string;
    state: string;
    country: string;
    postalCode: string;
    addressType: string;
    province: number;
    city: number;
    district: number;
    village: number;
  }>;
}

// Tambahkan interface untuk request emergency contact
interface EmergencyContactRequest {
  id?: string;
  contactName: string;
  phoneNumber: string;
  relationshipStatus: string;
  isWhatsapp: boolean;
  address: string;
}

// Tambahkan interface untuk request note
interface CreateNoteRequest {
  clinicalServiceId: string;
  content: string;
  noteType: string;
}

interface UpdateNoteRequest {
  content: string;
  noteType: string;
}

// Tambahkan interface untuk request
export interface UpdateServiceInfoRequest {
  id: string;
  hex: string;
  status: boolean;
  name: string;
  description: string;
  category: string;
  createdDate: string;
  facility: string;
  beforeTreatmentInMinutes: number;
  afterTreatmentInMinutes: number;
}

// Tambahkan interface untuk response job title
interface JobTitleResponse {
  id: string;
  name: string;
  description: string;
  color: string;
  totalOfService: number;
}

interface UpdateClinicalServiceRequest {
  name: string;
  description: string;
  categoryId: string;
  color: string;
  beforeTreatmentInMinutes: number;
  afterTreatmentInMinutes: number;
  isDefaultForTimeGap: boolean;
  status: boolean;
  createdDate: string;
}

// Tambahkan interface untuk booking settings
interface BookingSettingsResponse {
  id: string;
  createdDate: string;
  modifiedDate: string;
  createdBy: string;
  modifiedBy: string;
  policyName: string;
  isNotedForReasonVisit: boolean;
  isDiagnosisRecord: boolean;
  isDefault: boolean;
}

// Tambahkan interface untuk pricing request
interface ClinicalServicePricingRequest {
  title: string;
  duration?: number;
  durationType?: string;
  min?: number;
  max?: number;
  customerId: string[];
  location: string[];
  price: number;
}

interface UpdatePricingRequest {
  pricingType: 'Fixed' | 'Rated';
  taxPolicy: string;
  clinicalServicePricing: ClinicalServicePricingRequest[];
}

// Tambahkan interface untuk request update employee
export interface UpdateEmployeeRequest {
  additionalCostType: 'Fixed' | 'Percentage';
  totalEmployee: number;
  employee: Array<{
    id?: string;
    employeeId: string;
    additionalCost: string;
  }>;
}

// Tambahkan interface untuk parameter query kebijakan
interface PolicyQueryParams {
  page: number;
  limit: number;
  orderBy: string;
  direction: 'ASC' | 'DESC';
  name?: string;
}

// Tambahkan interface untuk request update deposit
interface PolicyDepositUpdateRequest {
  offlineDepositAmount: number;
  onlineDepositAmount: number;
  securityDepositAmount: number;
  cancellationBefore: number;
  onTimeCancellationPercentage: number;
  lateCancellationPercentage: number;
  offlineDepositAmountType: 'PERCENTAGE' | 'RUPIAH';
  onlineDepositAmountType: 'PERCENTAGE' | 'RUPIAH';
  securityDepositAmountType: 'PERCENTAGE' | 'RUPIAH';
  daysCancellation:
    | 'MINUTES_BEFORE_BOOKING'
    | 'HOURS_BEFORE_BOOKING'
    | 'DAYS_BEFORE_BOOKING';
}

// Tambahkan interface untuk request update basic information
interface PolicyBasicInfoUpdateRequest {
  name: string;
  status: boolean;
  description: string;
  isRefundAllowed?: boolean;
  startDate: string;
  endDate?: string | null;
  createdDate: string;
}

// Tambahkan interface untuk request delete policy
interface DeletePolicyRequest {
  id: string[];
}

export const serviceApi = createApi({
  reducerPath: 'service',
  tagTypes: ['Service'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    timeout: 10000
  }),
  endpoints: builder => ({
    getService: builder.query<ClinicalServiceData, ServiceQueryParams>({
      query: params => {
        const queryParams = new URLSearchParams();

        // Parameter dasar
        queryParams.append('page', params.page.toString());
        queryParams.append('limit', params.limit.toString());
        queryParams.append('orderBy', params.orderBy);
        queryParams.append('direction', params.direction);

        // Filter tambahan
        if (params.gender?.length) {
          params.gender.forEach(g => queryParams.append('gender', g));
        }
        if (params.location?.length) {
          params.location.forEach(l =>
            queryParams.append('branch', l.toLowerCase())
          );
        }
        if (params.startAt) {
          queryParams.append('startAt', params.startAt);
        }
        if (params.endAt) {
          queryParams.append('endAt', params.endAt);
        }
        if (params.name) {
          queryParams.append('name', params.name);
        }

        const url = `api/v1/clinical-service?${queryParams.toString()}`;
        console.log('Request URL:', url);
        return url;
      },
      transformResponse: (
        baseQueryReturnValue: unknown
      ): ClinicalServiceData => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = baseQueryReturnValue as any;

        return {
          status: response.status || 'success',
          code: response.code,
          message: response.message,
          data: {
            result: response.data?.result || [],
            total: response.data?.total || 0,
            totalPages: response.data?.totalPages || 0,
            page: parseInt(response.data?.page || '1')
          }
        };
      },
      providesTags: ['Service']
    }),
    addService: builder.mutation<ServiceCreateResponse, FormData>({
      query: newData => ({
        url: 'api/v1/service',
        method: 'POST',
        body: newData
      })
    }),
    getServiceById: builder.query<
      BaseResponse & { data: ClinicalServiceDetailResponse },
      string
    >({
      query: id => `api/v1/clinical-service/${id}`,
      transformResponse: (
        response: BaseResponse & { data: ClinicalServiceDetailResponse }
      ) => {
        return response;
      }
    }),
    editService: builder.mutation({
      query: data => ({
        url: '/api/v1/service/detail/edit',
        method: 'PUT',
        body: data // Semua data dikirimkan melalui body
      })
    }),
    updateLocation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/location/edit/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    updateEmail: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/email/edit/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    updateEmergencyContact: builder.mutation<
      BaseResponse,
      { id: string; data: EmergencyContactRequest[] }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/service/${id}/emergency-contact`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    addServiceNote: builder.mutation<
      BaseResponse & {
        data: {
          id: string;
          content: string;
          type: string;
          createdDate: string;
        };
      },
      {
        serviceId: number;
        content: string;
        noteType: string;
      }
    >({
      query: newNote => ({
        url: '/api/v1/servicenote/create',
        method: 'POST',
        body: newNote
      })
    }),
    deleteService: builder.mutation<BaseResponse, DeleteServiceRequest>({
      query: deleteRequest => ({
        url: 'api/v1/clinical-service',
        method: 'DELETE',
        body: deleteRequest
      }),
      invalidatesTags: ['Service']
    }),
    // getUniqueBranches: builder.query<string[], void>({
    //   query: () => 'api/v1/service?page=1&limit=1000',
    //   transformResponse: (response: ServiceData) => {
    //     if (!response.data) return [];

    //     const branches = response.data
    //       .map(service => service.branch)
    //       .filter(
    //         (branch): branch is string =>
    //           Boolean(branch) && typeof branch === 'string'
    //       )
    //       .filter(
    //         (branch, index, self) =>
    //           self.findIndex(b => b.toLowerCase() === branch.toLowerCase()) ===
    //           index
    //       )
    //       .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

    //     console.log('Transformed branches:', branches);
    //     return branches;
    //   }
    // }),
    createLocation: builder.mutation<
      BaseResponse,
      { serviceId: string; data: LocationRequest }
    >({
      query: ({ serviceId, data }) => ({
        url: `/api/v1/location/create/${serviceId}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    updateServiceNames: builder.mutation<
      BaseResponse,
      { id: string; data: UpdateServiceNamesRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/service/${id}/names`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    updatePersonalInformation: builder.mutation<
      BaseResponse,
      UpdatePersonalInformationRequest & { id: string }
    >({
      query: ({ id, ...data }) => ({
        url: `/api/v1/service/${id}/personal-information`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    updateAddressContact: builder.mutation<
      BaseResponse,
      { id: string; data: AddressContactRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/service/${id}/address-contact`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    createServiceNote: builder.mutation<BaseResponse, CreateNoteRequest>({
      query: data => ({
        url: '/api/v1/clinical-service/note',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    updateServiceNote: builder.mutation<
      BaseResponse,
      { id: string; data: UpdateNoteRequest }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/clinical-service/note/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    updateServiceInfo: builder.mutation<
      BaseResponse,
      { serviceId: string; data: UpdateServiceInfoRequest }
    >({
      query: ({ serviceId, data }) => ({
        url: `api/v1/service/${serviceId}/info`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    getJobTitles: builder.query<JobTitleResponse[], void>({
      query: () => 'api/v1/service/job-title?limit=1000',
      transformResponse: (response: {
        data: {
          result: JobTitleResponse[];
        };
      }) => response.data.result
    }),
    createClinicalService: builder.mutation<
      ClinicalServiceResponse,
      ClinicalServiceCreateRequest
    >({
      query: data => ({
        url: 'api/v1/clinical-service',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    updateClinicalService: builder.mutation<
      BaseResponse,
      { id: string; data: UpdateClinicalServiceRequest }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/clinical-service/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    editServiceNote: builder.mutation<
      BaseResponse,
      { id: string; data: { content: string; noteType: string } }
    >({
      query: ({ id, data }) => ({
        url: `/api/v1/clinical-service/note/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    deleteServiceNote: builder.mutation<BaseResponse, string>({
      query: id => ({
        url: `/api/v1/clinical-service/note/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Service']
    }),
    getBookingSettings: builder.query<BookingSettingsResponse[], void>({
      query: () => 'api/v1/clinical-service/booking-settings',
      transformResponse: (response: {
        code: number;
        message: string;
        data: BookingSettingsResponse[];
      }) => {
        return response.data || [];
      }
    }),
    updateBookingSettings: builder.mutation<
      BaseResponse,
      { id: string; data: Partial<BookingSettingsResponse> }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/clinical-service/booking-settings/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    updateClinicalServicePricing: builder.mutation<
      BaseResponse,
      { id: string; data: UpdatePricingRequest }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/clinical-service/${id}/pricing`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    getAllPolicies: builder.query<PolicyData, PolicyQueryParams>({
      query: params => {
        const queryParams = new URLSearchParams();

        // Parameter dasar
        queryParams.append('page', params.page.toString());
        queryParams.append('limit', params.limit.toString());
        queryParams.append('orderBy', params.orderBy);
        queryParams.append('direction', params.direction);

        // Parameter opsional
        if (params.name) {
          queryParams.append('name', params.name);
        }

        return `api/v1/clinical-service/policy?${queryParams.toString()}`;
      },
      providesTags: ['Service']
    }),
    createPolicy: builder.mutation<BaseResponse, PolicyCreateRequest>({
      query: data => ({
        url: 'api/v1/clinical-service/policy',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    deletePolicy: builder.mutation<BaseResponse, string[]>({
      query: ids => ({
        url: 'api/v1/clinical-service/policy',
        method: 'DELETE',
        body: { id: ids }
      }),
      invalidatesTags: ['Service']
    }),
    getPolicyById: builder.query<
      BaseResponse & { data: PolicyDetailResponse },
      string
    >({
      query: id => `api/v1/clinical-service/policy/${id}/detail`,
      providesTags: ['Service']
    }),
    updateClinicalServiceEmployee: builder.mutation<
      BaseResponse,
      { id: string; data: UpdateEmployeeRequest }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/clinical-service/${id}/employee`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    updatePolicyDeposit: builder.mutation<
      void,
      { id: string; data: PolicyDepositUpdateRequest }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/clinical-service/policy/${id}/deposit`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    updatePolicyBasicInfo: builder.mutation<
      BaseResponse,
      { id: string; data: PolicyBasicInfoUpdateRequest }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/clinical-service/policy/${id}/basic-information`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    updatePolicyRefund: builder.mutation<
      BaseResponse,
      {
        id: string;
        data: {
          refundDaysBeforeBooking: number;
          refundPaymentMethod: string[];
          refundDaysBeforeBookingType:
            | 'MINUTES_BEFORE_BOOKING'
            | 'HOURS_BEFORE_BOOKING'
            | 'DAYS_BEFORE_BOOKING';
        };
      }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/clinical-service/policy/${id}/refund`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Service']
    })
  })
});

export const {
  useGetServiceQuery,
  useAddServiceMutation,
  useGetServiceByIdQuery,
  useEditServiceMutation,
  useUpdateLocationMutation,
  useUpdateEmailMutation,
  useUpdateEmergencyContactMutation,
  useAddServiceNoteMutation,
  useDeleteServiceMutation,
  useEditServiceNoteMutation,
  useDeleteServiceNoteMutation,
  useCreateLocationMutation,
  useUpdateServiceNamesMutation,
  useUpdatePersonalInformationMutation,
  useUpdateAddressContactMutation,
  useCreateServiceNoteMutation,
  useUpdateServiceNoteMutation,
  useUpdateServiceInfoMutation,
  useGetJobTitlesQuery,
  useCreateClinicalServiceMutation,
  useUpdateClinicalServiceMutation,
  useGetBookingSettingsQuery,
  useUpdateBookingSettingsMutation,
  useUpdateClinicalServicePricingMutation,
  useGetAllPoliciesQuery,
  useCreatePolicyMutation,
  useDeletePolicyMutation,
  useGetPolicyByIdQuery,
  useUpdateClinicalServiceEmployeeMutation,
  useUpdatePolicyDepositMutation,
  useUpdatePolicyBasicInfoMutation,
  useUpdatePolicyRefundMutation
} = serviceApi;
