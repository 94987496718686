import React, { useState } from 'react';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { showCustomAlert } from 'helpers/showCustomAlert';
import {
  useUpdateServiceTemplateResponseMutation,
  UpdateTemplateRequest
} from 'api/serviceTemplateApi';
import Select from 'react-select';

interface LayananTemplateCardProps {
  templateResponses: {
    id: string;
    field: {
      clinicalserviceTemplateId: string;
      fieldName: string;
      fieldTypeId: string;
      label: string;
      isRequired: boolean;
      fieldSize: string;
      fieldTextLength: number;
      position: number;
    };
    responseValue: string;
    clinicalserviceTemplateOptionResponse: Array<{
      id: string;
      option: string;
      optionValue: string;
      selectedChoice: boolean;
    }>;
  }[];
  serviceId: string;
  onSuccess?: () => void;
}

// Tambahkan mapping fieldTypeId ke code
const FIELD_TYPE_MAPPING: { [key: string]: string } = {
  'a1a096b3-1a6e-45f8-8d28-aadf6b66e317': 'SINGLE_LINE_TEXT',
  '828d3cd9-db1a-4861-bc30-a957965b97f0': 'MULTIPLE_LINE_TEXT',
  'a2b387a8-27e2-476c-96e4-ce1a26c09253': 'RADIO_BUTTONS',
  '80d0f4d6-8373-4ad7-8f35-a8c5a0e68756': 'CHECKBOXES',
  '7321fa99-d033-4418-b7d5-61295151666d': 'DROPDOWN_LIST',
  '18403d84-4973-4de6-99b8-3d4a5da9d8d4': 'MULTI_SELECT_LIST',
  'e94475c2-bbcb-4091-87d5-ad4e32cca008': 'DATE'
};

const LayananTemplateCard: React.FC<LayananTemplateCardProps> = ({
  templateResponses,
  serviceId,
  onSuccess
}) => {
  const [editingStates, setEditingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [editedResponses, setEditedResponses] = useState<{
    [key: string]: {
      responseValue?: string;
      options?: { id: string; selectedChoice: boolean }[];
    };
  }>({});
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  const [updateTemplateResponse] = useUpdateServiceTemplateResponseMutation();

  // Fungsi validasi field
  const validateFields = () => {
    const errors: { [key: string]: string } = {};

    templateResponses.forEach(response => {
      const { field } = response;
      if (field.isRequired) {
        const editedResponse = editedResponses[field.fieldTypeId];
        const hasOptions =
          response.clinicalserviceTemplateOptionResponse.length > 0;

        if (hasOptions) {
          // Cek apakah ada opsi yang dipilih untuk field dengan options
          const hasSelected =
            response.clinicalserviceTemplateOptionResponse.some(opt => {
              const editedOption = editedResponse?.options?.find(
                edited => edited.id === opt.id
              );
              return editedOption?.selectedChoice ?? opt.selectedChoice;
            });

          if (!hasSelected) {
            errors[field.fieldTypeId] = 'Pilih minimal satu opsi';
          }
        } else {
          // Cek nilai untuk field tanpa options
          const value = editedResponse?.responseValue ?? response.responseValue;
          if (!value || value.trim() === '') {
            errors[field.fieldTypeId] = `${field.label} tidak boleh kosong`;
          }
        }
      }
    });

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Update renderField untuk menggunakan mapping
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderField = (response: any) => {
    const { field } = response;
    const fieldType = FIELD_TYPE_MAPPING[field.fieldTypeId];

    switch (fieldType) {
      case 'SINGLE_LINE_TEXT':
        return (
          <Form.Control
            type="text"
            value={
              editedResponses[field.fieldTypeId]?.responseValue ??
              response.responseValue ??
              ''
            }
            onChange={e => {
              setEditedResponses(prev => ({
                ...prev,
                [field.fieldTypeId]: {
                  responseValue: e.target.value
                }
              }));
            }}
            isInvalid={!!fieldErrors[field.fieldTypeId]}
            maxLength={field.fieldTextLength}
            placeholder={`Masukkan ${field.label.toLowerCase()}`}
          />
        );

      case 'MULTIPLE_LINE_TEXT':
        return (
          <Form.Control
            as="textarea"
            rows={3}
            value={
              editedResponses[field.fieldTypeId]?.responseValue ??
              response.responseValue ??
              ''
            }
            onChange={e => {
              setEditedResponses(prev => ({
                ...prev,
                [field.fieldTypeId]: {
                  responseValue: e.target.value
                }
              }));
            }}
            isInvalid={!!fieldErrors[field.fieldTypeId]}
            maxLength={field.fieldTextLength}
            placeholder={`Masukkan ${field.label.toLowerCase()}`}
          />
        );

      case 'RADIO_BUTTONS':
        return (
          <div>
            {response.clinicalserviceTemplateOptionResponse.map(
              (option: {
                id: string;
                optionValue: string;
                selectedChoice: boolean;
              }) => (
                <Form.Check
                  key={option.id}
                  type="radio"
                  name={`radio-${field.fieldTypeId}`}
                  label={option.optionValue}
                  checked={
                    editedResponses[field.fieldTypeId]?.options?.find(
                      (opt: { id: string }) => opt.id === option.id
                    )?.selectedChoice ?? option.selectedChoice
                  }
                  onChange={() => {
                    setEditedResponses(prev => ({
                      ...prev,
                      [field.fieldTypeId]: {
                        options:
                          response.clinicalserviceTemplateOptionResponse.map(
                            (opt: { id: string }) => ({
                              id: opt.id,
                              selectedChoice: opt.id === option.id
                            })
                          )
                      }
                    }));
                  }}
                />
              )
            )}
          </div>
        );

      case 'CHECKBOXES':
        return (
          <div>
            {response.clinicalserviceTemplateOptionResponse.map(
              (option: {
                id: string;
                optionValue: string;
                selectedChoice: boolean;
              }) => (
                <Form.Check
                  key={option.id}
                  type="checkbox"
                  label={option.optionValue}
                  checked={
                    editedResponses[field.fieldTypeId]?.options?.find(
                      (opt: { id: string }) => opt.id === option.id
                    )?.selectedChoice ?? option.selectedChoice
                  }
                  onChange={e => {
                    const currentOptions =
                      editedResponses[field.fieldTypeId]?.options ||
                      response.clinicalserviceTemplateOptionResponse.map(
                        (opt: { id: string; selectedChoice: boolean }) => ({
                          id: opt.id,
                          selectedChoice: opt.selectedChoice
                        })
                      );

                    setEditedResponses(prev => ({
                      ...prev,
                      [field.fieldTypeId]: {
                        options: currentOptions.map((opt: { id: string }) =>
                          opt.id === option.id
                            ? { ...opt, selectedChoice: e.target.checked }
                            : opt
                        )
                      }
                    }));
                  }}
                />
              )
            )}
          </div>
        );

      case 'DROPDOWN_LIST':
        return (
          <Form.Select
            value={
              editedResponses[field.fieldTypeId]?.options
                ? editedResponses[field.fieldTypeId]?.options?.find(
                    opt => opt.selectedChoice
                  )?.id || ''
                : response.clinicalserviceTemplateOptionResponse.find(
                    (opt: { selectedChoice: boolean }) => opt.selectedChoice
                  )?.id || ''
            }
            onChange={e => {
              const selectedValue = e.target.value;
              if (!selectedValue) {
                setEditedResponses(prev => ({
                  ...prev,
                  [field.fieldTypeId]: {
                    options: response.clinicalserviceTemplateOptionResponse.map(
                      (opt: { id: string; option: string }) => ({
                        id: opt.id,
                        optionId: opt.option,
                        selectedChoice: false
                      })
                    )
                  }
                }));
                return;
              }

              setEditedResponses(prev => ({
                ...prev,
                [field.fieldTypeId]: {
                  options: response.clinicalserviceTemplateOptionResponse.map(
                    (opt: { id: string; option: string }) => ({
                      id: opt.id,
                      optionId: opt.option,
                      selectedChoice: opt.id === selectedValue
                    })
                  )
                }
              }));
            }}
            isInvalid={!!fieldErrors[field.fieldTypeId]}
          >
            <option value="">Pilih {field.label.toLowerCase()}</option>
            {response.clinicalserviceTemplateOptionResponse.map(
              (option: { id: string; optionValue: string }) => (
                <option key={option.id} value={option.id}>
                  {option.optionValue}
                </option>
              )
            )}
          </Form.Select>
        );

      case 'MULTI_SELECT_LIST':
        return (
          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={response.clinicalserviceTemplateOptionResponse
              .filter((opt: { id: string; selectedChoice: boolean }) => {
                const editedOption = editedResponses[
                  field.fieldTypeId
                ]?.options?.find(
                  (edited: { id: string }) => edited.id === opt.id
                );
                return editedOption?.selectedChoice ?? opt.selectedChoice;
              })
              .map((opt: { id: string; optionValue: string }) => ({
                value: opt.id,
                label: opt.optionValue
              }))}
            options={response.clinicalserviceTemplateOptionResponse.map(
              (option: { id: string; optionValue: string }) => ({
                value: option.id,
                label: option.optionValue
              })
            )}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(selectedOptions: any) => {
              setEditedResponses(prev => ({
                ...prev,
                [field.fieldTypeId]: {
                  options: response.clinicalserviceTemplateOptionResponse.map(
                    (opt: { id: string }) => ({
                      id: opt.id,
                      selectedChoice: selectedOptions.some(
                        (selected: { value: string }) =>
                          selected.value === opt.id
                      )
                    })
                  )
                }
              }));
            }}
            placeholder={`Pilih ${field.label.toLowerCase()}`}
          />
        );

      case 'DATE':
        return (
          <Form.Control
            type="date"
            value={
              editedResponses[field.fieldTypeId]?.responseValue ??
              response.responseValue ??
              ''
            }
            onChange={e => {
              setEditedResponses(prev => ({
                ...prev,
                [field.fieldTypeId]: {
                  responseValue: e.target.value
                }
              }));
            }}
            isInvalid={!!fieldErrors[field.fieldTypeId]}
          />
        );

      default:
        return null;
    }
  };

  // Update renderTemplateGroups untuk menggunakan renderField
  const renderTemplateGroups = () => {
    if (!templateResponses.length) return null;

    // Kelompokkan responses berdasarkan clinicalserviceTemplateId
    const groupedResponses = templateResponses.reduce(
      (groups, response) => {
        const templateId = response.field.clinicalserviceTemplateId;
        if (!groups[templateId]) {
          groups[templateId] = [];
        }
        groups[templateId].push(response);
        return groups;
      },
      {} as { [key: string]: typeof templateResponses }
    );

    // Render setiap grup dalam card terpisah
    return Object.entries(groupedResponses).map(([templateId, responses]) => (
      <Card key={templateId} className="w-100 mb-4">
        <Card.Body>
          <Row className="mb-4">
            <Col xs={12} className="d-flex align-items-center">
              <h3>Form Template</h3>
              {!editingStates[templateId] && (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() =>
                    setEditingStates(prev => ({ ...prev, [templateId]: true }))
                  }
                >
                  <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
                </Button>
              )}
            </Col>
          </Row>

          {!editingStates[templateId] ? (
            <div>
              {[...responses]
                .sort((a, b) => a.field.label.localeCompare(b.field.label))
                .map(response => {
                  const { field } = response;
                  return (
                    <Row className="mb-3" key={field.fieldTypeId}>
                      <Col xs={12} md={12} className="d-flex flex-column pe-3">
                        <span className="fw-bold text-muted text-capitalize">
                          {field.label}
                        </span>
                        <span className="fw-normal">
                          {response.clinicalserviceTemplateOptionResponse
                            .length > 0 ? (
                            <div>
                              {response.clinicalserviceTemplateOptionResponse
                                .filter(opt => opt.selectedChoice)
                                .map(opt => opt.optionValue)
                                .join(', ') || '-'}
                            </div>
                          ) : (
                            response.responseValue || '-'
                          )}
                        </span>
                      </Col>
                    </Row>
                  );
                })}
            </div>
          ) : (
            <Form>
              {[...responses]
                .sort((a, b) => a.field.label.localeCompare(b.field.label))
                .map(response => (
                  <Form.Group key={response.field.fieldTypeId} className="mb-3">
                    <Form.Label className="fw-bold p-0 fs-8 text-capitalize">
                      {response.field.label}
                      {response.field.isRequired && (
                        <span className="text-danger">*</span>
                      )}
                    </Form.Label>
                    {renderField(response)}
                    {fieldErrors[response.field.fieldTypeId] && (
                      <Form.Control.Feedback type="invalid">
                        {fieldErrors[response.field.fieldTypeId]}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                ))}

              <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
                <Button
                  variant="link"
                  style={{ color: 'red' }}
                  onClick={() => handleCancel(templateId)}
                >
                  Batalkan
                </Button>
                <Button onClick={() => handleSave(templateId)}>Simpan</Button>
              </div>
            </Form>
          )}
        </Card.Body>
      </Card>
    ));
  };

  // Update handleSave untuk format request yang benar
  const handleSave = async (templateId: string) => {
    if (!validateFields()) return;

    showCustomAlert({
      icon: 'warning',
      label: 'Konfirmasi Simpan',
      deskripsi: 'Apakah Anda yakin ingin menyimpan perubahan?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const updatePayload: UpdateTemplateRequest[] = templateResponses.map(
            response => {
              const editedResponse =
                editedResponses[response.field.fieldTypeId];
              const hasOptions =
                response.clinicalserviceTemplateOptionResponse.length > 0;

              const payload = {
                clinicalServiceTemplateResponseId: response.id,
                clinicalServiceId: serviceId,
                fieldId: response.field.fieldTypeId,
                responseValue: hasOptions
                  ? ''
                  : editedResponse?.responseValue ??
                    response.responseValue ??
                    '',
                optionResponse: hasOptions
                  ? response.clinicalserviceTemplateOptionResponse.map(
                      option => ({
                        id: option.id,
                        optionId: option.option,
                        selectedChoice:
                          editedResponse?.options?.find(
                            opt => opt.id === option.id
                          )?.selectedChoice ?? option.selectedChoice
                      })
                    )
                  : undefined
              };

              console.log('Individual payload:', payload); // Debug individual payload
              return payload;
            }
          );

          console.log('Full update payload:', updatePayload); // Debug full payload

          await updateTemplateResponse(updatePayload).unwrap();

          // Panggil refetch setelah API berhasil
          if (onSuccess) {
            onSuccess();
          }

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data template berhasil diperbarui',
            buttonType: 'ok',
            onConfirm: () => {
              setEditingStates(prev => ({ ...prev, [templateId]: false }));
              setEditedResponses({});
            }
          });
        } catch (error) {
          console.error('Update Error:', error); // Debug error
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: 'Terjadi kesalahan saat menyimpan data',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleCancel = (templateId: string) => {
    if (Object.keys(editedResponses).length > 0) {
      showCustomAlert({
        icon: 'warning',
        label: 'Konfirmasi Batal',
        deskripsi:
          'Perubahan yang Anda buat akan hilang. Apakah Anda yakin ingin membatalkan?',
        buttonType: 'yes-no',
        onConfirm: () => {
          setEditingStates(prev => ({ ...prev, [templateId]: false }));
          setEditedResponses({});
        }
      });
    } else {
      setEditingStates(prev => ({ ...prev, [templateId]: false }));
    }
  };

  return (
    <div className="d-flex flex-column p-0 m-0 my-1">
      {renderTemplateGroups()}
    </div>
  );
};

export default LayananTemplateCard;
