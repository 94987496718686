import React, { useState } from 'react';
import { Card, Row, Col, Table, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt,
  faGripVertical,
  faMinus,
  faXmark,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import TinymceEditor from 'components/base/TinymceEditor';
import { showCustomAlert } from 'helpers/showCustomAlert';

interface KebijakanCardProps {
  data: {
    isFormattingAllowed: boolean;
    allowPractitionerSignatureRecording: boolean;
    contentOfAgreement: string;
    additionalProvision?: string[];
    clinicalServiceCheckboxAgreement: Array<{
      id: string;
      isCheckboxRequired: boolean;
      displayIn2Columns: boolean;
      title: string;
      clinicalServiceCheckboxAgreementOption: Array<{
        id: string;
        selectedChoice: boolean;
        content: string;
      }>;
    }>;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdate?: (updatedData: any) => void;
}

const KebijakanCard: React.FC<KebijakanCardProps> = ({ data, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    allowFormating: data.isFormattingAllowed,
    allowSignature: data.allowPractitionerSignatureRecording,
    content: data.contentOfAgreement
  });
  const [plainContent, setPlainContent] = useState(data.contentOfAgreement);
  const [formattedContent, setFormattedContent] = useState(
    data.contentOfAgreement
  );
  const [additionalTerms, setAdditionalTerms] = useState<string[]>(
    data.additionalProvision || ['', '']
  );

  // Initialize checkboxGroups with existing data
  const [checkboxGroups, setCheckboxGroups] = useState(
    data.clinicalServiceCheckboxAgreement.map(group => ({
      id: group.id,
      title: group.title,
      isRequired: group.isCheckboxRequired,
      showInTwoColumns: group.displayIn2Columns,
      options: group.clinicalServiceCheckboxAgreementOption.map(opt => ({
        id: opt.id,
        text: opt.content,
        isDefault: opt.selectedChoice
      }))
    }))
  );

  const renderHtmlContent = (content: string) => {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const handleEditorChange = (content: string) => {
    setFormattedContent(content);
    setFormData(prev => ({
      ...prev,
      content
    }));
  };

  const handlePlainContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPlainContent(value);
    setFormData(prev => ({
      ...prev,
      content: value
    }));
  };

  const hasChanges = () => {
    // Add checkbox groups comparison
    const originalCheckboxGroups = data.clinicalServiceCheckboxAgreement.map(
      group => ({
        id: group.id,
        title: group.title,
        isRequired: group.isCheckboxRequired,
        showInTwoColumns: group.displayIn2Columns,
        options: group.clinicalServiceCheckboxAgreementOption.map(opt => ({
          id: opt.id,
          text: opt.content,
          isDefault: opt.selectedChoice
        }))
      })
    );

    return (
      formData.allowFormating !== data.isFormattingAllowed ||
      formData.allowSignature !== data.allowPractitionerSignatureRecording ||
      formData.content !== data.contentOfAgreement ||
      JSON.stringify(checkboxGroups) !== JSON.stringify(originalCheckboxGroups)
    );
  };

  const handleCancel = () => {
    if (!hasChanges()) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Perubahan data tidak akan disimpan',
      deskripsi: 'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        // Reset all form data including checkbox groups
        setFormData({
          allowFormating: data.isFormattingAllowed,
          allowSignature: data.allowPractitionerSignatureRecording,
          content: data.contentOfAgreement
        });
        setPlainContent(data.contentOfAgreement);
        setFormattedContent(data.contentOfAgreement);
        // Reset checkbox groups to original data
        setCheckboxGroups(
          data.clinicalServiceCheckboxAgreement.map(group => ({
            id: group.id,
            title: group.title,
            isRequired: group.isCheckboxRequired,
            showInTwoColumns: group.displayIn2Columns,
            options: group.clinicalServiceCheckboxAgreementOption.map(opt => ({
              id: opt.id,
              text: opt.content,
              isDefault: opt.selectedChoice
            }))
          }))
        );
        setIsEditing(false);
      }
    });
  };

  const handleSave = () => {
    showCustomAlert({
      icon: 'question',
      label: 'Perubahan data akan disimpan',
      deskripsi:
        'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          if (onUpdate) {
            await onUpdate({
              isFormattingAllowed: formData.allowFormating,
              allowPractitionerSignatureRecording: formData.allowSignature,
              contentOfAgreement: formData.content,
              additionalProvision: additionalTerms.filter(
                term => term.trim() !== ''
              )
            });
          }

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Pengaturan kebijakan berhasil disimpan',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
            }
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Error',
            deskripsi: 'Gagal menyimpan pengaturan kebijakan',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleAddTerm = () => {
    setAdditionalTerms([...additionalTerms, '']);
  };

  const handleRemoveTerm = (index: number) => {
    const newTerms = additionalTerms.filter((_, i) => i !== index);
    setAdditionalTerms(newTerms);
  };

  const handleTermChange = (index: number, value: string) => {
    const newTerms = [...additionalTerms];
    newTerms[index] = value;
    setAdditionalTerms(newTerms);
  };

  const handleAddCheckboxGroup = () => {
    setCheckboxGroups(prev => [
      ...prev,
      {
        id: (prev.length + 1).toString(),
        title: '',
        isRequired: false,
        showInTwoColumns: false,
        options: [
          { id: '1', text: '', isDefault: true },
          { id: '2', text: '', isDefault: false }
        ]
      }
    ]);
  };

  const handleRemoveCheckboxGroup = (groupId: string) => {
    setCheckboxGroups(prev => prev.filter(group => group.id !== groupId));
  };

  const handleCheckboxGroupChange = (
    groupId: string,
    field: string,
    value: string | boolean
  ) => {
    setCheckboxGroups(prev =>
      prev.map(group => {
        if (group.id === groupId) {
          return { ...group, [field]: value };
        }
        return group;
      })
    );
  };

  const handleAddOption = (groupId: string) => {
    setCheckboxGroups(prev =>
      prev.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            options: [
              ...group.options,
              { id: Date.now().toString(), text: '', isDefault: false }
            ]
          };
        }
        return group;
      })
    );
  };

  const handleRemoveOption = (groupId: string, optionId: string) => {
    setCheckboxGroups(prev =>
      prev.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            options: group.options.filter(opt => opt.id !== optionId)
          };
        }
        return group;
      })
    );
  };

  const handleOptionChange = (
    groupId: string,
    optionId: string,
    field: string,
    value: string | boolean
  ) => {
    setCheckboxGroups(prev =>
      prev.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            options: group.options.map(opt => {
              if (opt.id === optionId) {
                return { ...opt, [field]: value };
              }
              return opt;
            })
          };
        }
        return group;
      })
    );
  };

  const tinyConfig = {
    height: '300px',
    placeholder: 'Masukkan isi kebijakan...',
    plugins: [
      'advlist',
      'autolink',
      'lists',
      'link',
      'image',
      'charmap',
      'preview',
      'anchor',
      'searchreplace',
      'visualblocks',
      'fullscreen',
      'insertdatetime',
      'media',
      'table',
      'help',
      'wordcount'
    ],
    toolbar: [
      'undo redo | styles | fontsize | bold italic underline strikethrough | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist | outdent indent | ' +
        'table link image | removeformat | fullscreen'
    ],
    content_style: 'body { font-family:Arial,sans-serif; font-size:11pt }'
  };

  if (isEditing) {
    return (
      <Card>
        <Card.Body>
          <Row className="mb-4">
            <Col xs={12} className="d-flex align-items-center">
              <h3>Kebijakan</h3>
            </Col>
          </Row>

          <div className="d-flex flex-column">
            {/* Isi Persetujuan (Consent) */}
            <div className="mb-2">
              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                Isi Persetujuan (Consent)
              </Form.Label>
              <div className="d-flex gap-3">
                <Form.Check
                  type="checkbox"
                  id="allowFormating"
                  label="Izinkan pemformatan"
                  name="allowFormating"
                  checked={formData.allowFormating}
                  onChange={handleChange}
                />
                <Form.Check
                  type="checkbox"
                  id="allowSignature"
                  label="Rekam tanda tangan praktisi"
                  name="allowSignature"
                  checked={formData.allowSignature}
                  onChange={handleChange}
                />
              </div>
            </div>

            {!formData.allowFormating ? (
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="content"
                  value={plainContent}
                  onChange={handlePlainContentChange}
                  placeholder="Masukkan consent"
                />
              </Form.Group>
            ) : (
              <Form.Group>
                <div className="d-flex flex-column">
                  <TinymceEditor
                    value={formattedContent}
                    onChange={handleEditorChange}
                    options={tinyConfig}
                  />
                </div>
              </Form.Group>
            )}

            {!formData.allowFormating && (
              <>
                {/* Ketentuan Tambahan */}
                <Form.Group>
                  <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                    Ketentuan Tambahan
                  </Form.Label>
                  {additionalTerms.map((term, index) => (
                    <div key={index} className="d-flex gap-2 mb-2">
                      <Form.Control
                        type="text"
                        value={term}
                        onChange={e => handleTermChange(index, e.target.value)}
                        placeholder={`Masukan ketentuan tambahan ${index + 1}`}
                      />
                      <Button
                        variant="primary"
                        onClick={() => handleRemoveTerm(index)}
                        className="px-3"
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </Button>
                    </div>
                  ))}
                  <Button
                    variant="outline-primary"
                    onClick={handleAddTerm}
                    className="w-100 mt-2"
                  >
                    + Tambah Ketentuan
                  </Button>
                </Form.Group>
              </>
            )}

            {/* Grup Checkbox */}
            {checkboxGroups.map((group, index) => (
              <div key={group.id}>
                <hr style={{ borderWidth: '1px', borderColor: '#9FA6BC' }} />
                <div>
                  <Form.Label className="mb-2 p-0 fs-8 text-capitalize d-flex justify-content-between align-items-center">
                    {`Grup Checkbox ${index + 1}`}
                    <Button
                      variant="link"
                      className="text-dark p-0 rounded-circle"
                      onClick={() => handleRemoveCheckboxGroup(group.id)}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </Button>
                  </Form.Label>

                  <div className="d-flex gap-4 mb-3">
                    <Form.Check
                      type="checkbox"
                      id={`required-${group.id}`}
                      label="Checkbox wajib isi"
                      checked={group.isRequired}
                      onChange={e =>
                        handleCheckboxGroupChange(
                          group.id,
                          'isRequired',
                          e.target.checked
                        )
                      }
                    />
                    <Form.Check
                      type="checkbox"
                      id={`columns-${group.id}`}
                      label="Tampilkan dalam 2 kolom"
                      checked={group.showInTwoColumns}
                      onChange={e =>
                        handleCheckboxGroupChange(
                          group.id,
                          'showInTwoColumns',
                          e.target.checked
                        )
                      }
                    />
                  </div>
                </div>

                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                    Judul Checkbox <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukkan judul checkbox"
                    value={group.title}
                    onChange={e =>
                      handleCheckboxGroupChange(
                        group.id,
                        'title',
                        e.target.value
                      )
                    }
                  />
                </Form.Group>

                <div className="mb-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <span className="fs-8">Default</span>
                      <span className="ms-4 fs-8">Isi Pilihan</span>
                    </div>
                  </div>

                  {group.options.map(option => (
                    <div
                      key={option.id}
                      className="d-flex gap-3 align-items-center mb-2"
                    >
                      <Form.Check
                        type="checkbox"
                        checked={option.isDefault}
                        onChange={e =>
                          handleOptionChange(
                            group.id,
                            option.id,
                            'isDefault',
                            e.target.checked
                          )
                        }
                      />
                      <Form.Control
                        type="text"
                        placeholder="Masukan pilihan"
                        value={option.text}
                        onChange={e =>
                          handleOptionChange(
                            group.id,
                            option.id,
                            'text',
                            e.target.value
                          )
                        }
                      />
                      <Button
                        variant="outline-primary"
                        onClick={() => handleRemoveOption(group.id, option.id)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </Button>
                    </div>
                  ))}
                </div>

                <Button
                  variant="outline-primary"
                  className="w-100"
                  onClick={() => handleAddOption(group.id)}
                >
                  + Tambah Pilihan
                </Button>
              </div>
            ))}

            <hr style={{ borderWidth: '1px', borderColor: '#9FA6BC' }} />
            <Button
              variant="primary"
              style={{ width: '30%' }}
              onClick={handleAddCheckboxGroup}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Tambah Checkbox
            </Button>
          </div>

          {/* Tombol Batalkan dan Simpan */}
          <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
            <Button
              variant="link"
              style={{ color: 'red' }}
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button onClick={handleSave}>Simpan</Button>
          </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title as="h3" className="mb-4">
          Kebijakan{' '}
          <FontAwesomeIcon
            icon={faPencilAlt}
            className="fs-8 ms-2"
            style={{ cursor: 'pointer' }}
            onClick={() => setIsEditing(true)}
          />
        </Card.Title>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <span className="text-muted d-block fw-bold">
              Izinkan Pemformatan
            </span>
            <span>{data.isFormattingAllowed ? 'Iya' : 'Tidak'}</span>
          </Col>
          <Col sm={12} md={6}>
            <span className="text-muted d-block fw-bold">
              Rekam Tanda Tangan Praktisi
            </span>
            <span>
              {data.allowPractitionerSignatureRecording ? 'Iya' : 'Tidak'}
            </span>
          </Col>
        </Row>

        <span className="text-muted d-block fw-bold">
          Isi Persetujuan (Consent)
        </span>
        {data.isFormattingAllowed ? (
          <div className="border rounded p-3">
            {renderHtmlContent(data.contentOfAgreement)}
          </div>
        ) : (
          <div className="border rounded p-3">
            <span>{data.contentOfAgreement}</span>
          </div>
        )}

        <div className="mt-4">
          <span className="text-muted d-block fw-bold mb-2">
            Ketentuan Tambahan
          </span>
          <Table hover>
            <thead>
              <tr className="fs-9">
                <th className="ps-0">ISI KETENTUAN TAMBAHAN</th>
              </tr>
            </thead>
            <tbody className="fs-9">
              {data.additionalProvision?.map((provision, index) => (
                <tr key={index}>
                  <td className="ps-0">{provision}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="mt-4">
          <span className="text-muted d-block fw-bold">
            Checkbox Persetujuan (Consent)
          </span>
          <div className="p-2">
            <Table hover>
              <thead>
                <tr className="fs-9">
                  <th style={{ width: '40px' }}></th>
                  <th className="ps-0">JUDUL CHECKBOX</th>
                  <th>JUMLAH PILIHAN</th>
                  <th>WAJIB ISI</th>
                  <th>TAMPILKAN DALAM 2 KOLOM</th>
                </tr>
              </thead>
              <tbody className="fs-9">
                {data.clinicalServiceCheckboxAgreement.map(checkbox => (
                  <tr key={checkbox.id}>
                    <td>
                      <div style={{ cursor: 'grab' }}>
                        <FontAwesomeIcon
                          icon={faGripVertical}
                          className="text-muted"
                        />
                      </div>
                    </td>
                    <td className="ps-0">{checkbox.title}</td>
                    <td>
                      {checkbox.clinicalServiceCheckboxAgreementOption.length}
                    </td>
                    <td>{checkbox.isCheckboxRequired ? 'Iya' : 'Tidak'}</td>
                    <td>{checkbox.displayIn2Columns ? 'Iya' : 'Tidak'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default KebijakanCard;
