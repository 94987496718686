import { useState, useEffect } from 'react';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import {
  useGetEmployeeByIdQuery,
  useUpdateEmergencyContactMutation
} from 'api/employeeApi';
import { showCustomAlert } from 'helpers/showCustomAlert';

const KontakDaruratPegawaiCard = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: employeeData,
    isLoading,
    refetch
  } = useGetEmployeeByIdQuery(id!);
  const [updateEmergencyContact] = useUpdateEmergencyContactMutation();
  const [isEditing, setIsEditing] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [originalFormData, setOriginalFormData] = useState<any[]>([]);

  // Update state untuk mendukung multiple kontak
  const [formDataList, setFormDataList] = useState<
    Array<{
      id?: string;
      namaKontakDarurat: string;
      emergencyPhone: string;
      statusHubungan: string;
      terhubungWA: boolean;
      alamatDarurat: string;
    }>
  >([]);

  // Load data saat komponen dimount
  useEffect(() => {
    if (employeeData?.data) {
      const mappedContacts = employeeData.data.emergencyContacts.map(
        contact => ({
          id: contact.id,
          namaKontakDarurat: contact.contactName || '',
          emergencyPhone: contact.phoneNumber || '',
          statusHubungan: contact.relationshipStatus || '',
          terhubungWA: contact.isWhatsapp || false,
          alamatDarurat: contact.address || ''
        })
      );
      setFormDataList(mappedContacts);
    }
  }, [employeeData?.data]);

  // Handle edit click
  const handleEditClick = () => {
    setOriginalFormData(JSON.parse(JSON.stringify(formDataList)));
    setIsEditing(true);
  };

  // Handle tambah kontak darurat
  const handleAddContact = () => {
    setFormDataList([
      ...formDataList,
      {
        namaKontakDarurat: '',
        emergencyPhone: '',
        statusHubungan: '',
        terhubungWA: false,
        alamatDarurat: ''
      }
    ]);
  };

  // Handle save
  const handleSave = () => {
    showCustomAlert({
      icon: 'question',
      label: 'Simpan Perubahan',
      deskripsi: 'Anda yakin ingin menyimpan perubahan?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const payload = formDataList.map(contact => ({
            ...(contact.id && { id: contact.id }), // Hanya sertakan id jika ada
            contactName: contact.namaKontakDarurat,
            phoneNumber: contact.emergencyPhone,
            relationshipStatus: contact.statusHubungan,
            isWhatsapp: contact.terhubungWA,
            address: contact.alamatDarurat
          }));

          await updateEmergencyContact({
            id: id!,
            data: payload
          }).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data kontak darurat berhasil diperbarui',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
              refetch();
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: error?.data?.message || 'Terjadi kesalahan pada server.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  // Handle cancel dengan konfirmasi
  const handleCancel = () => {
    const currentData = JSON.stringify(formDataList);
    const originalData = JSON.stringify(originalFormData);

    const hasChanges = currentData !== originalData;

    if (!hasChanges) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Batalkan Perubahan',
      deskripsi: 'Anda yakin ingin membatalkan perubahan?',
      buttonType: 'yes-no',
      onConfirm: () => {
        setFormDataList(JSON.parse(JSON.stringify(originalFormData)));
        setIsEditing(false);
      }
    });
  };

  // Tambahkan fungsi handleInputChange
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    index: number
  ) => {
    const { name, value } = e.target;
    const newFormDataList = [...formDataList];

    if (name === 'terhubungWA') {
      newFormDataList[index] = {
        ...newFormDataList[index],
        terhubungWA: value === 'true'
      };
    } else {
      newFormDataList[index] = {
        ...newFormDataList[index],
        [name]: value
      };
    }

    setFormDataList(newFormDataList);
  };

  if (isLoading) return <div>Loading...</div>;
  if (!employeeData?.data) return <div>No employee data found</div>;

  return (
    <Card>
      <Card.Body>
        {/* Judul dan Tombol Edit */}
        <Row className="mb-2">
          <Col xs={12} className="d-flex align-items-center">
            <h3>Kontak Darurat</h3>
            {!isEditing && (
              <Button variant="ghost" size="sm" onClick={handleEditClick}>
                <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
              </Button>
            )}
          </Col>
        </Row>

        {/* Mode tampilan */}
        {!isEditing && (
          <>
            {employeeData.data.emergencyContacts.map((contact, index) => (
              <div key={contact.id}>
                {index > 0 && <hr className="my-3 border border-dashed" />}
                <Row className="mb-2">
                  <Col xs={12} md={12} className="d-flex flex-column pe-3">
                    <span className="fw-bold text-muted fs-7 fw-bold">
                      Kontak Darurat {index + 1}
                    </span>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={12} md={12} className="d-flex flex-column pe-3">
                    <span className="fw-bold text-muted">Alamat</span>
                    <span className="fw-normal">{contact.address || '-'}</span>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={12} md={12} className="d-flex flex-column pe-3">
                    <span className="fw-bold text-muted">
                      Nama Kontak Darurat
                    </span>
                    <span className="fw-normal">
                      {contact.contactName || '-'}
                    </span>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={12} md={12} className="d-flex flex-column pe-3">
                    <span className="fw-bold text-muted">Status Hubungan</span>
                    <span className="fw-normal">
                      {contact.relationshipStatus || '-'}
                    </span>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={12} md={12} className="d-flex flex-column pe-3">
                    <span className="fw-bold text-muted">Nomor Telepon</span>
                    <span className="fw-normal">
                      {contact.phoneNumber ? (
                        <a
                          href={`https://wa.me/${contact.phoneNumber}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {contact.phoneNumber}
                        </a>
                      ) : (
                        '-'
                      )}
                    </span>
                  </Col>
                </Row>
              </div>
            ))}
          </>
        )}

        {/* Mode edit */}
        {isEditing && (
          <>
            {formDataList.map((formData, index) => (
              <div key={index}>
                {index > 0 && <hr className="my-4" />}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="mb-0 fs-8 text-capitalize">
                    Kontak Darurat {index + 1}
                  </h2>
                  {index > 0 && (
                    <Button
                      variant="link"
                      className="text-danger p-0"
                      onClick={() => {
                        const newFormDataList = [...formDataList];
                        newFormDataList.splice(index, 1);
                        setFormDataList(newFormDataList);
                      }}
                    >
                      Hapus Kontak Darurat
                    </Button>
                  )}
                </div>

                <Row className="mb-3">
                  <Col xs={12} md={12}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Nama Kontak Darurat
                      </Form.Label>
                      <Form.Control
                        name="namaKontakDarurat"
                        value={formData.namaKontakDarurat}
                        onChange={e => handleInputChange(e, index)}
                        placeholder="Masukkan nama kontak darurat"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={12} md={12}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Nomor Telepon
                      </Form.Label>
                      <Form.Control
                        name="emergencyPhone"
                        value={formData.emergencyPhone}
                        onChange={e => handleInputChange(e, index)}
                        placeholder="Masukkan nomor telepon"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={12} md={8}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Status Hubungan
                      </Form.Label>
                      <Form.Select
                        name="statusHubungan"
                        value={formData.statusHubungan}
                        onChange={e => handleInputChange(e, index)}
                      >
                        <option value="">Pilih status hubungan</option>
                        <option value="family">Keluarga</option>
                        <option value="friend">Teman</option>
                        <option value="colleague">Rekan Kerja</option>
                        <option value="other">Lainnya</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Terhubung WhatsApp
                      </Form.Label>
                      <div className="d-flex gap-2 pt-1">
                        <Form.Check
                          type="radio"
                          name={`terhubungWA-${index}`}
                          label="Ya"
                          value="true"
                          checked={formData.terhubungWA === true}
                          onChange={e => handleInputChange(e, index)}
                        />
                        <Form.Check
                          type="radio"
                          name={`terhubungWA-${index}`}
                          label="Tidak"
                          value="false"
                          checked={formData.terhubungWA === false}
                          onChange={e => handleInputChange(e, index)}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={12} md={12}>
                    <Form.Group>
                      <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                        Alamat
                      </Form.Label>
                      <Form.Control
                        name="alamatDarurat"
                        value={formData.alamatDarurat}
                        onChange={e => handleInputChange(e, index)}
                        placeholder="Masukkan alamat"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            ))}

            <Button
              variant="outline-primary"
              className="mt-2 w-100"
              onClick={handleAddContact}
            >
              + Tambah Kontak Darurat
            </Button>

            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button onClick={handleSave}>Simpan</Button>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default KontakDaruratPegawaiCard;
