/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Nav,
  Tab,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBill,
  faFileExport,
  faTrash,
  faUndo,
  faPlus,
  faFileLines,
  faMinus,
  faPaperclip,
  faImage,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { showCustomAlert } from 'helpers/showCustomAlert';
import TinymceEditor from 'components/base/TinymceEditor';
import Select from 'react-select';
import { Editor as TinyMCEEditor } from 'tinymce';
import { useCreatePolicyMutation } from 'api/serviceApi';
import { PolicyCreateRequest } from 'api/request/PolicyCreateRequest';

interface TambahKebijakanModalProps {
  show: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
}

interface PriceRow {
  id: string;
  title: string;
  duration: string;
  durationUnit: string;
  customerType: string;
  location: string;
  price: string;
}

interface RatedPriceRow {
  id: string;
  title: string;
  rate: string;
  minDuration: string;
  maxDuration: string;
  customerType: string;
  location: string;
  price: string;
}

interface StaffRow {
  id: string;
  name: string;
  position: string;
  additionalFee: string;
}

const TambahKebijakanModal = ({
  show,
  handleClose,
  onSuccess
}: TambahKebijakanModalProps) => {
  const [activeTab, setActiveTab] = useState('kebijakan');
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    status: '',
    createdDate: '',
    startDate: '',
    endDate: '',
    type: 'Layanan',
    allowRefund: false,
    allowFormating: false,
    allowSignature: false,
    content: ''
  });

  // Ubah initial state menjadi array kosong
  const initialCheckboxGroups: {
    id: string;
    title: string;
    isRequired: boolean;
    showInTwoColumns: boolean;
    options: Array<{
      id: string;
      text: string;
      isDefault: boolean;
    }>;
  }[] = [];

  const [checkboxGroups, setCheckboxGroups] = useState(initialCheckboxGroups);

  const [paymentForm, setPaymentForm] = useState({
    depositAmount: '0.00',
    depositUnit: 'PERCENTAGE' as 'PERCENTAGE' | 'RUPIAH',
    depositSecurityAmount: '0.00',
    depositSecurityUnit: 'PERCENTAGE' as 'PERCENTAGE' | 'RUPIAH',
    depositOnlineAmount: '0.00',
    depositOnlineUnit: 'PERCENTAGE' as 'PERCENTAGE' | 'RUPIAH'
  });

  const [additionalTerms, setAdditionalTerms] = useState<string[]>([]);

  const [formattedContent, setFormattedContent] = useState('');
  const [plainContent, setPlainContent] = useState('');

  const [refundForm, setRefundForm] = useState({
    refundMethods: [] as string[],
    refundDeadline: '0',
    refundDeadlineUnit: 'MINUTES_BEFORE_BOOKING' as
      | 'MINUTES_BEFORE_BOOKING'
      | 'HOURS_BEFORE_BOOKING'
      | 'DAYS_BEFORE_BOOKING',
    onTimeCancellationPercentage: '0',
    lateCancellationPercentage: '0'
  });

  const refundMethodOptions = [
    { value: 'HANYA_TRANSFER', label: 'Hanya Transfer' },
    { value: 'HANYA_CASH', label: 'Hanya Cash' },
    { value: 'HANYA_CREDIT_VOUCHER', label: 'Hanya Credit/Voucher' },
    { value: 'ALL', label: 'Semua Metode' }
  ];

  const [isFormDirty, setIsFormDirty] = useState(false);

  // Tambahkan state untuk errors
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Tambahkan initial state untuk semua form
  const initialFormData = {
    name: '',
    description: '',
    status: '',
    createdDate: '',
    startDate: '',
    endDate: '',
    type: 'Layanan',
    allowRefund: false,
    allowFormating: false,
    allowSignature: false,
    content: ''
  };

  const initialPaymentForm = {
    depositAmount: '0.00',
    depositUnit: 'PERCENTAGE' as 'PERCENTAGE' | 'RUPIAH',
    depositSecurityAmount: '0.00',
    depositSecurityUnit: 'PERCENTAGE' as 'PERCENTAGE' | 'RUPIAH',
    depositOnlineAmount: '0.00',
    depositOnlineUnit: 'PERCENTAGE' as 'PERCENTAGE' | 'RUPIAH'
  };

  const initialRefundForm = {
    refundMethods: [] as string[],
    refundDeadline: '0',
    refundDeadlineUnit: 'MINUTES_BEFORE_BOOKING' as
      | 'MINUTES_BEFORE_BOOKING'
      | 'HOURS_BEFORE_BOOKING'
      | 'DAYS_BEFORE_BOOKING',
    onTimeCancellationPercentage: '0',
    lateCancellationPercentage: '0'
  };

  // Tambahkan state untuk menyimpan nilai backup
  const [backupFormValues, setBackupFormValues] = useState({
    paymentForm: initialPaymentForm,
    refundForm: initialRefundForm
  });

  const [createPolicy] = useCreatePolicyMutation();

  // Modifikasi fungsi resetForm untuk menggunakan initialCheckboxGroups
  const resetForm = () => {
    setFormData(initialFormData);
    setPaymentForm(initialPaymentForm);
    setRefundForm(initialRefundForm);
    setCheckboxGroups(initialCheckboxGroups); // Gunakan initial state yang baru
    setAdditionalTerms([]);
    setFormattedContent('');
    setPlainContent('');
    setActiveTab('kebijakan');
    setErrors({});
    setIsFormDirty(false);
  };

  // Tambahkan fungsi untuk mengecek duplikat judul checkbox
  const hasDuplicateCheckboxTitles = () => {
    const titles = checkboxGroups.map(group =>
      group.title.trim().toLowerCase()
    );
    return titles.length !== new Set(titles).size;
  };

  // Modifikasi fungsi validateForm
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!formData.name.trim()) newErrors.name = 'Nama kebijakan wajib diisi';
    if (!formData.status) newErrors.status = 'Status wajib diisi';
    if (!formData.createdDate)
      newErrors.createdDate = 'Tanggal dibuat wajib diisi';
    if (!formData.startDate)
      newErrors.startDate = 'Tanggal mulai berlaku wajib diisi';

    // Validasi judul checkbox yang duplikat
    if (hasDuplicateCheckboxTitles()) {
      checkboxGroups.forEach(group => {
        const duplicates = checkboxGroups.filter(
          g => g.title.trim().toLowerCase() === group.title.trim().toLowerCase()
        );
        if (duplicates.length > 1) {
          newErrors[`checkboxTitle_${group.id}`] =
            'Judul checkbox tidak boleh sama';
        }
      });
    }

    // Validasi judul checkbox kosong
    checkboxGroups.forEach(group => {
      if (!group.title.trim()) {
        newErrors[`checkboxTitle_${group.id}`] = 'Judul checkbox wajib diisi';
      }
    });

    // Validasi content
    if (!formData.content.trim()) {
      newErrors.content = 'Isi persetujuan wajib diisi';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Modifikasi useEffect yang memantau perubahan formData.type
  useEffect(() => {
    if (formData.type === 'Subscription') {
      // Backup nilai form saat ini sebelum direset
      setBackupFormValues({
        paymentForm: { ...paymentForm },
        refundForm: { ...refundForm }
      });

      // Reset form ke nilai default untuk subscription
      setPaymentForm({
        depositAmount: '0.00',
        depositUnit: 'PERCENTAGE' as 'PERCENTAGE' | 'RUPIAH',
        depositSecurityAmount: '0.00',
        depositSecurityUnit: 'PERCENTAGE' as 'PERCENTAGE' | 'RUPIAH',
        depositOnlineAmount: '0.00',
        depositOnlineUnit: 'PERCENTAGE' as 'PERCENTAGE' | 'RUPIAH'
      });

      setRefundForm({
        refundMethods: [],
        refundDeadline: '0',
        refundDeadlineUnit: 'MINUTES_BEFORE_BOOKING' as
          | 'MINUTES_BEFORE_BOOKING'
          | 'HOURS_BEFORE_BOOKING'
          | 'DAYS_BEFORE_BOOKING',
        onTimeCancellationPercentage: '0',
        lateCancellationPercentage: '0'
      });

      setActiveTab('kebijakan');

      setFormData(prev => ({
        ...prev,
        allowRefund: false
      }));
    } else {
      // Kembalikan nilai yang di-backup jika kembali ke tipe Layanan
      setPaymentForm(backupFormValues.paymentForm);
      setRefundForm(backupFormValues.refundForm);
    }
  }, [formData.type]);

  useEffect(() => {
    if (formData.allowFormating) {
      setFormData(prev => ({
        ...prev,
        content: formattedContent
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        content: plainContent
      }));
    }
  }, [formData.allowFormating]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Kebijakan akan disimpan',
      deskripsi: 'Apakah Anda yakin data kebijakan ini sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const requestData: PolicyCreateRequest = {
            policyType: formData.type as 'Layanan' | 'Subscription',
            name: formData.name,
            description: formData.description,
            isRefundAllowed:
              formData.type === 'Subscription' ? false : formData.allowRefund,
            status: formData.status === 'active',
            createdDate: formData.createdDate,
            startDate: formData.startDate,
            endDate: formData.endDate || undefined,
            clinicalServicePolicy: {
              isFormattingAllowed: formData.allowFormating,
              allowPractitionerSignatureRecording: formData.allowSignature,
              additionalProvision: additionalTerms.filter(
                term => term.trim() !== ''
              ),
              contentOfAgreement: formData.content,
              clinicalServiceCheckboxAgreement: checkboxGroups.map(group => ({
                isCheckboxRequired: group.isRequired,
                displayIn2Columns: group.showInTwoColumns,
                title: group.title,
                clinicalServiceCheckboxAgreementOption: group.options.map(
                  opt => ({
                    content: opt.text,
                    selectedChoice: opt.isDefault
                  })
                )
              }))
            },
            ClinicalServiceDeposit:
              formData.type === 'Subscription'
                ? {
                    offlineDepositAmount: 0,
                    onlineDepositAmount: 0,
                    securityDepositAmount: 0,
                    cancellationBefore: 0,
                    onTimeCancellationPercentage: 0,
                    lateCancellationPercentage: 0,
                    offlineDepositAmountType: 'PERCENTAGE',
                    onlineDepositAmountType: 'PERCENTAGE',
                    securityDepositAmountType: 'PERCENTAGE',
                    daysCancellation: 'MINUTES_BEFORE_BOOKING'
                  }
                : {
                    offlineDepositAmount: Number(paymentForm.depositAmount),
                    onlineDepositAmount: Number(
                      paymentForm.depositOnlineAmount
                    ),
                    securityDepositAmount: Number(
                      paymentForm.depositSecurityAmount
                    ),
                    cancellationBefore: Number(refundForm.refundDeadline),
                    onTimeCancellationPercentage: Number(
                      refundForm.onTimeCancellationPercentage
                    ),
                    lateCancellationPercentage: Number(
                      refundForm.lateCancellationPercentage
                    ),
                    offlineDepositAmountType: paymentForm.depositUnit,
                    onlineDepositAmountType: paymentForm.depositOnlineUnit,
                    securityDepositAmountType: paymentForm.depositSecurityUnit,
                    daysCancellation: refundForm.refundDeadlineUnit
                  },
            clinicalServiceRefund:
              formData.type === 'Subscription'
                ? {
                    refundPaymentMethod: [],
                    refundDaysBeforeBooking: 0,
                    refundDaysBeforeBookingType: 'MINUTES_BEFORE_BOOKING'
                  }
                : {
                    refundPaymentMethod: refundForm.refundMethods,
                    refundDaysBeforeBooking: Number(refundForm.refundDeadline),
                    refundDaysBeforeBookingType: refundForm.refundDeadlineUnit
                  }
          };

          await createPolicy(requestData).unwrap();

          if (onSuccess) {
            onSuccess();
          }

          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Kebijakan baru berhasil disimpan',
            buttonType: 'ok',
            onConfirm: () => {
              resetForm();
              handleClose();
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          // Cek apakah error adalah duplikat nama kebijakan atau judul checkbox
          if (error?.data?.message?.includes('Duplicate entry for name')) {
            showCustomAlert({
              icon: 'error',
              label: 'Terdapat nama kebijakan yang sama',
              deskripsi: 'Mohon ubah nama kebijakan yang akan Anda tambahkan',
              buttonType: 'ok'
            });
          } else if (
            error?.data?.message?.includes('Duplicate entry for title')
          ) {
            showCustomAlert({
              icon: 'error',
              label: 'Terdapat judul checkbox yang sama',
              deskripsi: 'Mohon ubah judul checkbox yang akan Anda tambahkan',
              buttonType: 'ok'
            });
          } else {
            showCustomAlert({
              icon: 'error',
              label: 'Gagal Menyimpan data',
              deskripsi: 'Terjadi kesalahan saat menyimpan data',
              buttonType: 'ok'
            });
          }
        }
      }
    });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setIsFormDirty(true);
    const target = e.target as HTMLInputElement;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Tambahkan logika untuk mengubah tab ketika checkbox allowRefund di-uncheck
    if (name === 'allowRefund' && !value && activeTab === 'pengembalian') {
      setActiveTab('kebijakan');
    }
  };

  const handleEditorChange = (content: string) => {
    setFormattedContent(content);
    setFormData(prev => ({
      ...prev,
      content
    }));
  };

  const handlePlainContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPlainContent(value);
    setFormData(prev => ({
      ...prev,
      content: value
    }));
  };

  const handleAddOption = (groupId: string) => {
    setCheckboxGroups(prev =>
      prev.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            options: [
              ...group.options,
              { id: Date.now().toString(), text: '', isDefault: false }
            ]
          };
        }
        return group;
      })
    );
  };

  const handleRemoveOption = (groupId: string, optionId: string) => {
    setCheckboxGroups(prev =>
      prev.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            options: group.options.filter(opt => opt.id !== optionId)
          };
        }
        return group;
      })
    );
  };

  const handleCheckboxGroupChange = (
    groupId: string,
    field: string,
    value: string | boolean
  ) => {
    setCheckboxGroups(prev => {
      const updatedGroups = prev.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            [field]: value
          };
        }
        return group;
      });

      // Cek duplikat judul secara real-time
      if (field === 'title') {
        const newErrors = { ...errors };

        // Hapus semua error checkbox title yang lama
        Object.keys(newErrors).forEach(key => {
          if (key.startsWith('checkboxTitle_')) {
            delete newErrors[key];
          }
        });

        // Cek duplikat
        updatedGroups.forEach(group => {
          const duplicates = updatedGroups.filter(
            g =>
              g.id !== group.id &&
              g.title.trim().toLowerCase() ===
                group.title.trim().toLowerCase() &&
              group.title.trim() !== ''
          );

          if (duplicates.length > 0) {
            newErrors[`checkboxTitle_${group.id}`] =
              'Judul checkbox tidak boleh sama';
            duplicates.forEach(dupGroup => {
              newErrors[`checkboxTitle_${dupGroup.id}`] =
                'Judul checkbox tidak boleh sama';
            });
          }
        });

        setErrors(newErrors);
      }

      return updatedGroups;
    });
  };

  const handleOptionChange = (
    groupId: string,
    optionId: string,
    field: string,
    value: string | boolean
  ) => {
    setCheckboxGroups(prev =>
      prev.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            options: group.options.map(opt => {
              if (opt.id === optionId) {
                return {
                  ...opt,
                  [field]: value
                };
              }
              return opt;
            })
          };
        }
        return group;
      })
    );
  };

  const handlePaymentChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setPaymentForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddTerm = () => {
    setAdditionalTerms([...additionalTerms, '']);
  };

  const handleRemoveTerm = (index: number) => {
    const newTerms = additionalTerms.filter((_, i) => i !== index);
    setAdditionalTerms(newTerms);
  };

  const handleTermChange = (index: number, value: string) => {
    const newTerms = [...additionalTerms];
    newTerms[index] = value;
    setAdditionalTerms(newTerms);
  };

  // Modifikasi handleAddCheckboxGroup untuk menambah grup baru
  const handleAddCheckboxGroup = () => {
    setCheckboxGroups(prev => {
      const newGroups = [...prev];
      const newId = (newGroups.length + 1).toString();
      return [
        ...newGroups,
        {
          id: newId,
          title: '',
          isRequired: false,
          showInTwoColumns: false,
          options: [
            { id: '1', text: '', isDefault: true },
            { id: '2', text: '', isDefault: false }
          ]
        }
      ];
    });
  };

  const handleRemoveCheckboxGroup = (groupId: string) => {
    setCheckboxGroups(prev => {
      const filteredGroups = prev.filter(group => group.id !== groupId);
      // Reorder remaining groups
      return filteredGroups.map((group, index) => ({
        ...group,
        id: (index + 1).toString()
      }));
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRefundMethodChange = (selected: any) => {
    setRefundForm(prev => ({
      ...prev,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      refundMethods: selected.map((item: any) => item.value)
    }));
  };

  // Modifikasi handleCancel
  const handleCancel = () => {
    const isFormFilled =
      formData.name.trim() !== '' ||
      formData.content.trim() !== '' ||
      formData.type !== 'Layanan' ||
      formData.status !== '';

    if (isFormFilled) {
      showCustomAlert({
        icon: 'warning',
        label: 'Kebijakan tidak akan disimpan',
        deskripsi: 'Apakah Anda yakin untuk membatalkan proses ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          resetForm();
          handleClose();
        }
      });
    } else {
      resetForm();
      handleClose();
    }
  };

  // Modifikasi handleSaveAndNew
  const handleSaveAndNew = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Kebijakan akan disimpan',
      deskripsi: 'Apakah Anda yakin data kebijakan ini sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          // Implementasi submit form
          if (onSuccess) {
            onSuccess();
          }

          // Reset form dan tutup modal
          resetForm();
          handleClose();

          // Tampilkan pesan sukses dan buka modal baru
          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Kebijakan baru berhasil disimpan',
            buttonType: 'ok',
            onConfirm: () => {
              // Trigger parent untuk membuka modal baru
              if (onSuccess) {
                onSuccess();
              }
            }
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal Menyimpan data',
            deskripsi: 'Terjadi kesalahan saat menyimpan data',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  // Modifikasi konfigurasi TinyMCE
  const tinyConfig = {
    height: '300px',
    placeholder: 'Masukkan isi kebijakan...',
    plugins: [
      'advlist',
      'autolink',
      'lists',
      'link',
      'image',
      'charmap',
      'preview',
      'anchor',
      'searchreplace',
      'visualblocks',
      'fullscreen',
      'insertdatetime',
      'media',
      'table',
      'help',
      'wordcount'
    ],
    toolbar: [
      'undo redo | styles | fontsize | bold italic underline strikethrough | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist | outdent indent | ' +
        'table link image | removeformat | fullscreen'
    ],
    /* enable title field in the Image dialog */
    image_title: true,
    /* enable automatic uploads of images represented by blob or data URIs*/
    automatic_uploads: true,
    file_picker_types: 'image',
    /* and here's our custom image picker */
    file_picker_callback: (
      cb: (url: string, obj?: { title: string }) => void,
      value: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      meta: Record<string, any>
    ) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      input.addEventListener('change', e => {
        const file = (e.target as HTMLInputElement).files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const id = 'blobid' + new Date().getTime();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const blobCache = (window as any).tinymce.activeEditor.editorUpload
            .blobCache;
          const base64 = (reader.result as string).split(',')[1];
          const blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          cb(blobInfo.blobUri(), { title: file.name });
        });
        reader.readAsDataURL(file);
      });

      input.click();
    },
    content_style: `
      body { font-family:Arial,sans-serif; font-size:11pt }
      .tox-toolbar, 
      .tox-toolbar__overflow,
      .tox-toolbar__primary,
      .tox-toolbar-overlord,
      .tox-editor-header { 
        background: none !important;
        background-color: #EFF2F6 !important;
      }
    `
  };

  const handleRefundDeadlineUnitChange = (value: string) => {
    setRefundForm(prev => ({
      ...prev,
      refundDeadlineUnit: value as
        | 'MINUTES_BEFORE_BOOKING'
        | 'HOURS_BEFORE_BOOKING'
        | 'DAYS_BEFORE_BOOKING'
    }));
  };

  return (
    <>
      <style>
        {`
          /* Style untuk modal utama TambahKebijakan */
          .modal-dialog-centered.tambah-kebijakan {
            max-width: 1600px !important;
            width: 95% !important;
          }

          /* Style untuk vertical divider */
          .vertical-divider {
            position: absolute;
            left: 41.666667%;
            top: 0;
            bottom: 0;
            width: 1px;
            background-color: #c5c5c5;
            transform: translateX(-50%);
            z-index: 1;
            opacity: 1;
          }
        `}
      </style>
      <Modal
        show={show}
        onHide={handleCancel}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
        dialogClassName="tambah-kebijakan"
        style={{ zIndex: 1050 }} // Pastikan lebih rendah dari z-index TinyMCE
      >
        <Modal.Header className="px-5 py-4">
          <Modal.Title className="me-auto fw-bolder fs-6">
            Tambah Kebijakan
          </Modal.Title>
          <div className="d-flex gap-2">
            <Button
              variant="link"
              className="text-danger"
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button
              variant="outline-primary"
              type="button"
              onClick={handleSaveAndNew}
            >
              Simpan & Buat Baru
            </Button>
            <Button variant="primary" type="submit" form="addPolicyForm">
              Simpan
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body className="bg-light px-5 py-4 position-relative">
          <Form
            id="addPolicyForm"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <div className="vertical-divider" />
            <Row>
              <Col md={5} className="d-flex flex-column gap-3">
                <div>
                  <h4 className="mb-3">Informasi Kebijakan</h4>

                  {/* Tipe Kebijakan */}
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Tipe Kebijakan
                    </Form.Label>
                    <div className="d-flex gap-3">
                      <Form.Check
                        type="radio"
                        id="layanan"
                        name="type"
                        label="Layanan"
                        value="Layanan"
                        checked={formData.type === 'Layanan'}
                        onChange={handleChange}
                        isInvalid={!!errors.type}
                      />
                      <Form.Check
                        type="radio"
                        id="subscription"
                        name="type"
                        label="Subscription"
                        value="Subscription"
                        checked={formData.type === 'Subscription'}
                        onChange={handleChange}
                        isInvalid={!!errors.type}
                      />
                    </div>
                    {errors.type && (
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: 'block' }}
                      >
                        {errors.type}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  {/* Nama Kebijakan */}
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Nama Kebijakan <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                      placeholder="Masukkan nama kebijakan"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Deskripsi Kebijakan */}
                  <Form.Group controlId="description" className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Deskripsi Kebijakan
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder="Masukan deskripsi kebijakan"
                    />
                  </Form.Group>

                  {/* Kebijakan Pemesanan - hanya tampil jika tipe Layanan */}
                  {formData.type === 'Layanan' && (
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                        Kebijakan Pemesanan
                      </Form.Label>
                      <div className="d-flex flex-column">
                        <Form.Check
                          type="checkbox"
                          id="allowRefund"
                          label="Izinkan pengembalian dana"
                          name="allowRefund"
                          checked={formData.allowRefund}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  )}

                  {/* Status */}
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Status <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Pilih status</option>
                      <option value="active">Aktif</option>
                      <option value="inactive">Tidak Aktif</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Tanggal Dibuat */}
                  <Form.Group controlId="createdDate" className="mb-3">
                    <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                      Tanggal Dibuat <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="createdDate"
                      value={formData.createdDate}
                      onChange={handleChange}
                      isInvalid={!!errors.createdDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.createdDate}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Row className="mb-3">
                    <Col>
                      <Form.Group controlId="startDate">
                        <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                          Tanggal Mulai Berlaku{' '}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="startDate"
                          value={formData.startDate}
                          onChange={handleChange}
                          isInvalid={!!errors.startDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.startDate}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="endDate">
                        <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                          Tanggal berakhir
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          value={formData.endDate}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={7}>
                <Tab.Container
                  activeKey={activeTab}
                  onSelect={k => k && setActiveTab(k)}
                >
                  <Nav
                    variant="tabs"
                    className="mb-3 d-flex justify-content-between flex-wrap gap-3"
                  >
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'kebijakan'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('kebijakan')}
                      >
                        <FontAwesomeIcon icon={faFileLines} className="me-2" />
                        Kebijakan
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'deposit'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('deposit')}
                        disabled={formData.type === 'Subscription'}
                      >
                        <FontAwesomeIcon icon={faMoneyBill} className="me-2" />
                        Deposit
                      </Button>
                    </Nav.Item>
                    <Nav.Item className="flex-fill">
                      <Button
                        size="sm"
                        className="rounded-pill fs-10 w-100"
                        variant={
                          activeTab === 'pengembalian'
                            ? 'primary'
                            : 'outline-primary'
                        }
                        onClick={() => setActiveTab('pengembalian')}
                        disabled={
                          formData.type === 'Subscription' ||
                          !formData.allowRefund
                        }
                      >
                        <FontAwesomeIcon icon={faUndo} className="me-2" />
                        Pengembalian Dana
                      </Button>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="kebijakan">
                      <div className="d-flex flex-column">
                        <h4 className="mb-3 mt-2">Kebijakan</h4>

                        {/* Isi Persetujuan (Consent) */}
                        <Form.Group className="mb-1">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Isi Persetujuan (Consent){' '}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <div className="d-flex gap-4">
                            <Form.Check
                              className="m-0"
                              type="checkbox"
                              id="allowFormating"
                              label="Izinkan pemformatan"
                              name="allowFormating"
                              onChange={handleChange}
                            />
                            <Form.Check
                              className="m-0"
                              type="checkbox"
                              id="allowSignature"
                              label="Rekam tanda tangan praktisi"
                              name="allowSignature"
                              onChange={handleChange}
                            />
                          </div>
                        </Form.Group>
                        {!formData.allowFormating ? (
                          <>
                            <Form.Group className="mb-3 mt-2">
                              <Form.Control
                                type="text"
                                name="content"
                                value={plainContent}
                                onChange={handlePlainContentChange}
                                placeholder="Masukkan consent"
                                isInvalid={!!errors.content}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.content}
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* Ketentuan Tambahan */}
                            <Form.Group>
                              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                                Ketentuan Tambahan
                              </Form.Label>
                              {additionalTerms.map((term, index) => (
                                <div key={index} className="d-flex gap-2 mb-2">
                                  <Form.Control
                                    type="text"
                                    value={term}
                                    onChange={e =>
                                      handleTermChange(index, e.target.value)
                                    }
                                    placeholder={`Masukan ketentuan tambahan ${
                                      index + 1
                                    }`}
                                  />
                                  <Button
                                    variant="primary"
                                    onClick={() => handleRemoveTerm(index)}
                                    className="px-3"
                                  >
                                    -
                                  </Button>
                                </div>
                              ))}
                              <Button
                                variant="outline-primary"
                                onClick={handleAddTerm}
                                className="w-100 mt-2"
                              >
                                + Tambah Ketentuan
                              </Button>
                            </Form.Group>
                          </>
                        ) : (
                          <Form.Group>
                            <div className="d-flex flex-column">
                              <TinymceEditor
                                value={formattedContent}
                                onChange={handleEditorChange}
                                options={{
                                  ...tinyConfig,
                                  placeholder: 'Masukkan isi kebijakan...'
                                }}
                              />
                              {errors.content && (
                                <div
                                  className="text-danger mt-1"
                                  style={{ fontSize: '0.875em' }}
                                >
                                  {errors.content}
                                </div>
                              )}
                            </div>
                          </Form.Group>
                        )}
                        {/* Grup Checkbox */}
                        {checkboxGroups.map(group => (
                          <div key={group.id}>
                            <hr
                              style={{
                                borderWidth: '1px',
                                borderColor: '#9FA6BC'
                              }}
                            />
                            <div>
                              <Form.Label className="mb-2 p-0 fs-8 text-capitalize d-flex justify-content-between align-items-center">
                                {`Grup Checkbox ${parseInt(group.id)}`}
                                <Button
                                  variant="link"
                                  className="text-dark p-0 rounded-circle"
                                  onClick={() =>
                                    handleRemoveCheckboxGroup(group.id)
                                  }
                                >
                                  <FontAwesomeIcon icon={faXmark} />
                                </Button>
                              </Form.Label>

                              <div className="d-flex gap-4 mb-3">
                                <Form.Check
                                  type="checkbox"
                                  id={`required-${group.id}`}
                                  label="Checkbox wajib isi"
                                  checked={group.isRequired}
                                  onChange={e =>
                                    handleCheckboxGroupChange(
                                      group.id,
                                      'isRequired',
                                      e.target.checked
                                    )
                                  }
                                />
                                <Form.Check
                                  type="checkbox"
                                  id={`columns-${group.id}`}
                                  label="Tampilkan dalam 2 kolom"
                                  checked={group.showInTwoColumns}
                                  onChange={e =>
                                    handleCheckboxGroupChange(
                                      group.id,
                                      'showInTwoColumns',
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <Form.Group className="mb-3">
                              <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                                Judul Checkbox{' '}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Masukkan judul checkbox"
                                value={group.title}
                                onChange={e =>
                                  handleCheckboxGroupChange(
                                    group.id,
                                    'title',
                                    e.target.value
                                  )
                                }
                                isInvalid={
                                  !!errors[`checkboxTitle_${group.id}`]
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors[`checkboxTitle_${group.id}`]}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <div className="mb-3">
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <div>
                                  <span className="fs-8">Default</span>
                                  <span className="ms-4 fs-8">Isi Pilihan</span>
                                </div>
                                <div></div>
                              </div>

                              {group.options.map(option => (
                                <div
                                  key={option.id}
                                  className="d-flex gap-3 align-items-center mb-2"
                                >
                                  <Form.Check
                                    type="checkbox"
                                    checked={option.isDefault}
                                    onChange={e =>
                                      handleOptionChange(
                                        group.id,
                                        option.id,
                                        'isDefault',
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <Form.Control
                                    type="text"
                                    placeholder="Masukan pilihan"
                                    value={option.text}
                                    onChange={e =>
                                      handleOptionChange(
                                        group.id,
                                        option.id,
                                        'text',
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Button
                                    variant="outline-primary"
                                    className=""
                                    onClick={() =>
                                      handleRemoveOption(group.id, option.id)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </Button>
                                </div>
                              ))}
                            </div>

                            <Button
                              variant="outline-primary"
                              className="w-100"
                              onClick={() => handleAddOption(group.id)}
                            >
                              + Tambah Pilihan
                            </Button>
                          </div>
                        ))}
                        <hr
                          style={{ borderWidth: '1px', borderColor: '#9FA6BC' }}
                        />
                        <Button
                          variant="primary"
                          style={{ width: '30%' }}
                          onClick={handleAddCheckboxGroup}
                        >
                          Tambah Checkbox +
                        </Button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="deposit">
                      <div className="d-flex flex-column">
                        <h4 className="mb-3 mt-2">Kebijakan Deposit</h4>

                        {/* Jumlah Deposit Offline */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Jumlah Deposit pada Pelanggan Offline saat Melakukan
                            Booking
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="depositAmount"
                              onChange={handlePaymentChange}
                              placeholder="0.00"
                              min="0"
                              step="0.01"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="depositUnit"
                              value={paymentForm.depositUnit}
                              onChange={handlePaymentChange}
                              style={{ width: '30%' }}
                            >
                              <option value="Percentage">
                                Persen Harga Jual (%)
                              </option>
                              <option value="Rupiah">Rupiah (Rp)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Jumlah Deposit Online */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Jumlah Deposit pada Pelanggan Online saat Melakukan
                            Booking
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="depositOnlineAmount"
                              onChange={handlePaymentChange}
                              placeholder="0.00"
                              min="0"
                              step="0.01"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="depositOnlineUnit"
                              value={paymentForm.depositOnlineUnit}
                              onChange={handlePaymentChange}
                              style={{ width: '30%' }}
                            >
                              <option value="PERCENTAGE">
                                Persen Harga Jual (%)
                              </option>
                              <option value="RUPIAH">Rupiah (Rp)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Jumlah Deposit Keamanan */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Jumlah Deposit Keamanan yang Harus Dibayar saat
                            Melakukan Booking
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="depositSecurityAmount"
                              placeholder="0.00"
                              onChange={handlePaymentChange}
                              min="0"
                              step="0.01"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="depositSecurityUnit"
                              value={paymentForm.depositSecurityUnit}
                              onChange={handlePaymentChange}
                              style={{ width: '30%' }}
                            >
                              <option value="Percentage">
                                Persen Harga Jual (%)
                              </option>
                              <option value="Rupiah">Rupiah (Rp)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Kebijakan Pengembalian Deposit */}
                        <h4 className="mb-3 mt-4">
                          Kebijakan Pengembalian Deposit
                        </h4>

                        {/* Batas Waktu Pengajuan */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Pelanggan Harus Mengisi Data Ajuan Pembatalan Jadwal
                            Sebelum
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="refundDeadline"
                              placeholder="0"
                              onChange={e =>
                                setRefundForm(prev => ({
                                  ...prev,
                                  refundDeadline: e.target.value
                                }))
                              }
                              min="0"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="refundDeadlineUnit"
                              value={refundForm.refundDeadlineUnit}
                              onChange={e =>
                                handleRefundDeadlineUnitChange(e.target.value)
                              }
                              style={{ width: '30%' }}
                            >
                              <option value="MINUTES_BEFORE_BOOKING">
                                Menit Sebelum Waktu Booking
                              </option>
                              <option value="HOURS_BEFORE_BOOKING">
                                Jam Sebelum Waktu Booking
                              </option>
                              <option value="DAYS_BEFORE_BOOKING">
                                Hari Sebelum Waktu Booking
                              </option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Persentase Deposit yang Ditahan bila Dibatalkan Tepat Waktu */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Persentase Deposit yang Ditahan bila Dibatalkan
                            Tepat Waktu
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="onTimeCancellationPercentage"
                              onChange={e =>
                                setRefundForm(prev => ({
                                  ...prev,
                                  onTimeCancellationPercentage: e.target.value
                                }))
                              }
                              placeholder="0"
                              min="0"
                              style={{ width: '70%' }}
                            />
                            <Form.Select style={{ width: '30%' }} disabled>
                              <option>Persen Jumlah Deposit (%)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {/* Persentase Deposit yang Ditahan bila Dibatalkan Terlambat */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Persentase Deposit yang Ditahan bila Dibatalkan
                            Terlambat
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="lateCancellationPercentage"
                              onChange={e =>
                                setRefundForm(prev => ({
                                  ...prev,
                                  lateCancellationPercentage: e.target.value
                                }))
                              }
                              placeholder="0"
                              min="0"
                              style={{ width: '70%' }}
                            />
                            <Form.Select style={{ width: '30%' }} disabled>
                              <option>Persen Jumlah Deposit (%)</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="pengembalian">
                      <div className="d-flex flex-column">
                        <h4 className="mb-3 mt-2">
                          Kebijakan Pengembalian Dana
                        </h4>

                        {/* Metode Pembayaran */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Metode Pembayaran Pengembalian Dana
                          </Form.Label>
                          <Select
                            isMulti
                            options={refundMethodOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={{
                              placeholder: provided => ({
                                ...provided,
                                fontSize: '12px'
                              }),
                              option: provided => ({
                                ...provided,
                                fontSize: '12px'
                              }),
                              control: provided => ({
                                ...provided,
                                fontSize: '12px'
                              }),
                              multiValue: provided => ({
                                ...provided,
                                fontSize: '12px'
                              }),
                              multiValueLabel: provided => ({
                                ...provided,
                                fontSize: '12px'
                              })
                            }}
                            onChange={handleRefundMethodChange}
                            value={refundMethodOptions.filter(option =>
                              refundForm.refundMethods.includes(option.value)
                            )}
                            placeholder="Pilih metode pembayaran"
                          />
                        </Form.Group>

                        {/* Batas Waktu Pengajuan */}
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                            Pelanggan Harus Mengisi Data Ajuan Pengembalian Dana
                            Sebelum
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="refundDeadline"
                              placeholder="0"
                              onChange={e =>
                                setRefundForm(prev => ({
                                  ...prev,
                                  refundDeadline: e.target.value
                                }))
                              }
                              min="0"
                              style={{ width: '70%' }}
                            />
                            <Form.Select
                              name="refundDeadlineUnit"
                              value={refundForm.refundDeadlineUnit}
                              onChange={e =>
                                handleRefundDeadlineUnitChange(e.target.value)
                              }
                              style={{ width: '30%' }}
                            >
                              <option value="MINUTES_BEFORE_BOOKING">
                                Menit Sebelum Waktu Booking
                              </option>
                              <option value="HOURS_BEFORE_BOOKING">
                                Jam Sebelum Waktu Booking
                              </option>
                              <option value="DAYS_BEFORE_BOOKING">
                                Hari Sebelum Waktu Booking
                              </option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TambahKebijakanModal;
