import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileLines,
  faMoneyBill,
  faTrash,
  faUndo
} from '@fortawesome/free-solid-svg-icons';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { detailKebijakanBreadcrumbItems } from 'data/commonData';
import { useGetPolicyByIdQuery, useDeletePolicyMutation } from 'api/serviceApi';
import { showCustomAlert } from 'helpers/showCustomAlert';
import KebijakanCard from 'components/cards/KebijakanCard';
import DepositCard from 'components/cards/DepositCard';
import PengembalianDanaCard from 'components/cards/PengembalianDanaCard';
import InformasiDasarKebijakanCard from 'components/cards/InformasiDasarKebijakanCard';

const KebijakanDetails = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {
    data: policyData,
    isLoading,
    refetch
  } = useGetPolicyByIdQuery(id || '');
  const [deletePolicy, { isLoading: isDeleting }] = useDeletePolicyMutation();

  const [visibleCards, setVisibleCards] = useState({
    kebijakan: true,
    deposit: true,
    pengembalianDana: true
  });

  const toggleCard = (cardName: keyof typeof visibleCards) => {
    setVisibleCards(prev => ({
      ...prev,
      [cardName]: !prev[cardName]
    }));
  };

  const handleDeletePolicy = () => {
    showCustomAlert({
      icon: 'warning',
      label: 'Kebijakan akan dihapus!',
      deskripsi: 'Apakah Anda yakin untuk menghapus kebijakan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await deletePolicy([id || '']).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Kebijakan berhasil dihapus',
            buttonType: 'ok',
            onConfirm: () => {
              navigate('/layanan/kebijakan');
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          const errorMessage =
            error.data?.message || 'Gagal menghapus kebijakan';
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: errorMessage,
            buttonType: 'ok'
          });
        }
      }
    });
  };

  if (isLoading) {
    return (
      <div className="text-center py-4">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <PageBreadcrumb items={detailKebijakanBreadcrumbItems} />
      <Row className="d-flex align-items-center justify-content-between g-3 mb-4">
        <Col xs="auto">
          <h2 className="mb-0">Detail Kebijakan</h2>
        </Col>
        <Col xs="auto" className="d-flex gap-3 flex-wrap p-0">
          <Button
            variant="outline-danger"
            size="sm"
            onClick={handleDeletePolicy}
            disabled={isDeleting}
          >
            <FontAwesomeIcon icon={faTrash} className="me-2" />
            {isDeleting ? 'Menghapus...' : 'Hapus Kebijakan'}
          </Button>
        </Col>
      </Row>
      <Row className="g-5">
        {/* Kolom Kiri - Informasi Dasar */}
        <Col xs={12} sm={12} md={3}>
          {policyData?.data && (
            <InformasiDasarKebijakanCard
              data={policyData.data.basicInformation}
              onUpdate={updatedData => {
                console.log('Updated basic info:', updatedData);
              }}
            />
          )}
        </Col>

        {/* Kolom Kanan - Cards */}
        <Col xs={12} sm={12} md={9}>
          {/* Toggle Buttons dengan style baru */}
          <div className="d-flex gap-3 mb-2 w-100">
            {Object.entries({
              kebijakan: {
                label: 'Kebijakan',
                icon: faFileLines,
                disabled: false
              },
              deposit: {
                label: 'Deposit',
                icon: faMoneyBill,
                disabled:
                  policyData?.data.basicInformation?.policyType ===
                  'Subscription'
              },
              pengembalianDana: {
                label: 'Pengembalian Dana',
                icon: faUndo,
                disabled:
                  policyData?.data.basicInformation?.policyType ===
                    'Subscription' ||
                  !policyData?.data.basicInformation.isRefundAllowed
              }
            }).map(([key, { label, icon, disabled }]) => (
              <div
                key={key}
                className={`form-check border rounded-pill px-3 py-2 mt-1 flex-fill text-center ${
                  visibleCards[key as keyof typeof visibleCards]
                    ? 'border-primary bg-primary'
                    : 'border-secondary'
                }`}
                style={{
                  cursor: disabled ? 'not-allowed' : 'pointer',
                  opacity: disabled ? 0.5 : 1
                }}
                onClick={() =>
                  !disabled && toggleCard(key as keyof typeof visibleCards)
                }
              >
                <input
                  type="checkbox"
                  className="form-check-input d-none"
                  id={key}
                  name={key}
                  checked={visibleCards[key as keyof typeof visibleCards]}
                  disabled={disabled}
                  onChange={() => {}}
                />
                <label
                  className={`form-check-label mb-0 w-100 ${
                    visibleCards[key as keyof typeof visibleCards]
                      ? 'text-white'
                      : 'text-secondary'
                  }`}
                  style={{
                    cursor: disabled ? 'not-allowed' : 'pointer',
                    userSelect: 'none'
                  }}
                >
                  <FontAwesomeIcon icon={icon} className="me-2" />
                  {label}
                </label>
              </div>
            ))}
          </div>

          {/* Cards */}
          <div className="d-flex flex-column gap-4 mb-4">
            {visibleCards.kebijakan && policyData?.data && (
              <KebijakanCard
                data={policyData.data.basicPolicy}
                onUpdate={updatedData => {
                  console.log('Updated data:', updatedData);
                }}
              />
            )}

            {(policyData?.data.basicInformation?.policyType === 'Layanan' ||
              policyData?.data.basicInformation?.policyType === undefined) && (
              <>
                {visibleCards.deposit && policyData?.data && (
                  <DepositCard
                    data={policyData.data.policyDeposit}
                    onUpdate={updatedData => {
                      console.log('Updated deposit data:', updatedData);
                    }}
                  />
                )}

                {visibleCards.pengembalianDana &&
                  policyData?.data.basicInformation.isRefundAllowed && (
                    <PengembalianDanaCard
                      data={{
                        ...policyData.data.refundPolicy,
                        refundPaymentMethod: Array.isArray(
                          policyData.data.refundPolicy.refundPaymentMethod
                        )
                          ? policyData.data.refundPolicy.refundPaymentMethod
                          : policyData.data.refundPolicy.refundPaymentMethod
                              .split(',')
                              .map(s => s.trim())
                      }}
                      id={policyData.data.basicInformation.id}
                      refetch={refetch}
                      onUpdate={updatedData => {
                        console.log('Updated refund data:', updatedData);
                      }}
                    />
                  )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default KebijakanDetails;
