import {
  useGetServiceByIdQuery,
  useCreateServiceNoteMutation,
  useUpdateServiceNoteMutation,
  useDeleteServiceNoteMutation
} from 'api/serviceApi';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { useState, useEffect } from 'react';
import { ButtonGroup, Card, Form, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faPencilAlt,
  faXmark,
  faFloppyDisk
} from '@fortawesome/free-solid-svg-icons';

interface NoteProps {
  id: string;
  note: string;
  date: string;
  type: string;
  isLast?: boolean;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  onEdit: (id: string, content: string, type: string) => void;
  onUpdate: (id: string, content: string, type: string) => void;
  onDelete: (id: string) => void;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month}, ${year}`;
};

const Note = ({
  id,
  note,
  date,
  type,
  isLast,
  isEditing,
  setIsEditing,
  onUpdate,
  onDelete
}: NoteProps) => {
  const [editedNote, setEditedNote] = useState(note);
  const [editedType, setEditedType] = useState(type);

  const handleSave = () => {
    onUpdate(id, editedNote, editedType);
  };

  const handleCancel = () => {
    const hasChanges = editedNote !== note || editedType !== type;

    if (hasChanges) {
      showCustomAlert({
        icon: 'question',
        label: 'Batalkan Perubahan',
        deskripsi: 'Apakah Anda yakin ingin membatalkan perubahan?',
        buttonType: 'yes-no',
        onConfirm: () => {
          setEditedNote(note);
          setEditedType(type);
          setIsEditing(false);
        }
      });
    } else {
      setIsEditing(false);
    }
  };

  return (
    <div
      className={classNames('fs-9 fw-semibold position-relative', {
        'border-bottom border-dashed pb-3 mb-4': !isLast
      })}
    >
      {isEditing ? (
        <>
          <div className="position-relative mb-3">
            <Form.Control
              as="textarea"
              value={editedNote}
              onChange={e => setEditedNote(e.target.value)}
            />
            {editedNote && (
              <Button
                variant="link"
                className="position-absolute top-0 end-0 text-secondary p-2"
                onClick={() => setEditedNote('')}
              >
                <FontAwesomeIcon icon={faXmark} />
              </Button>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex align-items-center gap-2 bg-success bg-opacity-10 rounded px-2 py-1">
                <span className="text-success">UMUM</span>
                <Form.Check
                  type="radio"
                  name="noteType"
                  checked={editedType === 'public'}
                  onChange={() => setEditedType('public')}
                  className="text-success fw-bold custom-radio-as-checkbox"
                />
              </div>
              <div className="d-flex align-items-center gap-2 bg-primary bg-opacity-10 rounded px-2 py-1">
                <span className="text-primary">PRIBADI</span>
                <Form.Check
                  type="radio"
                  name="noteType"
                  checked={editedType === 'private'}
                  onChange={() => setEditedType('private')}
                  className="text-primary fw-bold custom-radio-as-checkbox"
                />
              </div>
            </div>
            <div className="d-flex">
              <Button
                size="sm"
                variant="link"
                className="text-danger"
                onClick={handleCancel}
              >
                <FontAwesomeIcon icon={faXmark} className="me-2" />
                Batalkan
              </Button>
              <Button
                size="sm"
                variant="link"
                onClick={handleSave}
                className="pe-0"
              >
                <FontAwesomeIcon icon={faFloppyDisk} className="me-2" />
                Simpan
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <p className="text-body-highlight mb-3">{note}</p>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2">
              <p className="text-body-tertiary text-opacity-85 mb-0">
                {formatDate(date)}
              </p>
              <span
                className={`badge ${
                  type === 'private'
                    ? 'bg-primary-subtle text-primary'
                    : 'bg-success-subtle text-success'
                }`}
              >
                {type === 'private' ? 'PRIBADI' : 'UMUM'}
              </span>
            </div>
            <div className="d-flex gap-2">
              <FontAwesomeIcon
                icon={faPencilAlt}
                className="cursor-pointer text-primary"
                onClick={() => {
                  setIsEditing(true);
                }}
              />
              <FontAwesomeIcon
                icon={faTrash}
                className="cursor-pointer text-danger"
                onClick={() => onDelete(id)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const ServiceNotesCard = ({ className }: { className?: string }) => {
  const { id } = useParams<{ id: string }>();
  const { data: serviceData, isLoading, refetch } = useGetServiceByIdQuery(id!);
  const [createNote] = useCreateServiceNoteMutation();
  const [updateNote] = useUpdateServiceNoteMutation();
  const [deleteServiceNote] = useDeleteServiceNoteMutation();

  const [notes, setNotes] = useState<
    Array<{ id: string; content: string; type: string; createdDate: string }>
  >([]);
  const [newNote, setNewNote] = useState('');
  const [noteType, setNoteType] = useState('private');
  const [editingNoteId, setEditingNoteId] = useState<string | null>(null);

  useEffect(() => {
    if (serviceData?.data?.clinicalServiceNote) {
      const sortedNotes = [...serviceData.data.clinicalServiceNote]
        .reverse()
        .map(note => ({
          id: note.id,
          content: note.content,
          type: note.noteType,
          createdDate: note.createdDate || new Date().toISOString()
        }));
      setNotes(sortedNotes);
    }
  }, [serviceData]);

  const handleAddNote = async () => {
    if (newNote.trim() === '') {
      showCustomAlert({
        icon: 'error',
        label: 'Error',
        deskripsi: 'Catatan tidak boleh kosong!',
        buttonType: 'ok'
      });
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Tambah Catatan',
      deskripsi: 'Apakah Anda yakin ingin menambahkan catatan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await createNote({
            clinicalServiceId: id!,
            content: newNote,
            noteType: noteType
          }).unwrap();

          setNewNote('');
          refetch();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Catatan berhasil ditambahkan!',
            buttonType: 'ok'
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal menambahkan catatan',
            deskripsi: 'Terjadi kesalahan saat menambahkan catatan.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleNoteTypeChange = (type: string) => {
    setNoteType(type);
  };

  const handleEdit = (id: string) => {
    setEditingNoteId(id);
  };

  const handleUpdate = async (id: string, content: string, type: string) => {
    showCustomAlert({
      icon: 'question',
      label: 'Simpan Perubahan',
      deskripsi: 'Apakah Anda yakin ingin menyimpan perubahan catatan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await updateNote({
            id,
            data: {
              content,
              noteType: type
            }
          }).unwrap();

          refetch();
          setEditingNoteId(null);

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Catatan berhasil diperbarui!',
            buttonType: 'ok'
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Error',
            deskripsi: 'Gagal memperbarui catatan. Silakan coba lagi.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleDelete = async (noteId: string) => {
    showCustomAlert({
      icon: 'question',
      label: 'Hapus Catatan',
      deskripsi: 'Apakah Anda yakin ingin menghapus catatan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await deleteServiceNote(noteId).unwrap();
          refetch();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Catatan berhasil dihapus!',
            buttonType: 'ok'
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Error',
            deskripsi: 'Gagal menghapus catatan. Silakan coba lagi.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  if (isLoading) {
    return (
      <Card className={classNames(className)}>
        <Card.Body className="text-center">
          <Spinner animation="border" />
          <p>Loading...</p>
        </Card.Body>
      </Card>
    );
  }

  if (!serviceData?.data) {
    return (
      <Card className={classNames(className)}>
        <Card.Body>
          <p className="text-danger">Gagal memuat data. Silakan coba lagi.</p>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className={classNames(className)}>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-start">
          <h3 className="mb-4">Catatan untuk Layanan</h3>
          <ButtonGroup>
            <Button
              variant={noteType === 'private' ? 'primary' : 'outline-primary'}
              onClick={() => handleNoteTypeChange('private')}
            >
              Pribadi
            </Button>
            <Button
              variant={noteType === 'public' ? 'primary' : 'outline-primary'}
              onClick={() => handleNoteTypeChange('public')}
            >
              Umum
            </Button>
          </ButtonGroup>
        </div>

        <div className="position-relative mb-3">
          <Form.Control
            as="textarea"
            rows={4}
            value={newNote}
            onChange={e => setNewNote(e.target.value)}
            placeholder="Tulis catatan baru di sini..."
          />
          {newNote && (
            <Button
              variant="link"
              className="position-absolute top-0 end-0 text-secondary p-2"
              onClick={() => setNewNote('')}
            >
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          )}
        </div>

        <Button
          variant="primary"
          className="w-100 mb-4"
          onClick={handleAddNote}
        >
          Tambah Catatan
        </Button>

        {notes.map((note, index) => (
          <Note
            key={note.id}
            id={note.id}
            note={note.content}
            date={note.createdDate}
            type={note.type}
            isLast={index === notes.length - 1}
            isEditing={editingNoteId === note.id}
            setIsEditing={value => {
              setEditingNoteId(value ? note.id : null);
            }}
            onEdit={handleEdit}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

export default ServiceNotesCard;
