/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  useGetServiceByIdQuery,
  useGetBookingSettingsQuery,
  useUpdateBookingSettingsMutation
} from 'api/serviceApi';
import { useParams } from 'react-router-dom';
import { ClinicalServiceResponse } from 'api/response/ServiceResponse';
import { showCustomAlert } from 'helpers/showCustomAlert';
// Sesuaikan dengan lokasi file

const ServiceBookingConfigurationCard = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: serviceData,
    isLoading,
    error
  } = useGetServiceByIdQuery(id || '');

  const { data: bookingSettings } = useGetBookingSettingsQuery();
  const [updateBookingSettings] = useUpdateBookingSettingsMutation();

  // State untuk mode edit
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    policyType: 'default', // 'default' atau 'custom'
    policyId: '',
    isNotedForReasonVisit: false,
    isDiagnosisRecord: false
  });

  // Tambahkan useEffect untuk menginisialisasi formData
  useEffect(() => {
    if (serviceData?.data?.clinicalServiceBookingSetting) {
      const bookingSetting = serviceData.data.clinicalServiceBookingSetting;
      setFormData({
        policyType: bookingSetting.policyId ? 'custom' : 'default',
        policyId: bookingSetting.policyId || '',
        isNotedForReasonVisit: bookingSetting.isNotedForReasonVisit || false,
        isDiagnosisRecord: bookingSetting.isDiagnosisRecord || false
      });
    }
  }, [serviceData]);

  // Handler untuk perubahan form
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  // Fungsi untuk mengecek apakah ada perubahan data
  const hasChanges = () => {
    if (!serviceData?.data?.clinicalServiceBookingSetting) return false;

    const originalData = {
      policyType: 'default',
      policyId: serviceData.data.clinicalServiceBookingSetting.policyId || '',
      isNotedForReasonVisit:
        serviceData.data.clinicalServiceBookingSetting.isNotedForReasonVisit ||
        false,
      isDiagnosisRecord:
        serviceData.data.clinicalServiceBookingSetting.isDiagnosisRecord ||
        false
    };

    return JSON.stringify(originalData) !== JSON.stringify(formData);
  };

  // Handler untuk tombol batalkan
  const handleCancel = () => {
    if (!hasChanges()) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Perubahan data tidak akan disimpan',
      deskripsi: 'Apakah Anda yakin untuk membatalkan proses perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        // Reset form data ke nilai awal
        if (serviceData?.data?.clinicalServiceBookingSetting) {
          setFormData({
            policyType: 'default',
            policyId:
              serviceData.data.clinicalServiceBookingSetting.policyId || '',
            isNotedForReasonVisit:
              serviceData.data.clinicalServiceBookingSetting
                .isNotedForReasonVisit || false,
            isDiagnosisRecord:
              serviceData.data.clinicalServiceBookingSetting
                .isDiagnosisRecord || false
          });
        }
        setIsEditing(false);
      }
    });
  };

  // Handler untuk tombol simpan
  const handleSave = async () => {
    showCustomAlert({
      icon: 'question',
      label: 'Perubahan data akan disimpan',
      deskripsi:
        'Apakah Anda yakin dengan perubahan data yang Anda lakukan sudah benar?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await updateBookingSettings({
            id: id || '',
            data: {
              policyName:
                formData.policyType === 'default'
                  ? 'Default Policy'
                  : bookingSettings?.find(p => p.id === formData.policyId)
                      ?.policyName,
              isNotedForReasonVisit: formData.isNotedForReasonVisit,
              isDiagnosisRecord: formData.isDiagnosisRecord,
              isDefault: formData.policyType === 'default'
            }
          }).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Pengaturan booking berhasil disimpan',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
            }
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Error',
            deskripsi: 'Gagal menyimpan pengaturan booking',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  if (isLoading) {
    return (
      <Card className="mb-3">
        <Card.Body>
          <div className="text-center">Memuat data...</div>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="mb-3">
        <Card.Body>
          <div className="text-center text-danger">Gagal memuat data</div>
        </Card.Body>
      </Card>
    );
  }

  const bookingConfigurationData =
    serviceData?.data?.clinicalServiceBookingSetting;

  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="mb-4">
          <Col xs={12} className="d-flex align-items-center">
            <h3>Pengaturan Booking</h3>
            {!isEditing && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsEditing(true)}
              >
                <FontAwesomeIcon icon={faPencil} className="h-4 w-4" />
              </Button>
            )}
          </Col>
        </Row>

        {isEditing ? (
          // Mode Edit
          <>
            <div className="d-flex flex-column">
              {/* Kebijakan */}
              <div className="mb-2">
                <Form.Label className="mb-2 p-0 fs-8 text-capitalize">
                  Kebijakan
                </Form.Label>
                <div className="d-flex gap-3">
                  <Form.Check
                    type="radio"
                    id="default-policy"
                    name="policyType"
                    label="Kebijakan Default"
                    value="default"
                    checked={formData.policyType === 'default'}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    id="custom-policy"
                    name="policyType"
                    label="Kebijakan Kustom"
                    value="custom"
                    checked={formData.policyType === 'custom'}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Dropdown Pilih Kebijakan */}
              <Form.Group className="mb-3">
                <Form.Select
                  name="policyId"
                  value={formData.policyId}
                  onChange={e =>
                    setFormData(prev => ({ ...prev, policyId: e.target.value }))
                  }
                  disabled={formData.policyType === 'default'}
                >
                  <option value="">Pilih Kebijakan</option>
                  {bookingSettings?.map(policy => (
                    <option key={policy.id} value={policy.id}>
                      {policy.policyName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              {/* Checkbox Options */}
              <div className="mb-2">
                <Form.Check
                  type="checkbox"
                  id="isNotedForReasonVisit"
                  name="isNotedForReasonVisit"
                  label="Catat alasan kunjungan"
                  checked={formData.isNotedForReasonVisit}
                  onChange={handleChange}
                />
              </div>

              <div>
                <Form.Check
                  type="checkbox"
                  id="isDiagnosisRecord"
                  name="isDiagnosisRecord"
                  label="Rekam Diagnosa"
                  checked={formData.isDiagnosisRecord}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Tombol Batalkan dan Simpan */}
            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button onClick={handleSave}>Simpan</Button>
            </div>
          </>
        ) : (
          // Mode View
          <>
            <Row className="mb-3">
              <Col xs={12} md={6} className="d-flex flex-column">
                <span className="fw-bold text-muted">Kebijakan</span>
                <span className="fw-normal">
                  {bookingConfigurationData?.policyName || '-'}
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={6} className="d-flex flex-column">
                <span className="fw-bold text-muted">
                  Catat alasan kunjungan
                </span>
                <span className="fw-normal">
                  {bookingConfigurationData?.isNotedForReasonVisit !== undefined
                    ? bookingConfigurationData.isNotedForReasonVisit
                      ? 'Ya'
                      : 'Tidak'
                    : '-'}
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={6} className="d-flex flex-column">
                <span className="fw-bold text-muted">Diagnosa</span>
                <span className="fw-normal">
                  {bookingConfigurationData?.isDiagnosisRecord !== undefined
                    ? bookingConfigurationData.isDiagnosisRecord
                      ? 'Ya'
                      : 'Tidak'
                    : '-'}
                </span>
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ServiceBookingConfigurationCard;
