import { useState } from 'react';
import { toast } from 'react-toastify';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useRefreshHandler = (refetch: () => Promise<any>) => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      const result = await refetch();

      if ('error' in result) {
        toast.error('Gagal memperbarui data');
        return;
      }

      if (result.isSuccess) {
        toast.success('Data berhasil diperbarui');
      }
    } catch (error) {
      toast.error('Gagal memperbarui data');
      console.error('Refresh error:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  return { isRefreshing, handleRefresh };
};

export default useRefreshHandler;
