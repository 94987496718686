/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  faChevronDown,
  faFileExport,
  // faPlus,
  faTrash,
  faUndo,
  faFilter,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import JabatanTable, {
  jabatanTableColumns
} from 'components/tables/jabatanTable';
import { daftarJabatanBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Dropdown } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import DownloadTableModal from 'components/modals/DownloadTableModal';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import {
  useDeleteEmployeeMutation,
  useGetEmployeeQuery
} from 'api/employeeApi';
import DeleteEmployeeModal from 'components/modals/DeleteEmployeeModal';
import TambahJabatanModal from 'components/modals/TambahJabatanModal';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { PositionResponse } from 'api/response/PositionResponse';
import {
  useGetAllPositionsQuery,
  useDeletePositionMutation
} from 'api/positionApi';
import DeleteCustomerModal from 'components/modals/DeleteCustomerModal';
import { debounce } from 'lodash';
import useRefreshHandler from 'hooks/useRefreshHandler';

const DaftarJabatan = () => {
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTambahJabatanModal, setShowTambahJabatanModal] = useState(false);

  // Gunakan query untuk mengambil data jabatan
  const {
    data: positionData,
    refetch,
    isFetching
  } = useGetAllPositionsQuery({
    page,
    limit: pageSize,
    orderBy: 'id',
    direction: 'ASC',
    name: searchQuery
  });

  // console.log('Employee Data:', employeeData);
  // console.log('Is Loading:', isLoading);
  // console.log('Is Error:', isError);
  // console.log('Error:', error);

  const table = useAdvanceTable<PositionResponse>({
    data: positionData?.data?.result || [],
    columns: jabatanTableColumns,
    pageSize,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      pagination: {
        pageSize,
        pageIndex: page - 1
      }
    }
  });

  const { isRefreshing, handleRefresh } = useRefreshHandler(refetch);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    table.setPageIndex(newPage - 1);
  };

  // const handleShowModal = () => {
  //   // Implementasi untuk menampilkan modal tambah jabatan
  // };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;

    // Prevent default form submission
    e.preventDefault();

    setSearchQuery(query);

    // Optional: debounce the search to reduce unnecessary API calls
    const debouncedSearch = debounce(() => {
      refetch();
    }, 300);

    debouncedSearch();
  };

  // Add an onKeyDown handler to prevent form submission

  const handleDownload = (format: string) => {
    const doc = new jsPDF(format === 'portrait-pdf' ? 'p' : 'l', 'mm', 'a4');

    // Ambil data dari tabel
    const tableData = table.getRowModel().rows.map(row => {
      const cells = row.getAllCells();
      return cells.map(cell => cell.getValue());
    });

    // Ambil header kolom
    const headers = table
      .getAllColumns()
      .map(column => column.columnDef.header);

    // Generate PDF menggunakan autoTable
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (doc as any).autoTable({
      head: [headers],
      body: tableData,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255 }
    });

    // Download PDF
    doc.save('daftar-jabatan.pdf');
    toast.success('Berhasil mengunduh tabel');
  };

  // const { refetch } = useGetAllPositionsQuery({
  //   page,
  //   limit: pageSize,
  //   orderBy: 'id',
  //   direction: 'ASC'
  // });

  // Tambahkan useEffect untuk memperbarui data saat page berubah
  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    const selectedRowsCount = Object.keys(table.getState().rowSelection).length;
    setSelectedRows(selectedRowsCount);
  }, [table.getState().rowSelection]);

  const [lastToastTime, setLastToastTime] = useState(0);

  useEffect(() => {
    // Check for empty data
    if (positionData && positionData.data.total === 0) {
      const currentTime = new Date().getTime();

      // If more than 3 seconds have passed since the last toast
      if (!lastToastTime || currentTime - lastToastTime >= 3000) {
        toast.info('Data Tidak Ditemukan');

        // Update the last toast time
        setLastToastTime(currentTime);
      }
    }
  }, [positionData, lastToastTime]);

  // First, import the delete mutation
  const [deleteEmployee, { isLoading: isDeleting }] =
    useDeleteEmployeeMutation();

  const [deletePosition, { isLoading: isDeletingPosition }] =
    useDeletePositionMutation();

  const handleConfirmDelete = async (reason: string, notes: string) => {
    const selectedRows = table.getSelectedRowModel().rows;
    const hasEmployees = selectedRows.some(
      row => (row.original.totalOfEmployee ?? 0) > 0
    );

    showCustomAlert({
      icon: 'warning',
      label: 'Jabatan akan dihapus!',
      deskripsi: hasEmployees
        ? 'Terdapat pegawai yang memiliki jabatan ini. Apakah Anda yakin untuk menghapus jabatan ini?'
        : 'Apakah Anda yakin untuk menghapus jabatan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const selectedIds = selectedRows.map(row => String(row.original.id));
          const deletedCount = selectedRows.length;

          await deletePosition({
            notes: notes,
            deletionReason: reason,
            data: selectedIds
          }).unwrap();

          // Reset selection
          table.resetRowSelection();
          setSelectedRows(0);
          setShowDeleteModal(false);
          await refetch();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: `${deletedCount} jabatan berhasil dihapus`,
            buttonType: 'ok'
          });
        } catch (error) {
          toast.error('Gagal menghapus jabatan');
          console.error('Delete error:', error);
        }
      }
    });
  };

  const handleDeleteClick = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    if (selectedRows.length === 0) {
      toast.warning('Silakan pilih jabatan yang akan dihapus');
      return;
    }
    setShowDeleteModal(true);
  };

  const handleShowTambahJabatanModal = () => {
    setShowTambahJabatanModal(true);
  };

  const handleCloseTambahJabatanModal = () => {
    setShowTambahJabatanModal(false);
  };

  return (
    <div>
      <PageBreadcrumb items={daftarJabatanBreadcrumbItems} />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="mb-9">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="mb-0">Daftar Jabatan</h2>
        </div>

        <div className="d-flex justify-content-between align-items-center gap-3 mb-4">
          <div className="d-flex gap-2 align-items-center">
            <SearchBox
              placeholder="Cari jabatan"
              style={{ maxWidth: '300px' }}
              onChange={handleSearchInputChange}
              value={searchQuery}
            />
          </div>
          <div className="d-flex align-items-center gap-3">
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={() => setShowDownloadModal(true)}
            >
              <FontAwesomeIcon icon={faFileExport} className="me-2" />
              Unduh tabel
            </Button>
            <Button
              variant="link"
              className="text-secondary p-0"
              onClick={handleRefresh}
              disabled={isRefreshing || isFetching}
            >
              <FontAwesomeIcon
                icon={faUndo}
                className={`me-2 ${
                  isRefreshing || isFetching ? 'fa-spin' : ''
                }`}
              />
              {isRefreshing || isFetching ? 'Memperbarui...' : 'Refresh'}
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                className="text-secondary p-0 dropdown-caret-none"
              >
                Tindakan lainnya
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item>Export Selection</Dropdown.Item>
                <Dropdown.Item>Bulk Edit</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant="outline-danger"
              onClick={handleDeleteClick}
              disabled={selectedRows === 0 || isDeletingPosition}
              size="sm"
            >
              <FontAwesomeIcon icon={faTrash} className="me-2" />
              Hapus Jabatan ({selectedRows})
            </Button>
            {/* <DeleteEmployeeModal
              show={showDeleteModal}
              onHide={() => setShowDeleteModal(false)}
              selectedEmployees={table.getSelectedRowModel().rows.map(row => ({
                name: row.original.names?.fullName || 'Nama tidak tersedia',
                id: row.original.id
              }))}
              onDelete={handleConfirmDelete}
              isDeleting={isDeleting}
            /> */}
            <Button
              variant="primary"
              size="sm"
              onClick={handleShowTambahJabatanModal}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Tambah Jabatan
            </Button>
          </div>
        </div>

        <AdvanceTableProvider {...table}>
          <div style={{ width: '95%' }} className="mx-auto">
            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
              <JabatanTable
                onPageChange={handlePageChange}
                currentPage={page}
                totalPages={positionData?.data?.totalPages || 1}
                positionData={{
                  totalRecords: positionData?.data?.total || 0
                }}
              />
            </div>
          </div>
        </AdvanceTableProvider>
      </div>

      <DownloadTableModal
        show={showDownloadModal}
        onHide={() => setShowDownloadModal(false)}
        onDownload={handleDownload}
        title="Unduh Tabel"
      />

      <TambahJabatanModal
        show={showTambahJabatanModal}
        handleClose={handleCloseTambahJabatanModal}
        onSuccess={() => {
          handleCloseTambahJabatanModal();
          handleRefresh();
        }}
      />

      <DeleteCustomerModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        selectedCustomers={table.getSelectedRowModel().rows.map(row => ({
          name: row.original.name || 'Nama jabatan tidak tersedia',
          totalEmployee: row.original.totalOfEmployee
        }))}
        onDelete={handleConfirmDelete}
        title="Hapus Jabatan"
      />
    </div>
  );
};

export default DaftarJabatan;
