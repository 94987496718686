import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useGetEmployeeTemplateQuery } from 'api/employeeTemplateApi';

export type DefaultCheckboxKeys =
  | 'showFormTemplat'
  | 'showInformasiPekerjaan'
  | 'showLayananJamKerja'
  | 'showPelayanan'
  | 'showPenilaianUlasan'
  | 'showGajiKompensasi'
  | 'showLayanan'
  | 'showJamKerja'
  | 'showLayananTable'
  | 'showUlasan';

interface CheckboxState {
  showFormTemplat: boolean;
  showInformasiPekerjaan: boolean;
  showLayananJamKerja: boolean;
  showPelayanan: boolean;
  showPenilaianUlasan: boolean;
  showGajiKompensasi: boolean;
  showLayanan: boolean;
  showJamKerja: boolean;
  showLayananTable: boolean;
  showUlasan: boolean;
  [key: string]: boolean;
}

interface Props {
  show: boolean;
  onHide: () => void;
  checkboxes: CheckboxState;
  onCheckboxChange: (updatedVisibility: CheckboxState) => void;
}

const CheckboxDetailPegawaiModal = ({
  show,
  onHide,
  checkboxes,
  onCheckboxChange
}: Props) => {
  // State sementara untuk menyimpan perubahan sebelum disimpan
  const [tempCheckboxes, setTempCheckboxes] =
    useState<CheckboxState>(checkboxes);

  const { data: templateData, refetch } = useGetEmployeeTemplateQuery({
    page: 1,
    limit: 10,
    orderBy: 'createdDate',
    direction: 'DESC'
  });

  useEffect(() => {
    if (show) {
      refetch();
    }
  }, [show, refetch]);

  useEffect(() => {
    const initialState = { ...checkboxes };
    templateData?.data?.result.forEach(template => {
      const templateKey = `show_template_${template.id}`;
      if (!(templateKey in initialState)) {
        initialState[templateKey] = false;
      }
    });
    setTempCheckboxes(initialState);
  }, [checkboxes, templateData]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setTempCheckboxes(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleSave = () => {
    onCheckboxChange(tempCheckboxes);
    showCustomAlert({
      icon: 'success',
      label: 'Berhasil',
      deskripsi: 'Pengaturan tampilan berhasil disimpan',
      buttonType: 'ok',
      onConfirm: onHide
    });
  };

  const handleCancel = () => {
    setTempCheckboxes(checkboxes); // Reset ke nilai awal
    onHide();
  };

  return (
    <Modal show={show} onHide={handleCancel} centered>
      <Modal.Header
        closeButton={false}
        className="d-flex justify-content-between p-4"
      >
        <Modal.Title>
          <h4 className="fw-bolder m-0">Opsi Menu Kustom</h4>
        </Modal.Title>
        <div className="d-flex gap-2">
          <Button
            variant="link"
            style={{ color: 'red' }}
            onClick={handleCancel}
          >
            Batalkan
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Simpan
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="showInformasiPekerjaan"
              label="Informasi Pekerjaan"
              checked={tempCheckboxes.showInformasiPekerjaan}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="showLayananJamKerja"
              label="Layanan dan Jam Kerja"
              checked={tempCheckboxes.showLayananJamKerja}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="showPelayanan"
              label="Pelayanan"
              checked={tempCheckboxes.showPelayanan}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="showPenilaianUlasan"
              label="Penilaian dan Ulasan"
              checked={tempCheckboxes.showPenilaianUlasan}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="showFormTemplat"
              label="Form Templat"
              checked={tempCheckboxes.showFormTemplat}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="mt-3">
            <h6>Template:</h6>
            {templateData?.data?.result
              .filter(template => template.status)
              .map(template => (
                <Form.Check
                  key={template.id}
                  type="checkbox"
                  label={template.templateName}
                  name={`show_template_${template.id}`}
                  checked={
                    tempCheckboxes[`show_template_${template.id}`] || false
                  }
                  onChange={handleCheckboxChange}
                />
              ))}
          </div>
        </Form>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="link" onClick={handleCancel} style={{ color: 'red' }}>
          Batalkan
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Simpan
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default CheckboxDetailPegawaiModal;
