import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { PolicyResult } from 'api/response/ServiceResponse';

// Update interface untuk menggunakan data dari API
export type Kebijakan = PolicyResult;

const getStatusBadge = (status: boolean) => {
  return (
    <Badge bg={status ? 'success' : 'danger'} className="fs-10">
      {status ? 'Aktif' : 'Tidak Aktif'}
    </Badge>
  );
};

export const kebijakanTableColumns: ColumnDef<Kebijakan>[] = [
  {
    accessorKey: 'name',
    header: 'Nama Kebijakan',
    cell: ({ row: { original } }) => {
      return (
        <Link
          to={`/layanan/kebijakan/detail-kebijakan/${original.id}`}
          className="d-flex align-items-center text-body-emphasis"
        >
          <p className="mb-0 fw-bold">{original.name}</p>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'description',
    header: 'Deskripsi',
    cell: ({ row: { original } }) => {
      return <span className="fw-semibold">{original.description}</span>;
    },
    meta: {
      headerProps: { style: { width: '30%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  },
  {
    accessorKey: 'startDate',
    header: 'Tanggal Berlaku',
    cell: ({ row: { original } }) => {
      return (
        <span className="fw-semibold">
          {new Date(original.startDate).toLocaleDateString('id-ID', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}
        </span>
      );
    },
    meta: {
      headerProps: { style: { width: '12%' }, className: 'px-3 text-end' },
      cellProps: { className: 'px-3 py-2 text-end' }
    }
  },
  {
    accessorKey: 'endDate',
    header: 'Tanggal Berakhir',
    cell: ({ row: { original } }) => {
      return (
        <span className="fw-semibold">
          {new Date(original.endDate).toLocaleDateString('id-ID', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}
        </span>
      );
    },
    meta: {
      headerProps: { style: { width: '12%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  },
  {
    accessorKey: 'createdDate',
    header: 'Tanggal Dibuat',
    cell: ({ row: { original } }) => {
      return (
        <span className="fw-semibold">
          {new Date(original.createdDate).toLocaleDateString('id-ID', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}
        </span>
      );
    },
    meta: {
      headerProps: { style: { width: '12%' }, className: 'px-3 text-end' },
      cellProps: { className: 'px-3 py-2 text-end' }
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row: { original } }) => {
      return <div className="text-end">{getStatusBadge(original.status)}</div>;
    },
    meta: {
      headerProps: { style: { width: '9%' }, className: 'px-3 text-end' },
      cellProps: { className: 'px-3 py-2' }
    }
  }
];

interface KebijakanTableProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
  kebijakanData?: {
    totalRecords?: number;
  };
}

const KebijakanTable = ({
  onPageChange,
  currentPage,
  totalPages,
  kebijakanData
}: KebijakanTableProps) => {
  return (
    <div>
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
        }}
      />
      <AdvanceTableFooter
        pagination
        className="mt-3"
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalRecords={kebijakanData?.totalRecords || 0}
      />
    </div>
  );
};

export default KebijakanTable;
